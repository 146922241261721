import React, { useEffect } from 'react';
import { MdErrorOutline, MdHighlightOff, MdCheckCircle, MdInfoOutline } from 'react-icons/md';

import { useToast } from '../../../hooks/ToastContext';
import { Container } from './styles';

const icons = {
  info: <MdInfoOutline size={20} />,
  error: <MdErrorOutline size={20} />,
  success: <MdCheckCircle size={20} />,
};

function Toast({ message, style }) {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id)
    }, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, [removeToast, message.id]);

  return (
    <Container type={message.type} hasDescription={!!message.description} style={style}>
      {icons[message.type || 'info']}

      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button onClick={() => removeToast(message.id)} type="button">
        <MdHighlightOff size={18} />
      </button>
    </Container>
  );
}

export default Toast;