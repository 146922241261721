import styled, { css } from 'styled-components';

import { shade } from 'polished';

import backgroundImg from '../../assets/images/background_2.jpg';

import ToolTip from '../../components/Tooltip';

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 160px 60px 30px;

  @media (max-width: 500px) {
    padding: 50px 20px 80px;
  }
`;

export const BoxContent = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 50px 30px;

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  width: 100%;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: #27293c;
    padding: 8px 16px 8px 16px;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    margin-right: 20px;
    width: 170px;
    height: 36px;

    transition: 0.2s;
    outline-style: none;

    &:hover {
      background: ${shade(0.2, '#27293c')}
    }

    > svg {
      margin-right: 8px;
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row4 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const InputArea = styled.div`
  > p {
    color: #0073FC;
    margin: 5px 0 18px;

    &.link {
      cursor: pointer;
      text-decoration: underline;
      /* border-bottom: 1px solid #0073FC; */
    }

    @media (max-width: 500px) {
      margin-bottom: 20px;
    }
  }
`;

export const SelectStatus = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;

  > button {
    display: flex;
    align-items: center;

    background: #27293c;
    padding: 8px 16px 8px 16px;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    margin-right: 20px;

    transition: 0.2s;
    outline-style: none;

    &:hover {
      background: ${shade(0.2, '#27293c')}
    }

    > svg {
      margin-right: 8px;
    }
  }

  form {
    display: flex;
    align-items: center;

    .sc-cjzMPC.dRiRcZ {
      margin: 0;
    }

    button {
      padding: 8px 22px;
      background: #0073FC;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;

      transition: 0.2s;
      outline-style: none;

      &:hover {
        background: ${shade(0.2, '#0073FC')}
      }
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 220px;
  height: 40px;

  background: #FEAC02;
  /* border: 1px solid #CCCCCC; */
  border-radius: 4px;
  box-sizing: border-box;

  margin-right: 30px;
  padding: 1px 11px 1px 1px;

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  select {
    border: none;
    background: #FEAC02;
    color: #fff;

    height: 34px;
    width: 100%;
    padding: 0 10px;

    ${props => props.statusColor === "APROVADO" && css`
      background: #5CB85C;
    `}

    ${props => props.statusColor === "PROCESSANDO" && css`
      background: #5097FF;
    `}

    ${props => props.statusColor === "CANCELADO" && css`
      background: #F35757;
    `}
  }

  option {
    color: #000;
    background: #fff;
  }

  ${props => props.statusColor === "APROVADO" && css`
    background: #5CB85C;
  `}

  ${props => props.statusColor === "PROCESSANDO" && css`
    background: #5097FF;
  `}

  ${props => props.statusColor === "CANCELADO" && css`
    background: #F35757;
  `}
`;

export const ModalSection = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 5;

  ${props => props.modalOpened && css`
    display: none;
  `}
`;

export const ModalContent = styled.div`
  color: #fff;
  background: #10104F;
  padding: 30px;
  border-radius: 8px;
  max-width: 640px;
  /* max-height: 420px; */

  width: 100%;
  /* height: 100%; */

  > svg {
    margin-left: calc(100% - 30px);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 30px;

    @media (max-width: 500px) {
      font-size: 18px;
      display: block;
      margin-bottom: 20px;
    }
  }

  div.buttonRow {
    margin: 30px auto 0;
    text-align: center;

    button {
      padding: 8px 27px;
      background: #0073FC;
      border: 1px solid #0073FC;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;

      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#0073FC')};
      }
    }

    button.cancelar {
      padding: 8px 27px;
      background: #F5F8FB;
      border: 1px solid #ADADAD;
      color: #373A3C;
      border-radius: 4px;

      margin-left: 30px;
      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, '#F5F8FB')};
      }
    }

    @media (max-width: 500px) {
      margin: 20px auto 0;
    }
  }

  @media (max-width: 500px) {
    width: 94%;
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const Aprovado = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => props.aprovadoHide && css`
    display: none;
  `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  div.upload {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 210px;
  }

  @media (max-width: 500px) {
    div.upload {
      overflow-x: hidden;
      overflow-y: scroll;
      height: 150px;
      margin-top: -10px;
      margin-bottom: -15px;
    }
  }
`;

export const Mensagem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 30px;

  ${props => props.messageHide && css`
    display: none;
  `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  div.inputArea {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 170px;

    textarea {
      border-radius: 4px;
    }

    div.labelRow {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const Confirmar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => props.confirmarHide && css`
    display: none;
  `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;;
    }

    @media (max-width: 500px) {
      font-size: 18px;
      text-align: center;
    }
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 11px;

  border: 1px solid #fff;
  border-radius: 4px;

  width: 200px;
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 5;

  img {
    width: 220px;
  }

  ${props => props.loadingHide && css`
    display: none;
  `}
`;
