import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import api from '../../services/api';

// Context API
import { useToast } from '../../hooks/ToastContext';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import LoadingContent from '../../components/LoadingContent';

// JSONs
import CodigoBarrasIcon from '../../assets/images/codigo_barras.svg';

import {
  Container,
  Page,
  Content,
  RowBox1,
  Box,
  BoletoOptions,
  InputArea,
  Row,
  Row3,
  RowBox2,
  RowBox3,
  RowBoxBoleto,
} from './styles';

function VerTransacao() {
  const history = useHistory();
  const location = useLocation();

  const { addToast } = useToast();

  const idTransacao = location.pathname.replace('/transacoes/ver/', '').split('').reverse().join('');

  const [transacaoInfos, setTransacaoInfos] = useState([]);
  const [transacaoInfosCliente, setTransacaoInfosCliente] = useState([]);
  const [transacaoInfosBoleto, setTransacaoInfosBoleto] = useState([]);
  const [transacaoInfosDadosBancarios, setTransacaoInfosDadosBancarios] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const [isLoading, setIsLoading] = useState(true);

  // Carrega as informações para alimentar a página
  useEffect(() => {
    setIsLoading(true);
    
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    async function requestTransacao() {
      const responseTransacao = await api.get(`/transacao/${idTransacao}`, auth);
      
      const result = responseTransacao.data.data.transacao;
      
      // Salvar dados da transacao
      setTransacaoInfos(result);

      // Salvar dados do cliente e telefone
      api.get(`/transacao/cliente/${result.id_cliente}`, auth).then(response => {
        const clienteTransacao = response.data.data;

        setTransacaoInfosCliente(clienteTransacao);
      });

      if (result.quantidade_boleto > 0) {
        // Salvar dados do boleto
        api.get('/boleto_trans?png=999999', auth).then(response => {
          let boletoTransacao = response.data.data;

          const boletoFiltrado = boletoTransacao.filter(item => item.id_transacao === idTransacao);

          setTransacaoInfosBoleto(boletoFiltrado);
          setTransacaoInfosDadosBancarios([]);
        });
      };
  
      if (result.quantidade_boleto === 0) {
        // Salvar dados bancários
        api.get('/dados_banc_trans?png=999999', auth).then(response => {
          let dadosBancariosTransacao = response.data.data;
          
          const dadosBancariosFiltrados = dadosBancariosTransacao.filter(item => item.id_transacao === idTransacao)
          
          setTransacaoInfosDadosBancarios(dadosBancariosFiltrados[0]);
          setTransacaoInfosBoleto([]);
        });
      };

      // Esconde o loading
      setIsLoading(false);
    }

    requestTransacao();
  }, [idTransacao, userToken]);

  const [depositoOpened, setDepositoOpened] = useState(true);
  const [boletoOptions, setBoletoOptions] = useState(true);

  useEffect(() => {
    if (transacaoInfos.quantidade_boleto === 0) {
      setDepositoOpened(false);
      setBoletoOptions(true);
    } else if (transacaoInfos.quantidade_boleto > 0) {
      setDepositoOpened(true);
      setBoletoOptions(false);
    }
  }, [transacaoInfos.quantidade_boleto]);

  /* const onDepositoSelected = useCallback(() => {
    if (transacaoInfos.quantidade_boleto === 0) {
      setDepositoOpened(false);
      setBoletoOptions(true);
    } else if (transacaoInfos.quantidade_boleto > 0) {
      setDepositoOpened(true);
      setBoletoOptions(false);
    }
  }, [transacaoInfos.quantidade_boleto]); */

  const [downloadBoletoHide, setDownloadBoletoHide] = useState(true);

  const [boleto, setBoleto] = useState('');
  const [codigoBarras, setCodigoBarras] = useState('');

  const [valorBoleto, setValorBoleto] = useState('');
  const [dataVencimento, setDataVencimento] = useState('');

  async function downloadBoleto(item) {

    setIsLoading(true);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    await api.get(`/documento_download/${item.id_boleto}`, auth).then(response => {
      const result = response.data.data;
      // const resultReplace = result.replace(`<img src= '`, '').replace(`' />`, '');

      setBoleto(result);
      setCodigoBarras(item.codigo_de_barras);

      if (item.valor === undefined || item.valor === null) {
        setValorBoleto(`Valor não encontrado`);
      } else {
        setValorBoleto(`R$ ${item.valor.toString().replace('.', ',')}`);
      }

      if (item.data_vencimento === undefined || item.data_vencimento === null) {
        setDataVencimento('Data não encontrada');
      } else {
        setDataVencimento(item.data_vencimento.substring(0, 10).split('-').reverse().join('/'));
      }

      setDownloadBoletoHide(false);
    });

    setIsLoading(false);
  };

  // Faz o download do comprovante do cliente
  async function downloadComprovante() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    if (transacaoInfos.id_doc_termo === "" || transacaoInfos.id_doc_termo === null) {
      addToast({
        type: "error",
        title: "Erro ao fazer download",
        description:
          "Esta transação foi feita no app e não possui comprovante.",
      });

      setIsLoading(false);

      return;
    }

    await api.get(`/documento_download/${transacaoInfos.id_doc_autorizacao}`, auth).then(response => {
      const result = response.data.data;

      // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
      const linkSource = result;
      const downloadLink = document.createElement("a");
      const fileName = "comprovante.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });

    setIsLoading(false);
  };

  // Faz o download do termo
  async function downloadTermo() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    // Baixa o termo das transações feitas no web
    if (transacaoInfosCliente.assinatura_id === null) {
      await api
      .get(`/documento_download/${transacaoInfos.id_doc_termo}`, auth)
      .then((response) => {
        const result = response.data.data;
        // console.log(result)

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = result;
        const downloadLink = document.createElement("a");
        const fileName = "termo.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    }

    // Baixa o termo das transações feitas no app
    if (transacaoInfosCliente.assinatura_id !== null) {
      await api.post(
        `/transacao/termo/${transacaoInfos.id}`,
        {},
        auth,
      ).then((response) => {
        const result = response.data;
        // console.log(result)

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = `data:application/pdf;base64,${result}`;
        const downloadLink = document.createElement("a");
        const fileName = "termo.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    }

    setIsLoading(false);
  };

  // Faz o download do documento com foto
  async function downloadDocFoto() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    await api.get(`/documento_download/${transacaoInfos.id_doc_documento_foto}`, auth).then(response => {
      const result = response.data.data;

      // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
      const linkSource = result;
      const downloadLink = document.createElement("a");
      const fileName = "documento_com_foto.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });

    setIsLoading(false);
  };

  return (
    <Container>
      <LoadingContent isLoading={isLoading} />

      <MenuBar subMenuActive="Deixa no Azul" />
      <NewMobileMenu active="Transações" />

      <Page>
        <TopBar pageTitle="Transação -" pageSubtitle="Deixa no Azul" />

        <Content>
          <form>
            <RowBox1>
              <Box>
                <h3>Informações da transação</h3>

                <InputArea>
                  <label>Nome</label>
                  <p>{transacaoInfosCliente.nome}</p>
                </InputArea>

                <Row>
                  <InputArea>
                    <label>Número do CPF</label>
                    <p>{transacaoInfosCliente.cpf}</p>
                  </InputArea>

                  <InputArea>
                    <label>Número do RG</label>
                    <p>{transacaoInfosCliente.rg}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>E-mail</label>
                    <p>{transacaoInfosCliente.email}</p>
                  </InputArea>

                  <InputArea>
                    <label>Telefone</label>
                    <p>{transacaoInfosCliente.telefone}</p>
                  </InputArea>
                </Row>
              
                <Row>
                  <InputArea>
                    <label>Data de Criação</label>
                    <p>
                      {moment(transacaoInfosCliente.created_at).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </InputArea>

                  <InputArea>
                    <label>Última Atualização</label>
                    <p>
                      {moment(transacaoInfosCliente.updated_at).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </InputArea>
                </Row>
              </Box>

              <div>
                <Box>
                  <h3>Informações para pagamentos</h3>

                  <InputArea>
                    <label>*Tipo de transação</label>
                    <p>{transacaoInfos.quantidade_boleto === 0 ? "Depósito bancário" : "Boleto"}</p>
                  </InputArea>
                </Box>

                <BoletoOptions boletoOptions={boletoOptions} className="boleto_infos">
                  <InputArea>
                    <label>*Quantidade de Boletos</label>
                    <p>{transacaoInfos.quantidade_boleto}</p>
                  </InputArea>
                </BoletoOptions>
              </div>
            </RowBox1>

            <RowBoxBoleto boletoOptions={boletoOptions}>
              <Box>
                {transacaoInfosBoleto.map(item => (
                  <span key={item.id_doc_boleto} style={{ display: 'flex', alignItems: 'center' }} onClick={() => downloadBoleto(item)}><img src={CodigoBarrasIcon} alt=""/>Boleto {transacaoInfosBoleto.indexOf(item) + 1}</span>
                ))}

                <Row className="marginTop" downloadBoletoHide={downloadBoletoHide}>
                  <InputArea className="barCode">
                    <label>Código de barra do boleto</label>
                    <p>{codigoBarras}</p>
                  </InputArea>

                  <InputArea>
                    <label>Valor do boleto</label>
                    <p>{valorBoleto}</p>
                  </InputArea>
                </Row>
                
                <Row className="marginTop" downloadBoletoHide={downloadBoletoHide}>
                  <InputArea>
                    <label>Data de vencimento do boleto</label>
                    <p>{dataVencimento}</p>
                  </InputArea>

                  <InputArea className="campoAnexo">
                    <label>Boleto</label>
                    <a download style={{ display: 'block' }} href={boleto}>Fazer Download</a>
                  </InputArea>
                </Row>
              </Box>

            </RowBoxBoleto>

            <RowBox2 depositoOpened={depositoOpened}>
              <h3>Informações da conta bancária</h3>

              <Row>
                <InputArea>
                  <label>Nome do titular</label>
                  <p>{transacaoInfosDadosBancarios.nome_titular}</p>
                </InputArea>

                <InputArea>
                  <label>Nome do banco</label>
                  <p>{transacaoInfosDadosBancarios.nome_banco}</p>
                </InputArea>
              </Row>

              <Row3>
                <InputArea>
                  <label>Tipo de conta</label>
                  <p>{transacaoInfosDadosBancarios.conta_juridica}</p>
                </InputArea>

                <InputArea>
                  <label>CPF/CNPJ</label>
                  <p>{transacaoInfosDadosBancarios.CPF_CNPJ}</p>
                </InputArea>

                <InputArea>
                  <label>Conta</label>
                  <p>
                    {transacaoInfosDadosBancarios.tipo_conta === "0"
                      ? "Corrente"
                      : "Poupança"}
                  </p>
                </InputArea>
              </Row3>

              <Row3>
                <InputArea>
                  <label>Agência</label>
                  <p>{transacaoInfosDadosBancarios.agencia}</p>
                </InputArea>

                <InputArea>
                  <label>Número da conta</label>
                  <p>{transacaoInfosDadosBancarios.numero_conta}</p>
                </InputArea>

                <InputArea>
                  <label>Operação</label>
                  <p>{transacaoInfosDadosBancarios.operacao}</p>
                </InputArea>
              </Row3>

            </RowBox2>
            
            <RowBox3>
              <Box>
                <h3>Anexos</h3>
                <Row>
                  <InputArea className="campoAnexo">
                    <label>Comprovante</label>
                    <button type="button" className="downloadButton" onClick={() => downloadComprovante()}>Fazer Download</button>
                  </InputArea>

                  <InputArea className="campoAnexo">
                    <label>Termo</label>
                    <button type="button" className="downloadButton" onClick={() => downloadTermo()}>Fazer Download</button>
                  </InputArea>
                </Row>

                <Row className="marginTop">
                  <InputArea className="campoAnexo">
                    <label>Documento com foto</label>
                    <button type="button" className="downloadButton" onClick={() => downloadDocFoto()}>Fazer Download</button>
                  </InputArea>

                </Row>
              </Box>
            </RowBox3>
            
            <button type="button" onClick={() => history.push('/transacoes')}>Voltar</button>
          </form>
        </Content>
      </Page>

    </Container>
  );
};

export default VerTransacao;
