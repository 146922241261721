import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import api from "../../services/api";


import TopBar from "../../components/TopBar";
import MenuBar from "../../components/MenuBar";
import NewMobileMenu from "../../components/NewMobileMenu";
import LoadingContent from '../../components/LoadingContent';
import FormatarValor from '../../components/FormatarValor';

import {
  Container,
  Page,
  Content,
  RowBox1,
  Box,
  BlueBox,
  Row,
  Row3,
  InputArea,
  RowBox2,
} from './styles';

function EditarCertificadoDigital() {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true); // Default true

  const userToken = localStorage.getItem("@DeixaNoAzul:accessToken");

  const idTransacao = location.pathname
    .replace("/certificado-digital/editar/", "")
    .split("")
    .reverse()
    .join("");

  const [transacaoInfos, setTransacaoInfos] = useState({});
  const [transacaoInfosEndereco, setTransacaoInfosEndereco] = useState({});

  // Faz as requisições para preencher as informações
  useEffect(() => {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    async function requestTransacao() {
      const responseTransacao = await api.get(
        `/digital/certificate/${idTransacao}`,
        auth
      );
      const resultTransacao = responseTransacao.data.data;
      // console.log('resultTransacao', resultTransacao);

      setTransacaoInfos(resultTransacao);
      setTransacaoInfosEndereco(resultTransacao.endereco);

      setIsLoading(false);
    }
    
    requestTransacao();
  }, [idTransacao, userToken]);

  // Faz o download do comprovante de conclusão anexado pelo Admin
  async function downloadComprovante() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    await api
      .get(`/documento_download/${transacaoInfos.comprovante_id}`, auth)
      .then((response) => {

        const result = response.data.data;

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = result;
        const downloadLink = document.createElement("a");
        const fileName = "comprovante_conclusão.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });

    setIsLoading(false);
  }
  
  return (
    <Container>
      <LoadingContent isLoading={isLoading} />

      <MenuBar subMenuActive="Certificado Digital" />
      <NewMobileMenu active="Transações" />

      <Page>
        <TopBar pageTitle="Transação -" pageSubtitle="Certificado Digital" />

        <Content>
          <RowBox1>
            <div>
              <Box>
                <h3>Dados do Certificado</h3>

                <Row>
                  <InputArea>
                    <label>Número do {transacaoInfos.type === 'e-cpf' ? 'CPF' : 'CNPJ'}</label>
                    <p>{transacaoInfos.type === 'e-cpf' ? transacaoInfos.cpf : transacaoInfos.cnpj}</p>
                  </InputArea>

                  <InputArea>
                    <label>Data de Nascimento</label>
                    <p>{
                      transacaoInfos.data_nascimento === undefined
                        ? ''
                        : transacaoInfos.data_nascimento.split("-").reverse().join("/")
                    }</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Doc. de Identidade (RG ou CNH)</label>
                    <p>{transacaoInfos.rg}</p>
                  </InputArea>

                  <InputArea>
                    <label>Celular</label>
                    <p>{transacaoInfos.telefone_pessoal}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>E-mail</label>
                    <p>{transacaoInfos.email}</p>
                  </InputArea>

                  <InputArea>
                    <label>Já emitiu certificado digital anteriormente?</label>
                    <p>{transacaoInfos.is_primeiro_certificado === true ? 'Sim' : 'Não'}</p>
                  </InputArea>
                </Row>
              </Box>

              <BlueBox>
                <h3>Informações de Pagamento</h3>

                <Row3>
                  <InputArea>
                    <label>Valor</label>
                    <p>R$ {FormatarValor(transacaoInfos.valor)}</p>
                  </InputArea>

                  <InputArea>
                    <label>Forma de pagamento</label>
                    <p>{transacaoInfos.payment_type === "credit" ? 'Crédito' : 'Débito'}</p>
                  </InputArea>

                  <InputArea>
                    <label>Data da Solicitação</label>
                    <p>{
                      transacaoInfos.created_at === undefined
                        ? ''
                        : transacaoInfos.created_at.substring(0, 10).split("-").reverse().join("/")
                    }</p>
                  </InputArea>
                </Row3>
              </BlueBox>
            </div>

            <div>
              <Box>
                <h3>Endereço</h3>

                <Row>
                  <InputArea>
                    <label>CEP</label>
                    <p>{transacaoInfosEndereco.cep}</p>
                  </InputArea>

                  <InputArea>
                    <label>Endereço</label>
                    <p>{transacaoInfosEndereco.rua}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Número</label>
                    <p>{transacaoInfosEndereco.numero}</p>
                  </InputArea>

                  <InputArea>
                    <label>Complemento</label>
                    <p>{transacaoInfosEndereco.complemento}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Bairro</label>
                    <p>{transacaoInfosEndereco.bairro}</p>
                  </InputArea>

                  <InputArea>
                    <label>Cidade</label>
                    <p>{transacaoInfosEndereco.cidade}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Estado</label>
                    <p>{transacaoInfosEndereco.uf}</p>
                  </InputArea>
                </Row>
              </Box>
            </div>
          </RowBox1>

          {transacaoInfos.status === "APROVADO" && (
            <RowBox2>
              <Box>
                <h3>Anexos do admin</h3>

                <InputArea>
                  <label>Comprovante de ativação</label>
                  <button
                    type="button"
                    className="downloadButton"
                    style={{ marginBottom: "0px" }}
                    onClick={() => downloadComprovante()}
                  >
                    Fazer Download
                  </button>
                </InputArea>
              </Box>
            </RowBox2>
          )}
        </Content>
      
      </Page>
    </Container>
  );
};

export default EditarCertificadoDigital;
