import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useForm } from "react-hook-form";
// import { MdErrorOutline } from 'react-icons/md';
import { FaFlagCheckered } from 'react-icons/fa';

import api from '../../services/api';

import { useToast } from '../../hooks/ToastContext';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';

import {
  Container,
  Page,
  Content,
  TableContent,
  // InputArea,
  // InputContainer,
  // Error,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
} from './styles';

function ClassificacaoECsRevendedor() {
  const { addToast } = useToast();

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  // const loggedUser = JSON.parse(localStorage.getItem('@DeixaNoAzul:loggedUser'));
  const revendedorData = JSON.parse(localStorage.getItem('@DeixaNoAzul:revendedorId'));

  const [ecs, setEcs] = useState([]);

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/ranking_ec_rv', auth).then(response => {
      const result = response.data.data;
      console.log(response)

      setEcs(result);
    })
    
  }, [userToken]);

  const { handleSubmit/* , register, errors */ } = useForm();
  const onSubmit = async (data) => {
    
    // Bearer token
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };


    api.post('/ranking_ec_rv', {
      revendedor_id: revendedorData.id,
      data_inicio: data.data_inicio,
      data_fim: data.data_fim
    }, auth).then(response => {
      const result = response.data.data;
      console.log(response)

      setEcs(result);
    })

    /* addToast({
      type: 'error',
      title: 'Erro no cadastro',
      description: 'Os valores do simulador foram modificados, volte novamente à página de simulação',
    }); */
  };
  const onError = (errors) => {
    console.log(errors);
    addToast({
      type: 'error',
      title: 'Erro no envio do formulário',
      description: 'Você precisa preencher todos os campos obrigatórios (marcados com *).',
    });
  };

  // Informações Head da tabela
  const headData = [
    { title: <FaFlagCheckered size={15} />, field: 'posicao', sorting: false, cellStyle: { width: '5%' }, headerStyle: { width: '5%' } },
    { title: 'Nome do EC', field: 'nome', sorting: false },
    { title: 'N° de transações', field: 'quantidade', sorting: false },
    { title: 'V. total transações', field: 'valor', sorting: false },
    { title: 'Ticket médio', field: 'ticket', sorting: false },
    { title: '% médio comissão', field: 'percentual', sorting: false },
    { title: 'Total comissões', field: 'total', sorting: false },
  ];

  // Informações Body da tabela
  // const bodyData = [];
  const bodyData = ecs === [] ? []
  : ecs.map(ec => {

    const valor_total = ec.total_transacoes_liquido.toString().split('.');
    const valorTotal = `${valor_total[0]},${valor_total[1] ? valor_total[1].substring(0, 2) : "00"}`;

    const ticket_medio = ec.ticket_medio.toString().split('.');
    const ticketMedio = `${ticket_medio[0]},${ticket_medio[1] ? ticket_medio[1].substring(0, 2) : "00"}`;

    const percentual = ec.percentual_medio_comissao === null || ec.percentual_medio_comissao === undefined ? "Processando" : `${ec.percentual_medio_comissao}%`;
    
    const total_gerador = ec.total_gerador_comissoes_ec !== null ? ec.total_gerador_comissoes_ec.toString().split('.') : '00';
    const valorGerador = `${total_gerador[0]},${total_gerador[1] ? total_gerador[1].substring(0, 2) : "00"}`;

    const total = ec.total_gerador_comissoes_ec === null ? "Processando" : `R$ ${valorGerador}`;
    
    return {
      posicao: ec.posicao,
      nome: ec.nome_fantasia_ec,
      quantidade: ec.quantidade_transacoes,
      valor: `R$ ${valorTotal}`,
      ticket: `R$ ${ticketMedio}`,
      percentual: percentual,
      total: total,
    }
  });

  /* function verDados(estabelecimento) {
    console.log(estabelecimento)
  } */

  function valorTotalTransacao(estabelecimento) {

    const valor_total = estabelecimento.total_transacoes_liquido.toString().split('.');
    const valorTotal = `${valor_total[0]},${valor_total[1] ? valor_total[1].substring(0, 2) : "00"}`;

    return <strong>R$ {valorTotal}</strong>
  };

  function valorTotalComissoes(estabelecimento) {

    const total_gerador = estabelecimento.total_gerador_comissoes_ec !== null ? estabelecimento.total_gerador_comissoes_ec.toString().split('.') : '00';
    const valorGerador = `${total_gerador[0]},${total_gerador[1] ? total_gerador[1].substring(0, 2) : "00"}`;

    const total = estabelecimento.total_gerador_comissoes_ec === null ? "Processando" : `R$ ${valorGerador}`;

    return <strong>{total}</strong>
  };

  return (
    <Container>
      <MenuBar />
      <NewMobileMenu />

      <Page>
        <TopBar pageTitle="Classificação de ECs" />
        <Content>

          <TableContent>

            <form onSubmit={handleSubmit(onSubmit, onError)}>
              {/* <InputArea>
                <label>*Data inicial</label>
                <InputContainer style={{ borderColor: errors.data_inicio && "red" }}>
                  <input
                    name="data_inicio"
                    type="date"
                    ref={register({ required: "Campo obrigatório" })}
                  />
                  {errors.data_inicio && <Error title={errors.data_inicio.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                </InputContainer>
              </InputArea>
              
              <InputArea>
                <label>*Data final</label>
                <InputContainer style={{ borderColor: errors.data_fim && "red" }}>
                  <input
                    name="data_fim"
                    type="date"
                    ref={register({ required: "Campo obrigatório" })}
                  />
                  {errors.data_fim && <Error title={errors.data_fim.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                </InputContainer>
              </InputArea>

              <button type="submit">Filtrar</button> */}
            </form>

            <MaterialTable
              title="Classificação de ECs"
              columns={headData}
              data={bodyData}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                  </div>
                )
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                },
                toolbar: {
                  searchPlaceholder: 'Buscar',
                  searchTooltip: 'Buscar'
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum dado encontrado',
                }
              }}
              options={{
                search: false,
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: '#10104F',
                  color: '#FFF'
                },
                emptyValue: "Nenhum dado encontrado"
              }}
            />

          </TableContent>

          <TableContentMobile>
            <Header>
              <h3>Classificação de ECs</h3>

              <span>Total: {ecs.length}</span>
            </Header>

            <MobileBody>
              {ecs === [] || ecs === "Nenhum estabelecimento encontrado" ?
                <span>Nenhum estabelecimento encontrado</span>
              : ecs.map((estabelecimento) => (
                <Line key={estabelecimento.id_ec}>
                  <p>
                    <FaFlagCheckered style={{ marginRight: "4px" }} size={13} />
                    {estabelecimento.posicao}
                  </p>
                  <span>{estabelecimento.nome_fantasia_ec}</span>
                  <p>Nº de transações: {estabelecimento.quantidade_transacoes}</p>
                  <p>V. total transações: {valorTotalTransacao(estabelecimento)}</p>
                  <p>% média comissão: {estabelecimento.percentual_medio_comissao === null || estabelecimento.percentual_medio_comissao === undefined ? "Processando" : `${estabelecimento.percentual_medio_comissao}%`}</p>
                  <p>Total comissões: {valorTotalComissoes(estabelecimento)}</p>
                  {/* <p>{verDados(estabelecimento)}</p> */}
          
                </Line>
              ))}
            </MobileBody>

          </TableContentMobile>

        </Content>
      </Page>
    </Container>
  );
}

export default ClassificacaoECsRevendedor;
