import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { MdErrorOutline, MdHighlightOff, MdGetApp } from 'react-icons/md';
import filesize from 'filesize';
import { uniqueId } from "lodash";

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import LoadingContent from '../../components/LoadingContent';
import FormatarValor from '../../components/FormatarValor';

import UploadPagamento from '../../components/Upload/pagamento';
import InfoPagamento from '../../components/UploadInfo/pagamento';

// Context API
import { useToast } from '../../hooks/ToastContext';

import {
  Container,
  Page,
  Content,
  BoxContent,
  Row3,
  InputArea,
  SelectStatus,
  InputContainer,
  ModalSection,
  ModalContent,
  Aprovado,
  Mensagem,
  Confirmar,
  UploadContainer,
  Error,
} from './styles';

function AdminEditarPagamentos() {
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const { addToast } = useToast();

  const location = useLocation();
  const idPagamento = location.pathname.replace('/admin/pagamentos/editar/', '').split('').reverse().join('');

  const [pagamentoInfos, setPagamentoInfos] = useState([]);
  const [pagamentoInfosDadosBancarios, setPagamentoInfosDadosBancarios] = useState([]);
  const [displayBoletoBotao, setDisplayBoletoBotao] = useState('none');
  const [formaPagamento, setFormaPagamento] = useState('');

  const [valorPagamento, setValorPagamento] = useState('');

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const { register, handleSubmit, getValues, setValue, setError, errors } = useForm();
  const onSubmit = async (data) => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    if (getValues('status') === "APROVADO") {

      // Verifica se um comprovante foi anexada
      if (uploadedPagamento.length === 0) {
        setError("doc_pagamento", {
          type: "manual",
          message: "Campo obrigatório."
        });

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Você precisa anexar o comprovante de pagamento.',
        });

        return;
      };

      // Faz o upload do comprovante de pagamento
      if (uploadedPagamento.length > 0) {

        const data = new FormData();

        uploadedPagamento.forEach(item => {
          data.append(`image${uploadedPagamento.indexOf(item)}`, item.file);
        });

        await api.post('/documento_upload_mult', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization : "Bearer "+userToken,
          },
          onUploadProgress: e => {
            setProgressPagamento(parseInt(Math.round((e.loaded * 100) / e.total)))
          }
        }).then(response => {
          setStatusPagamento({
            uploaded: true,
            error: false
          })

          const value = response.data.data;
          setValue("doc_pagamento", value.id);

        }).catch(() => {
          setStatusPagamento({
            uploaded: false,
            error: true
          });

          return;
        });

      };

      const response = await api.post(`/pagamento_update_status/${pagamentoInfos.pagamento_id}`, {
        doc_comprovante_pagamento: getValues("doc_pagamento"),
        status: data.status
      }, auth);

      console.log(response)

      if (response.data.errors) {
        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
        });

        return;
      }
    }

    if (getValues('status') === "CANCELADO") {
      const response = await api.post(`/pagamento_update_status/${pagamentoInfos.pagamento_id}`, {
        status: data.status
      }, auth);

      if (response.data.errors) {
        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
        });

        return;
      }
    }

    if (getValues('status') === "PROCESSANDO") {
      const response = await api.post(`/pagamento_update_status/${pagamentoInfos.pagamento_id}`, {
        status: data.status
      }, auth);

      if (response.data.errors) {
        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
        });

        return;
      }
    }

    addToast({
      type: 'success',
      title: 'Deu tudo certo',
      description: 'Status atualizado com sucesso.',
    });
    
    history.push('/admin/pagamentos');
  };
  const onError = (errors) => {
    // console.log(errors)
    addToast({
      type: 'error',
      title: 'Erro na atualização',
      description: 'Você precisa preencher os campos obrigatórios.',
    });
  };

  // pre-setar os dados
  useEffect(() => {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    async function requestPagamento() {
      const responsePagamento = await api.get(`/pagamento`, auth);
      const result = responsePagamento.data;
      // console.log(responsePagamento)
      const pagamentoFiltrado = result.filter(item => item.pagamento_id === idPagamento);
      // console.log(pagamentoFiltrado[0])

      // Salvar dados do pagamento
      setPagamentoInfos(pagamentoFiltrado[0]);

      // Salvar dados bancários do solicitante
      api.get(`/conta/${pagamentoFiltrado[0].dados_bancarios_id}`, auth).then(response => {
        const resultConta = response.data.data;
        // console.log(resultConta)

        setPagamentoInfosDadosBancarios(resultConta);
      });

      // Alterar o select de status
      setValue('status', pagamentoFiltrado[0].status);
      setStatusColor(pagamentoFiltrado[0].status.toString());
      
      // Ajusta o valor do pagamento para aparecer na tela
      setValorPagamento(pagamentoFiltrado[0].valor_pagamento);

      // Mostra o botão de baixar boleto caso a forma de pagamento seja boleto
      if (pagamentoFiltrado[0].boleto_pagamento !== null) {
        setDisplayBoletoBotao('block');
      };

      // Ajusta a forma de pagamento para aparecer na tela
      if (pagamentoFiltrado[0].forma_pagamento === 'TRANSF') { setFormaPagamento('Transferência') };
      if (pagamentoFiltrado[0].forma_pagamento === 'PIX') { setFormaPagamento('Pix') };
      if (pagamentoFiltrado[0].forma_pagamento === 'BOLETO') { setFormaPagamento('Boleto') };

      // Apaga o loading
      setIsLoading(false);
    };

    requestPagamento();
    
  }, [setValue, idPagamento, userToken]);

  // Abrir e fechar modal
  const [modalOpened, setModalOpened] = useState(true);

  // State para mudança de cor do select
  const [statusColor, setStatusColor] = useState('');

  // State para mostrar campo de upload de arquivos ou mensagem
  const [aprovadoHide, setAprovadoHide] = useState(true);
  const [messageHide, setMessageHide] = useState(true);
  const [confirmarHide, setConfirmarHide] = useState(true);

  // State para definir regra de obrigatoriedade
  const [requiredMessage, setRequiredMessage] = useState(false);

  const changeStatus = useCallback(() => {
    const selectValue =  getValues('status');

    setStatusColor(selectValue);

    if (selectValue === "PROCESSANDO") {
      setRequiredMessage(false)

      setAprovadoHide(true);
      setMessageHide(true);
      setConfirmarHide(false);
    }

    if (selectValue === "APROVADO") {
      setRequiredMessage(false)

      setAprovadoHide(false);
      setMessageHide(true);
      setConfirmarHide(true);
    }

    if (selectValue === "CANCELADO") {
      setRequiredMessage(false)

      setAprovadoHide(true);
      setMessageHide(true);
      setConfirmarHide(false);
    }
    
  }, [getValues]);

  // Upload do Comprovante de pagamento da transação ------------------ START 
  const [uploadedPagamento, setUploadedPagamento] = useState([]);
  const [progressPagamento, setProgressPagamento] = useState(0);
  const [statusPagamento, setStatusPagamento] = useState({
    uploaded: false,
    error: false
  });

  // Preparar arquivos para upload
  const submitFileComprovantePagamento = useCallback((file) => {
    const uploadFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
    };

    setUploadedPagamento([...uploadedPagamento, uploadFile]);
  }, [uploadedPagamento]);
  
  // Faz o upload de todos os arquivos
  /* const handleUploadPagamento = useCallback(() => {

    // Envia para a API
    const data = new FormData();

    uploadedPagamento.forEach(item => {
      data.append(`image${uploadedPagamento.indexOf(item)}`, item.file);
    });

    api.post('/documento_upload_mult', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization : "Bearer "+userToken,
      },
      onUploadProgress: e => {
        setProgressPagamento(parseInt(Math.round((e.loaded * 100) / e.total)))
      }
    }).then(response => {
      setStatusPagamento({
        uploaded: true,
        error: false
      })

      const value = response.data.data;
      setValue("doc_pagamento", value.id);

    }).catch(() => {
      setStatusPagamento({
        uploaded: false,
        error: true
      })
    });
  }, [uploadedPagamento, setValue, userToken]); */
  
  // Remove o arquivo da lista de arquivos
  const handleDeletePagamento = useCallback((id) => {
    const newFiles = uploadedPagamento.filter(termo => termo.id !== id);

    setUploadedPagamento(newFiles);
  }, [uploadedPagamento]);
  // Upload do Comprovante de pagamento da transação -------------------- END 

  async function downloadNotaFiscal() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    // pegar Nota Fiscal
    await api.get(`/documento_download/${pagamentoInfos.nota_fiscal_pagamento}`, auth).then(response => {
      const result = response.data.data;
      // console.log(result);

      const linkSource = result;
      const downloadLink = document.createElement("a");
      const fileName = "nota_fiscal.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });

    setIsLoading(false);
  };

  async function downloadBoleto() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    await api.get(`/documento_download/${pagamentoInfos.boleto_pagamento}`, auth).then(response => {
      const result = response.data.data;
      // console.log(result);

      const linkSource = result;
      const downloadLink = document.createElement("a");
      const fileName = "boleto_pagamento.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });

    setIsLoading(false);
  };

  return (
    <Container>
      <LoadingContent isLoading={isLoading} />

      <ModalSection modalOpened={modalOpened}>
        <ModalContent>
          <MdHighlightOff size={30} onClick={() => setModalOpened(!modalOpened)} />
          <form onSubmit={handleSubmit(onSubmit, onError)}>

            <Aprovado aprovadoHide={aprovadoHide}>
              <span>Anexar comprovante da transação</span>

              {/* <UploadContainer style={{ borderColor: errors.doc_pagamento && "red" }}>
                <UploadPagamento
                  onUpload={handlePagamentoUpload}
                  // isDisabled={uploadedPagamento.file ? true : false}
                />
                <input type="text" name="doc_pagamento" ref={register({ required: requiredUpload })} hidden />
                {errors.doc_pagamento && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
              </UploadContainer>
              {!!uploadedPagamento.file && <InfoPagamento file={uploadedPagamento} percent={progressPagamento} status={statusPagamento} />} */}

              <InputArea className="campoAnexo">
                <label>*Comprovante de pagamento</label>
                <UploadContainer style={{ borderColor: errors.doc_pagamento && "red" }}>
                  <UploadPagamento
                    onUpload={submitFileComprovantePagamento}
                    isDisabled={uploadedPagamento.length === 10 ? true : false}
                    isWhite={false}
                  />
                  <input type="text" name="doc_pagamento" ref={register} hidden />
                  {errors.doc_pagamento && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                </UploadContainer>
                <div className="upload">
                  {!!uploadedPagamento.length && <InfoPagamento files={uploadedPagamento} percent={progressPagamento} status={statusPagamento} onDelete={(id) => handleDeletePagamento(id)} isBlack={false} />}
                </div>
              </InputArea>

              <span>Tem certeza que deseja aprovar essa transação?</span>
            </Aprovado>

            <Mensagem messageHide={messageHide}>
              <span>Digite um comentário para o Estabelecimento</span>
              
              <div className="inputArea">
                <div className="labelRow">
                  <label>Digite aqui</label>
                  {errors.message && <Error title="Campo obrigatório" style={{ marginLeft: "8px" }}><MdErrorOutline color="c53030" size={20} style={{ marginBottom: "8px" }} /></Error>}
                </div>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  ref={register({ required: requiredMessage })}
                  style={{ borderColor: errors.message && "red" }}
                />
              </div>
            </Mensagem>
            
            <Confirmar confirmarHide={confirmarHide}>
              <span>Tem certeza que deseja alterar o status do pagamento?</span>
            </Confirmar>
            
            <div className="buttonRow">
              <button type="submit">Enviar</button>
              <button className="cancelar" type="button" onClick={() => setModalOpened(!modalOpened)}>Cancelar</button>
            </div>
          </form>
        </ModalContent>
      </ModalSection>

      <MenuBar />
      <NewMobileMenu />

      <Page>
        <TopBar pageTitle="Editar pagamento" />

        <Content>

          <BoxContent>
            <h3>Dados do pagamento</h3>

            <Row3>
              <InputArea>
                <label>Forma de pagamento</label>
                <p>{formaPagamento}</p>
              </InputArea>

              <InputArea>
                <label>Valor</label>
                <p style={{ fontWeight: 600 }}>R$ {FormatarValor(valorPagamento)}</p>
              </InputArea>

              <InputArea style={{ display: displayBoletoBotao }}>
                <label>Boleto</label>
                <p style={{ cursor: 'pointer' }} onClick={() => downloadBoleto()}>Fazer download</p>
              </InputArea>
            </Row3>

            <h3 style={{ marginTop: '20px', display: pagamentoInfos.forma_pagamento === 'BOLETO' ? 'none' : 'block' }}>Dados da conta bancária</h3>

            <Row3 style={{ display: pagamentoInfos.forma_pagamento === 'PIX' ? 'grid' : 'none' }}>
              <InputArea>
                <label>Tipo de Chave Pix</label>
                <p>{pagamentoInfosDadosBancarios.tipo_chave_pix}</p>
              </InputArea>
              
              <InputArea>
                <label>Chave Pix</label>
                <p>{pagamentoInfosDadosBancarios.chave_pix}</p>
              </InputArea>
            </Row3>

            <Row3 style={{ display: pagamentoInfos.forma_pagamento === 'TRANSF' ? 'grid' : 'none' }}>
              <InputArea>
                <label>Nome do titular</label>
                <p>{pagamentoInfosDadosBancarios.nome_titular}</p>
              </InputArea>
              
              <InputArea>
                <label>Papel</label>
                <p>{pagamentoInfos.papel === "ROLE_LA" ? "LA" : "EC"}</p>
              </InputArea>

              <InputArea>
                <label>CPF</label>
                <p>{pagamentoInfosDadosBancarios.cpf_cnpj}</p>
              </InputArea>
            </Row3>

            <Row3 style={{ display: pagamentoInfos.forma_pagamento === 'TRANSF' ? 'grid' : 'none' }}>
              <InputArea>
                <label>Nome do banco</label>
                <p>{pagamentoInfosDadosBancarios.nome_banco}</p>
              </InputArea>

              <InputArea>
                <label>Tipo de conta</label>
                <p>{pagamentoInfosDadosBancarios.conta_juridica === 0 ? "Pessoa física" : "Pessoa Jurídica"}</p>
              </InputArea>
              
              <InputArea>
                <label>Conta</label>
                <p>{pagamentoInfosDadosBancarios.tipo_conta}</p>
              </InputArea>
            </Row3>

            <Row3 style={{ display: pagamentoInfos.forma_pagamento === 'TRANSF' ? 'grid' : 'none' }}>
              <InputArea>
                <label>Agência</label>
                <p>{pagamentoInfosDadosBancarios.agencia}</p>
              </InputArea>

              <InputArea>
                <label>Número da conta</label>
                <p>{pagamentoInfosDadosBancarios.numero_conta}</p>
              </InputArea>
              
              <InputArea>
                <label>Operação</label>
                <p>{pagamentoInfosDadosBancarios.operacao}</p>
              </InputArea>
            </Row3>
          </BoxContent>

          <SelectStatus>
            <button type="button" onClick={() => downloadNotaFiscal()}>
              <MdGetApp color="#fff" />
              Baixar Nota Fiscal
            </button>

            {/* <button type="button" onClick={() => downloadFile()}>Download Test</button> */}

            <form /* onSubmit={handleSubmit(onSubmit, onError)} */>
              <InputContainer statusColor={statusColor}>
                <select
                  name="status"
                  placeholder="Selecione"
                  ref={register({ required: true })}
                  onChange={changeStatus}
                >
                  <option value="PROCESSANDO">Processando</option>
                  <option value="APROVADO">Aprovado</option>
                  <option value="CANCELADO">Cancelado</option>
                </select>
              </InputContainer>

              <button type="button" onClick={() => setModalOpened(false)}>Salvar</button>
            </form>
          </SelectStatus>

        </Content>
      </Page>
    </Container>
  );
}

export default AdminEditarPagamentos;