import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { MdErrorOutline } from 'react-icons/md';
import LoadingIcons from 'react-loading-icons'
import { MdArrowBack } from 'react-icons/md';

import api from '../../services/api';

import logoImg from '../../assets/images/logo.png';
import backgroundBlue from '../../assets/images/background-blue.png';
import backgroundGreen from '../../assets/images/background-green.png';

import { useToast } from '../../hooks/ToastContext';

import {
  Container,
  Content,
  Box,
  InputArea,
  InputContainer,
  Error,
  BgBlue,
  BgGreen,
} from './styles';

function ForgotPassword() {
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false); // Default false

  const {
    register,
    handleSubmit,
    errors,
  } = useForm();
  const onSubmit = async (data) => {
    // console.log(data);
    setLoading(true);

    try {
      // envia para a API
      await api.post('/requestresetpassword', {
        email: data.email,
      });

      history.push('/sentemail');

      setLoading(false);
    } catch (error) {
      console.log(error.response);

      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro no envio do e-mail',
        description: 'Algo deu errado. Cheque o e-mail digitado, tente novamente ou entre em contato com o Deixa no Azul.',
      });
    }

  };
  const onError = (errors) => {
    addToast({
      type: 'error',
      title: 'Erro no envio do formulário',
      description: 'Você precisa preencher todos os campos obrigatórios (marcados com *).',
    });
  };

  const anoAtual = new Date().getFullYear();
  
  return (
    <Container>
      <BgBlue src={backgroundBlue} alt="Imagem decorativa" />
      <BgGreen src={backgroundGreen} alt="Imagem decorativa" />

      <Content>
        <Box>
          <img src={logoImg} alt="Deixa no Azul" />

          <h2>Esqueceu sua senha?</h2>
          <p>Infome seu email cadastrado para recuperar a sua senha</p>

          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <InputArea>
              <label>E-mail</label>
              <InputContainer style={{ borderColor: errors.email && "red" }}>
                <input
                  name="email" 
                  type="text" 
                  // placeholder="Digite seu e-mail"
                  ref={register({ required: true })}
                />
                {errors.email && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
              </InputContainer>
            </InputArea>

            <button type="submit">
              {loading ? (
                <>
                  <LoadingIcons.Oval
                    speed={1.2}
                    style={{ width: 25, marginRight: 8 }}
                    strokeWidth={3.5}
                  />
                  <span>Enviando</span>
                </>
              ) : (
                <span>Enviar</span>
              )}
            </button>
          </form>

        </Box>

        <Link to="/">
          <MdArrowBack color="#0073FC" />
          Voltar para o login
        </Link>

        <span>© {anoAtual}. Todos os direitos reservados</span>
      </Content>
    </Container>
  );
}

export default ForgotPassword;