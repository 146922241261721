import React, { createContext, useState, useContext } from 'react';

const SimulatorContext = createContext();

function SimulatorProvider({ children }) {
  const [simulator, setSimulator] = useState({
    bandeira: "",
    conjunto_de_taxa: "",
    maquineta: "",
    quantidade_parcelas: "",
    taxa_servico: "",
    valor_acrescimo: "",
    valor_comissao_ec: "",
    valor_pagamento: "",
    valor_parcela: "",
    valor_total: "",
  });

  return (
    <SimulatorContext.Provider
      value={{
        simulator,
        setSimulator
      }}
    >
      {children}
    </SimulatorContext.Provider>
  );
};

function useSimulator() {
  const context = useContext(SimulatorContext);
  const { simulator, setSimulator } = context;
  return { simulator, setSimulator };
};

export { SimulatorProvider, useSimulator };
