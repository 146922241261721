import styled, { css } from "styled-components";
import { shade } from "polished";

import ToolTip from "../Tooltip";

export const Container = styled.div`
  background: #fff;
  border-radius: 8px;

  margin-bottom: 120px;

  h4 {
    font-size: 20px;
    font-weight: 500;
    color: #373a3c;
    margin-bottom: 30px;
  }
`;

export const Row = styled.div`
  display: flex;
  /* display: grid; */
  /* grid-template-columns: 0fr 1fr; */
  gap: 30px;
  width: 100%;
  flex-direction: row;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row4 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row5 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;

  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const ButtonRow = styled.div`
  button {
    padding: 8px 22px;
    width: 110px;
    background: #0073fc;
    border: 1px solid #0073fc;
    color: #ffffff;
    border: none;
    border-radius: 4px;

    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, "#0073FC")};
    }

    &.back {
      margin-left: 30px;

      width: 110px;
      color: #373a3c;
      background: #f5f8fb;
      border: 1px solid #adadad;

      &:hover {
        background: ${shade(0.1, "#F5F8FB")};
      }
    }
  }

  @media (max-width: 500px) {
    margin-top: 20px;
  }
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* width: 172px; */

  flex: 1;

  label {
    font-size: 16px;
    color: #373a3c;
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  /* input {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    box-sizing: border-box;
    
    height: 34px;
    width: 100%;
    padding: 0 10px;

    &.errored {
      border: 1px solid #c53030;
    }

    ${(props) =>
    props.isErrored &&
    css`
      border: 1px solid #c53030;
    `}
  } */
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;

  margin-bottom: 18px;
  padding: 1px 11px 1px 1px;

  ${(props) =>
    props.isErrored &&
    css`
      border: 1px solid #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border: 1px solid #0073fc;
    `}

  input {
    border: none;

    height: 34px;
    width: 172px;
    padding: 0 10px;
  }

  select {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const Abas = styled.div`
  padding: 24px;
`;

export const Aba1 = styled.div`
  visibility: hidden;
  height: 0;

  ${(props) =>
    props.tab1 &&
    css`
      visibility: visible;
      height: 100%;
    `}
`;

export const BoxInfo = styled.div`
  background: #f1f5f9;
  padding: 8px 16px;
  border-radius: 6px;
  margin-bottom: 16px;

  span {
    font-size: 14px;
  }

  &.modalBox {
    background: transparent;
    color: #f1f5f9;
    border: 1px solid #f1f5f9;
    margin: 10px 20px 10px;
  }
`;
