import React, { useCallback } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import { MdCheckCircle, MdError } from 'react-icons/md';

import { Container, FileInfo } from './styles';

function InfoBoleto({ files, percent, status, onDelete }) {

  const nomeDocumento = useCallback((files) => {
    if (files.name.length > 20) {
      return <strong>{`${files.name.slice(0, 11)}...${files.name.slice(-6)}`}</strong>
    } else {
      return <strong>{files.name}</strong>
    }
  }, []);

  return (
    <Container>
      {files.map(file => (
        <li key={file.name}>
          <FileInfo>
            <img src={file.preview} alt="" />
            <div>
              {nomeDocumento(file)}
              <span>
                {file.readableSize}
                {!status.uploaded && <button type="button" onClick={() => onDelete(file.id)}>Excluir</button>}
              </span>
            </div>
          </FileInfo>

          <div>
            {!status.uploaded && !status.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: '#0073FC' }
                }}
                strokeWidth={10}
                value={percent}
              />
            )}

            { status.uploaded && <MdCheckCircle size={24} color="#78e5d5" /> }
            { status.error && <MdError size={24} color="#c53030" /> }
          </div>
        </li>
      ))}
      
    </Container>
  );
}

export default InfoBoleto;
