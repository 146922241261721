import React, {useState, useCallback, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {MdErrorOutline, MdHighlightOff} from 'react-icons/md';
import {
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
} from 'react-icons/ai';
import moment from 'moment';

import {uniqueId} from 'lodash';
import {Tooltip} from '@material-ui/core/';

import Select from 'react-select';

import api from '../../services/api';
import filesize from 'filesize';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import LoadingContent from '../../components/LoadingContent';
import FormatarValor from '../../components/FormatarValor';

import paymentIcon from '../../assets/images/payment_branco_icon.svg';
import settingsIcon from '../../assets/images/settings_branco_icon.svg';
import vmehhIcon from '../../assets/images/vmehh_icon.svg';
import daIcon from '../../assets/images/da_icon.svg';
import editIcon from '../../assets/images/edit_icon.svg';

import CodigoBarrasIcon from '../../assets/images/codigo_barras.svg';

import {useToast} from '../../hooks/ToastContext';

import UploadPagamento from '../../components/Upload/pagamento';
import InfoPagamento from '../../components/UploadInfo/pagamento';

import {
  Container,
  Page,
  Content,
  RowBox1,
  RowBoxBoleto,
  RowBoxSim,
  RowBox2,
  RowBox4,
  Box,
  BoxSim,
  InputArea,
  InfoArea,
  Row,
  Row3,
  BoletoOptions,
  Head,
  PaymentInfos,
  InputContainer,
  SelectStatus,
  ModalSection,
  ModalContent,
  Aprovado,
  Mensagem,
  Confirmar,
  EditarPagamento,
  UploadContainer,
  Error,
  BoxInfo,
  Status,
  DadosBoleto,
  AcaoBoleto,
  AlterarStatus,
  ButtonPagarBoleto,
} from './styles';

function AdminEditarTransacao() {
  const [isLoading, setIsLoading] = useState(true);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const {addToast} = useToast();
  const history = useHistory();

  const [userID, setUserID] = useState('');

  const location = useLocation();
  const idTransacao = location.pathname
    .replace('/admin/transacoes/editar/', '')
    .split('')
    .reverse()
    .join('');

  const [transacaoInfos, setTransacaoInfos] = useState([]);
  const [transacaoInfosCliente, setTransacaoInfosCliente] = useState(
    []
  );
  const [transacaoInfosBoleto, setTransacaoInfosBoleto] = useState(
    []
  );
  const [
    transacaoInfosDadosBancarios,
    setTransacaoInfosDadosBancarios,
  ] = useState([]);

  const [tipoTransacao, setTipoTransacao] = useState();

  const [editStatusHide, setEditStatusHide] = useState(false);
  const [comprovantePagamentoHide, setComprovantePagamentoHide] =
    useState(true);

  const [depositoOpened, setDepositoOpened] = useState(true);
  const [boletoOptions, setBoletoOptions] = useState(true);

  // State para mudança de cor do select
  const [statusColor, setStatusColor] = useState('');

  const [valorParcela, setValorParcela] = useState();
  const [totalTransação, setTotalTransação] = useState();
  const [valorPagamento, setValorPagamento] = useState();
  const [valorAcrescimo, setValorAcrescimo] = useState();
  const [comissaoEC, setComissaoEC] = useState();
  const [comissaoLA, setComissaoLA] = useState();

  const [modalSatusClose, setModalStatusClose] = useState(true);
  const [tipoDeStatus, setTipoDeStatus] = useState('');
  const [idBoleto, setIdBoleto] = useState();

  const [uploadedComprovante, setUploadedComprovante] = useState([]);
  const [boletoIdShow, setBoletoIdShow] = useState('');
  const [progressComprovante, setProgressComprovante] = useState();
  const [atualizarComprovanteReload, setAtualizarComprovanteReload] =
    useState(false);
  const [enviarComprovante, setEnviarComprovante] = useState(false);

  const [boletoEmAberto, setBoletoEmAberto] = useState(false);
  const [boletoRejeitado, setBoletoRejeitado] = useState(false);

  const [aceitarBoletoRejeitado, setAceitarBoletoRejeitado] =
    useState(false);

  const [emailCliente, setEmailCliente] = useState(
    transacaoInfosCliente.email
  );

  const [aprovarTransacaoRejeitada, setAprovarTransacaoRejeitada] =
    useState(false);

  const [boletosPagosFinish, setBoletosPagosFinish] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    errors,
  } = useForm();
  // const onSubmit = async (data) => {
  //   // Bearer token
  //   const auth = {
  //     headers: {
  //       Authorization: "Bearer " + userToken,
  //     },
  //   };

  //   if (transacaoInfos.status === 3 && data.status === "3") {
  //     api
  //       .put(
  //         `/transacao/${transacaoInfos.id}`,
  //         {
  //           // Dados a ser alterado
  //           id_doc_pagamento: data.doc_pagamento,

  //           // Dados que não serão alterados
  //           numero_autorizacao: transacaoInfos.numero_autorizacao,
  //           numero_parcelas: transacaoInfos.numero_parcelas,
  //           valor_pagamento: transacaoInfos.valor_pagamento,
  //           valor_total: transacaoInfos.valor_total,
  //           valor_parcela: transacaoInfos.valor_parcela,
  //           valor_acrescimo: transacaoInfos.valor_acrescimo,
  //           bandeira: transacaoInfos.bandeira,
  //           quantidade_boleto: transacaoInfos.quantidade_boleto,
  //           id_doc_autorizacao: transacaoInfos.id_doc_autorizacao,
  //           id_doc_termo: transacaoInfos.id_doc_termo,
  //           id_doc_documento_foto: transacaoInfos.id_doc_documento_foto,
  //           conjunto_de_taxa: transacaoInfos.conjunto_de_taxa,
  //           estabelecimento_id: transacaoInfos.estabelecimento_id,
  //           maquineta: transacaoInfos.maquineta,
  //           taxa_servico: transacaoInfos.taxa_servico,
  //           nome: transacaoInfosCliente.nome,
  //           email: transacaoInfosCliente.email,
  //           cpf: transacaoInfosCliente.cpf,
  //           rg: transacaoInfosCliente.rg,
  //           telefone: transacaoInfosCliente.telefone,
  //         },
  //         auth
  //       )
  //       .then((response) => {
  //         // console.log(response)

  //         if (response.data.errors || response.data.error) {
  //           addToast({
  //             type: "error",
  //             title: "Erro na atualização",
  //             description:
  //               "Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.",
  //           });

  //           return;
  //         }
  //       });
  //   }

  //   // Atualiza o status para aprovado
  //   if (data.status === "3" && transacaoInfos.status !== 3) {
  //     // Verifica se um comprovante foi anexada
  //     if (uploadedPagamento.length === 0) {
  //       setError("doc_pagamento", {
  //         type: "manual",
  //         message: "Campo obrigatório.",
  //       });

  //       addToast({
  //         type: "error",
  //         title: "Erro na atualização",
  //         description: "Você precisa anexar o comprovante de pagamento.",
  //       });

  //       return;
  //     }

  //     // Faz o upload do comprovante de pagamento
  //     if (uploadedPagamento.length > 0) {
  //       const data = new FormData();

  //       uploadedPagamento.forEach((item) => {
  //         data.append(`image${uploadedPagamento.indexOf(item)}`, item.file);
  //       });

  //       await api
  //         .post("/documento_upload_mult", data, {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //             Authorization: "Bearer " + userToken,
  //           },
  //           onUploadProgress: (e) => {
  //             setProgressPagamento(
  //               parseInt(Math.round((e.loaded * 100) / e.total))
  //             );
  //           },
  //         })
  //         .then((response) => {
  //           setStatusPagamento({
  //             uploaded: true,
  //             error: false,
  //           });

  //           const value = response.data.data;
  //           setValue("doc_pagamento", value.id);
  //         })
  //         .catch(() => {
  //           setStatusPagamento({
  //             uploaded: false,
  //             error: true,
  //           });

  //           return;
  //         });
  //     }

  //     api
  //       .post(
  //         "/transacao_update_status",
  //         {
  //           status: data.status,
  //           id: transacaoInfos.id,
  //           id_doc_pagamento: getValues("doc_pagamento"),
  //         },
  //         auth
  //       )
  //       .then((response) => {
  //         console.log(response);

  //         if (response.data.errors) {
  //           addToast({
  //             type: "error",
  //             title: "Erro na atualização",
  //             description:
  //               "Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.",
  //           });

  //           return;
  //         }
  //       });
  //   }

  //   if (data.status !== "3" && data.status !== "1" && data.status !== "5") {
  //     api
  //       .post(
  //         "/transacao_update_status",
  //         {
  //           status: data.status,
  //           id: transacaoInfos.id,
  //         },
  //         auth
  //       )
  //       .then((response) => {
  //         console.log(response);

  //         if (response.data.errors) {
  //           addToast({
  //             type: "error",
  //             title: "Erro na atualização",
  //             description:
  //               "Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.",
  //           });

  //           return;
  //         }
  //       });

  //     // mensagem
  //     api
  //       .post(
  //         "/send_message",
  //         {
  //           recipient: userID,
  //           subject: "Atualização de status",
  //           message: data.message,
  //         },
  //         auth
  //       )
  //       .then((response) => response.status);
  //   }
  //////////////////////////
  //   if (data.status === "5") {
  //     api
  //       .post(
  //         "/transacao_update_status",
  //         {
  //           status: data.status,
  //           id: transacaoInfos.id,
  //         },
  //         auth
  //       )
  //       .then((response) => {
  //         console.log(response);

  //         if (response.data.errors) {
  //           addToast({
  //             type: "error",
  //             title: "Erro na atualização",
  //             description:
  //               "Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.",
  //           });

  //           return;
  //         }
  //       });
  //   }

  //   addToast({
  //     type: "success",
  //     title: "Deu tudo certo",
  //     description: "Dados atualizados com sucesso.",
  //   });

  //   // history.push("/admin/transacoes");
  // };

  const onSubmit = async (data) => {
    // Bearer token
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    // Faz o submit em caso de transação por Depósito
    if (tipoTransacao === 'Dados bancários') {
      try {
        if (transacaoInfos.status === 3 && data.status === '3') {
          // Atualiza os dados da transação
          api
            .put(
              `/transacao/${transacaoInfos.id}`,
              {
                // Dados a ser alterado
                id_doc_pagamento: data.doc_pagamento,

                // Dados que não serão alterados
                numero_autorizacao: transacaoInfos.numero_autorizacao,
                numero_parcelas: transacaoInfos.numero_parcelas,
                valor_pagamento: transacaoInfos.valor_pagamento,
                valor_total: transacaoInfos.valor_total,
                valor_parcela: transacaoInfos.valor_parcela,
                valor_acrescimo: transacaoInfos.valor_acrescimo,
                bandeira: transacaoInfos.bandeira,
                quantidade_boleto: transacaoInfos.quantidade_boleto,
                id_doc_autorizacao: transacaoInfos.id_doc_autorizacao,
                id_doc_termo: transacaoInfos.id_doc_termo,
                id_doc_documento_foto:
                  transacaoInfos.id_doc_documento_foto,
                conjunto_de_taxa: transacaoInfos.conjunto_de_taxa,
                estabelecimento_id: transacaoInfos.estabelecimento_id,
                maquineta: transacaoInfos.maquineta,
                taxa_servico: transacaoInfos.taxa_servico,
                nome: transacaoInfosCliente.nome,
                email: transacaoInfosCliente.email,
                cpf: transacaoInfosCliente.cpf,
                rg: transacaoInfosCliente.rg,
                telefone: transacaoInfosCliente.telefone,
              },
              auth
            )
            .then((response) => {
              // console.log(response)

              if (response.data.errors || response.data.error) {
                addToast({
                  type: 'error',
                  title: 'Erro na atualização',
                  description:
                    'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
                });

                return;
              }
            });
        }

        // Atualiza o status para aprovado
        if (data.status === '3' && transacaoInfos.status !== 3) {
          // Verifica se um comprovante foi anexada
          if (uploadedPagamento.length === 0) {
            setError('doc_pagamento', {
              type: 'manual',
              message: 'Campo obrigatório.',
            });

            addToast({
              type: 'error',
              title: 'Erro na atualização',
              description:
                'Você precisa anexar o comprovante de pagamento.',
            });

            return;
          }

          // Faz o upload do comprovante de pagamento
          if (uploadedPagamento.length > 0) {
            const data = new FormData();

            uploadedPagamento.forEach((item) => {
              data.append(
                `image${uploadedPagamento.indexOf(item)}`,
                item.file
              );
            });

            await api
              .post('/documento_upload_mult', data, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer ' + userToken,
                },
                onUploadProgress: (e) => {
                  setProgressPagamento(
                    parseInt(Math.round((e.loaded * 100) / e.total))
                  );
                },
              })
              .then((response) => {
                setStatusPagamento({
                  uploaded: true,
                  error: false,
                });

                const value = response.data.data;
                setValue('doc_pagamento', value.id);
              })
              .catch(() => {
                setStatusPagamento({
                  uploaded: false,
                  error: true,
                });

                return;
              });
          }

          api
            .post(
              '/transacao_update_status',
              {
                status: data.status,
                id: transacaoInfos.id,
                id_doc_pagamento: getValues('doc_pagamento'),
              },
              auth
            )
            .then((response) => {
              // console.log('update status', response);

              if (response.data.errors) {
                addToast({
                  type: 'error',
                  title: 'Erro na atualização',
                  description:
                    'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
                });

                return;
              }
            });
        }

        if (
          data.status !== '3' &&
          data.status !== '1' &&
          data.status !== '5'
        ) {
          api
            .post(
              '/transacao_update_status',
              {
                status: data.status,
                id: transacaoInfos.id,
              },
              auth
            )
            .then((response) => {
              // console.log('update status', response);

              if (response.data.errors) {
                addToast({
                  type: 'error',
                  title: 'Erro na atualização',
                  description:
                    'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
                });

                return;
              }
            });

          // mensagem
          api
            .post(
              '/send_message',
              {
                recipient: userID,
                subject: 'Atualização de status',
                message: `Transação: ${transacaoInfos.id}|${data.message}`,
              },
              auth
            )
            .then((response) => response.status);
        }

        // Atualiza o status para Checagem financeira
        if (data.status === '5') {
          api
            .post(
              '/transacao_update_status',
              {
                status: data.status,
                id: transacaoInfos.id,
              },
              auth
            )
            .then((response) => {
              // console.log('update status', response);

              if (response.data.errors) {
                addToast({
                  type: 'error',
                  title: 'Erro na atualização',
                  description:
                    'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
                });

                return;
              }
            });
        }

        addToast({
          type: 'success',
          title: 'Deu tudo certo',
          description: 'Dados atualizados com sucesso.',
        });

        history.push('/admin/transacoes');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description:
            'Ocorreu um erro ao atualizar a transação, tente novamente ou entre em contato.',
        });
      }
    }

    // Faz o submit em caso de transação por Boleto
    if (tipoTransacao === 'Boleto') {
      try {
        if (aprovarTransacaoRejeitada === false) {
          if (data.status === '3') {
            if (boletoEmAberto === true) {
              addToast({
                type: 'error',
                title: 'Erro na atualização',
                description:
                  'Não é permitido aprovar uma transação com boletos em aberto.',
              });
              return;
            }

            if (boletoRejeitado === true) {
              setAceitarBoletoRejeitado(true);
              return;
            }
          }
        }

        if (transacaoInfos.status === 3 && data.status === '3') {
          api
            .put(
              `/transacao/${transacaoInfos.id}`,
              {
                // Dados a ser alterado
                // id_doc_pagamento: data.doc_pagamento,

                // Dados que não serão alterados
                numero_autorizacao: transacaoInfos.numero_autorizacao,
                numero_parcelas: transacaoInfos.numero_parcelas,
                valor_pagamento: transacaoInfos.valor_pagamento,
                valor_total: transacaoInfos.valor_total,
                valor_parcela: transacaoInfos.valor_parcela,
                valor_acrescimo: transacaoInfos.valor_acrescimo,
                bandeira: transacaoInfos.bandeira,
                quantidade_boleto: transacaoInfos.quantidade_boleto,
                id_doc_autorizacao: transacaoInfos.id_doc_autorizacao,
                id_doc_termo: transacaoInfos.id_doc_termo,
                id_doc_documento_foto:
                  transacaoInfos.id_doc_documento_foto,
                conjunto_de_taxa: transacaoInfos.conjunto_de_taxa,
                estabelecimento_id: transacaoInfos.estabelecimento_id,
                maquineta: transacaoInfos.maquineta,
                taxa_servico: transacaoInfos.taxa_servico,
                nome: transacaoInfosCliente.nome,
                email: transacaoInfosCliente.email,
                cpf: transacaoInfosCliente.cpf,
                rg: transacaoInfosCliente.rg,
                telefone: transacaoInfosCliente.telefone,
              },
              auth
            )
            .then((response) => {
              // console.log(response)

              if (response.data.errors || response.data.error) {
                addToast({
                  type: 'error',
                  title: 'Erro na atualização',
                  description:
                    'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
                });

                return;
              }
            });
        }

        // Atualiza o status para aprovado
        if (data.status === '3' && transacaoInfos.status !== 3) {
          api
            .post(
              '/transacao_update_status',
              {
                status: data.status,
                id: transacaoInfos.id,
                // id_doc_pagamento: getValues("doc_pagamento"),
              },
              auth
            )
            .then((response) => {
              console.log(response);

              if (response.data.errors) {
                addToast({
                  type: 'error',
                  title: 'Erro na atualização',
                  description:
                    'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
                });

                return;
              }
            });
        }

        // Atualiza o status para aprovado
        if (
          data.status !== '3' &&
          data.status !== '1' &&
          data.status !== '5'
        ) {
          api
            .post(
              '/transacao_update_status',
              {
                status: data.status,
                id: transacaoInfos.id,
              },
              auth
            )
            .then((response) => {
              console.log(response);

              if (response.data.errors) {
                addToast({
                  type: 'error',
                  title: 'Erro na atualização',
                  description:
                    'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
                });

                return;
              }
            });

          // mensagem
          api
            .post(
              '/send_message',
              {
                recipient: userID,
                subject: 'Atualização de status',
                message: `Transação: ${transacaoInfos.id}|${data.message}`,
              },
              auth
            )
            .then((response) => response.status);
        }

        // Atualiza o status para Checagem financeira
        if (data.status === '5') {
          api
            .post(
              '/transacao_update_status',
              {
                status: data.status,
                id: transacaoInfos.id,
              },
              auth
            )
            .then((response) => {
              console.log(response);

              if (response.data.errors) {
                addToast({
                  type: 'error',
                  title: 'Erro na atualização',
                  description:
                    'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
                });

                return;
              }
            });
        }

        addToast({
          type: 'success',
          title: 'Deu tudo certo',
          description: 'Dados atualizados com sucesso.',
        });

        history.push('/admin/transacoes');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description:
            'Ocorreu um erro ao atualizar a transação, tente novamente ou entre em contato.',
        });
      }
    }
  };
  const onError = (errors) => {
    // console.log(errors)
    addToast({
      type: 'error',
      title: 'Erro na atualização',
      description:
        'Ocorreu um erro ao atualizar seus dados, tente novamente.',
    });
  };

  // Carrega as informações para alimentar a página
  useEffect(() => {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    async function requestTransacao() {
      const responseTransacao = await api.get(
        `/transacao/${idTransacao}`,
        auth
      );

      const result = responseTransacao.data.data.transacao;

      // console.log('resultTransacao', result);
      // console.log('nsu', result.nsu);

      // Salvar dados da transacao
      setTransacaoInfos(result);

      // Salvar dados do cliente
      api
        .get(`/transacao/cliente/${result.id_cliente}`, auth)
        .then((response) => {
          const clienteTransacao = response.data.data;

          setTransacaoInfosCliente(clienteTransacao);
          setEmailCliente(clienteTransacao.email);
        });

      if (result.quantidade_boleto > 0) {
        // Salvar dados do boleto
        api
          .get('/boleto_trans?png=1000000', auth)
          .then((response) => {
            const boletoTransacao = response.data.data;

            const boletoFiltrado = boletoTransacao.filter(
              (item) => item.id_transacao === idTransacao
            );

            // console.log(boletoTransacao);
            // console.log(boletoFiltrado);
            // console.log(idTransacao);

            boletoFiltrado.forEach((item) => {
              if (item.status === 'EM_ABERTO') {
                setBoletoEmAberto(true);
              } else {
                if (item.status === 'REJEITADO') {
                  setBoletoRejeitado(true);
                }
              }

              // console.log(item);
            });

            setTransacaoInfosBoleto(boletoFiltrado);
            setTransacaoInfosDadosBancarios([]);
          });
      }

      if (result.quantidade_boleto === 0) {
        // Salvar dados bancários
        // api.get(`/dados_banc_trans?condition`, auth);

        api
          .get('/dados_banc_trans?png=1000000', auth)
          .then((response) => {
            const dadosBancariosTransacao = response.data.data;
            // console.log('dadosBancariosTransacao', dadosBancariosTransacao);

            const dadosBancariosFiltrados =
              dadosBancariosTransacao.filter(
                (item) => item.id_transacao === idTransacao
              );

            // console.log('dadosBancariosFiltrados', dadosBancariosFiltrados);

            setTransacaoInfosDadosBancarios(
              dadosBancariosFiltrados[0]
            );
            setTransacaoInfosBoleto([]);

            if (dadosBancariosFiltrados === []) {
              setTransacaoInfosDadosBancarios({
                CPF_CNPJ: 'Dado não encontrado',
                agencia: 'Dado não encontrado',
                conta_juridica: 'Dado não encontrado',
                created_at: 'Dado não encontrado',
                deleted_at: 'Dado não encontrado',
                id: 'Dado não encontrado',
                id_transacao: 'Dado não encontrado',
                nome_banco: 'Dado não encontrado',
                nome_titular: 'Dado não encontrado',
                numero_conta: 'Dado não encontrado',
                operacao: 'Dado não encontrado',
                tipo_conta: 'Dado não encontrado',
                updated_at: 'Dado não encontrado',
              });
            }
          });
      }

      // pegar user_id do estabelecimento como destinatário das mensagens
      api
        .get(`/estabelecimento/${result.estabelecimento_id}`, auth)
        .then((response) => {
          const resultEstab = response.data.data;
          // console.log(resultEstab)

          setUserID(resultEstab.user_id);
        });

      /**
       * VALORES DA TRANSAÇÃO
       */

      // Valor da parcela
      setValorParcela(result.valor_parcela);

      // Valor Total transação
      setTotalTransação(result.valor_total);

      // Valor do pagamento (feito ao cliente final)
      setValorPagamento(result.valor_pagamento);

      // Valor do acréscimo para o EC
      setValorAcrescimo(result.valor_acrescimo);

      // Valor da comissao do EC
      setComissaoEC(responseTransacao.data.data.comissao_ec);

      /* const valores_somados =
        responseTransacao.data.data.comissao_ec;
      const comissao_ec = valores_somados.toString().split(".");
      setComissaoEC(
        `${comissao_ec[0]},${
          comissao_ec[1] ? comissao_ec[1].substring(0, 2) : "00"
        }`
      ); */

      // Valor da comissao do LA
      setComissaoLA(responseTransacao.data.data.comissao_la);

      /**
       * ANEXOS
       */

      setTipoTransacao(
        result.quantidade_boleto === 0 ? 'Dados bancários' : 'Boleto'
      );
      const tipo_transacao =
        result.quantidade_boleto === 0 ? 'Dados bancários' : 'Boleto';

      // Se Tipo de transação for DADOS BANCÁRIOS
      if (tipo_transacao === 'Dados bancários') {
        setDepositoOpened(false);
        setBoletoOptions(true);
      }

      // Se Tipo de transação for BOLETO
      if (tipo_transacao === 'Boleto') {
        setBoletoOptions(false);
        setDepositoOpened(true);
      }

      // Caso a transação esteja aprovada, esconde a edição de status
      // e mostra o box de downloado do comprovante de conclusão
      if (result.status === 3) {
        setEditStatusHide(true);
        setComprovantePagamentoHide(false);
      }

      setValue('status', result.status);
      setStatusColor(result.status.toString());

      setIsLoading(false);
    }

    requestTransacao();
  }, [
    idTransacao,
    userToken,
    setValue,
    atualizarComprovanteReload,
    boletosPagosFinish,
  ]);

  // Abrir e fechar modal
  const [modalOpened, setModalOpened] = useState(true);

  // State para definir regra de obrigatoriedade
  const [requiredMessage, setRequiredMessage] = useState(false);

  // State para mostrar campo de upload de arquivos ou mensagem
  const [aprovadoHide, setAprovadoHide] = useState(true);
  const [messageHide, setMessageHide] = useState(true);
  const [confirmarHide, setConfirmarHide] = useState(true);

  const [editarPagamentoHide, setEditarPagamentoHide] =
    useState(true);

  const changeStatus = useCallback(() => {
    const selectValue = getValues('status');

    setStatusColor(selectValue);

    if (selectValue === '3') {
      // setRequiredUpload(true);
      setRequiredMessage(false);

      setAprovadoHide(false);
      setMessageHide(true);
      setConfirmarHide(true);
    }

    if (selectValue === '2' || selectValue === '4') {
      setRequiredMessage(true);
      // setRequiredUpload(false);

      setMessageHide(false);
      setAprovadoHide(true);
      setConfirmarHide(true);
    }

    if (selectValue === '5' || selectValue === '1') {
      setRequiredMessage(false);
      // setRequiredUpload(false);

      setConfirmarHide(false);
      setMessageHide(true);
      setAprovadoHide(true);
    }
  }, [getValues]);

  const openModal = useCallback(() => {
    const selectValue = getValues('status');

    console.log(selectValue);
    console.log(transacaoInfos.status);

    if (Number(selectValue) === transacaoInfos.status) {
      addToast({
        type: 'info',
        title: 'Atualização não efetuada',
        description:
          'Você precisa selecionar um status diferente do atual.',
      });

      return;
    }

    if (selectValue !== '1') {
      setModalOpened(false);
    }

    if (selectValue === '1' && transacaoInfos.status !== 1) {
      setModalOpened(false);
    }
  }, [addToast, getValues, transacaoInfos]);

  const [pagamentoHide, setPagamentoHide] = useState(true);

  // Upload do Comprovante de pagamento da transação ------------------ START
  const [uploadedPagamento, setUploadedPagamento] = useState([]);
  const [progressPagamento, setProgressPagamento] = useState(0);
  const [statusPagamento, setStatusPagamento] = useState({
    uploaded: false,
    error: false,
  });

  // Preparar arquivos para upload
  const submitFileComprovantePagamento = useCallback(
    (file) => {
      const uploadFile = {
        file: file[0],
        id: uniqueId(),
        name: file[0].name,
        readableSize: filesize(file[0].size),
        preview: URL.createObjectURL(file[0]),
      };

      setUploadedPagamento([...uploadedPagamento, uploadFile]);
    },
    [uploadedPagamento]
  );

  const submitFileComprovanteStatus = useCallback(
    (file) => {
      const uploadFile = {
        file: file[0],
        id: uniqueId(),
        name: file[0].name,
        readableSize: filesize(file[0].size),
        preview: URL.createObjectURL(file[0]),
      };

      setUploadedComprovante([...uploadedComprovante, uploadFile]);
    },
    [uploadedComprovante]
  );

  // Faz o upload de todos os arquivos EM CASO DE EDIÇÃO DE COMPROVANTE
  const handleUploadNovoPagamento = useCallback(() => {
    // Envia para a API
    const data = new FormData();

    uploadedPagamento.forEach((item) => {
      data.append(
        `image${uploadedPagamento.indexOf(item)}`,
        item.file
      );
    });

    api
      .post('/documento_upload_mult', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userToken,
        },
        onUploadProgress: (e) => {
          setProgressPagamento(
            parseInt(Math.round((e.loaded * 100) / e.total))
          );
        },
      })
      .then((response) => {
        setStatusPagamento({
          uploaded: true,
          error: false,
        });

        const value = response.data.data;
        setValue('doc_pagamento', value.id);

        // abrir modal
        setModalOpened(false);
        setAprovadoHide(true);
        setMessageHide(true);
        setConfirmarHide(true);
        setEditarPagamentoHide(false);
      })
      .catch(() => {
        setStatusPagamento({
          uploaded: false,
          error: true,
        });
      });
  }, [uploadedPagamento, setValue, userToken]);

  // Remove o arquivo da lista de arquivos
  const handleDeletePagamento = useCallback(
    (id) => {
      const newFiles = uploadedPagamento.filter(
        (termo) => termo.id !== id
      );

      setUploadedPagamento(newFiles);
    },
    [uploadedPagamento]
  );

  // Remove o arquivo da lista de arquivos
  const handleDeleteComprovante = useCallback(
    (id) => {
      const newFiles = uploadedComprovante.filter(
        (termo) => termo.id !== id
      );

      setUploadedComprovante(newFiles);
    },
    [uploadedComprovante]
  );
  // Upload do Comprovante de pagamento da transação -------------------- END

  const [downloadBoletoHide, setDownloadBoletoHide] = useState(true);

  const [boleto, setBoleto] = useState('');
  // const [codigoBarras, setCodigoBarras] = useState("");

  async function downloadBoleto(item) {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    await api
      .get(`/documento_download/${item.id_boleto}`, auth)
      .then((response) => {
        const result = response.data.data;
        const resultReplace = result
          .replace(`<img src= '`, '')
          .replace(`' />`, '');

        setBoleto(resultReplace);
        // setCodigoBarras(item.codigo_de_barras);
        setDownloadBoletoHide(false);
      });

    setIsLoading(false);
  }

  // Faz o download do comprovante do cliente
  async function downloadComprovante() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    if (
      transacaoInfos.id_doc_termo === '' ||
      transacaoInfos.id_doc_termo === null
    ) {
      addToast({
        type: 'error',
        title: 'Erro ao fazer download',
        description:
          'Esta transação foi feita no app e não possui comprovante.',
      });

      setIsLoading(false);

      return;
    }

    await api
      .get(
        `/documento_download/${transacaoInfos.id_doc_autorizacao}`,
        auth
      )
      .then((response) => {
        const result = response.data.data;

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = result;
        const downloadLink = document.createElement('a');
        const fileName = 'comprovante.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });

    setIsLoading(false);
  }

  // Faz o download do termo
  async function downloadTermo() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    // Baixa o termo das transações feitas no web
    if (transacaoInfosCliente.assinatura_id === null) {
      await api
        .get(
          `/documento_download/${transacaoInfos.id_doc_termo}`,
          auth
        )
        .then((response) => {
          const result = response.data.data;
          // console.log(result)

          // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
          const linkSource = result;
          const downloadLink = document.createElement('a');
          const fileName = 'termo.pdf';
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
    }

    // Baixa o termo das transações feitas no app
    if (transacaoInfosCliente.assinatura_id !== null) {
      await api
        .post(`/transacao/termo/${transacaoInfos.id}`, {}, auth)
        .then((response) => {
          const result = response.data;
          // console.log(result)

          // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
          const linkSource = `data:application/pdf;base64,${result}`;
          const downloadLink = document.createElement('a');
          const fileName = 'termo.pdf';
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
    }

    setIsLoading(false);
  }

  // Faz o download do documento com foto
  async function downloadDocFoto() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    await api
      .get(
        `/documento_download/${transacaoInfos.id_doc_documento_foto}`,
        auth
      )
      .then((response) => {
        const result = response.data.data;

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = result;
        const downloadLink = document.createElement('a');
        const fileName = 'documento_com_foto.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });

    setIsLoading(false);
  }

  // Faz o download do comprovante de conclusão anexado pelo Admin
  async function downloadComprovanteConclusao() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    await api
      .get(
        `/documento_download/${transacaoInfos.id_doc_pagamento}`,
        auth
      )
      .then((response) => {
        const result = response.data.data;

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = result;
        const downloadLink = document.createElement('a');
        const fileName = 'comprovante_conclusão.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });

    setIsLoading(false);
  }

  const onSubmitSatatusBoleto = async () => {
    // Bearer token
    /* const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    }; */

    if (uploadedComprovante.length > 0) {
      const data = new FormData();

      uploadedComprovante.forEach((item) => {
        data.append(
          `image${uploadedComprovante.indexOf(item)}`,
          item.file
        );
      });

      // console.log(data);
      // console.log(uploadedComprovante);

      await api
        .post('/documento_upload_mult', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + userToken,
          },
          onUploadProgress: (e) => {
            setProgressComprovante(
              parseInt(Math.round((e.loaded * 100) / e.total))
            );
          },
        })
        .then(async (response) => {
          const value = response.data.data;

          // console.log(value);

          await api
            .post(
              `/boleto/updateStatus/${idBoleto}`,
              {
                status: tipoDeStatus,
                comprovante_id: value.id,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + userToken,
                },
              }
            )
            .then((response) => {
              setStatusPagamento({
                uploaded: true,
                error: false,
              });

              setUploadedComprovante([]);
              setModalStatusClose(!modalSatusClose);
              setAtualizarComprovanteReload(
                !atualizarComprovanteReload
              );

              addToast({
                type: 'success',
                title: 'Deu tudo certo',
                description: 'Dados atualizados com sucesso.',
              });

              setStatusPagamento({
                uploaded: false,
                error: false,
              });
            })
            .catch((error) => {
              console.log(error.response);
              return;
            });

          setValue('doc_pagamento', value.id);
          window.location.reload();
        })
        .catch((error) => {
          setStatusPagamento({
            uploaded: false,
            error: true,
          });

          console.log(error.response);

          return;
        });
    } else {
      if (tipoDeStatus === 'PAGO') {
        setEnviarComprovante(true);
      }

      if (
        tipoDeStatus === 'REJEITADO' ||
        tipoDeStatus === 'EM_ABERTO'
      ) {
        await api
          .post(
            `/boleto/updateStatus/${idBoleto}`,
            {
              status: tipoDeStatus,
            },
            {
              headers: {
                Authorization: 'Bearer ' + userToken,
              },
            }
          )
          .then((response) => {
            setStatusPagamento({
              uploaded: true,
              error: false,
            });

            setUploadedComprovante([]);
            setModalStatusClose(!modalSatusClose);
            setAtualizarComprovanteReload(
              !atualizarComprovanteReload
            );

            addToast({
              type: 'success',
              title: 'Deu tudo certo',
              description: 'Dados atualizados com sucesso.',
            });

            setStatusPagamento({
              uploaded: false,
              error: false,
            });

            window.location.reload();
          })
          .catch((error) => {
            console.log(error.response);
            return;
          });
      }
    }
  };

  // SO PODE PARGAR O BOLETO SE ESTIVER EM ABERTO
  async function pagarBoleto(id) {
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    try {
      setIsLoading(true);

      await api.get(`/payBill/${id}`, auth);

      setIsLoading(false);

      addToast({
        type: 'success',
        title: 'Deu tudo certo',
        description: 'Boleto pago com sucesso.',
      });

      setAtualizarComprovanteReload(!atualizarComprovanteReload);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Houve um problema',
        description: error.response.data.message,
      });
      console.log(error.response);
      setIsLoading(false);
    }
  }

  // SO PODE PARGAR O BOLETO SE ESTIVER EM ABERTO
  async function pagarMultBoletos() {
    if (transacaoInfosBoleto.length > 0) {
      try {
        transacaoInfosBoleto.map(async (item) => {
          if (item.status === 'EM_ABERTO') {
            const auth = {
              headers: {
                Authorization: 'Bearer ' + userToken,
              },
            };

            try {
              setIsLoading(true);

              await api.get(`/payBill/${item.id}`, auth);

              setIsLoading(false);

              addToast({
                type: 'success',
                title: 'Deu tudo certo',
                description: 'Boleto pago com sucesso.',
              });
            } catch (error) {
              addToast({
                type: 'error',
                title: 'Houve um problema',
                description: error.response.data.message,
              });
            }
          }

          setAtualizarComprovanteReload(!atualizarComprovanteReload);
        });
      } catch (error) {}
    }
  }

  async function reenviarComprovante() {
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    try {
      setIsLoading(true);

      await api.post(
        `/transacao/resendComprovante/${transacaoInfos.id}`,
        {
          email: emailCliente,
        },
        auth
      );

      setIsLoading(false);

      addToast({
        type: 'success',
        title: 'Comprovante enviado',
        description: 'Comprovante enviado com sucesso.',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao enviar',
        description: 'Erro ao enviar comprovante',
      });
    }
  }

  function valicacaoNSU() {
    if (transacaoInfos.nsu === 'failed') {
      return (
        <span
          style={{
            backgroundColor: '#f35757',
            color: '#fff',
            fontSize: '14px',
            fontWeight: 600,
            padding: '6px 12px',
            borderRadius: '3px',
          }}
        >
          Falha na validação
        </span>
      );
    }
    if (transacaoInfos.nsu === null) {
      return (
        <span
          style={{
            backgroundColor: '#feac02',
            color: '#fff',
            fontSize: '14px',
            fontWeight: 600,
            padding: '6px 12px',
            borderRadius: '3px',
          }}
        >
          Validação em análise
        </span>
      );
    }
    if (
      transacaoInfos.nsu !== null &&
      transacaoInfos.nsu !== 'failed'
    ) {
      return (
        <>
          <span
            style={{
              backgroundColor: '#5cb85c',
              color: '#fff',
              fontSize: '14px',
              fontWeight: 600,
              padding: '6px 12px',
              borderRadius: '3px',
            }}
          >
            Transação validada
          </span>
          <br />
          <p style={{marginTop: 10}}>NSU: {transacaoInfos.nsu}</p>
        </>
      );
    }
  }

  async function pagarBoletoTransacao() {
    try {
      const auth = {
        headers: {
          Authorization: 'Bearer ' + userToken,
        },
      };
      if (boletoEmAberto) {
        setIsLoading(true);

        await api.post(
          '/boleto/paycappta',
          {
            id_transaction: transacaoInfos.id,
          },
          auth
        );

        transacaoInfosBoleto.map(async (boleto) => {
          if (boleto.status === 'EM_ABERTO') {
            await api.post(
              `/boleto/updateStatus/${boleto.id}`,
              {
                status: 'PAGO',
                //Comprovante fake
                comprovante_id:
                  '54654122-9ce9-409b-9425-de1373321bbd',
              },
              auth
            );
          }
        });

        setIsLoading(false);

        addToast({
          type: 'success',
          title: 'Boleto pago',
          description: 'Boleto pago com sucesso.',
        });

        setBoletosPagosFinish(!boletosPagosFinish);
      } else {
        addToast({
          type: 'error',
          title: 'Boleto',
          description: 'Nem um boleto em aberto.',
        });
      }
    } catch (error) {
      console.log(error);
      addToast({
        type: 'error',
        title: 'Erro ao pagar',
        description: 'Erro ao pagar boleto',
      });
    }
  }

  return (
    <Container>
      <LoadingContent isLoading={isLoading} />

      <ModalSection modalOpened={modalOpened}>
        <ModalContent>
          <MdHighlightOff
            size={30}
            onClick={() => setModalOpened(!modalOpened)}
          />
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Aprovado aprovadoHide={aprovadoHide}>
              {tipoTransacao === 'Dados bancários' && (
                <span>Anexar comprovante da transação</span>
              )}

              {tipoTransacao === 'Dados bancários' && (
                <BoxInfo>
                  <span>
                    Você pode anexar até 10 documentos. Após
                    selecionar os documentos, clique em <b>Enviar.</b>
                  </span>{' '}
                  <br />
                </BoxInfo>
              )}

              {/* <UploadContainer style={{ borderColor: errors.doc_pagamento && "red" }}>
                <UploadPagamento
                  onUpload={handlePagamentoUpload}
                  // isDisabled={uploadedPagamento.file ? true : false}
                />
                <input type="text" name="doc_pagamento" ref={register({ required: requiredUpload })} hidden />
                {errors.doc_pagamento && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
              </UploadContainer>
              {!!uploadedPagamento.file && <InfoPagamento file={uploadedPagamento} percent={progressPagamento} status={statusPagamento} />} */}

              {tipoTransacao === 'Dados bancários' && (
                <InputArea className="campoAnexo">
                  <label>*Comprovante de pagamento</label>
                  <UploadContainer
                    style={{
                      borderColor: errors.doc_pagamento && 'red',
                    }}
                  >
                    <UploadPagamento
                      onUpload={submitFileComprovantePagamento}
                      isDisabled={
                        uploadedPagamento.length === 10 ? true : false
                      }
                      isWhite={false}
                    />
                    <input
                      type="text"
                      name="doc_pagamento"
                      ref={register}
                      hidden
                    />
                    {errors.doc_pagamento && (
                      <Error title="Campo obrigatório">
                        <MdErrorOutline color="c53030" size={20} />
                      </Error>
                    )}
                  </UploadContainer>
                  <div className="upload">
                    {!!uploadedPagamento.length && (
                      <InfoPagamento
                        files={uploadedPagamento}
                        percent={progressPagamento}
                        status={statusPagamento}
                        onDelete={(id) => handleDeletePagamento(id)}
                        isBlack={false}
                      />
                    )}
                  </div>
                </InputArea>
              )}

              {aceitarBoletoRejeitado === false ? (
                <span style={{textAlign: 'center'}}>
                  Tem certeza que deseja aprovar essa transação?
                </span>
              ) : (
                <span
                  style={{textAlign: 'center', fontWeight: 'bold'}}
                >
                  Existe um ou mais boleto com o status rejeitado,
                  <br /> deseja salvar a transação como concluída{' '}
                  <br /> mesmo assim?
                </span>
              )}
            </Aprovado>

            <Mensagem messageHide={messageHide}>
              <span>Digite um comentário para o Estabelecimento</span>

              <div className="inputArea">
                <div className="labelRow">
                  <label>Digite aqui</label>
                  {errors.message && (
                    <Error
                      title="Campo obrigatório"
                      style={{marginLeft: '8px'}}
                    >
                      <MdErrorOutline
                        color="c53030"
                        size={20}
                        style={{marginBottom: '8px'}}
                      />
                    </Error>
                  )}
                </div>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  ref={register({required: requiredMessage})}
                  style={{borderColor: errors.message && 'red'}}
                />
              </div>
            </Mensagem>

            <Confirmar confirmarHide={confirmarHide}>
              <span>
                Tem certeza que deseja alterar o status da transação?
              </span>
            </Confirmar>

            <EditarPagamento
              editarPagamentoHide={editarPagamentoHide}
            >
              <span>
                Tem certeza que deseja alterar o comprovante de
                pagamento?
              </span>
            </EditarPagamento>

            <div className="buttonRow">
              {aceitarBoletoRejeitado === false ? (
                <button type="submit">Alterar</button>
              ) : (
                <button
                  type="submit"
                  onClick={() => {
                    setAprovarTransacaoRejeitada(true);
                  }}
                >
                  Salvar
                </button>
              )}
              <button
                className="cancelar"
                type="button"
                onClick={() => setModalOpened(!modalOpened)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </ModalContent>
      </ModalSection>

      <ModalSection modalOpened={modalSatusClose}>
        <ModalContent>
          <MdHighlightOff
            size={30}
            onClick={() => setModalStatusClose(!modalSatusClose)}
          />
          <AlterarStatus>
            <span>Alterar status do boleto para:</span>
            <div
              style={{
                width: 180,
                marginTop: 40,
                marginBottom: 40,
                color: '#000',
              }}
            >
              <Select
                placeholder={tipoDeStatus}
                onChange={(value) => setTipoDeStatus(value.value)}
                options={[
                  {value: 'EM_ABERTO', label: 'Em aberto'},
                  {value: 'PAGO', label: 'Pago'},
                  {value: 'REJEITADO', label: 'Rejeitado'},
                ]}
              />
            </div>

            {tipoDeStatus === 'PAGO' && (
              <>
                <span className="anexar-comprovante">
                  Anexar comprovante de pagamento
                </span>

                <InputArea
                  className="campoAnexo"
                  style={{marginBottom: 30}}
                >
                  <UploadContainer
                    style={{
                      borderColor: enviarComprovante && 'red',
                      width: 170,
                    }}
                    onClick={() => {
                      setEnviarComprovante(false);
                    }}
                  >
                    <UploadPagamento
                      onUpload={submitFileComprovanteStatus}
                      isDisabled={
                        uploadedComprovante.length === 10
                          ? true
                          : false
                      }
                      isWhite={false}
                    />
                    <input
                      type="text"
                      name="doc_pagamento"
                      ref={register}
                      hidden
                    />
                    {errors.doc_pagamento && (
                      <Error title="Campo obrigatório">
                        <MdErrorOutline color="c53030" size={20} />
                      </Error>
                    )}
                  </UploadContainer>
                  <div className="upload">
                    {!!uploadedComprovante.length && (
                      <InfoPagamento
                        files={uploadedComprovante}
                        percent={progressComprovante}
                        status={statusPagamento}
                        onDelete={(id) => handleDeleteComprovante(id)}
                        isBlack={false}
                      />
                    )}
                  </div>
                </InputArea>
              </>
            )}

            {tipoDeStatus === 'REJEITADO' && (
              <>
                <span className="anexar-comprovante">
                  Se desejar, anexe comprovante de erro
                </span>

                <InputArea
                  style={{marginBottom: 30}}
                  className="campoAnexo"
                >
                  <UploadContainer
                    style={{
                      borderColor: errors.doc_pagamento && 'red',
                      width: 170,
                    }}
                  >
                    <UploadPagamento
                      onUpload={submitFileComprovanteStatus}
                      isDisabled={
                        uploadedComprovante.length === 10
                          ? true
                          : false
                      }
                      isWhite={false}
                    />
                    <input
                      type="text"
                      name="doc_pagamento"
                      ref={register}
                      hidden
                    />
                    {errors.doc_pagamento && (
                      <Error title="Campo obrigatório">
                        <MdErrorOutline color="c53030" size={20} />
                      </Error>
                    )}
                  </UploadContainer>
                  <div className="upload">
                    {!!uploadedComprovante.length && (
                      <InfoPagamento
                        files={uploadedComprovante}
                        percent={progressComprovante}
                        status={statusPagamento}
                        onDelete={(id) => handleDeleteComprovante(id)}
                        isBlack={false}
                      />
                    )}
                  </div>
                </InputArea>
              </>
            )}

            <div className="buttons">
              <button
                onClick={() => {
                  onSubmitSatatusBoleto();
                }}
                className="salvar"
              >
                Salvar
              </button>

              <button
                onClick={() => {
                  setModalStatusClose(true);
                }}
                className="cancelar"
              >
                {' '}
                Cancelar
              </button>
            </div>
          </AlterarStatus>
        </ModalContent>
      </ModalSection>

      <MenuBar />
      <NewMobileMenu />

      <Page>
        <TopBar
          pageTitle="Transação -"
          pageSubtitle="Deixa no Azul"
        />

        <Content>
          <RowBox1>
            <Box>
              <h3>Informações da transação</h3>

              <Row>
                <InputArea>
                  <label>Número de Validação (nsu) </label>
                  <p>{valicacaoNSU()}</p>
                </InputArea>

                <InputArea>
                  <label>Nome</label>
                  <p>{transacaoInfosCliente.nome}</p>
                </InputArea>
              </Row>

              <Row>
                <InputArea>
                  <label>Número do CPF</label>
                  <p>{transacaoInfosCliente.cpf}</p>
                </InputArea>

                <InputArea>
                  <label>Número do RG</label>
                  <p>{transacaoInfosCliente.rg}</p>
                </InputArea>
              </Row>

              <Row>
                <InputArea>
                  <label>E-mail</label>
                  <p>{transacaoInfosCliente.email}</p>
                </InputArea>

                <InputArea>
                  <label>Telefone</label>
                  <p>{transacaoInfosCliente.telefone}</p>
                </InputArea>
              </Row>

              <Row>
                <InputArea>
                  <label>Data de Criação</label>
                  <p>
                    {moment(transacaoInfosCliente.created_at).format(
                      'DD/MM/YYYY'
                    )}
                  </p>
                </InputArea>

                <InputArea>
                  <label>Última Atualização</label>
                  <p>
                    {moment(transacaoInfosCliente.updated_at).format(
                      'DD/MM/YYYY'
                    )}
                  </p>
                </InputArea>
              </Row>
            </Box>

            <div>
              <Box>
                <h3>Informações para pagamentos</h3>

                <InputArea>
                  <label>Tipo de transação</label>
                  <p>{tipoTransacao}</p>
                </InputArea>
              </Box>

              <BoletoOptions
                boletoOptions={boletoOptions}
                className="boleto_infos"
              >
                <InputArea>
                  <label>Quantidade de Boletos</label>
                  <p>{transacaoInfos.quantidade_boleto}</p>
                </InputArea>

                {/* <InputArea>
                  <label>Código de barra do boleto</label>
                  <p>{transacaoInfosBoleto.codigo_de_barras}</p>
                </InputArea> */}
              </BoletoOptions>
            </div>
          </RowBox1>

          <RowBoxBoleto boletoOptions={boletoOptions}>
            <Box>
              <div className="pagar-boleto">
                <h3>Informações sobre o(s) boleto(s)</h3>

                {boletoEmAberto === true && (
                  <button onClick={pagarMultBoletos}>
                    Pagar Todos
                  </button>
                )}
              </div>

              {transacaoInfosBoleto.map((item) => {
                return (
                  <>
                    <div
                      style={{
                        // background: "red",
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className="dados"
                    >
                      <DadosBoleto>
                        {item.id === boletoIdShow &&
                        downloadBoletoHide === false ? (
                          <AiOutlineMinusCircle
                            color="#0073FC"
                            size={25}
                            style={{cursor: 'pointer'}}
                            onClick={() =>
                              setDownloadBoletoHide(true)
                            }
                          />
                        ) : (
                          <AiOutlinePlusCircle
                            color="#0073FC"
                            size={25}
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              downloadBoleto(item);
                              setBoletoIdShow(item.id);
                            }}
                          />
                        )}

                        <img
                          style={{width: 30, marginLeft: 20}}
                          src={CodigoBarrasIcon}
                          alt=""
                        />

                        <span style={{marginLeft: 20}}>
                          Boleto{' '}
                          {transacaoInfosBoleto.indexOf(item) + 1}
                        </span>

                        <Status
                          style={{marginLeft: 20}}
                          tipoDeStatus={item.status}
                        >
                          {item.status}
                        </Status>
                      </DadosBoleto>

                      {item.status !== 'PAGO' && (
                        <AcaoBoleto
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <span
                            onClick={() => {
                              setModalStatusClose(false);
                              setTipoDeStatus(item.status);
                              setIdBoleto(item.id);
                            }}
                            className="status"
                          >
                            status
                          </span>

                          {item.status !== 'REJEITADO' && (
                            <span
                              onClick={() => {
                                pagarBoleto(item.id);
                              }}
                              className="pagar"
                            >
                              pagar
                            </span>
                          )}
                        </AcaoBoleto>
                      )}
                    </div>
                    {item.id === boletoIdShow && (
                      <Row
                        className="marginTop"
                        downloadBoletoHide={downloadBoletoHide}
                      >
                        <InputArea>
                          <label>Código de barra do boleto</label>
                          <p>{item.codigo_de_barras}</p>
                        </InputArea>

                        <InputArea>
                          <label>*Boleto</label>
                          <a download href={boleto}>
                            Fazer Download
                          </a>
                        </InputArea>
                      </Row>
                    )}
                  </>
                );
              })}
            </Box>

            <Box className="boxAnexos">
              <h3>Anexos do cliente</h3>

              <Row className="anexos">
                <InputArea>
                  <Tooltip
                    title="Anexos dos comprovantes da transação"
                    placement="top"
                    arrow
                  >
                    <label>Comprovante</label>
                  </Tooltip>
                  <button
                    type="button"
                    className="downloadButton"
                    onClick={() => downloadComprovante()}
                  >
                    Fazer Download
                  </button>
                </InputArea>

                <InputArea>
                  <Tooltip
                    title="Anexos dos termos da transação"
                    placement="top"
                    arrow
                  >
                    <label>Anexar Termo</label>
                  </Tooltip>
                  <button
                    type="button"
                    className="downloadButton"
                    onClick={() => downloadTermo()}
                  >
                    Fazer Download
                  </button>
                </InputArea>
              </Row>

              <InputArea>
                <Tooltip
                  title="Anexos dos documentos com foto do cliente"
                  placement="top"
                  arrow
                >
                  <label className="marginTop">
                    Documento com foto
                  </label>
                </Tooltip>
                <button
                  type="button"
                  className="downloadButton"
                  onClick={() => downloadDocFoto()}
                >
                  Fazer Download
                </button>
              </InputArea>
            </Box>
          </RowBoxBoleto>

          <RowBoxSim>
            <BoxSim className="box1">
              <Head>
                <h3>Valores da parcela</h3>

                <PaymentInfos>
                  <div>
                    <img src={paymentIcon} alt="Maquineta" />
                    <p>{transacaoInfos.maquineta}</p>
                  </div>

                  <div>
                    <img src={settingsIcon} alt="Conjunto de taxas" />
                    <p>{transacaoInfos.conjunto_de_taxa}</p>
                  </div>
                </PaymentInfos>
              </Head>

              <Row3>
                <InfoArea>
                  <label>Valor da parcela</label>
                  <p>
                    {transacaoInfos.numero_parcelas}x R${' '}
                    {FormatarValor(valorParcela)}
                  </p>
                </InfoArea>

                <InfoArea>
                  <label>Valor da taxa</label>
                  <p>{transacaoInfos.taxa_servico}%</p>
                </InfoArea>

                <InfoArea>
                  <label>Valor total</label>
                  <p>R$ {FormatarValor(totalTransação)}</p>
                </InfoArea>
              </Row3>
            </BoxSim>

            <BoxSim className="box2">
              <h3>Comissão do EC</h3>

              <InfoArea>
                <label>
                  Valor que ganhará nessa transação{' '}
                  <i>(comissão + TS)</i>
                </label>
                <p>R$ {FormatarValor(comissaoEC)}</p>
              </InfoArea>
            </BoxSim>

            <BoxSim className="box3">
              <h3>Valores da simulação</h3>

              <Row>
                <InfoArea>
                  <label>Valor pago ao cliente</label>
                  <p>R$ {FormatarValor(valorPagamento)}</p>
                </InfoArea>

                <InfoArea>
                  <label>Quantidade de parcelas </label>
                  <p>{transacaoInfos.numero_parcelas}x</p>
                </InfoArea>
              </Row>

              <Row>
                <InfoArea>
                  <label>Comissão da rede</label>
                  <p>+ R$ {FormatarValor(valorAcrescimo)}</p>
                </InfoArea>

                <InfoArea>
                  <label>Bandeiras</label>
                  {transacaoInfos.bandeira === 'VMEHH' ? (
                    <img src={vmehhIcon} alt="Bandeira" />
                  ) : (
                    <img src={daIcon} alt="Bandeira" />
                  )}
                </InfoArea>
              </Row>
            </BoxSim>

            <BoxSim className="box4">
              <h3>Comissão do LA</h3>

              <InfoArea>
                <label>Valor que ganhará nessa transação</label>
                <p>R$ {FormatarValor(comissaoLA)}</p>
              </InfoArea>
            </BoxSim>
          </RowBoxSim>

          <RowBox2 depositoOpened={depositoOpened}>
            <Box>
              <h3>Informações da conta bancária</h3>

              <Row>
                <InputArea>
                  <label>Nome do titular</label>
                  {transacaoInfosDadosBancarios === undefined ? (
                    <p style={{color: '#f35757'}}>
                      Dado não encontrado
                    </p>
                  ) : (
                    <p>{transacaoInfosDadosBancarios.nome_titular}</p>
                  )}
                </InputArea>

                <InputArea>
                  <label>Nome do banco</label>
                  {transacaoInfosDadosBancarios === undefined ? (
                    <p style={{color: '#f35757'}}>
                      Dado não encontrado
                    </p>
                  ) : (
                    <p>{transacaoInfosDadosBancarios.nome_banco}</p>
                  )}
                </InputArea>
              </Row>

              <Row3>
                <InputArea>
                  <label>Selecione a Entidade</label>
                  {transacaoInfosDadosBancarios === undefined ? (
                    <p style={{color: '#f35757'}}>
                      Dado não encontrado
                    </p>
                  ) : (
                    <p>
                      {transacaoInfosDadosBancarios.conta_juridica ===
                      0
                        ? 'Pessoa Física'
                        : 'Pessoa Jurídica'}
                    </p>
                  )}
                </InputArea>

                <InputArea>
                  <label>CPF/CNPJ</label>
                  {transacaoInfosDadosBancarios === undefined ? (
                    <p style={{color: '#f35757'}}>
                      Dado não encontrado
                    </p>
                  ) : (
                    <p>{transacaoInfosDadosBancarios.CPF_CNPJ}</p>
                  )}
                </InputArea>

                <InputArea>
                  <label>Tipo de conta</label>
                  {transacaoInfosDadosBancarios === undefined ? (
                    <p style={{color: '#f35757'}}>
                      Dado não encontrado
                    </p>
                  ) : (
                    <p>
                      {transacaoInfosDadosBancarios.tipo_conta === '0'
                        ? 'Corrente'
                        : 'Poupança'}
                    </p>
                  )}
                </InputArea>
              </Row3>

              <Row3>
                <InputArea>
                  <label>Agência</label>
                  {transacaoInfosDadosBancarios === undefined ? (
                    <p style={{color: '#f35757'}}>
                      Dado não encontrado
                    </p>
                  ) : (
                    <p>{transacaoInfosDadosBancarios.agencia}</p>
                  )}
                </InputArea>

                <InputArea>
                  <label>Número da conta</label>
                  {transacaoInfosDadosBancarios === undefined ? (
                    <p style={{color: '#f35757'}}>
                      Dado não encontrado
                    </p>
                  ) : (
                    <p>{transacaoInfosDadosBancarios.numero_conta}</p>
                  )}
                </InputArea>

                <InputArea>
                  <label>Operação</label>
                  {transacaoInfosDadosBancarios === undefined ? (
                    <p style={{color: '#f35757'}}>
                      Dado não encontrado
                    </p>
                  ) : (
                    <p>{transacaoInfosDadosBancarios.operacao}</p>
                  )}
                </InputArea>
              </Row3>

              <Row3>
                <InputArea>
                  <label>Chave Pix</label>
                  {transacaoInfosDadosBancarios === undefined ? (
                    <p style={{color: '#f35757'}}>
                      Dado não encontrado
                    </p>
                  ) : (
                    <p>{transacaoInfosDadosBancarios.chave_pix}</p>
                  )}
                </InputArea>
              </Row3>
            </Box>

            <Box className="boxAnexos">
              <h3>Anexos do cliente</h3>

              <InputArea>
                <Tooltip
                  title="Anexos dos comprovantes da transação"
                  placement="top"
                  arrow
                >
                  <label>Comprovante</label>
                </Tooltip>
                <button
                  type="button"
                  className="downloadButton"
                  onClick={() => downloadComprovante()}
                >
                  Fazer Download
                </button>
              </InputArea>

              <InputArea>
                <Tooltip
                  title="Anexos dos termos da transação"
                  placement="top"
                  arrow
                >
                  <label>Termo</label>
                </Tooltip>
                <button
                  type="button"
                  className="downloadButton"
                  onClick={() => downloadTermo()}
                >
                  Fazer Download
                </button>
              </InputArea>

              <InputArea>
                <Tooltip
                  title="Anexos dos documentos com foto do cliente"
                  placement="top"
                  arrow
                >
                  <label className="marginTop">
                    Documento com foto
                  </label>
                </Tooltip>
                <button
                  type="button"
                  className="downloadButton"
                  onClick={() => downloadDocFoto()}
                >
                  Fazer Download
                </button>
              </InputArea>
            </Box>
          </RowBox2>

          <RowBox4
            comprovantePagamentoHide={comprovantePagamentoHide}
          >
            <Box>
              <h3>Anexos do Admin</h3>

              <InputArea className="campoAnexo">
                <Tooltip
                  title="Anexos dos comprovantes de conclusão do pagamento pelo Admin"
                  placement="top"
                  arrow
                >
                  <label>Comprovante de conclusão</label>
                </Tooltip>
                <div className="action">
                  <button
                    type="button"
                    className="downloadButton"
                    style={{marginBottom: '0px'}}
                    onClick={() => downloadComprovanteConclusao()}
                  >
                    Fazer Download
                  </button>
                  <img
                    src={editIcon}
                    alt=""
                    onClick={() => setPagamentoHide(!pagamentoHide)}
                  />
                </div>

                <UploadContainer
                  className="edição"
                  style={{borderColor: errors.doc_pagamento && 'red'}}
                  pagamentoHide={pagamentoHide}
                >
                  <UploadPagamento
                    onUpload={submitFileComprovantePagamento}
                    isDisabled={
                      uploadedPagamento.length === 4 ? true : false
                    }
                    isWhite={true}
                  />
                  <input
                    type="text"
                    name="doc_pagamento"
                    ref={register}
                    hidden
                  />
                  {errors.doc_pagamento && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </UploadContainer>
                {!!uploadedPagamento.length && (
                  <InfoPagamento
                    files={uploadedPagamento}
                    percent={progressPagamento}
                    status={statusPagamento}
                    onDelete={(id) => handleDeletePagamento(id)}
                    isBlack={true}
                  />
                )}

                {!!uploadedPagamento.length &&
                  statusPagamento.uploaded === false && (
                    <button
                      type="button"
                      style={{marginBottom: 0}}
                      onClick={() => handleUploadNovoPagamento()}
                    >
                      Enviar arquivos
                    </button>
                  )}
              </InputArea>
            </Box>

            <Box>
              <h3>Reenviar Comprovante</h3>

              <input
                value={emailCliente}
                className="email"
                onChange={(e) => setEmailCliente(e.target.value)}
              />

              <div className="box-button">
                <button
                  onClick={reenviarComprovante}
                  className="button-enviar"
                >
                  Enviar
                </button>
              </div>
            </Box>
          </RowBox4>

          <SelectStatus editStatusHide={editStatusHide}>
            {transacaoInfos.status === 5 &&
              tipoTransacao === 'Boleto' && (
                <ButtonPagarBoleto onClick={pagarBoletoTransacao}>
                  Pagar boleto
                </ButtonPagarBoleto>
              )}

            <form /* onSubmit={handleSubmit(onSubmit, onError)} */>
              <InputContainer statusColor={statusColor}>
                <select
                  name="status"
                  placeholder="Selecione"
                  ref={register({required: true})}
                  onChange={changeStatus}
                >
                  <option value="1">Em Análise</option>
                  <option value="2">Solicitar documentos</option>
                  <option value="3">Aprovada</option>
                  <option value="4">Cancelada</option>
                  <option value="5">Checagem financeira</option>
                </select>
              </InputContainer>

              <button
                type="button"
                onClick={() => {
                  openModal();
                }}
              >
                Salvar
              </button>
            </form>
          </SelectStatus>
        </Content>
      </Page>
    </Container>
  );
}

export default AdminEditarTransacao;
