import React from 'react';

import Dropzone from 'react-dropzone';

import anexoIcon from '../../assets/images/anexo_branco_icon.svg';
import anexoIconGray from '../../assets/images/anexo_icon.svg';

import { InputPagamentoContainer } from './styles';

function UploadNotaFiscal({ onUpload, isDisabled, isWhite }) {

  const containerClass = isDisabled === true ? "disabled" : "";
  const icon = isWhite === false ? anexoIcon : anexoIconGray;

  return (
    <Dropzone accept="image/jpeg,image/jpg,image/png,.pdf" onDropAccepted={file => onUpload(file)}>
      { ({ getRootProps, getInputProps }) => (
        <InputPagamentoContainer
          {...getRootProps()}
          // isDragActive={isDragActive}
          // isDragReject={isDragReject}
          className={containerClass}
          isWhite={isWhite}
        >
          <input {...getInputProps()} disabled={isDisabled} />
          <img src={icon} alt=""/>
          Escolher arquivo
        </InputPagamentoContainer>
      ) }
    </Dropzone>
  );
}

export default UploadNotaFiscal;
