import React, {createContext, useContext, useState} from 'react';

const ActiveMenuContext = createContext();

const ActiveMenuProvider = ({children}) => {
  const [activeSideMenu, setActiveSideMenu] = useState(false);

  return (
    <ActiveMenuContext.Provider
      value={{activeSideMenu, setActiveSideMenu}}
    >
      {children}
    </ActiveMenuContext.Provider>
  );
};

function useActiveMenu() {
  const context = useContext(ActiveMenuContext);

  if (!context) {
    throw new Error(
      'useActiveMenu must be used within a ActiveMenuProvider'
    );
  }

  return context;
}

export {ActiveMenuProvider, useActiveMenu};
