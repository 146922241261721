import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { useAuth } from "../hooks/AuthContext";

// Componente que faz o redirecionamento de rotas autenticadas
import Route from "./Route";

import SignIn from "../pages/SignIn";
import PoliticaPrivacidade from "../pages/PoliticaPrivacidade";

import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import SentEmail from "../pages/SentEmail";
import Profile from "../pages/Profile";
import Mensagens from "../pages/Mensagens";

import Acompanhamento from "../pages/Acompanhamento";
import AcompanhamentoInterna from "../pages/AcompanhamentoInterna";

import EC from "../pages/EC";
import EditEC from "../pages/EditEC";

import Simulador from "../pages/Simulador";
import CriarTransacao from "../pages/CriarTransacao";
import Transacoes from "../pages/Transacoes";
import EditarTransacao from "../pages/EditarTransacao";
import VerTransacao from "../pages/VerTransacao";

import TransacoesContaDeLuz from "../pages/TransacoesContaDeLuz";
import SimuladorTransacaoContaDeLuz from "../pages/SimuladorTransacaoContaDeLuz";
import CriarTransacaoContaDeLuz from "../pages/CriarTransacaoContaDeLuz";
import EditarContaDeLuz from "../pages/EditarContaDeLuz";

import CertificadoDigital from "../pages/CertificadoDigital";
import EditarCertificadoDigital from "../pages/EditarCertificadoDigital";

import ECRevendedor from "../pages/ECRevendedor";
import EditECRevendedor from "../pages/EditECRevendedor";

import ContaDigitalLA from "../pages/ContaDigitalLA";
import ContaDigitalEC from "../pages/ContaDigitalEC";
import ContaDigitalRevendedor from "../pages/ContaDigitalRevendedor";
import ContaDigitalParceiro from "../pages/ContaDigitalParceiro";

import ClassificacaoECs from "../pages/ClassificacaoECs";
import ClassificacaoECsRevendedor from "../pages/ClassificacaoECsRevendedor";

import ExtratoPagamento from "../pages/ExtratoPagamento";

import AdminLayout from "../pages/AdminLayout";

// import AdminTransacoes from "../pages/AdminTransacoes";
import AdminEditarTransacao from "../pages/AdminEditarTransacao";
import AdminEstabelecimentos from "../pages/AdminEstabelecimentos";
import AdminEditarEstabelecimentos from "../pages/AdminEditarEstabelecimentos";
import AdminLicenciados from "../pages/AdminLicenciados";
import AdminEditarLicenciados from "../pages/AdminEditarLicenciados";
import Revendedores from "../pages/Revendedores";
import EditRevendedor from "../pages/EditRevendedor";

// import AdminConsultaSerasa from "../pages/AdminConsultaSerasa";
import AdminEditarConsultaSerasa from "../pages/AdminEditarConsultaSerasa";

import AdminParceiros from "../pages/AdminParceiros";
import AdminEditarParceiros from "../pages/AdminEditarParceiros";

import AdminPagamentos from "../pages/AdminPagamentos";
import AdminEditarPagamentos from "../pages/AdminEditarPagamentos";

import TaxaDeSimulacao from "../pages/TaxaDeSilumacao";
import TaxaComissao from "../pages/TaxaComissao";
import TaxaComissaoEprepag from "../pages/TaxaComissaoEprepag";

// import AdminContaDeLuz from "../pages/AdminContaDeLuz";
import AdminEditarContaDeLuz from "../pages/AdminEditarContaDeLuz";

// import AdminCertificadoDigital from "../pages/AdminCertificadoDigital";
import AdminEditarCertificadoDigital from "../pages/AdminEditarCertificadoDigital";

import SelfCredenciamento from "../pages/SelfCredenciamento";
import SelfCredenciamentoCriar from "../pages/SelfCredenciamentoCriar";
import SelfCredenciamentoFim from "../pages/SelfCredenciamentoFim";

import LoadingPage from "../pages/LoadingPage";

import ParceiroLayout from "../pages/ParceiroLayout";

// import ParceiroTransacoes from "../pages/ParceiroTransacoes";
import ParceiroLicenciados from "../pages/ParceiroLicenciados";
import ParceiroEditarLicenciados from "../pages/ParceiroEditarLicenciados";
import ParceiroEstabelecimentos from "../pages/ParceiroEstabelecimentos";
import ParceiroEditarEstabelecimentos from "../pages/ParceiroEditarEstabelecimentos";
import ParceiroRevendedores from "../pages/ParceiroRevendedores";
import ParceiroEditarRevendedores from "../pages/ParceiroEditarRevendedores";

import ListaLA from "../pages/ListaLA";
import EPrepag from "../pages/EPrepag";
import AprovarEprepag from "../pages/AprovarEprepag";

import AdminFGTS from "../pages/AdminFGTS";
import AdminEditarFGTS from "../pages/AdminEditarFGTS";

import TaxaComissaoFgts from "../pages/TaxaComissaoFgts";

const Routes = () => {
  const { user } = useAuth();

  return (
    <Switch>
      <Route path="/" exact /* component={SignIn} */>
        <SignIn />
      </Route>
      <Route path="/politicaprivacidade">
        <PoliticaPrivacidade />
      </Route>
      <Route path="/forgotpassword">
        <ForgotPassword />
      </Route>
      <Route path="/resetpassword">
        <ResetPassword />
      </Route>
      <Route path="/sentemail">
        <SentEmail />
      </Route>

      <Route path="/acompanhamento" exact>
        <Acompanhamento />
      </Route>
      <Route path="/acompanhamento/transacao/:id">
        <AcompanhamentoInterna />
      </Route>

      <Route path="/credenciamento" exact>
        <SelfCredenciamento />
      </Route>
      <Route path="/credenciamento-criar">
        <SelfCredenciamentoCriar />
      </Route>
      <Route path="/credenciamento-fim">
        <SelfCredenciamentoFim />
      </Route>

      {/* <Route path='/user/acompanhamento' exact isPrivate><Acompanhamento /></Route>
      <Route path='/user/acompanhamento/transacao' isPrivate><AcompanhamentoInterna /></Route> */}

      <Route path="/loading" isPrivate>
        <LoadingPage />
      </Route>

      <Route path="/profile" isPrivate>
        <Profile />
      </Route>

      <Route path="/messages" isPrivate>
        <Mensagens />
      </Route>

      <Route path="/ec" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_LA" ? (
          <Redirect to="/profile" />
        ) : (
          <EC />
        )}
      </Route>
      <Route path="/ec/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_LA" ? (
          <Redirect to="/profile" />
        ) : (
          <EditEC />
        )}
      </Route>

      <Route path="/contadigital/la" isPrivate>
        {user !== undefined && user.role !== "ROLE_LA" ? (
          <Redirect to="/profile" />
        ) : (
          <ContaDigitalLA />
        )}
      </Route>
      <Route path="/classificacao-ecs" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_LA" ? (
          <Redirect to="/profile" />
        ) : (
          <ClassificacaoECs />
        )}
      </Route>

      <Route path="/ec/revendedor" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_RV" ? (
          <Redirect to="/profile" />
        ) : (
          <ECRevendedor />
        )}
      </Route>
      <Route path="/ec/revendedor/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_RV" ? (
          <Redirect to="/profile" />
        ) : (
          <EditECRevendedor />
        )}
      </Route>

      <Route path="/contadigital/revendedor" isPrivate>
        {user !== undefined && user.role !== "ROLE_RV" ? (
          <Redirect to="/profile" />
        ) : (
          <ContaDigitalRevendedor />
        )}
      </Route>
      <Route path="/classificacao-ecs/revendedor" isPrivate>
        {user !== undefined && user.role !== "ROLE_RV" ? (
          <Redirect to="/profile" />
        ) : (
          <ClassificacaoECsRevendedor />
        )}
      </Route>

      <Route path="/simulador" isPrivate>
        {user !== undefined && user.role !== "ROLE_EC" ? (
          <Redirect to="/profile" />
        ) : (
          <Simulador />
        )}
      </Route>
      <Route path="/transacoes/criar" isPrivate>
        {user !== undefined && user.role !== "ROLE_EC" ? (
          <Redirect to="/profile" />
        ) : (
          <CriarTransacao />
        )}
      </Route>
      <Route path="/transacoes" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_EC" ? (
          <Redirect to="/profile" />
        ) : (
          <Transacoes />
        )}
      </Route>
      <Route path="/transacoes/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_EC" ? (
          <Redirect to="/profile" />
        ) : (
          <EditarTransacao />
        )}
      </Route>
      <Route path="/transacoes/ver/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_EC" ? (
          <Redirect to="/profile" />
        ) : (
          <VerTransacao />
        )}
      </Route>

      <Route path="/conta-de-luz" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_EC" ? (
          <Redirect to="/profile" />
        ) : (
          <TransacoesContaDeLuz />
        )}
      </Route>
      <Route path="/conta-de-luz/simulador" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_EC" ? (
          <Redirect to="/profile" />
        ) : (
          <SimuladorTransacaoContaDeLuz />
        )}
      </Route>
      <Route path="/conta-de-luz/criar" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_EC" ? (
          <Redirect to="/profile" />
        ) : (
          <CriarTransacaoContaDeLuz />
        )}
      </Route>
      <Route path="/conta-de-luz/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_EC" ? (
          <Redirect to="/profile" />
        ) : (
          <EditarContaDeLuz />
        )}
      </Route>

      <Route path="/certificado-digital" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_EC" ? (
          <Redirect to="/profile" />
        ) : (
          <CertificadoDigital />
        )}
      </Route>
      <Route path="/certificado-digital/editar/:id" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_EC" ? (
          <Redirect to="/profile" />
        ) : (
          <EditarCertificadoDigital />
        )}
      </Route>

      <Route path="/contadigital/ec" isPrivate>
        {user !== undefined && user.role !== "ROLE_EC" ? (
          <Redirect to="/profile" />
        ) : (
          <ContaDigitalEC />
        )}
      </Route>

      {/* Extrato de pagamentos */}
      <Route path="/extratopagamento" isPrivate>
        {user !== undefined && user.role === "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <ExtratoPagamento />
        )}
      </Route>

      <Route path="/eprepag" exact isPrivate>
        <EPrepag />
      </Route>
      <Route path="/aprovar-eprepag/:id" isPrivate>
        <AprovarEprepag />
      </Route>

      {/* Layout do Admin */}
      <Route path="/admin" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminLayout />
        )}
      </Route>
      <Route path="/admin/transacoes" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminLayout />
        )}
      </Route>
      <Route path="/admin/conta-luz" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminLayout />
        )}
      </Route>
      <Route path="/admin/certificado-digital" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminLayout />
        )}
      </Route>
      <Route path="/admin/saque-fgts" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminLayout />
        )}
      </Route>
      <Route path="/admin/consulta-serasa" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminLayout />
        )}
      </Route>

      <Route path="/admin/transacoes/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminEditarTransacao />
        )}
      </Route>
      <Route path="/admin/estabelecimentos" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminEstabelecimentos />
        )}
      </Route>
      <Route path="/admin/estabelecimentos/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminEditarEstabelecimentos />
        )}
      </Route>
      <Route path="/admin/licenciados" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminLicenciados />
        )}
      </Route>
      <Route path="/admin/licenciados/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminEditarLicenciados />
        )}
      </Route>
      <Route path="/admin/revendedor" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <Revendedores />
        )}
      </Route>
      <Route path="/admin/revendedor/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <EditRevendedor />
        )}
      </Route>
      <Route path="/admin/consulta-serasa/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminEditarConsultaSerasa />
        )}
      </Route>

      <Route path="/admin/parceiros" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminParceiros />
        )}
      </Route>
      <Route path="/admin/parceiros/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminEditarParceiros />
        )}
      </Route>

      <Route path="/admin/pagamentos" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminPagamentos />
        )}
      </Route>
      <Route path="/admin/pagamentos/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminEditarPagamentos />
        )}
      </Route>

      <Route path="/admin/conta-luz/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminEditarContaDeLuz />
        )}
      </Route>

      <Route path="/admin/certificado-digital/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminEditarCertificadoDigital />
        )}
      </Route>

      <Route path="/admin/taxadesimulacao" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <TaxaDeSimulacao />
        )}
      </Route>
      <Route path="/admin/taxacomissao/:type" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <TaxaComissao />
        )}
      </Route>
      <Route path="/admin/taxacomissaoeprepag" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <TaxaComissaoEprepag />
        )}
      </Route>

      <Route path="/admin/fgts" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminFGTS />
        )}
      </Route>
      <Route path="/admin/fgts/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <AdminEditarFGTS />
        )}
      </Route>

      <Route path="/admin/taxacomissaofgts" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <TaxaComissaoFgts />
        )}
      </Route>

      <Route path="/parceiro/transacoes" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_PT" ? (
          <Redirect to="/profile" />
        ) : (
          // <ParceiroTransacoes />
          <ParceiroLayout />
        )}
      </Route>
      <Route path="/parceiro/conta-luz" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_PT" ? (
          <Redirect to="/profile" />
        ) : (
          // <ParceiroTransacoes />
          <ParceiroLayout />
        )}
      </Route>
      <Route path="/parceiro/certificado-digital" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_PT" ? (
          <Redirect to="/profile" />
        ) : (
          // <ParceiroTransacoes />
          <ParceiroLayout />
        )}
      </Route>
      <Route path="/parceiro/saque-fgts" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_PT" ? (
          <Redirect to="/profile" />
        ) : (
          // <ParceiroTransacoes />
          <ParceiroLayout />
        )}
      </Route>
      {/* Inserir Editar Transação */}
      <Route path="/parceiro/licenciados" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_PT" ? (
          <Redirect to="/profile" />
        ) : (
          <ParceiroLicenciados />
        )}
      </Route>
      <Route path="/parceiro/licenciados/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_PT" ? (
          <Redirect to="/profile" />
        ) : (
          <ParceiroEditarLicenciados />
        )}
      </Route>
      <Route path="/parceiro/estabelecimentos" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_PT" ? (
          <Redirect to="/profile" />
        ) : (
          <ParceiroEstabelecimentos />
        )}
      </Route>
      <Route path="/parceiro/estabelecimentos/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_PT" ? (
          <Redirect to="/profile" />
        ) : (
          <ParceiroEditarEstabelecimentos />
        )}
      </Route>
      <Route path="/parceiro/revendedores" exact isPrivate>
        {user !== undefined && user.role !== "ROLE_PT" ? (
          <Redirect to="/profile" />
        ) : (
          <ParceiroRevendedores />
        )}
      </Route>
      <Route path="/parceiro/revendedores/editar/:id" isPrivate>
        {user !== undefined && user.role !== "ROLE_PT" ? (
          <Redirect to="/profile" />
        ) : (
          <ParceiroEditarRevendedores />
        )}
      </Route>
      <Route path="/parceiro/contadigital" isPrivate>
        {user !== undefined && user.role !== "ROLE_PT" ? (
          <Redirect to="/profile" />
        ) : (
          <ContaDigitalParceiro />
        )}
      </Route>

      <Route path="/lista-licenciados" isPrivate>
        {user !== undefined && user.role !== "ROLE_ADMIN" ? (
          <Redirect to="/profile" />
        ) : (
          <ListaLA />
        )}
      </Route>
    </Switch>
  );
};

export default Routes;
