import React, { createContext, useState, useContext } from 'react';

const LaInfosContext = createContext();

function LaInfosProvider({ children }) {
  const [laInfos, setLaInfos] = useState([]);
  const [laInfosUser, setLaInfosUser] = useState([]);
  const [laInfosEnderecoPessoa, setLaInfosEnderecoPessoa] = useState([]);
  const [laInfosEnderecoEstabelecimento, setLaInfosEnderecoEstabelecimento] = useState([]);
  const [laInfosDadosBancarios, setLaInfosDadosBancarios] = useState([]);
  const [laInfosTelefoneEstabelecimento, setLaInfosTelefoneEstabelecimento] = useState([]);

  return (
    <LaInfosContext.Provider
      value={{
        laInfos,
        setLaInfos,
        laInfosUser,
        setLaInfosUser,
        laInfosEnderecoPessoa,
        setLaInfosEnderecoPessoa,
        laInfosEnderecoEstabelecimento,
        setLaInfosEnderecoEstabelecimento,
        laInfosDadosBancarios,
        setLaInfosDadosBancarios,
        laInfosTelefoneEstabelecimento,
        setLaInfosTelefoneEstabelecimento,
      }}
    >
      {children}
    </LaInfosContext.Provider>
  );
};

function useLaInfos() {
  const context = useContext(LaInfosContext);
  const {
    laInfos, setLaInfos,
    laInfosUser, setLaInfosUser,
    laInfosEnderecoPessoa, setLaInfosEnderecoPessoa,
    laInfosEnderecoEstabelecimento, setLaInfosEnderecoEstabelecimento,
    laInfosDadosBancarios, setLaInfosDadosBancarios,
    laInfosTelefoneEstabelecimento, setLaInfosTelefoneEstabelecimento,
  } = context;
  return {
    laInfos, setLaInfos,
    laInfosUser, setLaInfosUser,
    laInfosEnderecoPessoa, setLaInfosEnderecoPessoa,
    laInfosEnderecoEstabelecimento, setLaInfosEnderecoEstabelecimento,
    laInfosDadosBancarios, setLaInfosDadosBancarios,
    laInfosTelefoneEstabelecimento, setLaInfosTelefoneEstabelecimento,
  };
};

export { LaInfosProvider, useLaInfos };
