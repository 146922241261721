import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #10104F;

  > img {
    width: 90px;
    margin-right: 10px;
  }

  span {
    font-size: 22px;
    font-weight: 700;
    color: #FFFFFF;
    display: flex;
  }

  > div {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;

      > span {
        font-size: 16px;
        font-weight: 700;
        color: #FFFFFF;
        opacity: 0.3;
        display: flex;
      }
    }
  }
`;

export const Mask = styled.div`
  width: 0vw;
  height: 100vh;

  position: absolute;
  z-index: 100;
  right: 0;
  /* background: #08BBE9; */
  /* background: #10104F; */
  background: #F5F8FB;
  animation-name: anime;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.15, 0.02, 0.15, 1);

  @keyframes anime {
    0% {width: 100vw;}
    100% {width: 0vw;}
  }
`;
