import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { MdErrorOutline } from 'react-icons/md';
// import { Tooltip } from '@material-ui/core/';
// import { jsPDF } from "jspdf";
// import { uniqueId } from "lodash";
// import moment from 'moment';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import LoadingContent from '../../components/LoadingContent';

// Context API
import { useToast } from '../../hooks/ToastContext';
import { useSimulatorContaDeLuz } from '../../hooks/SimulatorContaDeLuzContext';

// Máscaras
// import contaBancariaMask from '../../components/InputMasks/contaBancariaMask';
import cpfMask from '../../components/InputMasks/cpfMask';
// import rgMask from '../../components/InputMasks/rgMask';
// import cnpjMask from '../../components/InputMasks/cnpjMask';
import telefoneMask from '../../components/InputMasks/telefoneMask';

import companhias from '../../assets/json/cias_energia.json';

import {
  Container,
  Page,
  Content,
  RowBox1,
  // RowBoxInfos,
  Box,
  BoxBlue,
  ValueArea,
  // BoletoOptions,
  InputArea,
  Row,
  // Row3,
  // RowBox2,
  // RowBox3,
  InputContainer,
  Error,
} from './styles';

function SimuladorTransacaoContaDeLuz() {
  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToast();

  const {
    simuladorContaDeLuz,
    setSimuladorContaDeLuz,
    setCiaEnergiaSimuladorContaDeLuz,
    setUserSimuladorContaDeLuz,
  } = useSimulatorContaDeLuz();

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const { register, handleSubmit, getValues, setValue, setError, clearErrors, errors } = useForm();
  const onSubmit = async (data) => {
    setIsLoading(true);
    console.log(data);

    const auth = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    try {
      const responseSimulador = await api.post(
        '/energy/transaction/simulation',
        {
          parcelas: Number(data.parcelas),
          valor: Number(data.valor),
        },
        auth,
      );

      console.log(responseSimulador);
      const resultSimulador = responseSimulador.data.data;

      setSimuladorContaDeLuz({
        parcelas: resultSimulador.parcelas,
        valor_pagamento: resultSimulador.valor_pagamento,
        valor_adicional: resultSimulador.valor_adicional,
        valor_total: resultSimulador.valor_total,
        taxa: resultSimulador.taxa,
        valor_parcela: resultSimulador.valor_parcela,
      });
      setUserSimuladorContaDeLuz({
        nome: data.nome,
        cpf: data.cpf,
        data_nascimento: data.data_nascimento,
        email: data.email,
        telefone: data.telefone,
      });
      setCiaEnergiaSimuladorContaDeLuz({
        cia_energia: data.cia_energia,
        numero_instalacao: data.numero_instalacao,
        data_leitura: data.data_leitura,
      });

      setInfosOpend(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error.response);

      setIsLoading(false);

      addToast({
        type: 'error',
        title: 'Erro na simulação',
        description: 'Algo deu errado. Tente novamente ou entre em contato com o Deixa no Azul.',
      });
    }    
  };
  const onError = (errors) => {
    addToast({
      type: 'error',
      title: 'Erro no envio do formulário',
      description: 'Você precisa preencher todos os campos obrigatórios (marcados com *).',
    });
  };

  // Faz a validação do CPF e verifica se há dados com o cpf digitado
  function validarCPF() {
    const valorDigitado = getValues("cpf");
    const stringDigitada = valorDigitado.replaceAll('.', '').replace('-', '');

    let Soma;
    let Resto;
    Soma = 0;

    if (
      stringDigitada === "00000000000" || 
      stringDigitada === "11111111111" || 
      stringDigitada === "22222222222" || 
      stringDigitada === "33333333333" || 
      stringDigitada === "44444444444" || 
      stringDigitada === "55555555555" || 
      stringDigitada === "66666666666" || 
      stringDigitada === "77777777777" || 
      stringDigitada === "88888888888" || 
      stringDigitada === "99999999999"
    ) {
      setError("cpf", {
        type: "manual",
        message: "O CPF digitado é inválido."
      });

      return;
    };

    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(stringDigitada.substring(i-1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
    }

    if ((Resto === 10) || (Resto === 11)) { Resto = 0 };
    if (Resto !== parseInt(stringDigitada.substring(9, 10)) ) {
      setError("cpf", {
        type: "manual",
        message: "O CPF digitado é inválido."
      });

      return;
    };

    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(stringDigitada.substring(i-1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;
    }

    if ((Resto === 10) || (Resto === 11)) { Resto = 0 };
    if (Resto !== parseInt(stringDigitada.substring(10, 11) ) ) {
      setError("cpf", {
        type: "manual",
        message: "O CPF digitado é inválido."
      });

      return;
    };
    
    // CPF ok
    clearErrors("cpf")
    // return;
  };

  // Faz a validação do E-mail
  function validarEmail() {
    const valorDigitado = getValues("email");
    const indexArroba = valorDigitado.indexOf("@");

    const usuario = valorDigitado.substring(0, indexArroba);
    const dominio = valorDigitado.substring(indexArroba + 1, valorDigitado.length);

    if (
      (usuario.length >= 1) &&
      (dominio.length >= 3) &&
      (usuario.search("@") === -1) &&
      (dominio.search("@") === -1) &&
      (usuario.search(" ") === -1) &&
      (dominio.search(" ") === -1) &&
      (dominio.search(".") !== -1) &&
      (dominio.indexOf(".") >= 1)&&
      (dominio.lastIndexOf(".") < dominio.length - 1)
    ) {
      // e-mail válido
      clearErrors("email");
    } else { 
      // e-mail inválido
      setError("email", {
        type: "manual",
        message: "O E-mail digitado é inválido."
      });
    }
  };

  // Valida se a data é maior que a atual
  const validDate = useCallback(() => {
    const date = getValues("data_nascimento");
    const dateTimestamp = new Date(date).getTime();

    const today = Date.now()

    if (dateTimestamp > today) {
      setError("data_nascimento", {
        type: "manual",
        message: "A data não pode ser maior que o dia atual."
      });

      return;
    };

    clearErrors("data_nascimento");
  }, [getValues, setError, clearErrors]);

  const [infosOpened, setInfosOpend] = useState(false);
  
  const ciasEnergia = companhias;

  const [parcelas, setParcelas] = useState([]);

  // Função que monta o componente do conjunto de parcelas de acordo com o valor
  function montarParcelas() {
    const valor = getValues('valor');

    if (valor === '1000') {
      setParcelas([
        15, 16, 18
      ])
    }
    if (valor === '600') {
      setParcelas([
        16, 18
      ])
    }
    if (valor === '450') {
      setParcelas([
        8, 12, 15, 16, 18
      ])
    }
    if (
      valor === '900' ||
      valor === '800' ||
      valor === '700' ||
      valor === '500' ||
      valor === '400'
    ) {
      setParcelas([
        12, 15, 16, 18
      ])
    }
    if (
      valor === '350' ||
      valor === '300' ||
      valor === '250' ||
      valor === '200'
    ) {
      setParcelas([
        8, 12, 15, 16
      ])
    }
  }

  // Valor contrato
  const valor_contrato = simuladorContaDeLuz.valor_pagamento
    .toString()
    .split('.');
  const reaisValorContrato = valor_contrato[0];
  const centavosValorContrato = valor_contrato[1]
    ? valor_contrato[1].substring(0, 2)
    : '00';
  const valorContrato = `${reaisValorContrato},${
    centavosValorContrato !== undefined && centavosValorContrato.length > 1
      ? centavosValorContrato
      : `${centavosValorContrato}0`
  }`;

  // Valor parcela
  const valor_parcela = simuladorContaDeLuz.valor_parcela.toString().split('.');
  const reaisValorParcela = valor_parcela[0];
  const centavosValorParcela = valor_parcela[1]
    ? valor_parcela[1].substring(0, 2)
    : '00';
  const valorParcela = `${reaisValorParcela},${
    centavosValorParcela !== undefined && centavosValorParcela.length > 1
      ? centavosValorParcela
      : `${centavosValorParcela}0`
  }`;

  return (
    <Container>
      <LoadingContent isLoading={isLoading} />

      <MenuBar />
      <NewMobileMenu />

      <Page>
        <TopBar pageTitle="Criar Transação" />

        <Content>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <RowBox1>
              <Box>
                <h3>Informações da transação</h3>

                <InputArea>
                  <label>*Nome completo</label>
                  <InputContainer style={{ borderColor: errors.nome && "red" }}>
                    <input
                      name="nome"
                      type="text"
                      placeholder="Digite o nome completo"
                      ref={register({ required: true })}
                      // onBlur={() => setLocalStorage()}
                    />
                    {errors.nome && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <Row>
                  <InputArea>
                    <label>*CPF</label>
                    <InputContainer style={{ borderColor: errors.cpf && "red" }}>
                      <input
                        name="cpf"
                        placeholder="Digite o CPF (apenas números)"
                        onKeyUp={(e) => cpfMask(e)}
                        ref={register({ required: "Campo obrigatório" })}
                        onBlur={() => validarCPF()}
                      />
                      {errors.cpf && <Error title={errors.cpf.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*E-mail</label>
                    <InputContainer style={{ borderColor: errors.email && "red" }}>
                      <input
                        name="email"
                        type="text"
                        placeholder="Digite o e-mail"
                        ref={register({ required: "Campo obrigatório" })}
                        onBlur={() => validarEmail()}
                      />
                      {errors.email && <Error title={errors.email.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>*Data de nascimento</label>
                    <InputContainer style={{ borderColor: errors.data_nascimento && "red" }}>
                      <input
                        name="data_nascimento"
                        type="date"
                        placeholder="Digite sua data de nascimento"
                        ref={register({ required: "Campo obrigatório" })}
                        onBlur={validDate}
                      />
                      {errors.data_nascimento && <Error title={errors.data_nascimento.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                  
                  <InputArea>
                    <label>*Telefone</label>
                    <InputContainer style={{ borderColor: errors.telefone && "red" }}>
                    <input
                      name="telefone"
                      onKeyUp={(e) => telefoneMask(e)}
                      placeholder="Apenas números"
                      ref={register({ required: true })}
                      // onBlur={() => setLocalStorage()}
                    />
                    {errors.telefone && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row>
              </Box>

              <Box>
                <h3>Dados da Cia de Energia</h3>

                <InputArea>
                  <label>*Cia de Energia</label>
                  <InputContainer style={{ borderColor: errors.cia_energia && "red" }}>
                    <select
                      name="cia_energia"
                      placeholder="Selecione"
                      ref={register({ required: true })}
                    >
                      <option value="">Selecione</option>
                      {ciasEnergia.map(item => (
                        <option value={item.label}>{item.label}</option>
                      ))}
                    </select>
                    {errors.cia_energia && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*Número de instalação</label>
                  <InputContainer style={{ borderColor: errors.numero_instalacao && "red" }}>
                    <input
                      name="numero_instalacao"
                      placeholder="Digite o número de instalação"
                      // onKeyUp={(e) => cpfMask(e)}
                      ref={register({ required: "Campo obrigatório" })}
                      // onBlur={() => validarCPF()}
                    />
                    {errors.numero_instalacao && <Error title={errors.numero_instalacao.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*Data da leitura</label>
                  <InputContainer style={{ borderColor: errors.data_leitura && "red" }}>
                    <input
                      name="data_leitura"
                      type="date"
                      placeholder="Digite a data da leitura"
                      ref={register({ required: "Campo obrigatório" })}
                      // onBlur={validDate}
                    />
                    {errors.data_leitura && <Error title={errors.data_leitura.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                  </InputArea>
              </Box>
            </RowBox1>

            <RowBox1>
              <div>
                <Box>
                  <h3>Valores da transação</h3>

                  <Row>
                    <InputArea>
                      <label>*Valor do contrato</label>
                      <InputContainer style={{ borderColor: errors.valor && "red" }}>
                        <select
                          name="valor"
                          placeholder="Selecione"
                          ref={register({ required: true })}
                          onChange={() => {
                            setValue('parcelas', '')
                            montarParcelas();
                          }}
                        >
                          <option value="">Selecione</option>
                          <option value="200">R$ 200,00</option>
                          <option value="250">R$ 250,00</option>
                          <option value="300">R$ 300,00</option>
                          <option value="350">R$ 350,00</option>
                          <option value="400">R$ 400,00</option>
                          <option value="450">R$ 450,00</option>
                          <option value="500">R$ 500,00</option>
                          <option value="600">R$ 600,00</option>
                          <option value="700">R$ 700,00</option>
                          <option value="800">R$ 800,00</option>
                          <option value="900">R$ 900,00</option>
                          <option value="1000">R$ 1.000,00</option>
                        </select>
                        {errors.valor && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Número de parcelas</label>
                      <InputContainer style={{ borderColor: errors.parcelas && "red" }}>
                        <select
                          name="parcelas"
                          placeholder="Selecione"
                          ref={register({ required: true })}
                        >
                          <option value="">Selecione</option>
                          {parcelas.map(item => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                        {errors.parcelas && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                      </InputContainer>
                    </InputArea>
                  </Row>

                  <button type="submit">Simular</button>
                </Box>
              </div>

              <BoxBlue infosOpened={infosOpened}>
                <h3>Informações para o cliente</h3>

                <ValueArea>
                  <label>Valor da parcela</label>
                  <span>{simuladorContaDeLuz.parcelas}x R$ {valorParcela}</span>
                </ValueArea>

                <ValueArea>
                  <label>Valor do contrato</label>
                  <span>R$ {valorContrato}</span>
                </ValueArea>

                <Link to="/conta-de-luz/criar">
                  <button type="button">Aceitar</button>
                </Link>
              </BoxBlue>
            </RowBox1>
          </form>
        </Content>
      </Page>
    </Container>
  );
};

export default SimuladorTransacaoContaDeLuz;
