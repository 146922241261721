/* eslint-disable array-callback-return */
import React, {useState, useEffect, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {MdErrorOutline, MdHighlightOff} from 'react-icons/md';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import LoadingContent from '../../components/LoadingContent';

import FormatarValor from '../../components/FormatarValor';

import {useToast} from '../../hooks/ToastContext';

import {
  Container,
  Page,
  Content,
  RowBox1,
  Box,
  Row4,
  InputArea,
  BlueBox,
  StatusArea,
  Box2,
  InputContainer2,
  ModalSection,
  ModalContent,
  Mensagem,
  Error,
  Confirmar,
  Aprovado,
  BoxInfo,
  EditarPagamento,
  RowBox,
  Row6,
} from './styles';
import {Tooltip} from '@material-ui/core';

function AdminEditarConsultaSerasa() {
  const history = useHistory();
  const location = useLocation();
  const {addToast} = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const {register, handleSubmit, getValues, errors} = useForm();

  // const [userID, setUserID] = useState("");

  const idTransacao = location.pathname.replace(
    '/admin/consulta-serasa/editar/',
    ''
  );

  const [transacao, setTransacao] = useState({});
  const [estabelecimento, setEstabelecimento] = useState({});
  const [licenciado, setLicenciado] = useState({});

  const [statusColor, setStatusColor] = useState('');
  const [editStatusHide, setEditStatusHide] = useState(false);

  const [modalOpened, setModalOpened] = useState(true);
  const [messageHide, setMessageHide] = useState(true);

  const tipoTransacao = ''; // antigo state sem uso do set

  const [aprovadoHide, setAprovadoHide] = useState(true);
  const [requiredMessage, setRequiredMessage] = useState(false);
  const [confirmarHide, setConfirmarHide] = useState(true);

  const aceitarBoletoRejeitado = false; // antigo state sem uso do set

  const editarPagamentoHide = true; // antigo state sem uso do set
  // const [aprovarTransacaoRejeitada, setAprovarTransacaoRejeitada] = useState(false);
  // const [boletoEmAberto, setBoletoEmAberto] = useState(false);

  // const [userID, setUserID] = useState("");

  const loggedUser = JSON.parse(
    localStorage.getItem('@DeixaNoAzul:loggedUser')
  );

  // Faz as requisições para preencher as informações
  useEffect(() => {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    async function requestTransacao() {
      // Faz a requisição e filtra a transação FGTS
      const responseTransacao = await api.get(
        `/transaction/spc`,
        auth
      );

      const resultTransacao = responseTransacao.data.data;
      const transacaoFiltrada = resultTransacao.filter(
        (item) => item.id === idTransacao
      );

      const responseEstabe = await api.get(
        `/estabelecimento/${transacaoFiltrada[0].estabelecimento_id}`,
        auth
      );

      const resultEC = responseEstabe.data.data;

      const responseLA = await api.get(
        `/licenciado/${resultEC.licenciado_autorizado_id}`,
        auth
      );
      const resultLA = responseLA.data.data;

      // console.log(responseEstabe);
      // console.log(resultLA);

      // console.log('resultTransacao', resultTransacao);
      // console.log('transacaoFiltrada', transacaoFiltrada[0]);
      // console.log('resultEC', resultEC);
      // console.log('resultLA', resultLA);

      setTransacao(transacaoFiltrada[0]);
      setEstabelecimento(resultEC);
      setLicenciado(resultLA);
    }

    requestTransacao();
    setIsLoading(false);
  }, [idTransacao, userToken]);

  console.log(transacao);

  // let transacao = {};

  /* transacaoCliente.filter((trans) => {
    if(idTransacao === trans.id){
      transacao = trans
    }
  }); */

  // let estabelecimetoNome = '';
  // let idLa = '';
  // let estabelecimento = {};

  /* estabelecimentos.filter((esta) => {
    if(String(esta.id) === String(transacao.estabelecimento_id)){
      estabelecimetoNome = esta.nome_fantasia;
      idLa = esta.licenciado_autorizado_id;
      estabelecimento = esta;
    }
  }); */

  useEffect(() => {
    setStatusColor(String(transacao.transacao_status_id));
    if (
      String(transacao.transacao_status_id) === '3' ||
      String(transacao.transacao_status_id) === '4'
    ) {
      setEditStatusHide(true);
    }
  }, [transacao]);

  /* useEffect(() => {
    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    async function loadLa() {
      const responseTransacao = await api.get(
        `/licenciado/${idLa}`,
        auth
      );

      setLicenciado(responseTransacao.data.data)
    }

    if(idLa !== '') {
      loadLa();
    }
  }, [idLa, userToken]) */

  const changeStatus = useCallback(() => {
    const selectValue = getValues('status');

    setStatusColor(selectValue);

    if (selectValue === '3') {
      setRequiredMessage(false);

      setAprovadoHide(false);
      setMessageHide(true);
      setConfirmarHide(true);
    }

    if (selectValue === '2' || selectValue === '4') {
      setRequiredMessage(true);

      setMessageHide(false);
      setAprovadoHide(true);
      setConfirmarHide(true);
    }

    if (
      selectValue === '5' ||
      selectValue === '1' ||
      selectValue === '6'
    ) {
      setRequiredMessage(false);

      setConfirmarHide(false);
      setMessageHide(true);
      setAprovadoHide(true);
    }
  }, [getValues]);

  const openModal = useCallback(() => {
    const selectValue = getValues('status');

    console.log(selectValue);
    console.log(transacao.transacao_status_id);

    if (Number(selectValue) === transacao.transacao_status_id) {
      addToast({
        type: 'info',
        title: 'Atualização não efetuada',
        description:
          'Você precisa selecionar um status diferente do atual.',
      });

      return;
    }

    if (selectValue !== '1') {
      setModalOpened(false);
    }

    if (selectValue === '1' && transacao.transacao_status_id !== 1) {
      setModalOpened(false);
    }
  }, [addToast, getValues, transacao]);

  const onSubmit = async (data) => {
    // Bearer token
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    console.log(data.status);
    console.log(statusColor);

    try {
      // Atualiza os dados da transação
      api
        .put(
          `/transaction/spc/${transacao.id}/status`,
          {
            // Dados a ser alterado
            status: statusColor,
          },
          auth
        )
        .then((response) => {
          console.log('responseStatus', response);

          if (response === undefined) {
            addToast({
              type: 'error',
              title: 'Erro na atualização',
              description:
                'Estamos com um problema na atualização dessa transação. Tente novamente mais tarde ou entre em contato com a Deixa no Azul.',
            });

            return;
          }

          if (response.data.errors || response.data.error) {
            addToast({
              type: 'error',
              title: 'Erro na atualização',
              description:
                'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
            });

            return;
          }

          if (data.status === '2' || data.status === '4') {
            // mensagem
            api
              .post(
                '/send_message',
                {
                  recipient: estabelecimento.user_id,
                  subject: 'Atualização de status',
                  message: `SPC/LIMPA NOME: ${transacao.id}|${data.message}`,
                },
                auth
              )
              .then((response) => response.status);
          }

          addToast({
            type: 'success',
            title: 'Deu tudo certo',
            description: 'Dados atualizados com sucesso.',
          });

          history.push('/admin/consulta-serasa');
        });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro na atualização',
        description:
          'Ocorreu um erro ao atualizar a transação, tente novamente ou entre em contato.',
      });
    }
  };
  const onError = (errors) => {
    // console.log(errors)
    addToast({
      type: 'error',
      title: 'Erro na atualização',
      description:
        'Ocorreu um erro ao atualizar seus dados, tente novamente.',
    });
  };

  // Faz o download do comprovante do cliente
  async function downloadComprovante() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    if (
      transacao.document_result_id === '' ||
      transacao.document_result_id === null
    ) {
      addToast({
        type: 'error',
        title: 'Erro ao fazer download',
        description:
          'Esta transação foi feita no app e não possui comprovante.',
      });

      setIsLoading(false);

      return;
    }

    await api
      .get(
        `/documento_download/${transacao.document_result_id}`,
        auth
      )
      .then((response) => {
        const result = response.data.data;

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = result;
        const downloadLink = document.createElement('a');
        const fileName = 'comprovante.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });

    setIsLoading(false);
  }

  return (
    <Container>
      <LoadingContent isLoading={isLoading} />

      <ModalSection modalOpened={modalOpened}>
        <ModalContent>
          <MdHighlightOff
            size={30}
            onClick={() => setModalOpened(!modalOpened)}
          />
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Aprovado aprovadoHide={aprovadoHide}>
              {tipoTransacao === 'Dados bancários' && (
                <span>Anexar comprovante da transação</span>
              )}

              {tipoTransacao === 'Dados bancários' && (
                <BoxInfo>
                  <span>
                    Você pode anexar até 10 documentos. Após
                    selecionar os documentos, clique em <b>Enviar.</b>
                  </span>{' '}
                  <br />
                </BoxInfo>
              )}

              {aceitarBoletoRejeitado === false ? (
                <span style={{textAlign: 'center'}}>
                  Tem certeza que deseja aprovar essa transação?
                </span>
              ) : (
                <span
                  style={{textAlign: 'center', fontWeight: 'bold'}}
                >
                  Existe um ou mais boleto com o status rejeitado,
                  <br /> deseja salvar a transação como concluída{' '}
                  <br /> mesmo assim?
                </span>
              )}
            </Aprovado>

            <Mensagem messageHide={messageHide}>
              <span>Digite um comentário para o Estabelecimento</span>

              <div className="inputArea">
                <div className="labelRow">
                  <label>Digite aqui</label>
                  {errors.message && (
                    <Error
                      title="Campo obrigatório"
                      style={{marginLeft: '8px'}}
                    >
                      <MdErrorOutline
                        color="c53030"
                        size={20}
                        style={{marginBottom: '8px'}}
                      />
                    </Error>
                  )}
                </div>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  ref={register({required: requiredMessage})}
                  style={{borderColor: errors.message && 'red'}}
                />
              </div>
            </Mensagem>

            <Confirmar confirmarHide={confirmarHide}>
              <span>
                Tem certeza que deseja alterar o status da transação?
              </span>
            </Confirmar>

            <EditarPagamento
              editarPagamentoHide={editarPagamentoHide}
            >
              <span>
                Tem certeza que deseja alterar o comprovante de
                pagamento?
              </span>
            </EditarPagamento>

            <div className="buttonRow">
              {aceitarBoletoRejeitado === false ? (
                <button type="submit">Alterar</button>
              ) : (
                <button
                  type="submit"
                  onClick={() => {
                    // setAprovarTransacaoRejeitada(true);
                  }}
                >
                  Salvar
                </button>
              )}
              <button
                className="cancelar"
                type="button"
                onClick={() => setModalOpened(!modalOpened)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </ModalContent>
      </ModalSection>

      <MenuBar
        active={
          loggedUser.role === 'ROLE_ADMIN'
            ? 'AdminTransações'
            : 'Transações'
        }
        subMenuActive="SPC - Limpa Nome"
      />
      <NewMobileMenu
        active={
          loggedUser.role === 'ROLE_ADMIN'
            ? 'AdminTransações'
            : 'Transações'
        }
        subMenuActive="SPC - Limpa Nome"
      />

      <Page>
        <TopBar
          pageTitle="Transação -"
          pageSubtitle="SPC - Limpa Nome"
        />

        <Content>
          <RowBox>
            <Box2 style={{}}>
              <h3>Dados do Cliente</h3>

              <Row4>
                <InputArea>
                  <label>Nome</label>
                  <p>{transacao.client_name}</p>
                </InputArea>

                <InputArea>
                  <label>CPF</label>
                  <p>{transacao.client_cpf}</p>
                </InputArea>

                <InputArea>
                  <label>Telefone</label>
                  <p>{transacao.client_telefone}</p>
                </InputArea>

                <InputArea>
                  <label>E-mail</label>
                  <p>{transacao.client_email}</p>
                </InputArea>
              </Row4>
            </Box2>
          </RowBox>

          <RowBox1>
            {/* <div> </div> */}

            {/* Box azul */}
            <div>
              <BlueBox style={{}}>
                <h3>Informações de Pagamento</h3>

                <Row6>
                  <InputArea>
                    <label>Tipo da Consulta</label>
                    <p>{transacao.tipo_consulta}</p>
                  </InputArea>

                  <InputArea>
                    <label>Tipo da Transação</label>
                    <p>
                      {String(transacao.tipo_transacao).replace(
                        '_',
                        ' '
                      )}
                    </p>
                  </InputArea>

                  <InputArea>
                    <label>Consulta</label>
                    <p>{transacao.client_cpf}</p>
                  </InputArea>

                  <InputArea>
                    <label>Valor</label>
                    <p>R$ {FormatarValor(transacao.valor)}</p>
                  </InputArea>

                  <InputArea>
                    <label>Valor da Dívida</label>
                    <p>R$ {FormatarValor(transacao.valor_divida)}</p>
                  </InputArea>

                  <InputArea>
                    <label>Valor Total</label>
                    <p>
                      R${' '}
                      {FormatarValor(
                        transacao.valor_final_pago_ao_cliente
                      )}
                    </p>
                  </InputArea>
                </Row6>
              </BlueBox>
            </div>

            <div />

            <Box>
              <h3>Informações da Transação</h3>
              <Row4>
                <InputArea>
                  <label>Comissão</label>
                  {/* <p>{transacaoCliente.code}</p> */}
                  <p style={{fontStyle: 'italic', opacity: 0.3}}>
                    Dado não encontrado
                  </p>
                </InputArea>

                {/* <InputArea>
                    <label>Cód. da Transação</label>
                    <p style={{ fontStyle: 'italic', opacity: 0.3 }}>Dado não encontrado</p>
                  </InputArea> */}

                <InputArea>
                  <label>Estabelecimento</label>
                  <p>{estabelecimento.nome_fantasia}</p>
                </InputArea>

                <InputArea>
                  <label>Licenciado</label>
                  <p>{licenciado.nome_fantasia}</p>
                </InputArea>
              </Row4>
            </Box>
          </RowBox1>

          <StatusArea editStatusHide={editStatusHide}>
            <form /* onSubmit={handleSubmit(onSubmit, onError)} */>
              <InputContainer2 statusColor={statusColor}>
                <select
                  name="status"
                  placeholder="Selecione"
                  ref={register({required: true})}
                  onChange={changeStatus}
                  value={statusColor}
                >
                  <option value="1">Em Análise</option>
                  <option value="2">Solicitar documentos</option>
                  <option value="3">Aprovada</option>
                  <option value="4">Cancelada</option>
                  <option value="5">Checagem financeira</option>
                  <option value="6">Em andamento</option>
                </select>
              </InputContainer2>
            </form>

            <button
              type="button"
              onClick={() => {
                openModal();
              }}
            >
              Salvar
            </button>
          </StatusArea>
        </Content>
      </Page>
    </Container>
  );
}

export default AdminEditarConsultaSerasa;
