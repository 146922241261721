import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  /* background: rgba(55, 58, 60, 0.2); */
  background: rgba(8, 187, 233, 0.6);

  display: none;

  position: fixed;
  z-index: 500;

  > span {
    font-size: 22px;
    font-weight: 700;
    color: #FFFFFF;
    text-shadow: 0 0 10px rgba(8, 187, 233, 0.9);
  }

  ${props => props.isLoading && css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;
