import React, { createContext, useState, useContext } from 'react';

const EcInfosContext = createContext();

function EcInfosProvider({ children }) {
  const [ecInfos, setEcInfos] = useState([]);
  const [ecInfosUsuario, setEcInfosUsuario] = useState([]);
  const [ecInfosEnderecoPessoa, setEcInfosEnderecoPessoa] = useState([]);
  const [ecInfosEnderecoEstabelecimento, setEcInfosEnderecoEstabelecimento] = useState([]);
  const [ecInfosDadosBancarios, setEcInfosDadosBancarios] = useState([]);
  const [ecInfosTelefoneEstabelecimento, setEcInfosTelefoneEstabelecimento] = useState([]);
  const [ecInfosLA, setEcInfosLA] = useState([]);

  return (
    <EcInfosContext.Provider
      value={{
        ecInfos,
        setEcInfos,
        ecInfosUsuario,
        setEcInfosUsuario,
        ecInfosEnderecoPessoa,
        setEcInfosEnderecoPessoa,
        ecInfosEnderecoEstabelecimento,
        setEcInfosEnderecoEstabelecimento,
        ecInfosDadosBancarios,
        setEcInfosDadosBancarios,
        ecInfosTelefoneEstabelecimento,
        setEcInfosTelefoneEstabelecimento,
        ecInfosLA,
        setEcInfosLA,
      }}
    >
      {children}
    </EcInfosContext.Provider>
  );
};

function useEcInfos() {
  const context = useContext(EcInfosContext);
  const {
    ecInfos, setEcInfos,
    ecInfosUsuario, setEcInfosUsuario,
    ecInfosEnderecoPessoa, setEcInfosEnderecoPessoa,
    ecInfosEnderecoEstabelecimento, setEcInfosEnderecoEstabelecimento,
    ecInfosDadosBancarios, setEcInfosDadosBancarios,
    ecInfosTelefoneEstabelecimento, setEcInfosTelefoneEstabelecimento,
    ecInfosLA, setEcInfosLA
  } = context;
  return {
    ecInfos, setEcInfos,
    ecInfosUsuario, setEcInfosUsuario,
    ecInfosEnderecoPessoa, setEcInfosEnderecoPessoa,
    ecInfosEnderecoEstabelecimento, setEcInfosEnderecoEstabelecimento,
    ecInfosDadosBancarios, setEcInfosDadosBancarios,
    ecInfosTelefoneEstabelecimento, setEcInfosTelefoneEstabelecimento,
    ecInfosLA, setEcInfosLA
  };
};

export { EcInfosProvider, useEcInfos };
