import styled, { css } from 'styled-components';
import { shade } from 'polished';

import backgroundImg from '../../assets/images/background_2.jpg';

import ToolTip from '../../components/Tooltip';

export const Container = styled.div`
  .abled {
    cursor: pointer;
  }

  .disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 90px 60px 30px;

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const AddEC = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-bottom: 30px;

  > span {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin-right: 30px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #fff;
  border-radius: 50%;
  border: none;
  outline: none;

  width: 50px;
  height: 50px;

  > svg {
    width: 30px;
    height: 30px;
  }
`;

export const TableContent = styled.div`
  position: relative;

  .MuiTypography-root {
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
  }

  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-paddingCheckbox {
    background: #10104F;
  }

  span.MuiButtonBase-root.MuiTableSortLabel-root.Mui-disabled {
    color: transparent;
  }

  .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
    background-color: #fff;
  }

  tr.MuiTableRow-root {
    border: none;
  }

  tr.MuiTableRow-root:nth-of-type(odd) {
    background-color: #F5F5F5;
  }

  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
    padding-bottom: 20px;
    height: 100px;
  }

  .MuiToolbar-regular {
    min-height: 84px;
  }
  
  .css-2b097c-container {
    position: absolute;
    box-sizing: border-box;
    width: 140px;
    top: 23px;
    right: 300px;
    z-index: 50;
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const TableContentMobile = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px 3px;
  margin-bottom: 100px;

  @media (min-width: 500px) {
    display: none;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid #EEEEEE;
  padding: 0 17px 10px;

  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .filterStatusSelect.css-2b097c-container {
    width: 160px;
    margin-bottom: 20px;
  }

  > span {
    margin-bottom: 20px;
  }
`;

export const Line = styled.div`
  padding: 15px 17px;
  border-bottom: 1px solid #EEEEEE;

  > span {
    font-size: 18px;
    color: #313131;
  }

  > p {
    font-size: 14px;
    color: #9FA7BA;
    margin: 0;
  }

  div.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    > span {
      font-size: 12px;
      font-weight: 700;
      /* color: #5CB85C; */
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px 17px 5px;
`;

export const PaginationButton = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileBody = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  height: 448px;
`;

export const FilterBox = styled.div`
  /* position: relative; */
  /* top: -87px; */

  width: 60%;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  
  background: #ffffff;
  border-radius: 4px;
  padding: 15px 20px 15px;
  
  margin-left: auto;
  margin-bottom: 20px;
`;

export const FilterDate = styled.div`
  position: absolute;
  z-index: 55;
  top: 23px;
  right: 455px;
  
  display: flex;
  align-items: center;

  height: 38px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4px;
  /* padding: 0px 10px; */

  cursor: pointer;

  span {
    margin-right: 5px;
    color: #808080;
  }

  .apagarFiltro {
    position: absolute;
    z-index: 60;
    right: -10px;
    top: -10px;
    
    background: #FFFFFF;
    padding: 2px;
    border-radius: 50%;

    cursor: pointer;
  }
`;

export const DatePicker = styled.div`
  position: absolute;
  z-index: 55;
  top: 61px;
  right: 340px;

  display: none;
  
  background: #ffffff;
  border-radius: 4px;
  padding: 15px 20px 15px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  
  margin-left: auto;
  margin-bottom: 20px;

  ${props => props.filtroAberto && css`
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: flex-end; */
  `}
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  flex: 1;

  margin-right: 10px;

  label {
    font-size: 14px;
    color: #373A3C;
  }

  input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
  }
  input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  box-sizing: border-box;

  /* margin-bottom: 18px; */
  padding: 1px 11px 1px 1px;

  ${props => props.isErrored && css`
    border: 1px solid #c53030;
  `}

  ${props => props.isFocused && css`
    border: 1px solid #0073FC;
  `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }

  select {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  }
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const ButtonBox = styled.div`
  /* position: relative; */

  button {
    padding: 8px 20px;
    /* width: 110px; */
    background: #0073FC;
    border: 1px solid #0073FC;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;

    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#0073FC')}
    }

    &.back {
      margin-right: 20px;

      width: 110px;
      color: #373A3C;
      background: #F5F8FB;
      border: 1px solid #ADADAD;

      &:hover {
        background: ${shade(0.1, '#F5F8FB')}
      }
    }
  }

  svg {
    position: absolute;
    right: -10px;
    top: -10px;
    
    background: #FFFFFF;
    padding: 2px;
    border-radius: 50%;

    cursor: pointer;
  }
`;
