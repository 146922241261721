import React from 'react';

// import api from '../../services/api';

// import { useToast } from '../../hooks/ToastContext';

import { Container, Row, InputArea, BoxEmail } from './styles';

function PersonalData() {
  // const { addToast } = useToast();
  // const history = useHistory();

  // const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const userData = JSON.parse(localStorage.getItem('@DeixaNoAzul:loggedUser'));

  return (
    <Container>
      <BoxEmail>
        <span>Para solicitar alteração dos dados pessoais, envie um e-mail para <a href="mailto:comercial@deixanoazul.com.br"><i>comercial@deixanoazul.com.br</i></a></span>
      </BoxEmail>

      <Row>
        <InputArea>
          <label>*Nome</label>
          <p>{userData.name}</p>
        </InputArea>

        <InputArea>
          <label>*E-mail</label>
          <p>{userData.email}</p>
        </InputArea>
      </Row>
    </Container>
  );
}

export default PersonalData;