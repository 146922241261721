import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import api from "../../services/api";

import TopBar from "../../components/TopBar";
import MenuBar from "../../components/MenuBar";
import NewMobileMenu from "../../components/NewMobileMenu";
import LoadingContent from '../../components/LoadingContent';

import {
  Container,
  Page,
  Content,
  RowBox1,
  Box,
  Row,
  Row3,
  Row4,
  InputArea,
  Flex,
  Separator,
  BlueBox,
  StatusArea,
  Status,
} from './styles';

function EditarContaDeLuz() {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const userToken = localStorage.getItem("@DeixaNoAzul:accessToken");

  // const [userID, setUserID] = useState("");

  const idTransacao = location.pathname
    .replace("/conta-de-luz/editar/", "")
    .split("")
    .reverse()
    .join("");
  
  const [transacaoInfos, setTransacaoInfos] = useState({});
  const [transacaoCliente, setTransacaoCliente] = useState({});
  const [transacaoClienteEndereco, setTransacaoClienteEndereco] = useState({});
  const [transacaoClienteDadosBancarios, setTransacaoClienteDadosBancarios] = useState({});

  const [valorParcela, setValorParcela] = useState('');
  const [valorPagamento, setValorPagamento] = useState('');
  const [totalTransação, setTotalTransação] = useState('');
  const [sexo, setSexo] = useState('');

  // Faz as requisições para preencher as informações
  useEffect(() => {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    async function requestTransacao() {
      const responseTransacao = await api.get(
        `/energy/transaction/${idTransacao}`,
        auth
      );
      const resultTransacao = responseTransacao.data.data;

      // console.log('resultTransacao', resultTransacao);
      setTransacaoInfos(resultTransacao);
      
      const responseCliente = await api.get(
        `/energy/transaction/client/${resultTransacao.cliente_id}`,
        auth
      );
      const resultCliente = responseCliente.data.data;

      setTransacaoCliente(resultCliente);
      setTransacaoClienteEndereco(resultCliente.endereco);
      setTransacaoClienteDadosBancarios(resultCliente.dados_bancarios.data);

      resultCliente.sex === 'M' ? setSexo('Masculino') : setSexo('Feminino');

      /**
       * VALORES DA TRANSAÇÃO
       */

      // Valor da parcela
      const valor_parcela = resultTransacao.valor_parcela.toString().split(".");
      setValorParcela(
        `${valor_parcela[0]},${
          valor_parcela[1] ? valor_parcela[1].substring(0, 2) : "00"
        }`
      );

      // Valor Total transação
      const total_tansacao = resultTransacao.valor_final.toString().split(".");
      setTotalTransação(
        `${total_tansacao[0]},${
          total_tansacao[1] ? total_tansacao[1].substring(0, 2) : "00"
        }`
      );

      // Valor do pagamento (feito ao cliente final)
      const valor_pagamento = resultTransacao.valor_pagamento.toString().split(".");
      setValorPagamento(
        `${valor_pagamento[0]},${
          valor_pagamento[1] ? valor_pagamento[1].substring(0, 2) : "00"
        }`
      );

      setIsLoading(false);
    }
    
    requestTransacao();
  }, [idTransacao, userToken]);


  function statusTag() {

    let colorStatus = '';
    if(transacaoInfos.status === 'EM_ANALISE') {colorStatus = "#FEAC02"} // Em análise
    if(transacaoInfos.status === 'APROVADO') {colorStatus = "#5CB85C"} // Aprovada
    if(transacaoInfos.status === 'CANCELADO') {colorStatus = "#F35757"} // Cancelada
    if(transacaoInfos.status === 'REJEITADO') {colorStatus = "#F35757"} // Cancelada

    let messageStatus = '';
    if(transacaoInfos.status === 'EM_ANALISE') {messageStatus = "Em análise"} // Em análise
    if(transacaoInfos.status === 'APROVADO') {messageStatus = "Aprovada"} // Aprovada
    if(transacaoInfos.status === 'CANCELADO') {messageStatus = "Cancelada"} // Cancelada
    if(transacaoInfos.status === 'REJEITADO') {messageStatus = "Rejeitada"} // Cancelada

    return (
      <Status style={{ background: colorStatus }}>
        <span>{messageStatus}</span>
      </Status>
    )
  }

  return (
    <Container>
      <LoadingContent isLoading={isLoading} />

      <MenuBar subMenuActive="Conta de Luz" />
      <NewMobileMenu active="Transações" />

      <Page>
        <TopBar pageTitle="Transação -" pageSubtitle="Conta de Luz" />

        <Content>
          <RowBox1>
            <Box>
              <h3>Dados do Cliente</h3>

              <Row>
                <InputArea>
                  <label>Nome do Cliente</label>
                  <p>{transacaoCliente.name}</p>
                </InputArea>

                <InputArea>
                  <label>Data de Nascimento</label>
                  <p>{
                    transacaoCliente.data_aniversario === undefined
                      ? ''
                      : transacaoCliente.data_aniversario.split("-").reverse().join("/")
                  }</p>
                </InputArea>
              </Row>

              <Row>
                <InputArea>
                  <label>Número do CPF</label>
                  <p>{transacaoCliente.cpf}</p>
                </InputArea>

                <InputArea>
                  <label>Sexo</label>
                  <p>{sexo}</p>
                </InputArea>
              </Row>

              <Row>
                <InputArea>
                  <label>E-mail</label>
                  <p>{transacaoCliente.email}</p>
                </InputArea>

                <InputArea>
                  <label>Telefone</label>
                  <p>{transacaoCliente.numero_telefone_1}</p>
                </InputArea>
              </Row>
            
              <Row4>
                <InputArea>
                  <label>Número do RG</label>
                  <p>{transacaoCliente.rg}</p>
                </InputArea>

                <InputArea>
                  <label>Órgão Emissor</label>
                  <p>{transacaoCliente.rg_emissor}</p>
                </InputArea>

                <InputArea>
                  <label>UF</label>
                  <p>{transacaoCliente.rg_uf}</p>
                </InputArea>

                <InputArea>
                  <label>Data de Emissão</label>
                  <p>{
                    transacaoCliente.rg_data_emissao === undefined
                      ? ''
                      : transacaoCliente.rg_data_emissao.split("-").reverse().join("/")
                  }</p>
                </InputArea>
              </Row4>
            
              <Row4>
                <InputArea>
                  <label>Naturalidade</label>
                  <p>{transacaoCliente.naturalidade}</p>
                </InputArea>

                <InputArea>
                  <label>Nacionalidade</label>
                  <p>{transacaoCliente.nacionalidade}</p>
                </InputArea>

                <InputArea>
                  <label>Estado Civil</label>
                  <p>{transacaoCliente.estado_civil}</p>
                </InputArea>
              </Row4>
            
              <Row>
                <InputArea>
                  <label>Nome da Mãe</label>
                  <p>{transacaoCliente.nome_mae}</p>
                </InputArea>

                <InputArea>
                  <label>Nome do Pai</label>
                  <p>{transacaoCliente.nome_pai}</p>
                </InputArea>
              </Row>
            </Box>

            <div>
              <Box>
                <h3>Endereço</h3>

                <Row>
                  <InputArea>
                    <label>CEP</label>
                    <p>{transacaoClienteEndereco.cep}</p>
                  </InputArea>

                  <InputArea>
                    <label>Endereço</label>
                    <p>{transacaoClienteEndereco.rua}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Número</label>
                    <p>{transacaoClienteEndereco.numero}</p>
                  </InputArea>

                  <InputArea>
                    <label>Complemento</label>
                    <p>{transacaoClienteEndereco.complemento}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Bairro</label>
                    <p>{transacaoClienteEndereco.bairro}</p>
                  </InputArea>

                  <InputArea>
                    <label>Cidade</label>
                    <p>{transacaoClienteEndereco.cidade}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Estado</label>
                    <p>{transacaoClienteEndereco.uf}</p>
                  </InputArea>
                </Row>
              </Box>
            </div>

            <div>
              <Box>
                <h3>Dados Bancários</h3>
              
                <Row>
                  <InputArea>
                    <label>Banco</label>
                    <p>{transacaoClienteDadosBancarios.nome_banco}</p>
                  </InputArea>

                  <Flex>
                    <InputArea style={{ marginRight: 40 }}>
                      <label>Agência</label>
                      <p>{transacaoClienteDadosBancarios.agencia}</p>
                    </InputArea>

                    {/* <InputArea>
                      <label>Dígito</label>
                      <p>{transacaoClienteDadosBancarios.agencia}</p>
                    </InputArea> */}
                  </Flex>
                </Row>
              
                <Row>
                  <InputArea>
                    <label>Tipo de Conta</label>
                    <p>{transacaoClienteDadosBancarios.tipo_conta}</p>
                  </InputArea>

                  <Flex>
                    <InputArea style={{ marginRight: 40 }}>
                      <label>Número</label>
                      <p>{transacaoClienteDadosBancarios.numero_conta}</p>
                    </InputArea>

                    {/* <InputArea>
                      <label>Dígito</label>
                      <p>{transacaoClienteDadosBancarios.numero_conta}</p>
                    </InputArea> */}
                  </Flex>
                </Row>            
              </Box>
              
              <BlueBox style={{ marginTop: 30 }}>
                <h3>Valores da parcela</h3>
              
                <Row3> 
                  <InputArea>
                    <label>Valor da parcela</label>
                    <p>{transacaoInfos.numero_parcelas}x R$ {valorParcela}</p>
                  </InputArea>

                  <InputArea>
                    <label>Valor do Contrato</label>
                    <p>R$ {valorPagamento}</p>
                  </InputArea>

                  <InputArea>
                    <label>Valor total </label>
                    <p>R$ {totalTransação}</p>
                  </InputArea>
                </Row3>

                <Separator style={{ opacity: 0.3, marginTop: 20, marginBottom: 20 }} />
              
                <Row3>
                  <InputArea>
                    <label>Cia de Energia</label>
                    <p>{transacaoInfos.companhia}</p>
                  </InputArea>

                  <InputArea>
                    <label>Nº da Instalação</label>
                    <p>{transacaoInfos.numero_instalacao}</p>
                  </InputArea>

                  <InputArea>
                    <label>Data da Leitura</label>
                    <p>{transacaoInfos.data_leitura}</p>
                  </InputArea>
                </Row3>  
              </BlueBox>
            </div>

            <div>
              <Box className="referencias">
                <h3>Referências</h3>

                <InputArea>
                  <label>Nome</label>
                  <p>{transacaoCliente.contato_adicional_1_nome}</p>
                </InputArea>

                <InputArea>
                  <label>Endereço</label>
                  <p>{transacaoCliente.contato_adicional_1_endereco}</p>
                </InputArea>

                <InputArea>
                  <label>Telefone</label>
                  <p>{transacaoCliente.contato_adicional_1_numero_telefone}</p>
                </InputArea>

                <Separator />

                <InputArea>
                  <label>Nome</label>
                  <p>{transacaoCliente.contato_adicional_2_name}</p>
                </InputArea>

                <InputArea>
                  <label>Endereço</label>
                  <p>{transacaoCliente.contato_adicional_2_endereco}</p>
                </InputArea>

                <InputArea>
                  <label>Telefone</label>
                  <p>{transacaoCliente.contato_adicional_2_numero_telefone}</p>
                </InputArea>
              </Box>
            </div>
          </RowBox1>

          <StatusArea>
            {statusTag()}

            <button
              type="button"
              onClick={() => history.push('/conta-de-luz')}
            >
              Voltar
            </button>
          </StatusArea>
        </Content>
      </Page>
    </Container>
  );
};

export default EditarContaDeLuz;
