import React, { createContext, useState, useContext } from 'react';

const TransacaoExternaContext = createContext();

function TransacaoExternaProvider({ children }) {
  const [transacaoExterna, setTransacaoExterna] = useState([]);

  return (
    <TransacaoExternaContext.Provider
      value={{ transacaoExterna, setTransacaoExterna }}
    >
      {children}
    </TransacaoExternaContext.Provider>
  );
};

function useTransacaoExterna() {
  const context = useContext(TransacaoExternaContext);
  const {
    transacaoExterna, setTransacaoExterna,
  } = context;
  return {
    transacaoExterna, setTransacaoExterna,
  };
};

export { TransacaoExternaProvider, useTransacaoExterna };
