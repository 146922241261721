export default function telefoneMask(e) {

  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d)/, "($1)$2");
  value = value.replace(/(\d{5})(\d)/, "$1-$2");
  
  return e.currentTarget.value = value;

};
