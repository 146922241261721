import styled, { css } from 'styled-components';

import backgroundImg from "../../assets/images/background.jpg";

import { shade } from "polished";

import ToolTip from "../../components/Tooltip";

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 160px 20px 30px;

  @media (max-width: 1200px) {
    padding: 100px 100px 20px 30px;
  }

  @media (max-width: 934px) {
    padding: 80px 70px 20px 30px;
  }

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const RowBox1 = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 30px;

  margin-bottom: 30px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;

export const RowBox2 = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 0.7fr 1fr;
  gap: 30px;

  margin-bottom: 30px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;

export const Box = styled.div`
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;

  margin-bottom: 30px;

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  > span {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;

    img {
      width: 20px;
      margin-right: 10px;
    }
  }

  &.referencias {
    margin-top: -100px;

    @media (max-width: 1454px) {
      margin-top: -90px;
    }

    @media (max-width: 1350px) {
      margin-top: -80px;
    }

    @media (max-width: 1220px) {
      margin-top: -240px;
    }

    @media (max-width: 1040px) {
      margin-top: -470px;
    }

    @media (max-width: 934px) {
      margin-top: -440px;
    }

    @media (max-width: 500px) {
      margin-top: 0px;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

export const BlueBox = styled.div`
  background: #0073fc;
  padding: 20px 30px;
  border-radius: 8px;

  > h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #ffffff;
  }

  > div label {
    color: #ffffff;
  }

  > div p {
    color: #ffffff;
    font-weight: bold;
  }

  > span {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;

    img {
      width: 20px;
      margin-right: 10px;
    }
  }

  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  width: 100%;

  &.marginTop {
    margin-top: 18px;
  }

  ${(props) =>
    props.downloadBoletoHide &&
    css`
      display: none;
    `}

  &.anexos {
    @media (max-width: 1366px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  @media (max-width: 1040px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Row3 = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1fr 1fr;
  gap: 30px;

  width: 100%;

  @media (max-width: 1220px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1040px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const InputArea = styled.div`
  > label {
    display: block;

    &.marginTop {
      margin-top: 10px;
    }
  }

  button.downloadButton {
    display: inline-flex;
    background: transparent;
    padding: 0;
    margin: 5px 0 18px;
    color: #0073fc;
    border: none;
    outline-style: none;
    cursor: pointer;

    &:hover {
      background: transparent;
      color: ${shade(0.2, "#0073FC")};
    }
  }

  > p {
    color: #0073fc;
    margin: 5px 0 18px;

    &.link {
      cursor: pointer;
      text-decoration: underline;
      /* border-bottom: 1px solid #0073FC; */
    }
  }

  > button {
    padding: 8px 22px;
    width: 100%;
    background: #0073fc;
    border: 1px solid #0073fc;
    color: #ffffff;
    border: none;
    border-radius: 4px;

    margin-top: 20px;
    margin-bottom: 60px;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, "#0073FC")};
    }
  }

  div.action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 16px;
      height: 16px;

      cursor: pointer;
    }
  }

  @media (max-width: 500px) {
    &.campoAnexo {
      margin-bottom: 15px;
    }
  }
`;

export const SelectStatus = styled.div`
  margin-bottom: 30px;

  form {
    display: flex;
    align-items: center;

    .sc-cjzMPC.dRiRcZ {
      margin: 0;
    }

    button {
      padding: 8px 22px;
      background: #0073fc;
      color: #ffffff;
      border: none;
      border-radius: 4px;

      transition: 0.2s;
      outline-style: none;

      &:hover {
        background: ${shade(0.2, "#0073FC")};
      }
    }
  }

  ${(props) =>
    props.editStatusHide &&
    css`
      display: none;
    `}

  @media (max-width: 500px) {
    margin-bottom: 100px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 220px;
  height: 40px;

  background: #feac02;
  /* border: 1px solid #CCCCCC; */
  border-radius: 4px;
  box-sizing: border-box;

  margin-right: 30px;
  padding: 1px 11px 1px 1px;

  ${(props) =>
    props.isFocused &&
    css`
      border: 1px solid #0073fc;
    `}

  input {
    border: none;

    height: 34px;
    width: 100%;
    padding: 0 10px;
  }

  select {
    border: none;
    /* background: #FEAC02; */
    color: #fff;

    height: 34px;
    width: 100%;
    padding: 0 10px;

    ${(props) =>
      props.statusColor === "EM_ANALISE" &&
      css`
        background: #feac02;
      `}

    ${(props) =>
      props.statusColor === "2" &&
      css`
        background: #5097ff;
      `}

    ${(props) =>
      props.statusColor === "APROVADO" &&
      css`
        background: #5cb85c;
      `}

    ${(props) =>
      props.statusColor === "CANCELADO" &&
      css`
        background: #f35757;
      `}

    ${(props) =>
      props.statusColor === "REJEITADO" &&
      css`
        background: #f35757;
      `}

    ${(props) =>
      props.statusColor === "5" &&
      css`
        background: #8850ff;
      `}
  }

  option {
    color: #000;
    background: #fff;
  }

  ${(props) =>
    props.statusColor === "EM_ANALISE" &&
    css`
      background: #feac02;
    `}

  ${(props) =>
    props.statusColor === "2" &&
    css`
      background: #5097ff;
    `}

    ${(props) =>
    props.statusColor === "APROVADO" &&
    css`
      background: #5cb85c;
    `}

    ${(props) =>
    props.statusColor === "CANCELADO" &&
    css`
      background: #f35757;
    `}

    ${(props) =>
    props.statusColor === "REJEITADO" &&
    css`
      background: #f35757;
    `}

    ${(props) =>
    props.statusColor === "5" &&
    css`
      background: #8850ff;
    `}
`;

export const ModalSection = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(55, 58, 60, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 5;

  ${(props) =>
    props.modalOpened &&
    css`
      display: none;
    `}
`;

export const ModalContent = styled.div`
  color: #fff;
  background: #10104f;
  padding: 30px;
  border-radius: 8px;
  max-width: 640px;
  /* max-height: 420px; */

  width: 100%;
  /* height: 100%; */

  > svg {
    margin-left: calc(100% - 30px);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: ${shade(0.2, "#fff")};
    }
  }

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 30px;

    @media (max-width: 500px) {
      font-size: 18px;
      display: block;
      margin-bottom: 20px;
    }
  }

  div.buttonRow {
    margin: 30px auto 0;
    text-align: center;

    button {
      padding: 8px 27px;
      background: #0073fc;
      border: 1px solid #0073fc;
      color: #ffffff;
      border: none;
      border-radius: 4px;

      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, "#0073FC")};
      }
    }

    button.cancelar {
      padding: 8px 27px;
      background: #f5f8fb;
      border: 1px solid #adadad;
      color: #373a3c;
      border-radius: 4px;

      margin-left: 30px;
      transition: 0.2s;

      &:hover {
        background: ${shade(0.2, "#F5F8FB")};
      }
    }

    @media (max-width: 500px) {
      margin: 20px auto 0;
    }
  }

  @media (max-width: 500px) {
    width: 94%;
    padding: 20px;
    margin-bottom: 20px;
  }
`;

export const Aprovado = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.aprovadoHide &&
    css`
      display: none;
    `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  div.upload {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 150px;

    /* background: red; */
    
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #10104F;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  @media (max-width: 500px) {
    div.upload {
      overflow-x: hidden;
      overflow-y: scroll;
      height: 150px;
      margin-top: -10px;
      margin-bottom: -15px;
    }
  }
`;

export const Mensagem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 30px;

  ${(props) =>
    props.messageHide &&
    css`
      display: none;
    `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  div.inputArea {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 170px;

    textarea {
      border-radius: 4px;
    }

    div.labelRow {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const Confirmar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.confirmarHide &&
    css`
      display: none;
    `}

  > span {
    font-size: 20px;
    display: block;
    margin-bottom: 14px;

    &:last-child {
      margin-top: 14px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
      text-align: center;
    }
  }
`;

export const Error = styled(ToolTip)`
  span {
    background: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 11px;

  border: 1px solid #fff;
  border-radius: 4px;

  /* width: 210px; */
  width: 100%;

  &.edição {
    border: 1px solid #cccccc;
    background: transparent;
  }

  ${(props) =>
    props.pagamentoHide &&
    css`
      display: none;
  `}
`;
