import React from 'react';

// import sentEmailImg from '../../assets/images/sent-email.png';
import checkImg from '../../assets/images/check.svg';
import backgroundBlue from '../../assets/images/background-blue.png';
import backgroundGreen from '../../assets/images/background-green.png';

import { Container, BgBlue, BgGreen } from './styles';

function SentEmail() {
  const anoAtual = new Date().getFullYear();

  return (
    <Container>
      <BgBlue src={backgroundBlue} alt="Imagem decorativa" />
      <BgGreen src={backgroundGreen} alt="Imagem decorativa" />
    
      <img src={checkImg} alt="Check" />

      <h2>E-mail enviado com sucesso!</h2>
      <p>Confira seu email para finalizar a recuperação de senha.</p>

      <span>© {anoAtual}. Todos os direitos reservados</span>
    </Container>
  );
}

export default SentEmail;
