import React, { createContext, useCallback, useState, useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import api from '../services/api';
import { useToast } from './ToastContext';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // const history = useHistory();
  const { addToast } = useToast();

  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@DeixaNoAzul:accessToken');
    const user = localStorage.getItem('@DeixaNoAzul:loggedUser');

    if (token && user) {
      return { token, user: JSON.parse(user) };

      /* const decoded = jwt_decode(token);

      if (Date.now() < decoded.exp) {
        // Token ok, permanece na aplicação
        return { token, user: JSON.parse(user) };
      } else {
        // Token expirado, usuário deslogado

        localStorage.removeItem('@DeixaNoAzul:accessToken');
        localStorage.removeItem('@DeixaNoAzul:loggedUser');
        localStorage.removeItem('@DeixaNoAzul:licensedId');
        localStorage.removeItem('@DeixaNoAzul:estabelecimentoId');
      } */
    }

    return {};
  });


  const signIn = useCallback(async ({ email, password }) => {

    try {

      const response = await api.post('/login', {
        email,
        password,
      });
  
      // console.log('responseLogin', response)
  
      const { token, user } = response.data;
  
      localStorage.setItem('@DeixaNoAzul:accessToken', token);
      localStorage.setItem('@DeixaNoAzul:loggedUser', JSON.stringify(user))
  
      setData({ token, user });
      
    } catch (error) {
      console.log('errorLogin', error.response);
      
      addToast({
        type: 'error',
        title: 'Credenciais inválidas',
        description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
      });
    }

  }, [addToast]);

  const signOut = useCallback(() => {
    localStorage.removeItem('@DeixaNoAzul:accessToken');
    localStorage.removeItem('@DeixaNoAzul:loggedUser');
    localStorage.removeItem('@DeixaNoAzul:licensedId');
    localStorage.removeItem('@DeixaNoAzul:estabelecimentoId');
    localStorage.removeItem('@DeixaNoAzul:revendedorId');
    localStorage.removeItem('@DeixaNoAzul:parceiroId');
    localStorage.removeItem('@DeixaNoAzul:dadosSimulaçãoTransação');

    setData({});
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
