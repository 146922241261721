import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdErrorOutline, MdHighlightOff } from "react-icons/md";
import { uniqueId } from "lodash";
import filesize from "filesize";

import api from "../../services/api";

import { useToast } from "../../hooks/ToastContext";

import TopBar from "../../components/TopBar";
import MenuBar from "../../components/MenuBar";
import NewMobileMenu from "../../components/NewMobileMenu";
import LoadingContent from '../../components/LoadingContent';
import FormatarValor from '../../components/FormatarValor';

import UploadPagamento from "../../components/Upload/pagamento";
import InfoPagamento from "../../components/UploadInfo/pagamento";

import {
  Container,
  Page,
  Content,
  RowBox1,
  Box,
  BlueBox,
  Row,
  Row3,
  InputArea,
  SelectStatus,
  InputContainer,
  ModalSection,
  ModalContent,
  Aprovado,
  Mensagem,
  Confirmar,
  Error,
  UploadContainer,
  RowBox2,
} from './styles';

function AdminEditarCertificadoDigital() {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true); // Default true

  const userToken = localStorage.getItem("@DeixaNoAzul:accessToken");

  const { addToast } = useToast();

  const idTransacao = location.pathname
    .replace("/admin/certificado-digital/editar/", "")
    .split("")
    .reverse()
    .join("");
  
  const [transacaoInfos, setTransacaoInfos] = useState({});
  const [transacaoInfosEndereco, setTransacaoInfosEndereco] = useState({});
  
  const [ecInfos, setEcInfos] = useState({});

  const { register, handleSubmit, getValues, setValue, setError, clearErrors, errors } = useForm();
  const onSubmit = async (data) => {
    // console.log(data);

    // Bearer token
    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    if (data.status === "APROVADO" && uploadedPagamento.length === 0) {
      addToast({
        type: "error",
        title: "Erro na atualização",
        description: "Você precisa anexar um comprovante para aprovar um certificado.",
      });

      setError("doc_pagamento", {
        type: "manual",
        message: "Campo obrigatório.",
      });

      return;
    }

    try {

      if (data.status === "APROVADO") {
        // Faz o upload do comprovante de pagamento
        if (uploadedPagamento.length > 0) {
          const data = new FormData();

          uploadedPagamento.forEach((item) => {
            data.append(`image${uploadedPagamento.indexOf(item)}`, item.file);
          });

          await api
            .post("/documento_upload_mult", data, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + userToken,
              },
              onUploadProgress: (e) => {
                setProgressPagamento(
                  parseInt(Math.round((e.loaded * 100) / e.total))
                );
              },
            })
            .then((response) => {
              setStatusPagamento({
                uploaded: true,
                error: false,
              });

              const value = response.data.data;
              setValue("doc_pagamento", value.id);
            })
            .catch(() => {
              setStatusPagamento({
                uploaded: false,
                error: true,
              });

              return;
            });
        }

        await api.put(`/digital/certificate/status/${idTransacao}`,{
          status: data.status,
          comprovante_id: getValues('doc_pagamento')
        }, auth).then(response => console.log(response));
      }

      if (data.status === "REJEITADO" || data.status === "CANCELADO") {
        await api.put(`/digital/certificate/status/${idTransacao}`,{
          status: data.status,
        }, auth).then(response => console.log(response));

        // mensagem
        await api
        .post(
          "/send_message",
          {
            recipient: transacaoInfos.estabelecimento_id,
            subject: "Atualização de status",
            message: data.message,
          },
          auth
        )
        .then((response) => console.log(response));
      }

      addToast({
        type: "success",
        title: "Deu tudo certo",
        description: "Dados atualizados com sucesso.",
      });
  
      history.push("/admin/certificado-digital");
      
    } catch (error) {
      console.log(error.response);
      addToast({
        type: "error",
        title: "Erro na atualização",
        description: "Ocorreu um erro ao atualizar a transação, tente novamente ou entre em contato.",
      });
    }

  };
  const onError = (errors) => {
    // console.log(errors)
    addToast({
      type: "error",
      title: "Erro na atualização",
      description: "Ocorreu um erro ao atualizar seus dados, tente novamente.",
    });
  };

  // Faz as requisições para preencher as informações
  useEffect(() => {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    async function requestTransacao() {
      const responseTransacao = await api.get(
        `/digital/certificate/${idTransacao}`,
        auth
      );
      const resultTransacao = responseTransacao.data.data;
      // console.log('resultTransacao', resultTransacao);

      setTransacaoInfos(resultTransacao);
      setTransacaoInfosEndereco(resultTransacao.endereco);

      // Muda o valor e a cor do select
      setValue("status", resultTransacao.status);
      setStatusColor(resultTransacao.status.toString());

      if (resultTransacao.status === 'APROVADO') {
        setEditStatusHide(true);
      };

      const responseEC = await api.get(
        `/estabelecimento/${resultTransacao.estabelecimento_id}`,
        auth
      );

      const resultEC = responseEC.data.data;
      // console.log(resultEC);
      setEcInfos(resultEC);

      setIsLoading(false);
    }
    
    requestTransacao();
  }, [idTransacao, setValue, userToken]);

  // Abrir e fechar modal
  const [modalOpened, setModalOpened] = useState(true);

  const [editStatusHide, setEditStatusHide] = useState(false);
  // State para mudança de cor do select
  const [statusColor, setStatusColor] = useState("");

  // State para definir regra de obrigatoriedade
  const [requiredMessage, setRequiredMessage] = useState(false);

  // State para mostrar campo de upload de arquivos ou mensagem
  const [aprovadoHide, setAprovadoHide] = useState(true);
  const [messageHide, setMessageHide] = useState(true);
  const [confirmarHide, setConfirmarHide] = useState(true);

  const changeStatus = useCallback(() => {
    const selectValue = getValues("status");

    setStatusColor(selectValue);

    if (selectValue === "APROVADO") {
      // setRequiredUpload(true);
      setRequiredMessage(false);

      setAprovadoHide(false);
      setMessageHide(true);
      setConfirmarHide(true);
    }

    if (selectValue === "REJEITADO" || selectValue === "CANCELADO") {
      setRequiredMessage(true);
      // setRequiredUpload(false);

      setMessageHide(false);
      setAprovadoHide(true);
      setConfirmarHide(true);
    }

    if (selectValue === "5") {
      setRequiredMessage(false);
      // setRequiredUpload(false);

      setConfirmarHide(false);
      setMessageHide(true);
      setAprovadoHide(true);
    }
  }, [getValues]);

  const openModal = useCallback(() => {
    const selectValue = getValues("status");

    if (selectValue !== "EM_ANALISE") {
      setModalOpened(false);
    }
  }, [getValues]);

  // Upload do Comprovante de pagamento da transação ------------------ START
  const [uploadedPagamento, setUploadedPagamento] = useState([]);
  const [progressPagamento, setProgressPagamento] = useState(0);
  const [statusPagamento, setStatusPagamento] = useState({
    uploaded: false,
    error: false,
  });

  // Preparar arquivos para upload
  const submitFileComprovantePagamento = useCallback(
    (file) => {
      const uploadFile = {
        file: file[0],
        id: uniqueId(),
        name: file[0].name,
        readableSize: filesize(file[0].size),
        preview: URL.createObjectURL(file[0]),
      };

      clearErrors('doc_pagamento');

      setUploadedPagamento([...uploadedPagamento, uploadFile]);
    },
    [clearErrors, uploadedPagamento]
  );

  // Faz o upload de todos os arquivos EM CASO DE EDIÇÃO DE COMPROVANTE
  /* const handleUploadNovoPagamento = useCallback(() => {
    // Envia para a API
    const data = new FormData();

    uploadedPagamento.forEach((item) => {
      data.append(`image${uploadedPagamento.indexOf(item)}`, item.file);
    });

    api
      .post("/documento_upload_mult", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + userToken,
        },
        onUploadProgress: (e) => {
          setProgressPagamento(
            parseInt(Math.round((e.loaded * 100) / e.total))
          );
        },
      })
      .then((response) => {
        setStatusPagamento({
          uploaded: true,
          error: false,
        });

        const value = response.data.data;
        setValue("doc_pagamento", value.id);

        // abrir modal
        setModalOpened(false);
        setAprovadoHide(true);
        setMessageHide(true);
        setConfirmarHide(true);
        setEditarPagamentoHide(false);
      })
      .catch(() => {
        setStatusPagamento({
          uploaded: false,
          error: true,
        });
      });
  }, [uploadedPagamento, setValue, userToken]); */

  // Remove o arquivo da lista de arquivos
  const handleDeletePagamento = useCallback(
    (id) => {
      const newFiles = uploadedPagamento.filter((termo) => termo.id !== id);

      setUploadedPagamento(newFiles);
    },
    [uploadedPagamento]
  );
  // Upload do Comprovante de pagamento da transação -------------------- END

  // Faz o download do comprovante de conclusão anexado pelo Admin
  async function downloadComprovante() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    await api
      .get(`/documento_download/${transacaoInfos.comprovante_id}`, auth)
      .then((response) => {

        const result = response.data.data;

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = result;
        const downloadLink = document.createElement("a");
        const fileName = "comprovante_conclusão.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });

    setIsLoading(false);
  }

  return (
    <Container>
      <LoadingContent isLoading={isLoading} />

      <ModalSection modalOpened={modalOpened}>
        <ModalContent>
          <MdHighlightOff
            size={30}
            onClick={() => setModalOpened(!modalOpened)}
          />
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Aprovado aprovadoHide={aprovadoHide}>
              <span style={{ textAlign: "center", fontWeight: 700 }}>
                Anexar comprovante/print da ativação no sistema
              </span>

              <InputArea className="campoAnexo">
                <label>*Comprovante de pagamento</label>
                <UploadContainer
                  style={{ borderColor: errors.doc_pagamento && "red" }}
                >
                  <UploadPagamento
                    onUpload={submitFileComprovantePagamento}
                    isDisabled={uploadedPagamento.length === 10 ? true : false}
                    isWhite={false}
                  />
                  <input
                    type="text"
                    name="doc_pagamento"
                    ref={register}
                    hidden
                  />
                  {errors.doc_pagamento && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </UploadContainer>
                <div className="upload" style={{ height: uploadedPagamento.length === 0 ? 50 : 150 }}>
                  {!!uploadedPagamento.length && (
                    <InfoPagamento
                      files={uploadedPagamento}
                      percent={progressPagamento}
                      status={statusPagamento}
                      onDelete={(id) => handleDeletePagamento(id)}
                      isBlack={false}
                    />
                  )}
                </div>
              </InputArea>

              <span style={{ textAlign: "center", fontWeight: 700 }}>
                Tem certeza que deseja aprovar essa transação?
              </span>
            </Aprovado>

            <Mensagem messageHide={messageHide}>
              <span style={{ fontWeight: 700 }}>Por que esse certificado foi rejeitado?</span>

              <div className="inputArea">
                <div className="labelRow">
                  <label>Digite aqui</label>
                  {errors.message && (
                    <Error
                      title="Campo obrigatório"
                      style={{ marginLeft: "8px" }}
                    >
                      <MdErrorOutline
                        color="c53030"
                        size={20}
                        style={{ marginBottom: "8px" }}
                      />
                    </Error>
                  )}
                </div>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  ref={register({ required: requiredMessage })}
                  style={{ borderColor: errors.message && "red" }}
                />
              </div>
            </Mensagem>

            <Confirmar confirmarHide={confirmarHide}>
              <span>Tem certeza que deseja alterar o status da transação?</span>
            </Confirmar>

            <div className="buttonRow">
              <button type="submit">Enviar</button>
              <button
                className="cancelar"
                type="button"
                onClick={() => setModalOpened(!modalOpened)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </ModalContent>
      </ModalSection>

      <MenuBar subMenuActive="Certificado Digital" />
      <NewMobileMenu active="AdminTransações" />

      <Page>
        <TopBar pageTitle="Transação -" pageSubtitle="Certificado Digital" />

        <Content>
          <RowBox1>
            <div>
              <Box>
                <h3>Dados do Certificado</h3>

                <Row>
                  <InputArea>
                    <label>Número do {transacaoInfos.type === 'e-cpf' ? 'CPF' : 'CNPJ'}</label>
                    <p>{transacaoInfos.type === 'e-cpf' ? transacaoInfos.cpf : transacaoInfos.cnpj}</p>
                  </InputArea>

                  <InputArea>
                    <label>Data de Nascimento</label>
                    <p>{
                      transacaoInfos.data_nascimento === undefined
                        ? ''
                        : transacaoInfos.data_nascimento.split("-").reverse().join("/")
                    }</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Doc. de Identidade (RG ou CNH)</label>
                    <p>{transacaoInfos.rg}</p>
                  </InputArea>

                  <InputArea>
                    <label>Celular</label>
                    <p>{transacaoInfos.telefone_pessoal}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>E-mail</label>
                    <p>{transacaoInfos.email}</p>
                  </InputArea>

                  <InputArea>
                    <label>Já emitiu certificado digital anteriormente?</label>
                    <p>{transacaoInfos.is_primeiro_certificado === true ? 'Sim' : 'Não'}</p>
                  </InputArea>
                </Row>
              </Box>

              <BlueBox>
                <h3>Informações de Pagamento</h3>

                <Row3>
                  <InputArea>
                    <label>Valor</label>
                    <p>R$ {FormatarValor(transacaoInfos.valor)}</p>
                  </InputArea>

                  <InputArea>
                    <label>Forma de pagamento</label>
                    <p>{transacaoInfos.payment_type === "credit" ? 'Crédito' : 'Débito'}</p>
                  </InputArea>

                  <InputArea>
                    <label>Data da Solicitação</label>
                    <p>{
                      transacaoInfos.created_at === undefined
                        ? ''
                        : transacaoInfos.created_at.substring(0, 10).split("-").reverse().join("/")
                    }</p>
                  </InputArea>
                </Row3>
              </BlueBox>
            </div>

            <div>
              <Box>
                <h3>Endereço</h3>

                <Row>
                  <InputArea>
                    <label>CEP</label>
                    <p>{transacaoInfosEndereco.cep}</p>
                  </InputArea>

                  <InputArea>
                    <label>Endereço</label>
                    <p>{transacaoInfosEndereco.rua}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Número</label>
                    <p>{transacaoInfosEndereco.numero}</p>
                  </InputArea>

                  <InputArea>
                    <label>Complemento</label>
                    <p>{transacaoInfosEndereco.complemento}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Bairro</label>
                    <p>{transacaoInfosEndereco.bairro}</p>
                  </InputArea>

                  <InputArea>
                    <label>Cidade</label>
                    <p>{transacaoInfosEndereco.cidade}</p>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>Estado</label>
                    <p>{transacaoInfosEndereco.uf}</p>
                  </InputArea>
                </Row>
              </Box>
            </div>
          </RowBox1>

          {transacaoInfos.status === "APROVADO" && (
            <RowBox2>
              <Box>
                <h3>Anexos do admin</h3>

                <InputArea>
                  <label>Comprovante de ativação</label>
                  <button
                    type="button"
                    className="downloadButton"
                    style={{ marginBottom: "0px" }}
                    onClick={() => downloadComprovante()}
                  >
                    Fazer Download
                  </button>
                </InputArea>
              </Box>
            </RowBox2>
          )}

          <RowBox1>
            <Box>
              <h3>Dados do Estabelecimento</h3>

              <Row>
                <InputArea>
                  <label>Razão social</label>
                  <p>{ecInfos.razao_social}</p>
                </InputArea>

                <InputArea>
                  <label>Nome fantasia</label>
                  <p>{ecInfos.nome_fantasia}</p>
                </InputArea>
              </Row>
            </Box>
          </RowBox1>
          
          <SelectStatus editStatusHide={editStatusHide}>
            <form /* onSubmit={handleSubmit(onSubmit, onError)} */>
              <InputContainer statusColor={statusColor}>
                <select
                  name="status"
                  placeholder="Selecione"
                  ref={register({ required: true })}
                  onChange={changeStatus}
                >
                  <option value="EM_ANALISE">Em Análise</option>
                  {/* <option value="2">Solicitar documentos</option> */}
                  <option value="APROVADO">Aprovada</option>
                  <option value="CANCELADO">Cancelada</option>
                  <option value="REJEITADO">Rejeitada</option>
                </select>
              </InputContainer>

              <button
                type="button"
                onClick={() => {
                  openModal();
                }}
              >
                Salvar
              </button>
            </form>
          </SelectStatus>
        </Content>
      </Page>
    </Container>
  );
};

export default AdminEditarCertificadoDigital;
