import styled, { css } from 'styled-components';

import backgroundImg from '../../assets/images/background.jpg';

export const Container = styled.div`
  .abled {
    cursor: pointer;
  }

  .disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* margin-top: 72px; */
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 30px 160px 0 30px;

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const AddEC = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-bottom: 30px;

  > span {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin-right: 30px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #fff;
  border-radius: 50%;
  border: none;
  outline: none;

  width: 50px;
  height: 50px;

  > svg {
    width: 30px;
    height: 30px;
  }
`;

export const TableContent = styled.div`
  position: relative;

  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-paddingCheckbox {
    background: #10104F;
  }

  span.MuiButtonBase-root.MuiTableSortLabel-root.Mui-disabled {
    color: transparent;
  }

  .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
    background-color: #fff;
  }

  tr.MuiTableRow-root {
    border: none;
  }

  tr.MuiTableRow-root:nth-of-type(odd) {
    background-color: #F5F5F5;
  }

  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
    padding-bottom: 20px;
    height: 100px;
  }

  .MuiToolbar-regular {
    min-height: 84px;
  }
  
  .css-2b097c-container {
    position: absolute;
    box-sizing: border-box;
    width: 140px;
    top: 23px;
    right: 300px;
    z-index: 50;
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const FormSection = styled.div`
  margin-bottom: 60px;

  ${props => props.formOpened && css`
    display: none;
  `}
`;

export const FormEdit = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;

  div.head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 24px;
    background: #fff;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #efefef;

    h3 {
      font-size: 20px;
      font-weight: normal;
    }

    .closeEditForm {
      cursor: pointer;
    }
  }

  .closeEditForm {
    /* position: absolute;
    top: -40px;
    right: 10px; */
    cursor: pointer;
  }

  ${props => props.formEditOpened && css`
    display: none;
  `}
`;

export const TableContentMobile = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px 3px;
  margin-bottom: 100px;

  @media (min-width: 500px) {
    display: none;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid #EEEEEE;
  padding: 0 17px 10px;

  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .filterStatusSelect.css-2b097c-container {
    width: 160px;
    margin-bottom: 20px;
  }

  > span {
    margin-bottom: 20px;
  }
`;

export const MobileBody = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  height: 448px;
`;

export const Line = styled.div`
  padding: 15px 17px;
  border-bottom: 1px solid #EEEEEE;

  > span {
    font-size: 18px;
    color: #313131;
  }

  > p {
    font-size: 14px;
    color: #9FA7BA;
  }

  div.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    > span {
      font-size: 12px;
      font-weight: 700;
      /* color: #5CB85C; */
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px 17px 5px;
`;

export const PaginationButton = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationItem = styled.div``;
