import styled, { css } from 'styled-components';

import { shade } from 'polished';

export const InputContainer = styled.div.attrs({
  className: "dropzone"
})`
  display: flex;
  align-items: center;

  background: #fff;
  /* border: 1px solid #CCCCCC; */
  box-sizing: border-box;
  border-radius: 4px;
  color: #373A3C;

  padding: 7px 10px;
  margin: 0;
  transition: 0.2s;

  cursor: pointer;

  /* ${props => props.disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
  `} */

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  /* input.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  } */

  > img {
    margin-right: 10px;
  }

  &:hover {
    background: ${shade(0.1, '#fff')}
  }

  ${props => props.isBlue && css`
    background: #10104F;
    color: #fff;

    &:hover {
      background: ${shade(0.1, '#10104F')}
    }
  `}
`;

export const InputPagamentoContainer = styled.div.attrs({
  className: "dropzone"
})`
  display: flex;
  align-items: center;

  background: #10104F;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;

  padding: 7px 10px;
  margin: 0;
  transition: 0.2s;

  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  > img {
    margin-right: 10px;
  }

  &:hover {
    background: ${shade(0.1, '#10104F')}
  }

  ${props => props.isWhite && css`
    background: #fff;
    color: #373A3C;

    &:hover {
      background: ${shade(0.1, '#fff')}
    }
  `}
`;

