import styled, {css} from 'styled-components';

import {shade} from 'polished';

export const Container = styled.div`
  position: fixed;
  width: 220px;
  height: 100vh;
  background: #10104f;
  z-index: 1000;

  padding: 27px 17px;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Logo = styled.div`
  padding-bottom: 27px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  img {
    width: 155px;
  }
`;

export const Nav = styled.div`
  margin-top: 30px;
  margin-right: 17px;
  position: absolute;
  overflow-y: scroll;

  top: 116px;
  bottom: 0;

  overflow: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #10104f;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  a {
    text-decoration: none;

    &.disabled {
      display: none;
    }
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  /* z-index: 1000; */
  /* overflow: hidden; */

  padding: 7px 15px;
  margin-bottom: 26px;

  border-radius: 4px;

  background: #10104f;

  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#10104F')};
  }

  &.active {
    background: #0073fc;
  }

  img {
    width: 20px;
  }

  span {
    font-size: 15px;
    color: #fff;
    margin-left: 12px;
  }
`;

export const MenuItemPagamento = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  padding: 7px 15px;
  margin-bottom: 26px;

  border-radius: 4px;

  background: #10104f;

  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#10104F')};
  }

  &.active {
    background: #0073fc;
  }

  img {
    width: 20px;
  }

  span {
    font-size: 16px;
    color: #fff;
    margin-left: 12px;
  }

  .notify {
    position: absolute;
    top: -6px;
    left: 22px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #0073fc;
    border: 2px solid #fff;

    min-width: 18px;
    height: 18px;
    border-radius: 100px 100px 100px 100px;
    padding: 0 3px;

    &.hide {
      display: none;
    }

    > span {
      margin: 0;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
    }
  }
`;

export const SubMenuItem = styled.div`
  display: flex;
  align-items: center;

  padding: 7px 15px 7px 0px;
  margin-bottom: 16px;

  /* border-radius: 4px; */

  ${(props) =>
    props.sideMenu === true
      ? css`
          background: #0073fc;
        `
      : css`
          background: none;
        `}

  transition: 0.2s;

  &:hover {
    ${(props) =>
      props.sideMenu === true
        ? css`
            background: #10104f;
          `
        : css`
            background: #10104f;
          `}
  }

  &.active {
    background: red;
  }

  img {
    width: 20px;
  }

  span {
    font-size: 16px;
    color: #fff;
    margin-left: 12px;
    padding: 8px;
    border-radius: 5px;

    ${(props) =>
      props.active === true &&
      css`
        width: 200px;

        background: #10104f;
      `}
  }
`;

export const TitleSubMenu = styled.div`
  font-size: 16px;
  color: #fff;
  margin-left: 12px;
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: -10px;
`;

export const SideMenu = styled.div`
  ${(props) =>
    props.active
      ? css`
          display: block !important;
        `
      : css`
          display: none !important;
        `}
  position: absolute;

  background: #0073fc;
  width: 195px;
  height: 100vh;
  z-index: 1100;

  right: -195px;
  top: 0;
`;
