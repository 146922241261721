import React, { createContext, useState, useContext } from 'react';

const RevendedorInfosContext = createContext();

function RevendedorInfosProvider({ children }) {
  const [revendedorInfos, setRevendedorInfos] = useState([]);
  const [revendedorInfosUser, setRevendedorInfosUser] = useState([]);
  const [revendedorInfosEnderecoPessoa, setRevendedorInfosEnderecoPessoa] = useState([]);
  const [revendedorInfosEnderecoEstabelecimento, setRevendedorInfosEnderecoEstabelecimento] = useState([]);
  const [revendedorInfosDadosBancarios, setRevendedorInfosDadosBancarios] = useState([]);
  const [revendedorInfosTelefoneEstabelecimento, setRevendedorInfosTelefoneEstabelecimento] = useState([]);

  return (
    <RevendedorInfosContext.Provider
      value={{
        revendedorInfos,
        setRevendedorInfos,
        revendedorInfosUser,
        setRevendedorInfosUser,
        revendedorInfosEnderecoPessoa,
        setRevendedorInfosEnderecoPessoa,
        revendedorInfosEnderecoEstabelecimento,
        setRevendedorInfosEnderecoEstabelecimento,
        revendedorInfosDadosBancarios,
        setRevendedorInfosDadosBancarios,
        revendedorInfosTelefoneEstabelecimento,
        setRevendedorInfosTelefoneEstabelecimento,
      }}
    >
      {children}
    </RevendedorInfosContext.Provider>
  );
};

function useRevendedorInfos() {
  const context = useContext(RevendedorInfosContext);
  const {
    revendedorInfos, setRevendedorInfos,
    revendedorInfosUser, setRevendedorInfosUser,
    revendedorInfosEnderecoPessoa, setRevendedorInfosEnderecoPessoa,
    revendedorInfosEnderecoEstabelecimento, setRevendedorInfosEnderecoEstabelecimento,
    revendedorInfosDadosBancarios, setRevendedorInfosDadosBancarios,
    revendedorInfosTelefoneEstabelecimento, setRevendedorInfosTelefoneEstabelecimento,
  } = context;
  return {
    revendedorInfos, setRevendedorInfos,
    revendedorInfosUser, setRevendedorInfosUser,
    revendedorInfosEnderecoPessoa, setRevendedorInfosEnderecoPessoa,
    revendedorInfosEnderecoEstabelecimento, setRevendedorInfosEnderecoEstabelecimento,
    revendedorInfosDadosBancarios, setRevendedorInfosDadosBancarios,
    revendedorInfosTelefoneEstabelecimento, setRevendedorInfosTelefoneEstabelecimento,
  };
};

export { RevendedorInfosProvider, useRevendedorInfos };
