import React, {useState, useCallback, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {Tooltip} from '@material-ui/core/';
import {MdErrorOutline, MdHighlightOff} from 'react-icons/md';
import LoadingIcons from 'react-loading-icons';
import filesize from 'filesize';
import {uniqueId} from 'lodash';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import {ModalChangeStatus} from '../../components/ModalChangeStatus';

// Comprovante
import UploadComprovante from '../../components/Upload/comprovante';
import InfoComprovante from '../../components/UploadInfo/comprovante';

// Context API
import {useToast} from '../../hooks/ToastContext';
import {sendMessage} from '../../utils/sendMessage';

// JSONs
import BankList from '../../assets/json/bancos.json';
import EstadosList from '../../assets/json/estados.json';
import CnaeList from '../../assets/json/cnae.json';

// Máscaras
// import contaBancariaMask from '../../components/InputMasks/contaBancariaMask';
import cpfMask from '../../components/InputMasks/cpfMask';
// import rgMask from '../../components/InputMasks/rgMask';
// import cepMask from '../../components/InputMasks/cepMask';
import cnpjMask from '../../components/InputMasks/cnpjMask';
import telefoneMask from '../../components/InputMasks/telefoneMask';

import {
  Container,
  Page,
  Content,
  TabContent,
  NavegaçãoAbas,
  BotãoAba,
  Abas,
  Aba1,
  Aba2,
  Aba3,
  Aba4,
  Row,
  Row3,
  Row4,
  InputArea,
  Separator,
  SelectStatus,
  SelectInputContainer,
  ModalSection,
  ModalContent,
  Aprovado,
  Mensagem,
  InputContainer,
  Error,
  ButtonRow,
  Confirmar,
  BoxInfo,
  SelectLa,
  Box,
  SelectCodigoRastreio,
  UploadContainer,
} from './styles';

function AdminEditarEstabelecimentos() {
  const location = useLocation();
  const idEC = location.pathname
    .replace('/admin/estabelecimentos/editar/', '')
    .split('')
    .reverse()
    .join('');

  const [isLoading, setIsLoading] = useState(false);
  const [updateIsLoading, setUpdateIsLoading] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  /**
   * O credenciamento deste EC já gerou comissão?
   * Se sim (true), inabiliza o input do valor da comissão
   */
  const [ecGerouComissao, setEcGerouComissao] = useState(false);

  const history = useHistory();

  const {addToast} = useToast();
  // const { ecInfos, ecInfosUsuario, ecInfosEnderecoPessoa, ecInfosEnderecoEstabelecimento, ecInfosDadosBancarios, ecInfosTelefoneEstabelecimento, ecInfosLA } = useEcInfos();
  const [ecInfos, setEcInfos] = useState([]);
  const [ecInfosUsuario, setEcInfosUsuario] = useState([]);
  const [ecInfosEnderecoPessoa, setEcInfosEnderecoPessoa] = useState(
    []
  );
  const [
    ecInfosEnderecoEstabelecimento,
    setEcInfosEnderecoEstabelecimento,
  ] = useState([]);
  const [ecInfosDadosBancarios, setEcInfosDadosBancarios] = useState(
    []
  );
  const [
    ecInfosTelefoneEstabelecimento,
    setEcInfosTelefoneEstabelecimento,
  ] = useState([]);
  const [ecInfosLA, setEcInfosLA] = useState([]);
  const [ecInfosRV, setEcInfosRV] = useState([]);

  let telefonePessoal = '';
  let idTelefonePessoal = '';
  const telefonePessoalArray = ecInfosTelefoneEstabelecimento.filter(
    (item) => item.descricao === 'Pessoal'
  );
  telefonePessoalArray.forEach((item) => {
    telefonePessoal = item.telefone;
    idTelefonePessoal = item.id;
  });

  let telefoneEstabelecimento = '';
  let idTelefoneEstabelecimento = '';
  const telefoneEstabelecimentoArray =
    ecInfosTelefoneEstabelecimento.filter(
      (item) => item.descricao === 'Estabelecimento'
    );
  telefoneEstabelecimentoArray.forEach((item) => {
    telefoneEstabelecimento = item.telefone;
    idTelefoneEstabelecimento = item.id;
  });

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    errors,
  } = useForm();
  // submit dos dados do EC
  const onSubmit = async (data) => {
    // Bearer token
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    // Valida se todos os dados bancários estão vazios
    if (
      data.tipo_chave_pix === '' &&
      data.chave_pix === '' &&
      data.agencia === '' &&
      data.conta_juridica === '' &&
      data.cpf_cnpj_conta === '' &&
      data.nome_banco === '' &&
      data.nome_titular === '' &&
      data.numero_banco === '' &&
      data.numero_conta === '' &&
      data.tipo_conta === ''
    ) {
      addToast({
        type: 'error',
        title: 'Erro no envio do formulário',
        description:
          'Você precisa informar algum dado bancário, seja os campos da Chave Pix ou os demais dados bancários para transferência bancária.',
      });

      return;
    }

    const tipo_chave_pix = data.tipo_chave_pix;
    const chave_pix = data.chave_pix;

    // Faz a validação da obrigatoriedade da Chave Pix
    if (tipo_chave_pix !== '' && chave_pix === '') {
      setError('chave_pix', {
        type: 'manual',
        message: 'Você deve incluir uma Chava Pix',
      });

      return;
    }

    // Faz a validação da obrigatoriedade da Chave Pix
    if (tipo_chave_pix === '' && chave_pix !== '') {
      setError('tipo_chave_pix', {
        type: 'manual',
        message: 'Você deve incluir um Tipo de Chava Pix',
      });

      return;
    }

    // Valida se, caso algum dado bancário seja preenchido, os outros também devem ser
    if (
      (data.agencia !== '' ||
        data.conta_juridica !== '' ||
        data.cpf_cnpj_conta !== '' ||
        data.nome_banco !== '' ||
        data.nome_titular !== '' ||
        // data.numero_banco !== "" ||
        data.numero_conta !== '' ||
        data.tipo_conta !== '') &&
      (data.agencia === '' ||
        data.conta_juridica === '' ||
        data.cpf_cnpj_conta === '' ||
        data.nome_banco === '' ||
        data.nome_titular === '' ||
        // data.numero_banco === "" ||
        data.numero_conta === '' ||
        data.tipo_conta === '')
    ) {
      addToast({
        type: 'error',
        title: 'Erro no envio do formulário',
        description:
          'Se você preencheu algum dado bancário (exceto as Chaves Pix) você precisará preencher todo o formulário.',
      });

      return;
    }

    // if (ecInfos.licenciado_autorizado_id === "5e0db4d3-d3f7-4c67-a5e8-4c632e579230")

    // ENVIA OS DADOS PARA API
    try {
      setIsLoading(true);

      // user
      await api.put(
        `/user/${ecInfos.user_id}`,
        {
          name: data.name,
          email: data.email,
          role: 'ROLE_EC',
        },
        auth
      );

      // estabelecimento
      await api.put(
        `/estabelecimento/${ecInfos.id}`,
        {
          cargo: data.cargo,
          estado_civil: data.estado_civil,
          rg: data.rg,
          cpf: data.cpf,
          data_nascimento: data.data_nascimento,
          razao_social: data.razao_social,
          tipo_de_empresa: data.tipo_empresa,
          cnpj: data.cnpj,
          serial_number: data.serial_number,
          sessao_cnae: data.sessao_cnae,
          cnae: data.cnae,
          nome_fantasia: data.nome_fantasia,
        },
        auth
      );

      // endereço pessoa
      await api.put(
        `/endereco/${ecInfos.endereco_pessoa_id}`,
        {
          rua: data.pessoal_rua,
          numero: data.pessoal_numero,
          complemento: data.pessoal_complemento,
          bairro: data.pessoal_bairro,
          cidade: data.pessoal_cidade,
          cep: data.pessoal_cep,
          uf: data.pessoal_uf,
        },
        auth
      );

      // endereço estabelecimento
      await api.put(
        `/endereco/${ecInfos.endereco_estabelecimento_id}`,
        {
          rua: data.estabelecimento_rua,
          numero: data.estabelecimento_numero,
          complemento: data.estabelecimento_complemento,
          bairro: data.estabelecimento_bairro,
          cidade: data.estabelecimento_cidade,
          cep: data.estabelecimento_cep,
          uf: data.estabelecimento_uf,
        },
        auth
      );

      // dados bancários
      await api.put(
        `/conta/${ecInfos.dados_bancarios_id}`,
        {
          tipo_chave_pix:
            data.tipo_chave_pix === '' ? null : data.tipo_chave_pix,
          chave_pix: data.chave_pix === '' ? null : data.chave_pix,
          //
          agencia: data.agencia === '' ? null : data.agencia,
          conta_juridica:
            data.conta_juridica === '' ? null : data.conta_juridica,
          cpf_cnpj:
            data.cpf_cnpj_conta === '' ? null : data.cpf_cnpj_conta,
          nome_banco: data.nome_banco === '' ? null : data.nome_banco,
          nome_titular:
            data.nome_titular === '' ? null : data.nome_titular,
          numero_banco:
            data.numero_banco === '' ? null : data.numero_banco,
          numero_conta:
            data.numero_conta === '' ? null : data.numero_conta,
          tipo_conta: data.tipo_conta === '' ? null : data.tipo_conta,
          operacao: data.operacao === '' ? null : data.operacao,
        },
        auth
      );

      // telefone pessoal
      await api.put(
        `/telefone/estabelecimento/${idTelefonePessoal}`,
        {
          estabelecimento_id: ecInfos.id,
          telefone: data.pessoal_telefone,
          descricao: 'Pessoal',
        },
        auth
      );

      // telefone estabelecimento
      await api.put(
        `/telefone/estabelecimento/${idTelefoneEstabelecimento}`,
        {
          estabelecimento_id: ecInfos.id,
          telefone: data.estabelecimento_telefone,
          descricao: 'Estabelecimento',
        },
        auth
      );

      addToast({
        type: 'success',
        title: 'Deu tudo certo',
        description: 'Dados atualizados com sucesso.',
      });

      setIsLoading(false);

      history.push('/admin/estabelecimentos');
    } catch (error) {
      setIsLoading(false);

      addToast({
        type: 'error',
        title: 'Erro na atualização dos dados',
        description:
          'Encontramos um erro ao atualizar os dados do Estabelecimento. Tente novamente ou entre em contato com o suporte.',
      });
    }

    // window.location.href = '/admin/estabelecimentos';
  };
  const onError = (errors) => {
    // console.log(errors)
    addToast({
      type: 'error',
      title: 'Erro na atualização',
      description:
        'Algum campo obrigatório pode estar vazio, confira os dados preenchidos e tente novamente.',
    });
  };

  // submit dos status
  async function submitStatus(value) {
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    // Verifica se o LA é algum dos LAs default e se o campo de LA responsável foi prenchido
    if (
      (ecInfos.licenciado_autorizado_id ===
        '5e0db4d3-d3f7-4c67-a5e8-4c632e579230' ||
        ecInfos.licenciado_autorizado_id ===
          'b3345f5c-2882-444b-8b2a-33765a898317' ||
        ecInfos.licenciado_autorizado_id ===
          'c34c363a-cfad-466c-8f26-b05ea9b1aefa') &&
      (getValues('la_responsavel') === '' ||
        getValues('la_responsavel') ===
          '5e0db4d3-d3f7-4c67-a5e8-4c632e579230')
    ) {
      addToast({
        type: 'info',
        title: 'Selecione um Licenciado',
        description:
          'Você não pode aprovar o estabelecimento enquanto não o associar a um licenciado.',
      });

      setModalOpened(!modalOpened);

      setError('la_responsavel', {
        type: 'manual',
        message: 'Você precisa selecionar um Licenciado.',
      });

      return;
    }

    // Atualiza o LA e Revenda do EC caso todos os campos estejam preenchidos corretamente
    if (
      (ecInfos.licenciado_autorizado_id ===
        '5e0db4d3-d3f7-4c67-a5e8-4c632e579230' ||
        ecInfos.licenciado_autorizado_id ===
          'b3345f5c-2882-444b-8b2a-33765a898317' ||
        ecInfos.licenciado_autorizado_id ===
          'c34c363a-cfad-466c-8f26-b05ea9b1aefa') &&
      (getValues('la_responsavel') !== '' ||
        getValues('la_responsavel') !==
          '5e0db4d3-d3f7-4c67-a5e8-4c632e579230')
    ) {
      // estabelecimento
      await api.put(
        `/estabelecimento/update/affiliation/${ecInfos.id}`,
        {
          licenciado_autorizado_id: getValues('la_responsavel'),
          revendedor_id:
            getValues('rv_responsavel') === ''
              ? null
              : getValues('rv_responsavel'),
        },
        auth
      );

      // console.log('responsePutLaId', responsePutLaId);
    }

    const dataStatus = getValues('status');
    // const dataMessage = getValues('message');
    const dataMessage = value.message;

    // const responsavelPeloEC = ecInfos.revendedor_id ? ecInfos.revendedor_id : ecInfos.licenciado_autorizado_id;
    // const responsavelPeloECType = ecInfos.revendedor_id ? 'ROLE_RV' : 'ROLE_LA';

    // altera o status do estabelecimento
    try {
      // Só atualiza a nota_fiscal se for o status aprovado
      if (dataStatus === '0') {
        console.log('value', value);
        return;
      }
      /* if (dataStatus === '0' && value.nota_fiscal_id !== null) {
        // Edita o EC para colocar a nota_fiscal_id
        await api.put(`/estabelecimento/${ecInfos.id}`, {
          nota_fiscal_licenca_id: value.nota_fiscal_id,
        }, auth).then(response => {
          console.log('responseAtualizaNotaFiscalNoEC', response);
        });
      } */

      // Atualiza o status
      await api.post(
        `/estabelecimento_update_status`,
        {
          status: dataStatus,
          id: ecInfos.id,
        },
        auth
      );
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro na atualização do status',
        description:
          'Encontramos um erro ao atualizar o status. Tente novamente ou entre em contato com o suporte.',
      });
    }

    // envia a mensagem caso o status seja diferente de 'aprovado' ou 'em análise'
    if (dataStatus !== '0' && dataStatus !== '1') {
      try {
        // Mensagem
        const messageSender = await sendMessage({
          recipient_id:
            ecInfos.revendedor_id === null
              ? ecInfosLA.user_id
              : ecInfosRV.user_id,
          subject: 'Atualização de status',
          message: dataMessage,
          transaction_type: 'Estabelecimento',
          transaction_id: ecInfos.id,
        });

        // Exige mensagem de erro no envio da mensagem
        if (messageSender.status === false) {
          addToast({
            type: 'error',
            title: 'Erro ao enviar mensagem',
            description:
              'A transação foi editada mas não conseguimos enviar sua mensagem.',
          });
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao enviar mensagem',
          description:
            'Encontramos um erro ao enviar sua mensagem. Tente novamente ou entre em contato com o suporte.',
        });
      }
    }

    addToast({
      type: 'success',
      title: 'Deu tudo certo',
      description: 'Status atualizado com sucesso.',
    });

    // history.push('/admin/estabelecimentos');
    window.location.reload(true);
  }

  // Carrega as informações para alimentar a página
  useEffect(() => {
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    async function requestEC() {
      const responseEC = await api.get(
        `/estabelecimento/${idEC}`,
        auth
      );
      const result = responseEC.data.data;
      console.log('resultEC', result);

      if (
        result.licenciado_autorizado_id ===
          '5e0db4d3-d3f7-4c67-a5e8-4c632e579230' ||
        result.licenciado_autorizado_id ===
          'b3345f5c-2882-444b-8b2a-33765a898317' ||
        result.licenciado_autorizado_id ===
          'c34c363a-cfad-466c-8f26-b05ea9b1aefa'
      ) {
        setLAHide(false);
      }

      setEcInfos(result);

      // salvar dados do usuário
      api.get(`/user/${result.user_id}`, auth).then((response) => {
        const userEstabelecimento = response.data.data;
        setEcInfosUsuario(userEstabelecimento);
      });

      // Salvar dados do endereço pessoal
      api
        .get(`/endereco/${result.endereco_pessoa_id}`, auth)
        .then((response) => {
          const enderecoPessoa = response.data.data;
          setEcInfosEnderecoPessoa(enderecoPessoa);
        });

      // Salvar dados do endereço do estabelecimento
      api
        .get(`/endereco/${result.endereco_estabelecimento_id}`, auth)
        .then((response) => {
          const enderecoEstabelecimento = response.data.data;
          setEcInfosEnderecoEstabelecimento(enderecoEstabelecimento);
        });

      // Salvar dados bancários
      api
        .get(`/conta/${result.dados_bancarios_id}`, auth)
        .then((response) => {
          const dadosBancarios = response.data.data;
          setEcInfosDadosBancarios(dadosBancarios);
        });

      // Salvar dados do telefone
      api
        .get('/telefone/estabelecimento?png=999999', auth)
        .then((response) => {
          const result = response.data.data;

          const dadosTelefones = result.filter(
            (dado) => dado.estabelecimento_id === idEC
          );
          setEcInfosTelefoneEstabelecimento(dadosTelefones);
        });

      // Salvar user_id do LA
      api
        .get(`/licenciado/${result.licenciado_autorizado_id}`, auth)
        .then((response) => {
          const dadosLA = response.data.data;
          setEcInfosLA(dadosLA);
        });

      if (result.revendedor_id !== null) {
        // Salvar user_id do RV
        api
          .get(`/revendedor/${result.revendedor_id}`, auth)
          .then((response) => {
            const dadosRV = response.data.data;
            setEcInfosRV(dadosRV);
          });

        // Verifica se o EC já gerou comissão por credenciamento
        await api
          .get(`/comissao?png=999999`, auth)
          .then((response) => {
            const comissoesResult = response.data.data;
            const comissaosFiltradas = comissoesResult.filter(
              (item) =>
                item.comissionavel_id === result.revendedor_id &&
                item.transacao_id === result.id
            );
            comissaosFiltradas.length > 0 && setEcGerouComissao(true);
            // setEcInfosEnderecoPessoa(enderecoPessoa)
          });
      } else {
        // Verifica se o EC já gerou comissão por credenciamento
        await api
          .get(`/comissao?png=999999`, auth)
          .then((response) => {
            const comissoesResult = response.data.data;
            const comissaosFiltradas = comissoesResult.filter(
              (item) =>
                item.comissionavel_id ===
                  result.licenciado_autorizado_id &&
                item.transacao_id === result.id
            );
            comissaosFiltradas.length > 0 && setEcGerouComissao(true);
            // setEcInfosEnderecoPessoa(enderecoPessoa)
          });
      }

      /**
       * STATUS
       */
      setValue('status', result.status);
      setStatusColor(result.status.toString());
    }

    requestEC();
  }, [idEC, userToken, setValue]);

  const [colaboradores, setColaboradores] = useState([]);
  const [users, setUsers] = useState([]);

  // Pega os dados dos colaboradores deste ec
  useEffect(() => {
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    async function getColaborador() {
      await api.get(`/user?png=999999`, auth).then((response) => {
        const result = response.data.data;

        setUsers(result);
      });

      await api.get(`/colaborador`, auth).then((response) => {
        const result = response.data.data;

        const colaboradoresFiltrados = result.filter(
          (item) => item.estabelecimento_id === idEC
        );
        setColaboradores(colaboradoresFiltrados);
      });
    }

    getColaborador();
  }, [idEC, userToken]);

  // Abas START
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [tab4, setTab4] = useState(false);

  const open1 = () => {
    setTab1(true);
    setTab2(false);
    setTab3(false);
    setTab4(false);
  };
  const open2 = () => {
    setTab1(false);
    setTab2(true);
    setTab3(false);
    setTab4(false);
  };
  const open3 = () => {
    setTab1(false);
    setTab2(false);
    setTab3(true);
    setTab4(false);
  };
  const open4 = () => {
    setTab1(false);
    setTab2(false);
    setTab3(false);
    setTab4(true);
  };
  // Abas END

  // Máscara do número da conta
  const inputAccountMask = useCallback((e) => {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{1})$/, '$1-$2');
    e.currentTarget.value = value;
  }, []);

  // Máscara do CPF
  const inputCPFMask = useCallback((e) => {
    e.currentTarget.maxLength = 14;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{2})$/, '$1-$2');
    e.currentTarget.value = value;
  }, []);

  // Máscara do RG
  const inputRGMask = useCallback((e) => {
    let value = e.currentTarget.value;
    value = value.replace(/[a-z]/, '');
    value = value.replace(/[A-Z]/, '');
    e.currentTarget.value = value;
  }, []);

  // Máscara do Cep
  const inputCepMask = useCallback((e) => {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{5})(\d)/, '$1-$2');
    e.currentTarget.value = value;
  }, []);

  // Máscara do CNPJ
  const inputCNPJMask = useCallback((e) => {
    e.currentTarget.maxLength = 18;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1/$2');
    value = value.replace(/(\d{4})(\d{2})$/, '$1-$2');
    e.currentTarget.value = value;
  }, []);

  // Máscara do Telefone
  const inputTelMask = useCallback((e) => {
    e.currentTarget.maxLength = 14;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{2})(\d)/, '($1)$2');
    value = value.replace(/(\d{5})(\d)/, '$1-$2');
    e.currentTarget.value = value;
  }, []);

  // Máscara do Input CPF/CNPJ
  const inputMask = useCallback(
    (e) => {
      let value = e.currentTarget.value;
      if (getValues('conta_juridica') === '') {
        e.currentTarget.maxLength = 0;
      }
      if (getValues('conta_juridica') === '0') {
        e.currentTarget.maxLength = 14;
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d{2})$/, '$1-$2');
        e.currentTarget.value = value;
      }
      if (getValues('conta_juridica') === '1') {
        e.currentTarget.maxLength = 18;
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{2})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d)/, '$1/$2');
        value = value.replace(/(\d{4})(\d{2})$/, '$1-$2');
        e.currentTarget.value = value;
      }
      return e;
    },
    [getValues]
  );

  // Valida se a data é maior que a atual
  const validDate = useCallback(() => {
    const date = getValues('data_nascimento');
    const dateTimestamp = new Date(date).getTime();

    const today = Date.now();

    if (dateTimestamp > today) {
      setError('data_nascimento', {
        type: 'manual',
        message: 'A data não pode ser maior que o dia atual.',
      });
    }
  }, [getValues, setError]);

  const [placeholder, serPlaceholder] = useState('');

  const changeContaJuridica = useCallback(() => {
    const inputValue = getValues('conta_juridica');

    if (inputValue === '0') {
      serPlaceholder('Digite o CPF');
    }
    if (inputValue === '1') {
      serPlaceholder('Digite o CNPJ');
    }
  }, [getValues]);

  // Cargos
  const cargoOptions = [
    {value: 'Proprietário', label: 'Proprietário'},
    {value: 'Diretor', label: 'Diretor'},
    {value: 'Gerente', label: 'Gerente'},
    {value: 'Coordenador', label: 'Coordenador'},
    {value: 'Supervisor', label: 'Supervisor'},
    {value: 'Vendedor', label: 'Vendedor'},
  ];

  // Lista de bancos
  const listaBancos = BankList;

  // Lista de estados
  const listaEstados = EstadosList;

  // Sessões CNAE
  const sessãoCnaeOptions = [
    {
      value: 'A',
      label:
        'Agricultura, Pecuária, Produção Florestal, Pesca e Aqüicultura',
    },
    {value: 'B', label: 'Indústrias Extrativas'},
    {value: 'C', label: 'Indústrias de Transformação'},
    {value: 'D', label: 'Eletricidade e Gás'},
    {
      value: 'E',
      label:
        'Água, Esgoto, Atividades de Gestão de Resíduos e Descontaminação',
    },
    {value: 'F', label: 'Construção'},
    {
      value: 'G',
      label:
        'Comércio; Reparação de Veículos Automotores e Motocicletas',
    },
    {value: 'H', label: 'Transporte, Armazenagem e Correio'},
    {value: 'I', label: 'Alojamento e Alimentação'},
    {value: 'J', label: 'Informação e Comunicação'},
    {
      value: 'K',
      label:
        'Atividades Financeiras, de Seguros e Serviços Relacionados',
    },
    {value: 'L', label: 'Atividades Imobiliárias'},
    {
      value: 'M',
      label: 'Atividades Profissionais, Científicas e Técnicas',
    },
    {
      value: 'N',
      label: 'Atividades Administrativas e Serviços Complementares',
    },
    {
      value: 'O',
      label: 'Administração Pública, Defesa e Seguridade Social',
    },
    {value: 'P', label: 'Educação'},
    {value: 'Q', label: 'Saúde Humana e Serviços Sociais'},
    {value: 'R', label: 'Artes, Cultura, Esporte e Recreação'},
    {value: 'S', label: 'Outras Atividades de Serviços'},
    {value: 'T', label: 'Serviços Domésticos'},
    {
      value: 'U',
      label:
        'Organismos Internacionais e Outras Instituições Extraterritoriais',
    },
  ];

  // Filtrar Atividades CNAE por sessões START
  const [cnaeSubclasses, setCnaeSubclasses] = useState([]);

  /* const getCnaeActivities = useCallback(() => {
    const sessaoValue = getValues("sessao_cnae");

    fetch(`https://servicodados.ibge.gov.br/api/v2/cnae/secoes/${sessaoValue}/subclasses`)
    .then((res) => res.json())
    .then((data) => {
      const descriptions = data.map(description => {
        return {
          value: description.id.replace(/^(\d{4})(\d)(\d{2})$/, "$1-$2/$3"),
          label: description.descricao.toLowerCase()
        }
      })

      const descriptionsFilter = descriptions.filter(function (a) {
        return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
      }, Object.create(null)) 

      // console.log(descriptionsFilter)
      setCnaeSubclasses(descriptionsFilter)
    });
  }, [getValues]); */

  const listaCNAE = CnaeList;

  const setCNAE = useCallback(() => {
    const sessaoValue = getValues('sessao_cnae');

    const atividadesCNAE = listaCNAE.filter(
      (item) => item.secao === sessaoValue
    );

    setCnaeSubclasses(atividadesCNAE);
  }, [getValues, listaCNAE]);
  // Filtrar Atividades CNAE por sessões END

  // CEP START
  const onBlurCepPes = useCallback(
    async (e) => {
      const typedValue = e.target.value;

      const cep = typedValue?.replace(/[^0-9]/g, '');
      if (cep?.length !== 8) {
        return;
      }

      await fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json())
        .then((data) => {
          setValue('pessoal_rua', data.logradouro);
          setValue('pessoal_bairro', data.bairro);
          setValue('pessoal_uf', data.uf);
          setValue('pessoal_cidade', data.localidade);
        });
    },
    [setValue]
  );

  const onBlurCepEst = useCallback(
    async (e) => {
      const typedValue = e.target.value;

      const cep = typedValue?.replace(/[^0-9]/g, '');
      if (cep?.length !== 8) {
        return;
      }

      await fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json())
        .then((data) => {
          setValue('estabelecimento_rua', data.logradouro);
          setValue('estabelecimento_bairro', data.bairro);
          setValue('estabelecimento_uf', data.uf);
          setValue('estabelecimento_cidade', data.localidade);
        });
    },
    [setValue]
  );
  // CEP END

  // Abrir e fechar modal
  const [modalOpened, setModalOpened] = useState(false);

  // State para mudança de cor do select
  const [statusColor, setStatusColor] = useState('');

  // State para definir regra de obrigatoriedade
  // const [requiredUpload, setRequiredUpload] = useState(false);
  const [requiredMessage, setRequiredMessage] = useState(false);

  // State para mostrar campo de upload de arquivos ou mensagem
  const [aprovadoHide, setAprovadoHide] = useState(true);
  const [messageHide, setMessageHide] = useState(true);
  const [confirmarHide, setConfirmarHide] = useState(false);
  const [modalMessage, setModalMessage] = useState('confirmar');
  const [modalTitle, setModalTitle] = useState(
    'Tem certeza que deseja alterar o status desse estabelecimento?'
  );

  const changeStatus = useCallback(() => {
    const selectValue = getValues('status');

    setStatusColor(selectValue);

    // Aprovado
    if (selectValue === '0') {
      setRequiredMessage(false);
      setAprovadoHide(false);
      setMessageHide(true);
      setConfirmarHide(true);
      // Nova forma
      setModalMessage('aprovado');
      setRequiredMessage(false);
      setModalTitle(
        'Tem certeza que deseja aprovar esse estabelecimento?'
      );
    }

    // Em análise
    if (selectValue === '1') {
      setRequiredMessage(true);
      setMessageHide(false);
      setAprovadoHide(true);
      setConfirmarHide(true);
      // Nova forma
      setModalMessage('confirmar');
      setRequiredMessage(false);
      setModalTitle(
        'Tem certeza que deseja alterar o status desse estabelecimento?'
      );
    }

    // Documentos pendentes || Documentos checados || Reprovado
    if (
      selectValue === '2' ||
      selectValue === '3' ||
      selectValue === '4'
    ) {
      setRequiredMessage(true);
      setMessageHide(false);
      setAprovadoHide(true);
      setConfirmarHide(true);
      // Nova forma
      setModalMessage('mensagem');
      setRequiredMessage(true);
      setModalTitle('Digite uma mensagem para o Licenciado');
    }
  }, [getValues]);

  const openModal = useCallback(() => {
    const selectValue = getValues('status');

    if (selectValue !== '1') {
      setModalOpened(true);
    }

    // setAprovadoHide(false);
    setModalOpened(true);
  }, [getValues]);

  // pre-setar os dados
  useEffect(() => {
    //
    setValue('name', ecInfosUsuario.name);
    setValue('email', ecInfosUsuario.email);
    setValue('cargo', ecInfos.cargo);
    setValue('estado_civil', ecInfos.estado_civil);
    setValue('rg', ecInfos.rg);
    setValue('cpf', ecInfos.cpf);
    setValue('data_nascimento', ecInfos.data_nascimento);
    setValue('razao_social', ecInfos.razao_social);
    setValue('tipo_empresa', ecInfos.tipo_de_empresa);
    setValue('cnpj', ecInfos.cnpj);
    setValue('nome_fantasia', ecInfos.nome_fantasia);
    setValue('serial_number', ecInfos.serial_number);
    setValue('sessao_cnae', ecInfos.sessao_cnae);
    // getCnaeActivities();
    setCNAE();
    setValue('pessoal_rua', ecInfosEnderecoPessoa.rua);
    setValue('pessoal_numero', ecInfosEnderecoPessoa.numero);
    setValue(
      'pessoal_complemento',
      ecInfosEnderecoPessoa.complemento
    );
    setValue('pessoal_bairro', ecInfosEnderecoPessoa.bairro);
    setValue('pessoal_cidade', ecInfosEnderecoPessoa.cidade);
    setValue('pessoal_cep', ecInfosEnderecoPessoa.cep);
    setValue('pessoal_uf', ecInfosEnderecoPessoa.uf);
    setValue('pessoal_telefone', telefonePessoal);
    //
    setValue(
      'estabelecimento_rua',
      ecInfosEnderecoEstabelecimento.rua
    );
    setValue(
      'estabelecimento_numero',
      ecInfosEnderecoEstabelecimento.numero
    );
    setValue(
      'estabelecimento_complemento',
      ecInfosEnderecoEstabelecimento.complemento
    );
    setValue(
      'estabelecimento_bairro',
      ecInfosEnderecoEstabelecimento.bairro
    );
    setValue(
      'estabelecimento_cidade',
      ecInfosEnderecoEstabelecimento.cidade
    );
    setValue(
      'estabelecimento_cep',
      ecInfosEnderecoEstabelecimento.cep
    );
    setValue('estabelecimento_uf', ecInfosEnderecoEstabelecimento.uf);
    setValue('estabelecimento_telefone', telefoneEstabelecimento);
    //
    setValue('nome_banco', ecInfosDadosBancarios.nome_banco);
    setValue('agencia', ecInfosDadosBancarios.agencia);
    setValue('numero_conta', ecInfosDadosBancarios.numero_conta);
    setValue('nome_titular', ecInfosDadosBancarios.nome_titular);
    setValue('tipo_conta', ecInfosDadosBancarios.tipo_conta);
    setValue('conta_juridica', ecInfosDadosBancarios.conta_juridica);
    setValue('cpf_cnpj_conta', ecInfosDadosBancarios.cpf_cnpj);
    setValue('operacao', ecInfosDadosBancarios.operacao);
    setValue('numero_banco', ecInfosDadosBancarios.numero_banco);
    //
    setValue('tipo_chave_pix', ecInfosDadosBancarios.tipo_chave_pix);
    setValue('chave_pix', ecInfosDadosBancarios.chave_pix);

    if (ecInfos.rastreio_setup) {
      setValue('rastreio_setup', ecInfos.rastreio_setup);
    }
    //
    setValue('cnae', ecInfos.cnae);
  }, [
    ecInfos.status,
    ecInfosDadosBancarios.chave_pix,
    ecInfosDadosBancarios.tipo_chave_pix,
    ecInfos.cargo,
    ecInfos.cnpj,
    ecInfos.cpf,
    ecInfos.data_nascimento,
    ecInfos.estado_civil,
    ecInfos.nome_fantasia,
    ecInfos.razao_social,
    ecInfos.rg,
    ecInfosUsuario.email,
    ecInfosUsuario.name,
    ecInfosDadosBancarios.agencia,
    ecInfosDadosBancarios.conta_juridica,
    ecInfosDadosBancarios.nome_banco,
    ecInfosDadosBancarios.nome_titular,
    ecInfosDadosBancarios.numero_conta,
    ecInfosDadosBancarios.operacao,
    ecInfosDadosBancarios.tipo_conta,
    ecInfosEnderecoEstabelecimento.bairro,
    ecInfosEnderecoEstabelecimento.cep,
    ecInfosEnderecoEstabelecimento.cidade,
    ecInfosEnderecoEstabelecimento.complemento,
    ecInfosEnderecoEstabelecimento.numero,
    ecInfosEnderecoEstabelecimento.rua,
    ecInfosEnderecoEstabelecimento.uf,
    ecInfosEnderecoPessoa.bairro,
    ecInfosEnderecoPessoa.cep,
    ecInfosEnderecoPessoa.cidade,
    ecInfosEnderecoPessoa.complemento,
    ecInfosEnderecoPessoa.numero,
    ecInfosEnderecoPessoa.rua,
    ecInfosEnderecoPessoa.uf,
    ecInfos.cnae,
    ecInfos.serial_number,
    ecInfos.sessao_cnae,
    ecInfos.tipo_de_empresa,
    ecInfosDadosBancarios.cpf_cnpj,
    ecInfosDadosBancarios.numero_banco,
    telefoneEstabelecimento,
    telefonePessoal,
    setCNAE,
    setValue,
    ecInfos.rastreio_setup,
  ]);

  const setNumeroBanco = useCallback(() => {
    const nomeBanco = getValues('nome_banco');

    /* if (nomeBanco === "Banco do Brasil S.A.") {
      setValue('numero_banco', "001");
      return
    }
    if (nomeBanco === "Banco Bradesco S.A.") {
      setValue('numero_banco', "237");
      return
    }
    if (nomeBanco === "Caixa Econômica Federal") {
      setValue('numero_banco', "104");
      return
    }
    if (nomeBanco === "Banco Santander (Brasil) S. A.") {
      setValue('numero_banco', "033");
      return
    }
    if (nomeBanco === "Itaú Unibanco S.A.") {
      setValue('numero_banco', "341");
      return
    } */

    const numerobanco = listaBancos.filter(
      (item) => item.label === nomeBanco
    );
    setValue('numero_banco', numerobanco[0].value);
  }, [getValues, setValue, listaBancos]);

  function mascaraPix(e) {
    if (getValues('tipo_chave_pix') === 'CPF') {
      cpfMask(e);
    }
    if (getValues('tipo_chave_pix') === 'CNPJ') {
      cnpjMask(e);
    }
    if (getValues('tipo_chave_pix') === 'TELEFONE') {
      telefoneMask(e);
    }
    if (getValues('tipo_chave_pix') === 'EMAIL') {
      e.currentTarget.maxLength = 99;
    }
    if (getValues('tipo_chave_pix') === 'CHAVE') {
      e.currentTarget.maxLength = 99;
    }
  }

  const [LAHide, setLAHide] = useState(true);

  const [licenciados, setLicenciados] = useState([]);
  const [revendedores, setRevendedores] = useState([]);

  // Faz a requisição para pegar os LAs e Revendedores
  useEffect(() => {
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    async function requestLA() {
      const responseLA = await api.get(
        '/licenciado?png=999999',
        auth
      );
      const resultLA = responseLA.data.data;

      setLicenciados(resultLA);
    }

    async function requestRV() {
      const responseRV = await api.get(
        '/revendedor?png=999999',
        auth
      );
      const resultRV = responseRV.data.data;

      setRevendedores(resultRV);
    }

    requestLA();
    requestRV();
  }, [userToken]);

  function colaboradorName(user_id) {
    const user = users.filter((item) => item.id === user_id);

    return user[0].name;
  }

  // Upload do Comprovante -------------------------------------------- START
  const [uploadedComprovante, setUploadedComprovante] = useState([]);
  const [progressComprovante, setProgressComprovante] = useState(0);
  const [statusComprovante, setStatusComprovante] = useState({
    uploaded: false,
    error: false,
  });

  // Preparar arquivos para upload
  const submitFileComprovante = useCallback(
    (file) => {
      const uploadFile = {
        file: file[0],
        id: uniqueId(),
        name: file[0].name,
        readableSize: filesize(file[0].size),
        preview: URL.createObjectURL(file[0]),
        // progress: progressComprovante,
        // uploaded: statusComprovante.uploaded,
        // error: statusComprovante.error,
      };

      setUploadedComprovante([...uploadedComprovante, uploadFile]);
      // setUploadedComprovante(uploadFile);
    },
    [uploadedComprovante]
  );

  // Faz o upload de todos os arquivos
  /* const handleUploadComprovante = useCallback(() => {

    // Envia para a API
    const data = new FormData();

    uploadedComprovante.forEach(item => {
      // console.log(`image${uploadedComprovante.indexOf(item)}`);
      data.append(`image${uploadedComprovante.indexOf(item)}`, item.file);
    });

    api.post('/documento_upload_mult', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization : "Bearer "+userToken,
      },
      onUploadProgress: e => {
        setProgressComprovante(parseInt(Math.round((e.loaded * 100) / e.total)))
      }
    }).then(response => {
      setStatusComprovante({
        uploaded: true,
        error: false
      })

      const value = response.data.data;
      setValue("comprovante", value.id);
    }).catch(() => {
      setStatusComprovante({
        uploaded: false,
        error: true
      })
    });
  }, [uploadedComprovante, setValue, userToken]); */

  // Remove o arquivo da lista de arquivos
  const handleDeleteComprovante = useCallback(
    (id) => {
      const newFiles = uploadedComprovante.filter(
        (comprovante) => comprovante.id !== id
      );

      setUploadedComprovante(newFiles);
    },
    [uploadedComprovante]
  );
  // Upload do Comprovante ---------------------------------------------- END

  // Máscara do valor da transação
  const inputValueMask = useCallback(
    (e) => {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d)(\d{2})$/, '$1,$2');
      value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
      e.currentTarget.value = value;

      if (
        Number(
          value.replace('.', '').replace('.', '').replace(',', '.')
        ) > 150
      ) {
        addToast({
          type: 'info',
          title: 'Valor da comissão muito alto',
          description:
            'Você está inserindo uma comissão maior que R$ 150,00.',
        });
      }
    },
    [addToast]
  );

  // Faz o update do código de rastreio
  async function updateRastreioComissaoENotaFiscal() {
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    // console.log('ecInfos', ecInfos);
    // console.log('uploadedComprovante', uploadedComprovante);

    // return;

    const codigoRastreio = getValues('rastreio_setup');
    const valorComissao = getValues('valor_comissao');

    if (
      codigoRastreio !== '' &&
      codigoRastreio !== ecInfos.rastreio_setup
    ) {
      setUpdateIsLoading(true);

      // Edita o EC para colocar o código de rastreio
      await api
        .put(
          `/estabelecimento/${ecInfos.id}`,
          {
            rastreio_setup: codigoRastreio,
          },
          auth
        )
        .then((response) => {
          // console.log('responseAtualizaNotaFiscalNoEC', response);
          setUpdateIsLoading(false);

          addToast({
            type: 'success',
            title: 'Código de rastreio atualizado',
            description:
              'Você atualizou o código de rastreio do equipamento desse Estabelecimento.',
          });
        });
    }

    const responsavelPeloEC = ecInfos.revendedor_id
      ? ecInfos.revendedor_id
      : ecInfos.licenciado_autorizado_id;
    const responsavelPeloECType = ecInfos.revendedor_id
      ? 'ROLE_RV'
      : 'ROLE_LA';

    if (valorComissao !== '') {
      setUpdateIsLoading(true);

      // Comissiona o LA ou RV
      await api
        .post(
          `/comissao`,
          {
            valor_comissao: Number(
              valorComissao
                .replace('.', '')
                .replace('.', '')
                .replace(',', '.')
            ),
            taxa_comissao: 1,
            status: 'NAO_PAGO',
            valor_acrescimo: '0',
            transacao_id: ecInfos.id,
            comissionavel_id: responsavelPeloEC,
            comissionavel_type: responsavelPeloECType,
            transacao_type: 'credenciamento',
            causer_id: JSON.parse(
              localStorage.getItem('@DeixaNoAzul:loggedUser')
            ).id,
          },
          auth
        )
        .then((response) => {
          // console.log('responseComissaoAprovaEC', response);
          setUpdateIsLoading(false);

          addToast({
            type: 'success',
            title: 'Comissão criada',
            description: `Você gerou uma comissão para o ${
              responsavelPeloECType === 'ROLE_RV'
                ? 'Revendedor'
                : 'Licenciado'
            } que cadastrou esse Estabelecimento.`,
          });
        });
    }

    if (uploadedComprovante.length > 0) {
      setUpdateIsLoading(true);

      const data = new FormData();

      uploadedComprovante.forEach((item) => {
        data.append(
          `image${uploadedComprovante.indexOf(item)}`,
          item.file
        );
      });

      await api
        .post('/documento_upload_mult', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + userToken,
          },
          onUploadProgress: (e) => {
            setProgressComprovante(
              parseInt(Math.round((e.loaded * 100) / e.total))
            );
          },
        })
        .then((response) => {
          setStatusComprovante({
            uploaded: true,
            error: false,
          });

          const value = response.data.data;
          setValue('nota_fiscal_id', value.id);
        })
        .catch(() => {
          setStatusComprovante({
            uploaded: false,
            error: true,
          });
        });

      // Edita o EC para colocar a nota_fiscal_id
      await api
        .put(
          `/estabelecimento/${ecInfos.id}`,
          {
            nota_fiscal_licenca_id: getValues('nota_fiscal_id'),
          },
          auth
        )
        .then((response) => {
          // console.log('responseAtualizaNotaFiscalNoEC', response);
          addToast({
            type: 'success',
            title: 'Nota fiscal anexada',
            description: `Você anexou com sucesso a nota fiscal de serviço.`,
          });

          setUpdateIsLoading(false);
        });
    }
  }

  async function downloadNotaFiscal() {
    setDownloadIsLoading(true);

    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    await api
      .get(
        `/documento_download/${ecInfos.nota_fiscal_licenca_id}`,
        auth
      )
      .then((response) => {
        const result = response.data.data;

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = result;
        const downloadLink = document.createElement('a');
        const fileName = 'comprovante.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });

    setDownloadIsLoading(false);
  }

  return (
    <Container>
      {modalOpened && (
        <form style={{background: '#F00', width: '100%'}}>
          <ModalChangeStatus
            register={register('message')}
            name="message"
            type="text"
            requiredMessage={requiredMessage}
            label="Digite aqui"
            placeholder="Digite aqui sua mensagem"
            errors={errors.message}
            modalMessage={modalMessage}
            modalTitle={modalTitle}
            onCloseModal={() => setModalOpened(false)}
            onSubmit={(value) => {
              // setResponseModalChangeStatus(value);
              submitStatus(value);
              // console.log(value);
            }}
          />
        </form>
      )}

      <ModalSection
        modalOpened={modalOpened}
        style={{display: 'none'}}
      >
        <ModalContent>
          <form>
            <MdHighlightOff
              size={30}
              onClick={() => setModalOpened(!modalOpened)}
            />

            <Aprovado aprovadoHide={aprovadoHide}>
              <span>
                Tem certeza que deseja aprovar esse estabelecimento?
              </span>
            </Aprovado>

            <Mensagem messageHide={messageHide}>
              <span>Digite um comentário para o Licenciado</span>

              <div className="inputArea">
                <div className="labelRow">
                  <label>Digite aqui</label>
                  {errors.message && (
                    <Error
                      title="Campo obrigatório"
                      style={{marginLeft: '8px'}}
                    >
                      <MdErrorOutline
                        color="c53030"
                        size={20}
                        style={{marginBottom: '8px'}}
                      />
                    </Error>
                  )}
                </div>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  ref={register({required: requiredMessage})}
                  style={{borderColor: errors.message && 'red'}}
                />
              </div>
            </Mensagem>

            <Confirmar confirmarHide={confirmarHide}>
              <span>
                Tem certeza que deseja alterar esse estabelecimento?
              </span>
            </Confirmar>

            <div className="buttonRow">
              <button type="button" onClick={() => submitStatus()}>
                Enviar
              </button>
              <button
                className="cancelar"
                type="button"
                onClick={() => setModalOpened(!modalOpened)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </ModalContent>
      </ModalSection>

      <MenuBar />
      <NewMobileMenu />

      <Page>
        <TopBar pageTitle="Editar Estabelecimento" />

        <Content>
          <TabContent>
            <NavegaçãoAbas>
              <BotãoAba tab1={tab1} onClick={open1}>
                01
              </BotãoAba>
              <BotãoAba tab2={tab2} onClick={open2}>
                02
              </BotãoAba>
              <BotãoAba tab3={tab3} onClick={open3}>
                03
              </BotãoAba>
              <BotãoAba tab4={tab4} onClick={open4}>
                04
              </BotãoAba>
            </NavegaçãoAbas>

            <Abas>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Aba1 tab1={tab1}>
                  <BoxInfo>
                    <span>
                      Caso queira alterar os dados do Estabelecimento,
                      altere o campo desejado, vá para a aba 4 e
                      clique em <b>Atualizar dados</b>.
                    </span>
                    <br />
                    <span>
                      Para atualizar o status, altere-o de acordo com
                      o status desejado e clique em{' '}
                      <b>Atualizar status</b>.
                    </span>
                    <br />
                  </BoxInfo>

                  <Row>
                    <InputArea>
                      <label>*Nome</label>
                      <InputContainer
                        style={{borderColor: errors.name && 'red'}}
                      >
                        <input
                          name="name"
                          type="text"
                          placeholder="Digite seu nome"
                          ref={register({required: true})}
                        />
                        {errors.nome && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*E-mail</label>
                      <InputContainer
                        style={{borderColor: errors.email && 'red'}}
                      >
                        <input
                          name="email"
                          type="text"
                          placeholder="exemplo@exemplo.com"
                          ref={register({required: true})}
                        />
                        {errors.email && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>
                  </Row>

                  <Row>
                    <InputArea>
                      <label>*RG</label>
                      <InputContainer
                        style={{borderColor: errors.rg && 'red'}}
                      >
                        <input
                          name="rg"
                          type="text"
                          placeholder="Digite seu RG"
                          ref={register({required: true})}
                          onKeyUp={(e) => inputRGMask(e)}
                        />
                        {errors.rg && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*CPF</label>
                      <InputContainer
                        style={{borderColor: errors.cpf && 'red'}}
                      >
                        <input
                          name="cpf"
                          type="text"
                          placeholder="Digite seu CPF (apenas números)"
                          ref={register({required: true})}
                          onKeyUp={(e) => inputCPFMask(e)}
                        />
                        {errors.cpf && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>
                  </Row>

                  <Row>
                    <InputArea>
                      <label>*Data de nascimento</label>
                      <InputContainer
                        style={{
                          borderColor:
                            errors.data_nascimento && 'red',
                        }}
                      >
                        <input
                          name="data_nascimento"
                          type="date"
                          placeholder="Digite sua data de nascimento"
                          ref={register({
                            required: 'Campo obrogatório',
                          })}
                          onBlur={validDate}
                        />
                        {errors.data_nascimento && (
                          <Error
                            title={errors.data_nascimento.message}
                          >
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Estado civil</label>
                      <InputContainer
                        style={{
                          borderColor: errors.estado_civil && 'red',
                        }}
                      >
                        <select
                          name="estado_civil"
                          placeholder="Selecione"
                          ref={register({required: true})}
                        >
                          <option value="">Selecione</option>
                          <option value="Solteiro">Solteiro</option>
                          <option value="Casado">Casado</option>
                          <option value="Divorciado">
                            Divorciado
                          </option>
                          <option value="Viúvo">Viúvo</option>
                        </select>
                        {errors.estado_civil && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>
                  </Row>

                  <ButtonRow>
                    <button type="button" onClick={open2}>
                      Avançar
                    </button>
                  </ButtonRow>
                </Aba1>

                <Aba2 tab2={tab2}>
                  <BoxInfo>
                    <span>
                      Caso queira alterar os dados do Estabelecimento,
                      altere o campo desejado, vá para a aba 4 e
                      clique em <b>Atualizar dados</b>.
                    </span>
                    <br />
                    <span>
                      Para atualizar o status, altere-o de acordo com
                      o status desejado e clique em{' '}
                      <b>Atualizar status</b>.
                    </span>
                    <br />
                  </BoxInfo>

                  <Row3>
                    <InputArea>
                      <label>*Cargo</label>
                      <InputContainer
                        style={{borderColor: errors.cargo && 'red'}}
                      >
                        <select
                          name="cargo"
                          placeholder="Selecione"
                          ref={register({required: true})}
                        >
                          <option value="">Selecione</option>
                          {cargoOptions.map((item) => (
                            <option value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {errors.cargo && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Razão social</label>
                      <InputContainer
                        style={{
                          borderColor: errors.razao_social && 'red',
                        }}
                      >
                        <input
                          name="razao_social"
                          type="text"
                          placeholder="Digite sua data de nascimento"
                          ref={register({required: true})}
                        />
                        {errors.razao_social && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Nome fantasia</label>
                      <InputContainer
                        style={{
                          borderColor: errors.nome_fantasia && 'red',
                        }}
                      >
                        <input
                          name="nome_fantasia"
                          type="text"
                          placeholder="Digite sua data de nascimento"
                          ref={register({required: true})}
                        />
                        {errors.nome_fantasia && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>
                  </Row3>

                  <Row>
                    <InputArea>
                      <label>*Sessão (CNAE)</label>
                      <InputContainer
                        style={{
                          borderColor: errors.sessao_cnae && 'red',
                        }}
                      >
                        <select
                          name="sessao_cnae"
                          placeholder="Selecione"
                          // onChange={getCnaeActivities}
                          onChange={() => setCNAE()}
                          ref={register({required: true})}
                        >
                          <option value="">Selecione</option>
                          {sessãoCnaeOptions.map((item) => (
                            <option value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {errors.sessao_cnae && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Atividade econômica (CNAE)</label>
                      <InputContainer
                        style={{borderColor: errors.cnae && 'red'}}
                      >
                        <select
                          name="cnae"
                          placeholder="Selecione"
                          ref={register({required: true})}
                        >
                          <option value="">Selecione</option>
                          {cnaeSubclasses.map((item) => (
                            <option value={item.cod}>
                              {item.desc}
                            </option>
                          ))}
                        </select>
                        {errors.cnae && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>
                  </Row>

                  <Row3>
                    <InputArea>
                      <label>*Tipo de empresa</label>
                      <InputContainer
                        style={{
                          borderColor: errors.tipo_empresa && 'red',
                        }}
                      >
                        <select
                          name="tipo_empresa"
                          placeholder="Selecione"
                          ref={register({required: true})}
                        >
                          <option value="">Selecione</option>
                          <option value="EI - Empresário Individual">
                            EI - Empresário Individual
                          </option>
                          <option value="MEI - Microempreendedor Individual">
                            MEI - Microempreendedor Individual
                          </option>
                          <option value="LIDA - Sociedade LTDA">
                            LIDA - Sociedade LTDA
                          </option>
                          <option value="S/A - Sociedade Anônima">
                            S/A - Sociedade Anônima
                          </option>
                          <option value="EIRELI - Empresa Individual de Responsabilidade Limitada">
                            EIRELI - Empresa Individual de
                            Responsabilidade Limitada
                          </option>
                        </select>
                        {errors.tipo_empresa && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*CNPJ</label>
                      <InputContainer
                        style={{borderColor: errors.cnpj && 'red'}}
                      >
                        <input
                          name="cnpj"
                          type="text"
                          placeholder="Digite o CNPJ (apenas números)"
                          ref={register({required: true})}
                          onKeyUp={(e) => inputCNPJMask(e)}
                        />
                        {errors.cnpj && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    {/* <InputArea>
                    <label>Serial number</label>
                    <p>{ecInfos.serial_number}</p>
                  </InputArea> */}

                    <InputArea>
                      <label>Serial number</label>
                      <InputContainer>
                        <input
                          name="serial_number"
                          type="text"
                          placeholder="xxxxxx"
                          ref={register}
                        />
                      </InputContainer>
                    </InputArea>
                  </Row3>

                  <ButtonRow>
                    <button type="button" onClick={open3}>
                      Avançar
                    </button>
                    <button
                      type="button"
                      className="back"
                      onClick={open1}
                    >
                      Voltar
                    </button>
                  </ButtonRow>
                </Aba2>

                <Aba3 tab3={tab3}>
                  <BoxInfo>
                    <span>
                      Caso queira alterar os dados do Estabelecimento,
                      altere o campo desejado, vá para a aba 4 e
                      clique em <b>Atualizar dados</b>.
                    </span>
                    <br />
                    <span>
                      Para atualizar o status, altere-o de acordo com
                      o status desejado e clique em{' '}
                      <b>Atualizar status</b>.
                    </span>
                    <br />
                  </BoxInfo>

                  <h4>Endereço pessoal</h4>
                  <Row4>
                    <InputArea>
                      <label>*CEP</label>
                      <InputContainer
                        style={{
                          borderColor: errors.pessoal_cep && 'red',
                        }}
                      >
                        <input
                          name="pessoal_cep"
                          type="text"
                          placeholder="Apenas números (8 dígitos)"
                          ref={register({required: true})}
                          onKeyUp={(e) => inputCepMask(e)}
                          onBlur={(e) => onBlurCepPes(e)}
                        />
                        {errors.pessoal_cep && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Rua</label>
                      <InputContainer
                        style={{
                          borderColor: errors.pessoal_rua && 'red',
                        }}
                      >
                        <input
                          name="pessoal_rua"
                          type="text"
                          placeholder="Digite o enredeço"
                          ref={register({required: true})}
                        />
                        {errors.pessoal_rua && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Bairro</label>
                      <InputContainer
                        style={{
                          borderColor: errors.pessoal_bairro && 'red',
                        }}
                      >
                        <input
                          name="pessoal_bairro"
                          type="text"
                          placeholder="Digite o bairro"
                          ref={register({required: true})}
                        />
                        {errors.pessoal_bairro && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Número</label>
                      <InputContainer
                        style={{
                          borderColor: errors.pessoal_numero && 'red',
                        }}
                      >
                        <input
                          name="pessoal_numero"
                          type="number"
                          placeholder="xxxx"
                          ref={register({required: true})}
                        />
                        {errors.pessoal_numero && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>
                  </Row4>

                  <Row4>
                    <InputArea>
                      <label>Complemento</label>
                      <InputContainer
                        style={{
                          borderColor:
                            errors.pessoal_complemento && 'red',
                        }}
                      >
                        <input
                          name="pessoal_complemento"
                          type="text"
                          placeholder="Ex. Casa"
                          ref={register}
                        />
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*UF</label>
                      <InputContainer
                        style={{
                          borderColor: errors.pessoal_uf && 'red',
                        }}
                      >
                        <select
                          name="pessoal_uf"
                          placeholder="Selecione"
                          ref={register({required: true})}
                        >
                          <option value="">Selecione</option>
                          {listaEstados.map((item) => (
                            <option value={item.sigla}>
                              {item.sigla}
                            </option>
                          ))}
                        </select>
                        {errors.pessoal_uf && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Cidade</label>
                      <InputContainer
                        style={{
                          borderColor: errors.pessoal_cidade && 'red',
                        }}
                      >
                        <input
                          name="pessoal_cidade"
                          type="text"
                          placeholder="Digite o nome da cidade"
                          ref={register({required: true})}
                        />
                        {errors.pessoal_cidade && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Telefone</label>
                      <InputContainer
                        style={{
                          borderColor:
                            errors.pessoal_telefone && 'red',
                        }}
                      >
                        <input
                          name="pessoal_telefone"
                          type="text"
                          placeholder="Apenas números"
                          ref={register({required: true})}
                          onKeyUp={(e) => inputTelMask(e)}
                        />
                        {errors.pessoal_telefone && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>
                  </Row4>

                  <Separator />

                  <h4>Endereço do estabelecimento</h4>

                  <Row4>
                    <InputArea>
                      <label>*CEP</label>
                      <InputContainer
                        style={{
                          borderColor:
                            errors.estabelecimento_cep && 'red',
                        }}
                      >
                        <input
                          name="estabelecimento_cep"
                          type="text"
                          placeholder="Apenas números (8 dígitos)"
                          ref={register({required: true})}
                          onKeyUp={(e) => inputCepMask(e)}
                          onBlur={(e) => onBlurCepEst(e)}
                        />
                        {errors.estabelecimento_cep && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Rua</label>
                      <InputContainer
                        style={{
                          borderColor:
                            errors.estabelecimento_rua && 'red',
                        }}
                      >
                        <input
                          name="estabelecimento_rua"
                          type="text"
                          placeholder="Digite o enredeço"
                          ref={register({required: true})}
                        />
                        {errors.estabelecimento_rua && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Bairro</label>
                      <InputContainer
                        style={{
                          borderColor:
                            errors.estabelecimento_bairro && 'red',
                        }}
                      >
                        <input
                          name="estabelecimento_bairro"
                          type="text"
                          placeholder="Digite o bairro"
                          ref={register({required: true})}
                        />
                        {errors.estabelecimento_bairro && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Número</label>
                      <InputContainer
                        style={{
                          borderColor:
                            errors.estabelecimento_numero && 'red',
                        }}
                      >
                        <input
                          name="estabelecimento_numero"
                          type="number"
                          placeholder="xxxx"
                          ref={register({required: true})}
                        />
                        {errors.estabelecimento_numero && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>
                  </Row4>

                  <Row4>
                    <InputArea>
                      <label>Complemento</label>
                      <InputContainer
                        style={{
                          borderColor:
                            errors.estabelecimento_complemento &&
                            'red',
                        }}
                      >
                        <input
                          name="estabelecimento_complemento"
                          type="text"
                          placeholder="Ex. Casa"
                          ref={register}
                        />
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*UF</label>
                      <InputContainer
                        style={{
                          borderColor:
                            errors.estabelecimento_uf && 'red',
                        }}
                      >
                        <select
                          name="estabelecimento_uf"
                          placeholder="Selecione"
                          ref={register({required: true})}
                        >
                          <option value="">Selecione</option>
                          {listaEstados.map((item) => (
                            <option value={item.sigla}>
                              {item.sigla}
                            </option>
                          ))}
                        </select>
                        {errors.estabelecimento_uf && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Cidade</label>
                      <InputContainer
                        style={{
                          borderColor:
                            errors.estabelecimento_cidade && 'red',
                        }}
                      >
                        <input
                          name="estabelecimento_cidade"
                          type="text"
                          placeholder="Digite o nome da cidade"
                          ref={register({required: true})}
                        />
                        {errors.estabelecimento_cidade && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>*Telefone</label>
                      <InputContainer
                        style={{
                          borderColor:
                            errors.estabelecimento_telefone && 'red',
                        }}
                      >
                        <input
                          name="estabelecimento_telefone"
                          type="text"
                          placeholder="Apenas números"
                          ref={register({required: true})}
                          onKeyUp={(e) => inputTelMask(e)}
                        />
                        {errors.estabelecimento_telefone && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>
                  </Row4>

                  <ButtonRow>
                    <button type="button" onClick={open4}>
                      Avançar
                    </button>
                    <button
                      type="button"
                      className="back"
                      onClick={open2}
                    >
                      Voltar
                    </button>
                  </ButtonRow>
                </Aba3>

                <Aba4 tab4={tab4}>
                  <BoxInfo>
                    <span>
                      Caso os campos da chave Pix estejam preenchidos,
                      os demais dados <b>não serão obrigatórios</b>.
                    </span>
                    <br />
                    <span>
                      Porém, se você incluir algum dos demais dados
                      bancários <b>você terá que preencher todos</b>.
                    </span>
                    <br />
                    <span>
                      Caso queira alterar os dados do Estabelecimento,
                      altere o campo desejado e clique em{' '}
                      <b>Atualizar dados</b>.
                    </span>
                  </BoxInfo>

                  <Row>
                    <InputArea>
                      <label>Tipo de Chave Pix</label>
                      <InputContainer
                        style={{
                          borderColor: errors.tipo_chave_pix && 'red',
                        }}
                      >
                        <select
                          name="tipo_chave_pix"
                          placeholder="Selecione"
                          onChange={() => setValue('chave_pix', '')}
                          ref={register}
                        >
                          <option value="">Selecione</option>
                          <option value="CPF">CPF</option>
                          <option value="CNPJ">CNPJ</option>
                          <option value="TELEFONE">TELEFONE</option>
                          <option value="EMAIL">EMAIL</option>
                          <option value="CHAVE">CHAVE</option>
                        </select>
                        {errors.tipo_chave_pix && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>Chave Pix</label>
                      <InputContainer
                        style={{
                          borderColor: errors.chave_pix && 'red',
                        }}
                      >
                        <input
                          name="chave_pix"
                          type="text"
                          placeholder="Digite sua chave"
                          onKeyUp={(e) => mascaraPix(e)}
                          ref={register}
                        />
                        {errors.chave_pix && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>
                  </Row>

                  <Row>
                    <InputArea>
                      <label>Nome do titular</label>
                      <InputContainer
                        style={{
                          borderColor: errors.nome_titular && 'red',
                        }}
                      >
                        <input
                          name="nome_titular"
                          type="text"
                          placeholder="Digite seu nome"
                          ref={register}
                        />
                        {errors.nome_titular && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>Nome do banco</label>
                      <InputContainer
                        style={{
                          borderColor: errors.nome_banco && 'red',
                        }}
                      >
                        <select
                          name="nome_banco"
                          placeholder="Selecione"
                          onChange={setNumeroBanco}
                          ref={register}
                        >
                          <option value="">Selecione</option>

                          {listaBancos.map((item) => (
                            <option value={item.label}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {errors.nome_banco && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                      <input
                        type="text"
                        name="numero_banco"
                        ref={register}
                        hidden
                      />
                    </InputArea>
                  </Row>

                  <Row3>
                    <InputArea>
                      <label>Agência</label>
                      <InputContainer
                        style={{borderColor: errors.agencia && 'red'}}
                      >
                        <input
                          name="agencia"
                          type="number"
                          placeholder="xxxx"
                          ref={register}
                        />
                        {errors.agencia && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>Número da conta</label>
                      <InputContainer
                        style={{
                          borderColor: errors.numero_conta && 'red',
                        }}
                      >
                        <input
                          name="numero_conta"
                          type="text"
                          placeholder="Apenas números"
                          ref={register}
                          onKeyUp={(e) => inputAccountMask(e)}
                        />
                        {errors.numero_conta && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>Conta</label>
                      <InputContainer
                        style={{
                          borderColor: errors.tipo_conta && 'red',
                        }}
                      >
                        <select
                          name="tipo_conta"
                          placeholder="Selecione"
                          ref={register}
                        >
                          <option value="">Selecione</option>
                          <option value="Corrente">Corrente</option>
                          <option value="Poupança">Poupança</option>
                        </select>
                        {errors.tipo_conta && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>
                  </Row3>

                  <Row3>
                    <InputArea>
                      <label>Selecione o Tipo de conta</label>
                      <InputContainer
                        style={{
                          borderColor: errors.conta_juridica && 'red',
                        }}
                      >
                        <select
                          name="conta_juridica"
                          placeholder="Selecione"
                          onChange={changeContaJuridica}
                          ref={register}
                        >
                          <option value="">Selecione</option>
                          <option value="0">Pessoa Física</option>
                          <option value="1">Pessoa Jurídica</option>
                        </select>
                        {errors.conta_juridica && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>CPF/CNPJ</label>
                      <InputContainer
                        style={{
                          borderColor: errors.cpf_cnpj_conta && 'red',
                        }}
                      >
                        <input
                          name="cpf_cnpj_conta"
                          onKeyUp={inputMask}
                          placeholder={placeholder}
                          ref={register}
                        />
                        {errors.cpf_cnpj_conta && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    </InputArea>

                    <InputArea>
                      <label>Operação</label>
                      <InputContainer>
                        <input
                          name="operacao"
                          type="number"
                          placeholder="xxx"
                          ref={register}
                        />
                      </InputContainer>
                    </InputArea>
                  </Row3>

                  <ButtonRow>
                    <button
                      type="button"
                      className="back"
                      style={{marginLeft: 0, marginRight: 30}}
                      onClick={open3}
                    >
                      Voltar
                    </button>
                    {isLoading ? (
                      <button
                        type="submit"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: 155,
                          height: 37,
                        }}
                      >
                        <LoadingIcons.Oval
                          speed={1.2}
                          style={{width: 15, marginRight: 8}}
                          strokeWidth={4}
                        />
                        Carregando
                      </button>
                    ) : (
                      <button type="submit" style={{width: 160}}>
                        Atualizar dados
                      </button>
                    )}
                  </ButtonRow>
                </Aba4>
              </form>
            </Abas>
          </TabContent>

          {ecInfos.status === '0' && (
            <SelectCodigoRastreio>
              <BoxInfo>
                <span>
                  Preencha <b>apenas</b> os campos que quer alterar.
                  Você poderá preencher os demais campos depois.
                </span>
                <br />
              </BoxInfo>

              <Row3>
                {/* Código de rastreio */}
                <InputArea>
                  <label>Código de rastreio do equipamento</label>
                  <InputContainer
                    style={{
                      borderColor: errors.rastreio_setup && 'red',
                    }}
                  >
                    <input
                      name="rastreio_setup"
                      type="text"
                      placeholder="Digite o código de rastreio"
                      ref={register({required: false})}
                    />
                    {errors.rastreio_setup && (
                      <Error title="Campo obrigatório">
                        <MdErrorOutline color="c53030" size={20} />
                      </Error>
                    )}
                  </InputContainer>
                </InputArea>

                {/* Valor da comissão */}
                <InputArea>
                  <Tooltip
                    title={
                      ecGerouComissao &&
                      'Este EC já gerou uma comissão'
                    }
                    placement="top"
                    arrow
                  >
                    <label>Valor da comissão</label>
                  </Tooltip>
                  <InputContainer
                    style={{
                      borderColor: errors.valor_comissao && 'red',
                      //cursor: ecGerouComissao && 'not-allowed',
                    }}
                  >
                    <input
                      defaultValue="150,00"
                      /*disabled={ecGerouComissao}*/
                      name="valor_comissao"
                      type="text"
                      placeholder="Comissão do LA ou Revenda"
                      ref={register({required: false})}
                      onKeyUp={(e) => inputValueMask(e)}
                    />
                    {errors.valor_comissao && (
                      <Error title="Campo obrigatório">
                        <MdErrorOutline color="c53030" size={20} />
                      </Error>
                    )}
                  </InputContainer>
                </InputArea>

                {/* Nota fiscal */}
                <InputArea className="campoAnexo">
                  <Tooltip
                    title="Anexe todas as notas fiscais aqui"
                    placement="top"
                    arrow
                  >
                    <label>Nota fiscal de serviço</label>
                  </Tooltip>
                  {ecInfos.nota_fiscal_licenca_id !== null ? (
                    <button
                      type="button"
                      className="downloadButton"
                      onClick={() => downloadNotaFiscal()}
                    >
                      {downloadIsLoading
                        ? 'Baixando'
                        : 'Fazer Download'}
                    </button>
                  ) : (
                    <>
                      <UploadContainer
                        style={{
                          borderColor: errors.comprovante && 'red',
                        }}
                      >
                        <UploadComprovante
                          onUpload={submitFileComprovante}
                          isDisabled={
                            uploadedComprovante.length === 4
                              ? true
                              : false
                          }
                        />
                        <input
                          type="text"
                          name="nota_fiscal_id"
                          ref={register}
                          hidden
                        />
                        {errors.comprovante && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </UploadContainer>
                      {!!uploadedComprovante.length && (
                        <InfoComprovante
                          files={uploadedComprovante}
                          percent={progressComprovante}
                          status={statusComprovante}
                          onDelete={(id) =>
                            handleDeleteComprovante(id)
                          }
                        />
                      )}
                    </>
                  )}
                </InputArea>
              </Row3>

              <ButtonRow>
                {updateIsLoading ? (
                  <button
                    type="button"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 160,
                      height: 37,
                    }}
                    onClick={() =>
                      updateRastreioComissaoENotaFiscal()
                    }
                  >
                    <LoadingIcons.Oval
                      speed={1.2}
                      style={{width: 15, marginRight: 8}}
                      strokeWidth={4}
                    />
                    Carregando
                  </button>
                ) : (
                  <button
                    type="button"
                    style={{width: 180}}
                    onClick={() =>
                      updateRastreioComissaoENotaFiscal()
                    }
                  >
                    Atualizar
                  </button>
                )}
              </ButtonRow>
            </SelectCodigoRastreio>
          )}

          <SelectLa LAHide={LAHide}>
            <Row>
              <InputArea>
                <label>*Licenciado responsável</label>
                <InputContainer
                  style={{
                    borderColor: errors.la_responsavel && 'red',
                  }}
                >
                  <select
                    name="la_responsavel"
                    placeholder="Selecione"
                    onChange={() => clearErrors('la_responsavel')}
                    ref={register}
                  >
                    <option value="">Selecione</option>
                    {licenciados.map((licenciado) => (
                      <option value={licenciado.id}>
                        {licenciado.nome_fantasia}
                      </option>
                    ))}
                  </select>
                  {errors.la_responsavel && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>Revendedor responsável</label>
                <InputContainer
                  style={{
                    borderColor: errors.rv_responsavel && 'red',
                  }}
                >
                  <select
                    name="rv_responsavel"
                    placeholder="Selecione"
                    ref={register}
                  >
                    <option value="">Selecione</option>
                    {revendedores.map((revendedor) => (
                      <option value={revendedor.id}>
                        {revendedor.nome_fantasia}
                      </option>
                    ))}
                  </select>
                  {errors.rv_responsavel && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row>
          </SelectLa>

          <SelectStatus>
            <SelectInputContainer statusColor={statusColor}>
              <select
                name="status"
                placeholder="Selecione"
                ref={register}
                onChange={changeStatus}
              >
                <option value="1">Em Análise</option>
                <option value="0">Aprovado</option>
                <option value="2">Documentos pendentes</option>
                <option value="3">Documentos checados</option>
                <option value="4">Reprovado</option>
              </select>
            </SelectInputContainer>

            <button type="button" onClick={openModal}>
              Atualizar status
            </button>
          </SelectStatus>

          {colaboradores.length > 0 && (
            <Box>
              <h4>Colaboradores do Estabelecimento</h4>
              <ul>
                {colaboradores.map((colaborador) => (
                  <li>{colaboradorName(colaborador.user_id)}</li>
                ))}
              </ul>
            </Box>
          )}
        </Content>
      </Page>
    </Container>
  );
}

export default AdminEditarEstabelecimentos;
