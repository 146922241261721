import React, {useState, useEffect, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {MdErrorOutline} from 'react-icons/md';
import LoadingIcons from 'react-loading-icons';

import api from '../../services/api';

// Context API
import {useToast} from '../../hooks/ToastContext';

// JSONs
import BankList from '../../assets/json/bancos.json';
import CnaeList from '../../assets/json/cnae.json';

// Máscaras
import contaBancariaMask from '../../components/InputMasks/contaBancariaMask';
import cpfMask from '../../components/InputMasks/cpfMask';
import rgMask from '../../components/InputMasks/rgMask';
import cepMask from '../../components/InputMasks/cepMask';
import cnpjMask from '../../components/InputMasks/cnpjMask';
import telefoneMask from '../../components/InputMasks/telefoneMask';

import {
  Container,
  Row,
  Row3,
  Row4,
  InputArea,
  ButtonRow,
  Separator,
  NavegaçãoAbas,
  BotãoAba,
  Abas,
  Aba1,
  Aba2,
  Aba3,
  Aba4,
  InputContainer,
  Error,
  BoxInfo,
} from './styles';

function FormEditLA() {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const [idLA, setIdLA] = useState('');

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const loggedUser = JSON.parse(
    localStorage.getItem('@DeixaNoAzul:loggedUser')
  );

  const [laInfos, setLaInfos] = useState([]);
  const [laInfosUser, setLaInfosUser] = useState([]);
  const [laInfosEnderecoPessoa, setLaInfosEnderecoPessoa] = useState(
    []
  );
  const [
    laInfosEnderecoEstabelecimento,
    setLaInfosEnderecoEstabelecimento,
  ] = useState([]);
  const [laInfosDadosBancarios, setLaInfosDadosBancarios] = useState(
    []
  );
  const [
    laInfosTelefoneEstabelecimento,
    setLaInfosTelefoneEstabelecimento,
  ] = useState([]);

  const [parceiros, setParceiros] = useState([]);

  const {addToast} = useToast();

  useEffect(() => {
    async function loadParceiros() {
      const auth = {
        headers: {
          Authorization: 'Bearer ' + userToken,
        },
      };

      const response = await api.get('partner', auth);

      setParceiros(response.data.data);
    }

    loadParceiros();
  }, [userToken]);

  const infoTelefonePessoal = laInfosTelefoneEstabelecimento.filter(
    (item) => item.descricao === 'Pessoal'
  );
  const infoTelefoneEstabelecimento =
    laInfosTelefoneEstabelecimento.filter(
      (item) => item.descricao === 'Estabelecimento'
    );

  let telefonePessoal = '';
  let idTelefonePessoal = '';
  infoTelefonePessoal.forEach((item) => {
    telefonePessoal = item.telefone;
    idTelefonePessoal = item.id;
  });

  let telefoneEstabelecimento = '';
  let idTelefoneEstabelecimento = '';
  infoTelefoneEstabelecimento.forEach((item) => {
    telefoneEstabelecimento = item.telefone;
    idTelefoneEstabelecimento = item.id;
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    errors,
  } = useForm();
  const onSubmit = async (data) => {
    // Bearer token
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    // Valida se todos os dados bancários estão vazios
    if (
      data.tipo_chave_pix === '' &&
      data.chave_pix === '' &&
      data.agencia === '' &&
      data.conta_juridica === '' &&
      data.cpf_cnpj_conta === '' &&
      data.nome_banco === '' &&
      data.nome_titular === '' &&
      // data.numero_banco === "" &&
      data.numero_conta === '' &&
      data.tipo_conta === ''
    ) {
      addToast({
        type: 'error',
        title: 'Erro no envio do formulário',
        description:
          'Você precisa informar algum dado bancário, seja os campos da Chave Pix ou os demais dados bancários para transferência bancária.',
      });

      return;
    }

    const tipo_chave_pix = data.tipo_chave_pix;
    const chave_pix = data.chave_pix;

    // Faz a validação da obrigatoriedade da Chave Pix
    if (tipo_chave_pix !== '' && chave_pix === '') {
      setError('chave_pix', {
        type: 'manual',
        message: 'Você deve incluir uma Chava Pix',
      });

      return;
    }

    // Faz a validação da obrigatoriedade da Chave Pix
    if (tipo_chave_pix === '' && chave_pix !== '') {
      setError('tipo_chave_pix', {
        type: 'manual',
        message: 'Você deve incluir um Tipo de Chava Pix',
      });

      return;
    }

    // Valida se, caso algum dado bancário seja preenchido, os outros também devem ser
    if (
      (data.agencia !== '' ||
        data.conta_juridica !== '' ||
        data.cpf_cnpj_conta !== '' ||
        data.nome_banco !== '' ||
        data.nome_titular !== '' ||
        // data.numero_banco !== "" ||
        data.numero_conta !== '' ||
        data.tipo_conta !== '') &&
      (data.agencia === '' ||
        data.conta_juridica === '' ||
        data.cpf_cnpj_conta === '' ||
        data.nome_banco === '' ||
        data.nome_titular === '' ||
        // data.numero_banco === "" ||
        data.numero_conta === '' ||
        data.tipo_conta === '')
    ) {
      addToast({
        type: 'error',
        title: 'Erro no envio do formulário',
        description:
          'Se você preencheu algum dado bancário (exceto as Chaves Pix) você precisará preencher todo o formulário.',
      });

      return;
    }

    try {
      setIsLoading(true);

      // licenciado
      await api.put(
        `/licenciado/${laInfos.id}`,
        {
          cargo: data.cargo,
          estado_civil: data.estado_civil,
          rg: data.rg,
          cpf: data.cpf,
          parceiro_id: data.parceiro_id,
          data_nascimento: data.data_nascimento,
          razao_social: data.razao_social,
          tipo_de_empresa: data.tipo_empresa,
          cnpj: data.cnpj,
          serial_number: data.serial_number,
          sessao_cnae: data.sessao_cnae,
          cnae: data.cnae,
          nome_fantasia: data.nome_fantasia,
          //
          nome: data.name,
          email: data.email,
          //
          rua_pessoal: data.pessoal_rua,
          numero_pessoal: data.pessoal_numero,
          complemento_pessoal: data.pessoal_complemento,
          bairro_pessoal: data.pessoal_bairro,
          cidade_pessoal: data.pessoal_cidade,
          cep_pessoal: data.pessoal_cep,
          uf_pessoal: data.pessoal_uf,
          //
          rua_estabelecimento: data.estabelecimento_rua,
          numero_estabelecimento: data.estabelecimento_numero,
          complemento_estabelecimento:
            data.estabelecimento_complemento,
          bairro_estabelecimento: data.estabelecimento_bairro,
          cidade_estabelecimento: data.estabelecimento_cidade,
          cep_estabelecimento: data.estabelecimento_cep,
          uf_estabelecimento: data.estabelecimento_uf,
          //
          telefone_pessoal: data.pessoal_telefone,
          descricao_telefone_pessoal: 'Pessoal',
          //
          telefone_estabelecimento: data.estabelecimento_telefone,
          descricao_telefone_estabelecimento: 'Estabelecimento',
        },
        auth
      );

      // user
      await api.put(
        `/user/${laInfos.user_id}`,
        {
          name: data.name,
          email: data.email,
          role: laInfosUser.role,
        },
        auth
      );

      // endereço pessoa
      await api.put(
        `/endereco/${laInfos.endereco_pessoa_id}`,
        {
          rua: data.pessoal_rua,
          numero: data.pessoal_numero,
          complemento: data.pessoal_complemento,
          bairro: data.pessoal_bairro,
          cidade: data.pessoal_cidade,
          cep: data.pessoal_cep,
          uf: data.pessoal_uf,
        },
        auth
      );

      // endereço estabelecimento
      await api.put(
        `/endereco/${laInfos.endereco_estabelecimento_id}`,
        {
          rua: data.estabelecimento_rua,
          numero: data.estabelecimento_numero,
          complemento: data.estabelecimento_complemento,
          bairro: data.estabelecimento_bairro,
          cidade: data.estabelecimento_cidade,
          cep: data.estabelecimento_cep,
          uf: data.estabelecimento_uf,
        },
        auth
      );

      // dados bancários
      await api.put(
        `/conta/${laInfos.dados_bancarios_id}`,
        {
          tipo_chave_pix:
            data.tipo_chave_pix === '' ? null : data.tipo_chave_pix,
          chave_pix: data.chave_pix === '' ? null : data.chave_pix,
          //
          agencia: data.agencia === '' ? null : data.agencia,
          conta_juridica:
            data.conta_juridica === '' ? null : data.conta_juridica,
          cpf_cnpj:
            data.cpf_cnpj_conta === '' ? null : data.cpf_cnpj_conta,
          nome_banco: data.nome_banco === '' ? null : data.nome_banco,
          nome_titular:
            data.nome_titular === '' ? null : data.nome_titular,
          numero_banco:
            data.numero_banco === '' ? null : data.numero_banco,
          numero_conta:
            data.numero_conta === '' ? null : data.numero_conta,
          tipo_conta: data.tipo_conta === '' ? null : data.tipo_conta,
          operacao: data.operacao === '' ? null : data.operacao,
        },
        auth
      );

      // telefone pessoal
      await api.put(
        `/fone/licenciado/${idTelefonePessoal}`,
        {
          licenciado_autorizado_id: laInfos.id,
          telefone: data.pessoal_telefone,
          descricao: 'Pessoal',
        },
        auth
      );

      // telefone estabelecimento
      await api.put(
        `/fone/licenciado/${idTelefoneEstabelecimento}`,
        {
          licenciado_autorizado_id: laInfos.id,
          telefone: data.estabelecimento_telefone,
          descricao: 'Estabelecimento',
        },
        auth
      );

      addToast({
        type: 'success',
        title: 'Deu tudo certo!',
        description: 'Licenciado editado com sucesso.',
      });

      setIsLoading(false);

      if (loggedUser.role === 'ROLE_ADMIN') {
        history.push('/admin/licenciados');
      }
      if (loggedUser.role === 'ROLE_PT') {
        history.push('/parceiro/licenciados');
      }
    } catch (error) {
      console.log(error.response);

      setIsLoading(false);

      addToast({
        type: 'error',
        title: 'Erro no envio do formulário',
        description:
          'Algo deu errado. Tente novamente ou entre em contato com o Deixa no Azul.',
      });
    }
  };
  const onError = (errors) => {
    addToast({
      type: 'error',
      title: 'Erro no envio do formulário',
      description:
        'Você precisa preencher todos os campos obrigatórios (marcados com *).',
    });
  };

  // Carrega as informações para alimentar a página
  useEffect(() => {
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    async function requestLA() {
      if (loggedUser.role === 'ROLE_ADMIN') {
        // Salva o ID em um estado para usar futuramente
        setIdLA(
          location.pathname
            .replace('/admin/licenciados/editar/', '')
            .split('')
            .reverse()
            .join('')
        );

        // Salva o ID para fazer a requisição
        const LaId = location.pathname
          .replace('/admin/licenciados/editar/', '')
          .split('')
          .reverse()
          .join('');

        // Faz a requisição para pegar as infos do LA
        const responseLA = await api.get(`/licenciado/${LaId}`, auth);
        const result = responseLA.data.data;
        // console.log('resultLA', result);

        setLaInfos(result);

        // Salvar usuário
        api.get(`/user/${result.user_id}`, auth).then((response) => {
          const dadosUsuario = response.data.data;
          setLaInfosUser(dadosUsuario);
        });

        // Salvar dados do endereço pessoal
        api
          .get(`/endereco/${result.endereco_pessoa_id}`, auth)
          .then((response) => {
            const enderecoPessoa = response.data;
            setLaInfosEnderecoPessoa(enderecoPessoa.data);
          });

        // Salvar dados do endereço do estabelecimento
        api
          .get(
            `/endereco/${result.endereco_estabelecimento_id}`,
            auth
          )
          .then((response) => {
            const enderecoEstabelecimento = response.data;
            setLaInfosEnderecoEstabelecimento(
              enderecoEstabelecimento.data
            );
          });

        // Salvar dados bancários
        api
          .get(`/conta/${result.dados_bancarios_id}`, auth)
          .then((response) => {
            const dadosBancarios = response.data;
            setLaInfosDadosBancarios(dadosBancarios.data);
          });

        // Salvar dados do telefone
        api
          .get('/fone/licenciado?png=999999', auth)
          .then((response) => {
            const result = response.data.data;
            const dadosTelefones = result.filter(
              (dado) => dado.licenciado_autorizado_id === idLA
            );
            setLaInfosTelefoneEstabelecimento(dadosTelefones);
          });
      }
      if (loggedUser.role === 'ROLE_PT') {
        // Salva o ID em um estado para usar futuramente
        setIdLA(
          location.pathname
            .replace('/parceiro/licenciados/editar/', '')
            .split('')
            .reverse()
            .join('')
        );

        // Salva o ID para fazer a requisição
        const LaId = location.pathname
          .replace('/parceiro/licenciados/editar/', '')
          .split('')
          .reverse()
          .join('');

        // Faz a requisição para pegar as infos do LA
        const responseLA = await api.get(`/licenciado/${LaId}`, auth);
        const result = responseLA.data.data;

        setLaInfos(result);

        // Salvar usuário
        api.get(`/user/${result.user_id}`, auth).then((response) => {
          const dadosUsuario = response.data.data;
          setLaInfosUser(dadosUsuario);
        });

        // Salvar dados do endereço pessoal
        api
          .get(`/endereco/${result.endereco_pessoa_id}`, auth)
          .then((response) => {
            const enderecoPessoa = response.data;
            setLaInfosEnderecoPessoa(enderecoPessoa.data);
          });

        // Salvar dados do endereço do estabelecimento
        api
          .get(
            `/endereco/${result.endereco_estabelecimento_id}`,
            auth
          )
          .then((response) => {
            const enderecoEstabelecimento = response.data;
            setLaInfosEnderecoEstabelecimento(
              enderecoEstabelecimento.data
            );
          });

        // Salvar dados bancários
        api
          .get(`/conta/${result.dados_bancarios_id}`, auth)
          .then((response) => {
            const dadosBancarios = response.data;
            setLaInfosDadosBancarios(dadosBancarios.data);
          });

        // Salvar dados do telefone
        api
          .get('/fone/licenciado?png=999999', auth)
          .then((response) => {
            const result = response.data.data;
            const dadosTelefones = result.filter(
              (dado) => dado.licenciado_autorizado_id === idLA
            );
            setLaInfosTelefoneEstabelecimento(dadosTelefones);
          });
      }
    }

    requestLA();
  }, [idLA, location.pathname, loggedUser.role, userToken]);

  // CEP START
  const onBlurCepPes = useCallback(
    async (e) => {
      const typedValue = e.target.value;

      const cep = typedValue?.replace(/[^0-9]/g, '');
      if (cep?.length !== 8) {
        return;
      }

      await fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json())
        .then((data) => {
          setValue('pessoal_rua', data.logradouro);
          setValue('pessoal_bairro', data.bairro);
          setValue('pessoal_uf', data.uf);
          setValue('pessoal_cidade', data.localidade);
        });
    },
    [setValue]
  );

  const onBlurCepEst = useCallback(
    async (e) => {
      const typedValue = e.target.value;

      const cep = typedValue?.replace(/[^0-9]/g, '');
      if (cep?.length !== 8) {
        return;
      }

      await fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json())
        .then((data) => {
          setValue('estabelecimento_rua', data.logradouro);
          setValue('estabelecimento_bairro', data.bairro);
          setValue('estabelecimento_uf', data.uf);
          setValue('estabelecimento_cidade', data.localidade);
        });
    },
    [setValue]
  );
  // CEP END

  // Abas START
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [tab4, setTab4] = useState(false);

  const open1 = () => {
    setTab1(true);
    setTab2(false);
    setTab3(false);
    setTab4(false);
  };
  const open2 = () => {
    setTab1(false);
    setTab2(true);
    setTab3(false);
    setTab4(false);
  };
  const open3 = () => {
    setTab1(false);
    setTab2(false);
    setTab3(true);
    setTab4(false);
  };
  const open4 = () => {
    setTab1(false);
    setTab2(false);
    setTab3(false);
    setTab4(true);
  };
  // Abas END

  const [placeholderCpfCnpj, setPlaceholderCpfCnpj] = useState('');

  const changeContaJuridica = useCallback(() => {
    const inputValue = getValues('conta_juridica');

    if (inputValue === '') {
      setPlaceholderCpfCnpj('');
    }

    if (inputValue === '0') {
      setPlaceholderCpfCnpj('Digite o CPF');
    }
    if (inputValue === '1') {
      setPlaceholderCpfCnpj('Digite o CNPJ');
    }
  }, [getValues]);

  // Faz a validação do CPF
  function validarCPF() {
    const valorDigitado = getValues('cpf');
    const stringDigitada = valorDigitado
      .replaceAll('.', '')
      .replace('-', '');

    let Soma;
    let Resto;
    Soma = 0;

    if (stringDigitada === '00000000000') {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      Soma =
        Soma +
        parseInt(stringDigitada.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
    }

    if (Resto === 10 || Resto === 11) {
      Resto = 0;
    }
    if (Resto !== parseInt(stringDigitada.substring(9, 10))) {
      setError('cpf', {
        type: 'manual',
        message: 'O CPF digitado é inválido.',
      });

      return;
    }

    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      Soma =
        Soma +
        parseInt(stringDigitada.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;
    }

    if (Resto === 10 || Resto === 11) {
      Resto = 0;
    }
    if (Resto !== parseInt(stringDigitada.substring(10, 11))) {
      setError('cpf', {
        type: 'manual',
        message: 'O CPF digitado é inválido.',
      });

      return;
    }

    clearErrors('cpf');
    return;
  }

  // Valida se a data é maior que a atual
  const validDate = useCallback(() => {
    const date = getValues('data_nascimento');
    const dateTimestamp = new Date(date).getTime();

    const today = Date.now();

    if (dateTimestamp > today) {
      setError('data_nascimento', {
        type: 'manual',
        message: 'A data não pode ser maior que o dia atual.',
      });
    }
  }, [getValues, setError]);

  // Faz a validação do CNPJ
  function validarCNPJ() {
    const valorDigitado = getValues('cnpj');
    const stringDigitada = valorDigitado
      .replaceAll('.', '')
      .replace('/', '')
      .replace('-', '');

    // console.log('valorDigitado', typeof(valorDigitado))
    // console.log('stringDigitada', typeof(stringDigitada))

    // cnpj = cnpj.replace(/[^\d]+/g,'');
    // if(stringDigitada === '') { return false };

    if (stringDigitada.length !== 14) {
      return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (
      stringDigitada === '00000000000000' ||
      stringDigitada === '11111111111111' ||
      stringDigitada === '22222222222222' ||
      stringDigitada === '33333333333333' ||
      stringDigitada === '44444444444444' ||
      stringDigitada === '55555555555555' ||
      stringDigitada === '66666666666666' ||
      stringDigitada === '77777777777777' ||
      stringDigitada === '88888888888888' ||
      stringDigitada === '99999999999999'
    ) {
      setError('cnpj', {
        type: 'manual',
        message: 'O CNPJ digitado é inválido.',
      });

      return;
    }

    let tamanho;
    let numeros;
    let digitos;
    let soma;
    let pos;
    let resultado;

    // Valida DVs
    tamanho = stringDigitada.length - 2;
    numeros = stringDigitada.substring(0, tamanho);
    digitos = stringDigitada.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado.toString() !== digitos.charAt(0)) {
      // Erro no primeiro dígito
      setError('cnpj', {
        type: 'manual',
        message: 'O CNPJ digitado é inválido.',
      });

      return;
    }

    tamanho = tamanho + 1;
    numeros = stringDigitada.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado.toString() !== digitos.charAt(1)) {
      // Erro no segundo dígito
      setError('cnpj', {
        type: 'manual',
        message: 'O CNPJ digitado é inválido.',
      });

      return;
    }

    // CNPJ ok
    clearErrors('cnpj');
    return;
  }

  // Faz a validação do E-mail
  function validarEmail() {
    const valorDigitado = getValues('email');
    const indexArroba = valorDigitado.indexOf('@');

    const usuario = valorDigitado.substring(0, indexArroba);
    const dominio = valorDigitado.substring(
      indexArroba + 1,
      valorDigitado.length
    );

    if (
      usuario.length >= 1 &&
      dominio.length >= 3 &&
      usuario.search('@') === -1 &&
      dominio.search('@') === -1 &&
      usuario.search(' ') === -1 &&
      dominio.search(' ') === -1 &&
      dominio.search('.') !== -1 &&
      dominio.indexOf('.') >= 1 &&
      dominio.lastIndexOf('.') < dominio.length - 1
    ) {
      // e-mail válido
      clearErrors('email');
    } else {
      // e-mail inválido
      setError('email', {
        type: 'manual',
        message: 'O E-mail digitado é inválido.',
      });
    }
  }

  // Cargos
  const cargoOptions = [
    {value: 'Proprietário', label: 'Proprietário'},
    {value: 'Diretor', label: 'Diretor'},
    {value: 'Gerente', label: 'Gerente'},
    {value: 'Coordenador', label: 'Coordenador'},
    {value: 'Supervisor', label: 'Supervisor'},
    {value: 'Vendedor', label: 'Vendedor'},
  ];

  // Lista de bancos
  const listaBancos = BankList;

  // Sessões CNAE
  const sessãoCnaeOptions = [
    {
      value: 'A',
      label:
        'Agricultura, Pecuária, Produção Florestal, Pesca e Aqüicultura',
    },
    {value: 'B', label: 'Indústrias Extrativas'},
    {value: 'C', label: 'Indústrias de Transformação'},
    {value: 'D', label: 'Eletricidade e Gás'},
    {
      value: 'E',
      label:
        'Água, Esgoto, Atividades de Gestão de Resíduos e Descontaminação',
    },
    {value: 'F', label: 'Construção'},
    {
      value: 'G',
      label:
        'Comércio; Reparação de Veículos Automotores e Motocicletas',
    },
    {value: 'H', label: 'Transporte, Armazenagem e Correio'},
    {value: 'I', label: 'Alojamento e Alimentação'},
    {value: 'J', label: 'Informação e Comunicação'},
    {
      value: 'K',
      label:
        'Atividades Financeiras, de Seguros e Serviços Relacionados',
    },
    {value: 'L', label: 'Atividades Imobiliárias'},
    {
      value: 'M',
      label: 'Atividades Profissionais, Científicas e Técnicas',
    },
    {
      value: 'N',
      label: 'Atividades Administrativas e Serviços Complementares',
    },
    {
      value: 'O',
      label: 'Administração Pública, Defesa e Seguridade Social',
    },
    {value: 'P', label: 'Educação'},
    {value: 'Q', label: 'Saúde Humana e Serviços Sociais'},
    {value: 'R', label: 'Artes, Cultura, Esporte e Recreação'},
    {value: 'S', label: 'Outras Atividades de Serviços'},
    {value: 'T', label: 'Serviços Domésticos'},
    {
      value: 'U',
      label:
        'Organismos Internacionais e Outras Instituições Extraterritoriais',
    },
  ];

  // Filtrar Atividades CNAE por sessões START
  const [cnaeSubclasses, setCnaeSubclasses] = useState([]);

  /* const getCnaeActivities = useCallback(() => {
    const sessaoValue = getValues("sessao_cnae");

    fetch(`https://servicodados.ibge.gov.br/api/v2/cnae/secoes/${sessaoValue}/subclasses`)
    .then((res) => res.json())
    .then((data) => {
      const descriptions = data.map(description => {
        return {
          value: description.id.replace(/^(\d{4})(\d)(\d{2})$/, "$1-$2/$3"),
          label: description.descricao.toLowerCase()
        }
      })

      const descriptionsFilter = descriptions.filter(function (a) {
        return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
      }, Object.create(null)) 

      // console.log(descriptionsFilter)
      setCnaeSubclasses(descriptionsFilter)
    });
  }, [getValues]); */

  const listaCNAE = CnaeList;

  const setCNAE = useCallback(() => {
    const sessaoValue = getValues('sessao_cnae');

    const atividadesCNAE = listaCNAE.filter(
      (item) => item.secao === sessaoValue
    );

    setCnaeSubclasses(atividadesCNAE);
  }, [getValues, listaCNAE]);
  // Filtrar Atividades CNAE por sessões END

  // pre-setar os dados
  useEffect(() => {
    setValue('name', laInfosUser.name);
    setValue('email', laInfosUser.email);
    setValue('cargo', laInfos.cargo);
    setValue('estado_civil', laInfos.estado_civil);
    setValue('rg', laInfos.rg);
    setValue('cpf', laInfos.cpf);
    setValue('data_nascimento', laInfos.data_nascimento);
    setValue('razao_social', laInfos.razao_social);
    setValue('tipo_empresa', laInfos.tipo_de_empresa);
    setValue('cnpj', laInfos.cnpj);
    setValue('nome_fantasia', laInfos.nome_fantasia);
    setValue('serial_number', laInfos.serial_number);
    setValue('sessao_cnae', laInfos.sessao_cnae);
    setCNAE();
    setValue('pessoal_rua', laInfosEnderecoPessoa.rua);
    setValue('pessoal_numero', laInfosEnderecoPessoa.numero);
    setValue(
      'pessoal_complemento',
      laInfosEnderecoPessoa.complemento
    );
    setValue('pessoal_bairro', laInfosEnderecoPessoa.bairro);
    setValue('pessoal_cidade', laInfosEnderecoPessoa.cidade);
    setValue('pessoal_cep', laInfosEnderecoPessoa.cep);
    setValue('pessoal_uf', laInfosEnderecoPessoa.uf);
    setValue('pessoal_telefone', telefonePessoal);
    //
    setValue(
      'estabelecimento_rua',
      laInfosEnderecoEstabelecimento.rua
    );
    setValue(
      'estabelecimento_numero',
      laInfosEnderecoEstabelecimento.numero
    );
    setValue(
      'estabelecimento_complemento',
      laInfosEnderecoEstabelecimento.complemento
    );
    setValue(
      'estabelecimento_bairro',
      laInfosEnderecoEstabelecimento.bairro
    );
    setValue(
      'estabelecimento_cidade',
      laInfosEnderecoEstabelecimento.cidade
    );
    setValue(
      'estabelecimento_cep',
      laInfosEnderecoEstabelecimento.cep
    );
    setValue('estabelecimento_uf', laInfosEnderecoEstabelecimento.uf);
    setValue('estabelecimento_telefone', telefoneEstabelecimento);
    //
    setValue('nome_banco', laInfosDadosBancarios.nome_banco);
    setValue('agencia', laInfosDadosBancarios.agencia);
    setValue('numero_conta', laInfosDadosBancarios.numero_conta);
    setValue('nome_titular', laInfosDadosBancarios.nome_titular);
    setValue('tipo_conta', laInfosDadosBancarios.tipo_conta);
    setValue('conta_juridica', laInfosDadosBancarios.conta_juridica);
    setValue('cpf_cnpj_conta', laInfosDadosBancarios.cpf_cnpj);
    setValue('operacao', laInfosDadosBancarios.operacao);
    setValue('numero_banco', laInfosDadosBancarios.numero_banco);
    //
    setValue('tipo_chave_pix', laInfosDadosBancarios.tipo_chave_pix);
    setValue('chave_pix', laInfosDadosBancarios.chave_pix);
    //
    setValue('cnae', laInfos.cnae);
    setValue('parceiro_id', laInfos.parceiro_id);
  }, [
    laInfos.cargo,
    laInfosDadosBancarios.chave_pix,
    laInfosDadosBancarios.tipo_chave_pix,
    laInfos.cnpj,
    laInfos.cpf,
    laInfos.data_nascimento,
    laInfos.estado_civil,
    laInfos.nome_fantasia,
    laInfos.razao_social,
    laInfos.rg,
    laInfosUser.email,
    laInfosUser.name,
    laInfosDadosBancarios.agencia,
    laInfosDadosBancarios.conta_juridica,
    laInfosDadosBancarios.nome_banco,
    laInfosDadosBancarios.nome_titular,
    laInfosDadosBancarios.numero_conta,
    laInfosDadosBancarios.operacao,
    laInfosDadosBancarios.tipo_conta,
    laInfosEnderecoEstabelecimento.bairro,
    laInfosEnderecoEstabelecimento.cep,
    laInfosEnderecoEstabelecimento.cidade,
    laInfosEnderecoEstabelecimento.complemento,
    laInfosEnderecoEstabelecimento.numero,
    laInfosEnderecoEstabelecimento.rua,
    laInfosEnderecoEstabelecimento.uf,
    laInfosEnderecoPessoa.bairro,
    laInfosEnderecoPessoa.cep,
    laInfosEnderecoPessoa.cidade,
    laInfosEnderecoPessoa.complemento,
    laInfosEnderecoPessoa.numero,
    laInfosEnderecoPessoa.rua,
    laInfosEnderecoPessoa.uf,
    laInfos.cnae,
    laInfos.serial_number,
    laInfos.sessao_cnae,
    laInfos.tipo_de_empresa,
    laInfosDadosBancarios.cpf_cnpj,
    laInfosDadosBancarios.numero_banco,
    laInfos.parceiro_id,
    telefoneEstabelecimento,
    telefonePessoal,
    setCNAE,
    setValue,
  ]);

  const setNumeroBanco = useCallback(() => {
    const nomeBanco = getValues('nome_banco');

    /* if (nomeBanco === "Banco do Brasil S.A.") {
      setValue('numero_banco', "001");
      return
    }
    if (nomeBanco === "Banco Bradesco S.A.") {
      setValue('numero_banco', "237");
      return
    }
    if (nomeBanco === "Caixa Econômica Federal") {
      setValue('numero_banco', "104");
      return
    }
    if (nomeBanco === "Banco Santander (Brasil) S. A.") {
      setValue('numero_banco', "033");
      return
    }
    if (nomeBanco === "Itaú Unibanco S.A.") {
      setValue('numero_banco', "341");
      return
    } */

    const numerobanco = listaBancos.filter(
      (item) => item.label === nomeBanco
    );
    setValue('numero_banco', numerobanco[0].value);
  }, [getValues, setValue, listaBancos]);

  function mascaraPix(e) {
    if (getValues('tipo_chave_pix') === 'CPF') {
      cpfMask(e);
    }
    if (getValues('tipo_chave_pix') === 'CNPJ') {
      cnpjMask(e);
    }
    if (getValues('tipo_chave_pix') === 'TELEFONE') {
      telefoneMask(e);
    }
    if (getValues('tipo_chave_pix') === 'EMAIL') {
      e.currentTarget.maxLength = 99;
    }
    if (getValues('tipo_chave_pix') === 'CHAVE') {
      e.currentTarget.maxLength = 99;
    }
  }

  return (
    <Container>
      <NavegaçãoAbas>
        <BotãoAba tab1={tab1} onClick={open1}>
          01
        </BotãoAba>
        <BotãoAba tab2={tab2} onClick={open2}>
          02
        </BotãoAba>
        <BotãoAba tab3={tab3} onClick={open3}>
          03
        </BotãoAba>
        <BotãoAba tab4={tab4} onClick={open4}>
          04
        </BotãoAba>
      </NavegaçãoAbas>

      <Abas>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Aba1 tab1={tab1}>
            <Row>
              <InputArea>
                <label>*Nome</label>
                <InputContainer
                  style={{borderColor: errors.name && 'red'}}
                >
                  <input
                    name="name"
                    type="text"
                    placeholder="Digite seu nome"
                    ref={register({required: true})}
                  />
                  {errors.nome && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*E-mail</label>
                <InputContainer
                  style={{borderColor: errors.email && 'red'}}
                >
                  <input
                    name="email"
                    type="text"
                    placeholder="exemplo@exemplo.com"
                    ref={register({required: 'Campo obrigatório'})}
                    onBlur={() => validarEmail()}
                  />
                  {errors.email && (
                    <Error title={errors.email.message}>
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row>

            <Row>
              <InputArea>
                <label>*RG</label>
                <InputContainer
                  style={{borderColor: errors.rg && 'red'}}
                >
                  <input
                    name="rg"
                    type="text"
                    placeholder="Digite seu RG"
                    ref={register({required: true})}
                    onKeyUp={(e) => rgMask(e)}
                  />
                  {errors.rg && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*CPF</label>
                <InputContainer
                  style={{borderColor: errors.cpf && 'red'}}
                >
                  <input
                    name="cpf"
                    type="text"
                    placeholder="Digite seu CPF (apenas números)"
                    ref={register({required: true})}
                    onKeyUp={(e) => cpfMask(e)}
                    onBlur={() => validarCPF()}
                  />
                  {errors.cpf && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row>

            <Row>
              <InputArea>
                <label>*Data de nascimento</label>
                <InputContainer
                  style={{
                    borderColor: errors.data_nascimento && 'red',
                  }}
                >
                  <input
                    name="data_nascimento"
                    type="date"
                    placeholder="Digite sua data de nascimento"
                    ref={register({required: 'Campo obrigatório'})}
                    onBlur={validDate}
                  />
                  {errors.data_nascimento && (
                    <Error title={errors.data_nascimento.message}>
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Estado civil</label>
                <InputContainer
                  style={{borderColor: errors.estado_civil && 'red'}}
                >
                  <select
                    name="estado_civil"
                    placeholder="Selecione"
                    ref={register({required: true})}
                  >
                    <option value="">Selecione</option>
                    <option value="Solteiro">Solteiro</option>
                    <option value="Casado">Casado</option>
                    <option value="Divorciado">Divorciado</option>
                    <option value="Viúvo">Viúvo</option>
                  </select>
                  {errors.estado_civil && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row>

            <Row>
              <InputArea>
                <label>*Selecione um responsável ou licenciado</label>
                <InputContainer
                  style={{borderColor: errors.parceiro_id && 'red'}}
                >
                  <select
                    name="parceiro_id"
                    placeholder="Selecione"
                    ref={register({required: true})}
                  >
                    <option value="">Selecione</option>

                    {parceiros.map((parceiro) => (
                      <option key={parceiro._id} value={parceiro.id}>
                        {parceiro.nome_fantasia}
                      </option>
                    ))}
                  </select>
                  {errors.estado_civil && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row>

            <ButtonRow>
              <button type="button" onClick={open2}>
                Avançar
              </button>
            </ButtonRow>
          </Aba1>

          <Aba2 tab2={tab2}>
            <Row3>
              <InputArea>
                <label>*Cargo</label>
                <InputContainer
                  style={{borderColor: errors.cargo && 'red'}}
                >
                  <select
                    name="cargo"
                    placeholder="Selecione"
                    ref={register({required: true})}
                  >
                    <option value="">Selecione</option>
                    {cargoOptions.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </select>
                  {errors.cargo && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Razão social</label>
                <InputContainer
                  style={{borderColor: errors.razao_social && 'red'}}
                >
                  <input
                    name="razao_social"
                    type="text"
                    placeholder="Digite sua data de nascimento"
                    ref={register({required: true})}
                  />
                  {errors.razao_social && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Nome fantasia</label>
                <InputContainer
                  style={{borderColor: errors.nome_fantasia && 'red'}}
                >
                  <input
                    name="nome_fantasia"
                    type="text"
                    placeholder="Digite sua data de nascimento"
                    ref={register({required: true})}
                  />
                  {errors.nome_fantasia && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row3>

            <Row>
              <InputArea>
                <label>*Sessão (CNAE)</label>
                <InputContainer
                  style={{borderColor: errors.sessao_cnae && 'red'}}
                >
                  <select
                    name="sessao_cnae"
                    placeholder="Selecione"
                    // onChange={getCnaeActivities}
                    onChange={() => setCNAE()}
                    ref={register({required: true})}
                  >
                    <option value="">Selecione</option>
                    {sessãoCnaeOptions.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </select>
                  {errors.sessao_cnae && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Atividade econômica (CNAE)</label>
                <InputContainer
                  style={{borderColor: errors.cnae && 'red'}}
                >
                  <select
                    name="cnae"
                    placeholder="Selecione"
                    ref={register({required: true})}
                  >
                    <option value="">Selecione</option>
                    {/* {cnaeSubclasses.map(item => (
                      <option value={item.value}>{item.label}</option>
                    ))} */}
                    {cnaeSubclasses.map((item) => (
                      <option value={item.cod}>{item.desc}</option>
                    ))}
                  </select>
                  {errors.cnae && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row>

            <Row3>
              <InputArea>
                <label>*Tipo de empresa</label>
                <InputContainer
                  style={{borderColor: errors.tipo_empresa && 'red'}}
                >
                  <select
                    name="tipo_empresa"
                    placeholder="Selecione"
                    ref={register({required: true})}
                  >
                    <option value="">Selecione</option>
                    <option value="EI - Empresário Individual">
                      EI - Empresário Individual
                    </option>
                    <option value="MEI - Microempreendedor Individual">
                      MEI - Microempreendedor Individual
                    </option>
                    <option value="LIDA - Sociedade LTDA">
                      LIDA - Sociedade LTDA
                    </option>
                    <option value="S/A - Sociedade Anônima">
                      S/A - Sociedade Anônima
                    </option>
                    <option value="EIRELI - Empresa Individual de Responsabilidade Limitada">
                      EIRELI - Empresa Individual de Responsabilidade
                      Limitada
                    </option>
                  </select>
                  {errors.tipo_empresa && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*CNPJ</label>
                <InputContainer
                  style={{borderColor: errors.cnpj && 'red'}}
                >
                  <input
                    name="cnpj"
                    type="text"
                    placeholder="Digite o CNPJ (apenas números)"
                    ref={register({required: true})}
                    onKeyUp={(e) => cnpjMask(e)}
                    onBlur={() => validarCNPJ()}
                  />
                  {errors.cnpj && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>Serial number</label>
                <InputContainer>
                  <input
                    name="serial_number"
                    type="text"
                    placeholder="xxxxxx"
                    ref={register}
                  />
                </InputContainer>
              </InputArea>
            </Row3>

            <ButtonRow>
              <button type="button" onClick={open3}>
                Avançar
              </button>
              <button type="button" className="back" onClick={open1}>
                Voltar
              </button>
            </ButtonRow>
          </Aba2>

          <Aba3 tab3={tab3}>
            <h4>Endereço pessoal</h4>
            <Row4>
              <InputArea>
                <label>*CEP</label>
                <InputContainer
                  style={{borderColor: errors.pessoal_cep && 'red'}}
                >
                  <input
                    name="pessoal_cep"
                    type="text"
                    placeholder="Apenas números (8 dígitos)"
                    ref={register({required: true})}
                    onKeyUp={(e) => cepMask(e)}
                    onBlur={(e) => onBlurCepPes(e)}
                  />
                  {errors.pessoal_cep && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Rua</label>
                <InputContainer
                  style={{borderColor: errors.pessoal_rua && 'red'}}
                >
                  <input
                    name="pessoal_rua"
                    type="text"
                    placeholder="Digite o enredeço"
                    ref={register({required: true})}
                  />
                  {errors.pessoal_rua && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Bairro</label>
                <InputContainer
                  style={{
                    borderColor: errors.pessoal_bairro && 'red',
                  }}
                >
                  <input
                    name="pessoal_bairro"
                    type="text"
                    placeholder="Digite o bairro"
                    ref={register({required: true})}
                  />
                  {errors.pessoal_bairro && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Número</label>
                <InputContainer
                  style={{
                    borderColor: errors.pessoal_numero && 'red',
                  }}
                >
                  <input
                    name="pessoal_numero"
                    type="number"
                    placeholder="xxxx"
                    ref={register({required: true})}
                  />
                  {errors.pessoal_numero && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row4>

            <Row4>
              <InputArea>
                <label>Complemento</label>
                <InputContainer
                  style={{
                    borderColor: errors.pessoal_complemento && 'red',
                  }}
                >
                  <input
                    name="pessoal_complemento"
                    type="text"
                    placeholder="Ex. Casa"
                    ref={register}
                  />
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*UF</label>
                <InputContainer
                  style={{borderColor: errors.pessoal_uf && 'red'}}
                >
                  <select
                    name="pessoal_uf"
                    placeholder="Selecione"
                    ref={register({required: true})}
                  >
                    <option value="">Selecione</option>
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PR">PR</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SP">SP</option>
                    <option value="SE">SE</option>
                    <option value="TO">TO</option>
                  </select>
                  {errors.pessoal_uf && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Cidade</label>
                <InputContainer
                  style={{
                    borderColor: errors.pessoal_cidade && 'red',
                  }}
                >
                  <input
                    name="pessoal_cidade"
                    type="text"
                    placeholder="Digite o nome da cidade"
                    ref={register({required: true})}
                  />
                  {errors.pessoal_cidade && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Telefone</label>
                <InputContainer
                  style={{
                    borderColor: errors.pessoal_telefone && 'red',
                  }}
                >
                  <input
                    name="pessoal_telefone"
                    type="text"
                    placeholder="Apenas números"
                    ref={register({required: true})}
                    onKeyUp={(e) => telefoneMask(e)}
                  />
                  {errors.pessoal_telefone && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row4>

            <Separator />

            <h4>Endereço do estabelecimento</h4>

            <Row4>
              <InputArea>
                <label>*CEP</label>
                <InputContainer
                  style={{
                    borderColor: errors.estabelecimento_cep && 'red',
                  }}
                >
                  <input
                    name="estabelecimento_cep"
                    type="text"
                    placeholder="Apenas números (8 dígitos)"
                    ref={register({required: true})}
                    onKeyUp={(e) => cepMask(e)}
                    onBlur={(e) => onBlurCepEst(e)}
                  />
                  {errors.estabelecimento_cep && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Rua</label>
                <InputContainer
                  style={{
                    borderColor: errors.estabelecimento_rua && 'red',
                  }}
                >
                  <input
                    name="estabelecimento_rua"
                    type="text"
                    placeholder="Digite o enredeço"
                    ref={register({required: true})}
                  />
                  {errors.estabelecimento_rua && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Bairro</label>
                <InputContainer
                  style={{
                    borderColor:
                      errors.estabelecimento_bairro && 'red',
                  }}
                >
                  <input
                    name="estabelecimento_bairro"
                    type="text"
                    placeholder="Digite o bairro"
                    ref={register({required: true})}
                  />
                  {errors.estabelecimento_bairro && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Número</label>
                <InputContainer
                  style={{
                    borderColor:
                      errors.estabelecimento_numero && 'red',
                  }}
                >
                  <input
                    name="estabelecimento_numero"
                    type="number"
                    placeholder="xxxx"
                    ref={register({required: true})}
                  />
                  {errors.estabelecimento_numero && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row4>

            <Row4>
              <InputArea>
                <label>Complemento</label>
                <InputContainer
                  style={{
                    borderColor:
                      errors.estabelecimento_complemento && 'red',
                  }}
                >
                  <input
                    name="estabelecimento_complemento"
                    type="text"
                    placeholder="Ex. Casa"
                    ref={register}
                  />
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*UF</label>
                <InputContainer
                  style={{
                    borderColor: errors.estabelecimento_uf && 'red',
                  }}
                >
                  <select
                    name="estabelecimento_uf"
                    placeholder="Selecione"
                    ref={register({required: true})}
                  >
                    <option value="">Selecione</option>
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PR">PR</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SP">SP</option>
                    <option value="SE">SE</option>
                    <option value="TO">TO</option>
                  </select>
                  {errors.estabelecimento_uf && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Cidade</label>
                <InputContainer
                  style={{
                    borderColor:
                      errors.estabelecimento_cidade && 'red',
                  }}
                >
                  <input
                    name="estabelecimento_cidade"
                    type="text"
                    placeholder="Digite o nome da cidade"
                    ref={register({required: true})}
                  />
                  {errors.estabelecimento_cidade && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Telefone</label>
                <InputContainer
                  style={{
                    borderColor:
                      errors.estabelecimento_telefone && 'red',
                  }}
                >
                  <input
                    name="estabelecimento_telefone"
                    type="text"
                    placeholder="Apenas números"
                    ref={register({required: true})}
                    onKeyUp={(e) => telefoneMask(e)}
                  />
                  {errors.estabelecimento_telefone && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row4>

            <ButtonRow>
              <button type="button" onClick={open4}>
                Avançar
              </button>
              <button type="button" className="back" onClick={open2}>
                Voltar
              </button>
            </ButtonRow>
          </Aba3>

          <Aba4 tab4={tab4}>
            <BoxInfo>
              <span>
                Caso os campos da chave Pix estejam preenchidos, os
                demais dados <b>não serão obrigatórios</b>.
              </span>
              <br />
              <span>
                Porém, se você incluir algum dos demais dados
                bancários <b>você terá que preencher todos</b>.
              </span>
            </BoxInfo>

            <Row>
              <InputArea>
                <label>Tipo de Chave Pix</label>
                <InputContainer
                  style={{
                    borderColor: errors.tipo_chave_pix && 'red',
                  }}
                >
                  <select
                    name="tipo_chave_pix"
                    placeholder="Selecione"
                    onChange={() => setValue('chave_pix', '')}
                    ref={register}
                  >
                    <option value="">Selecione</option>
                    <option value="CPF">CPF</option>
                    <option value="CNPJ">CNPJ</option>
                    <option value="TELEFONE">TELEFONE</option>
                    <option value="EMAIL">EMAIL</option>
                    <option value="CHAVE">CHAVE</option>
                  </select>
                  {errors.tipo_chave_pix && (
                    <Error title={errors.tipo_chave_pix.message}>
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>Chave Pix</label>
                <InputContainer
                  style={{borderColor: errors.chave_pix && 'red'}}
                >
                  <input
                    name="chave_pix"
                    type="text"
                    placeholder="Digite sua chave"
                    onKeyUp={(e) => mascaraPix(e)}
                    ref={register}
                  />
                  {errors.chave_pix && (
                    <Error title={errors.chave_pix.message}>
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row>

            <Row>
              <InputArea>
                <label>*Nome do titular</label>
                <InputContainer
                  style={{borderColor: errors.nome_titular && 'red'}}
                >
                  <input
                    name="nome_titular"
                    type="text"
                    placeholder="Digite seu nome"
                    ref={register}
                  />
                  {errors.nome_titular && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Nome do banco</label>
                <InputContainer
                  style={{borderColor: errors.nome_banco && 'red'}}
                >
                  <select
                    name="nome_banco"
                    placeholder="Selecione"
                    onChange={setNumeroBanco}
                    ref={register}
                  >
                    <option value="">Selecione</option>

                    {listaBancos.map((item) => (
                      <option value={item.label}>
                        {item.value + ' - ' + item.label}
                      </option>
                    ))}
                  </select>
                  {errors.nome_banco && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
                <input
                  type="text"
                  name="numero_banco"
                  ref={register}
                  hidden
                />
              </InputArea>
            </Row>

            <Row3>
              <InputArea>
                <label>*Agência</label>
                <InputContainer
                  style={{borderColor: errors.agencia && 'red'}}
                >
                  <input
                    name="agencia"
                    type="number"
                    placeholder="xxxx"
                    ref={register}
                  />
                  {errors.agencia && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Número da conta</label>
                <InputContainer
                  style={{borderColor: errors.numero_conta && 'red'}}
                >
                  <input
                    name="numero_conta"
                    type="text"
                    placeholder="Apenas números"
                    ref={register}
                    onKeyUp={(e) => contaBancariaMask(e)}
                  />
                  {errors.numero_conta && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*Conta</label>
                <InputContainer
                  style={{borderColor: errors.tipo_conta && 'red'}}
                >
                  <select
                    name="tipo_conta"
                    placeholder="Selecione"
                    ref={register}
                  >
                    <option value="">Selecione</option>
                    <option value="Corrente">Corrente</option>
                    <option value="Poupança">Poupança</option>
                  </select>
                  {errors.tipo_conta && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>
            </Row3>

            <Row3>
              <InputArea>
                <label>*Selecione o Tipo de conta</label>
                <InputContainer
                  style={{
                    borderColor: errors.conta_juridica && 'red',
                  }}
                >
                  <select
                    name="conta_juridica"
                    placeholder="Selecione"
                    onChange={changeContaJuridica}
                    ref={register}
                  >
                    <option value="">Selecione</option>
                    <option value="0">Pessoa Física</option>
                    <option value="1">Pessoa Jurídica</option>
                  </select>
                  {errors.conta_juridica && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>*CPF/CNPJ</label>
                <InputContainer
                  style={{
                    borderColor: errors.cpf_cnpj_conta && 'red',
                  }}
                >
                  <input
                    name="cpf_cnpj_conta"
                    onKeyUp={
                      getValues('conta_juridica') === '0'
                        ? (e) => cpfMask(e)
                        : (e) => cnpjMask(e)
                    }
                    placeholder={placeholderCpfCnpj}
                    ref={register}
                  />
                  {errors.cpf_cnpj_conta && (
                    <Error title="Campo obrigatório">
                      <MdErrorOutline color="c53030" size={20} />
                    </Error>
                  )}
                </InputContainer>
              </InputArea>

              <InputArea>
                <label>Operação</label>
                <InputContainer>
                  <input
                    name="operacao"
                    type="number"
                    placeholder="xxx"
                    ref={register}
                  />
                </InputContainer>
              </InputArea>
            </Row3>

            <ButtonRow>
              {isLoading ? (
                <button
                  type="submit"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 155,
                    height: 37,
                  }}
                >
                  <LoadingIcons.Oval
                    speed={1.2}
                    style={{width: 15, marginRight: 8}}
                    strokeWidth={4}
                  />
                  Carregando
                </button>
              ) : (
                <button type="submit">Finalizar</button>
              )}
            </ButtonRow>
          </Aba4>
        </form>
      </Abas>
    </Container>
  );
}

export default FormEditLA;
