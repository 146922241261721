import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { MdErrorOutline } from 'react-icons/md';
import filesize from 'filesize';
import { uniqueId } from "lodash";
import moment from 'moment';

import api from '../../services/api';

// Context API
import { useToast } from '../../hooks/ToastContext';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import LoadingContent from '../../components/LoadingContent';

// JSONs
import BankList from '../../assets/json/bancos.json';
import CodigoBarrasIcon from '../../assets/images/codigo_barras.svg';
import editIcon from '../../assets/images/edit_icon.svg';

// Comprovante
import UploadComprovante from '../../components/Upload/comprovante';
import InfoComprovante from '../../components/UploadInfo/comprovante';

// Termo
import UploadTermo from '../../components/Upload/termo';
import InfoTermo from '../../components/UploadInfo/termo';

// Documento com foto
import UploadDocFoto from '../../components/Upload/docFoto';
import InfoDocFoto from '../../components/UploadInfo/docFoto';

// Documento com foto
import UploadBoleto from '../../components/Upload/boleto';
import InfoBoleto from '../../components/UploadInfo/boleto';

// Máscaras
import contaBancariaMask from '../../components/InputMasks/contaBancariaMask';
import cpfMask from '../../components/InputMasks/cpfMask';
import rgMask from '../../components/InputMasks/rgMask';
import cnpjMask from '../../components/InputMasks/cnpjMask';
import telefoneMask from '../../components/InputMasks/telefoneMask';

import {
  Container,
  Page,
  Content,
  RowBox1,
  Box,
  BoletoOptions,
  InputArea,
  Row,
  Row3,
  RowBox2,
  RowBox3,
  InputContainer,
  Error,
  UploadContainer,
  RowBoxBoleto,
} from './styles';

function EditarTransacao() {
  const history = useHistory();
  const location = useLocation();

  const { addToast } = useToast();

  const idTransacao = location.pathname.replace('/transacoes/editar/', '').split('').reverse().join('');

  const [transacaoInfos, setTransacaoInfos] = useState([]);
  const [transacaoInfosCliente, setTransacaoInfosCliente] = useState([]);
  const [transacaoInfosBoleto, setTransacaoInfosBoleto] = useState([]);
  const [transacaoInfosDadosBancarios, setTransacaoInfosDadosBancarios] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const [isLoading, setIsLoading] = useState(true);

  const { register, handleSubmit, getValues, setValue, setError, clearErrors, errors } = useForm();
  const onSubmit = async (data) => {

    // Bearer token
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    // const quantidade_boleto = data.tipo_transacao === "Depósito bancário" ? 0 : data.quantidade_boletos;

    try {
      setIsLoading(true);

      // Atualiza os dados da transação
      await api.put(`/transacao/${transacaoInfos.id}`, {
        numero_autorizacao: data.numero_autorizacao,
        numero_parcelas: transacaoInfos.numero_parcelas,
        valor_pagamento: transacaoInfos.valor_pagamento,
        valor_final: transacaoInfos.valor_total,
        valor_total: transacaoInfos.valor_total,
        valor_parcela: transacaoInfos.valor_parcela,
        valor_acrescimo: transacaoInfos.valor_acrescimo,
        bandeira: transacaoInfos.bandeira,
        quantidade_boleto: transacaoInfos.quantidade_boleto,
        status: transacaoInfos.status,
        //
        id_doc_autorizacao: data.comprovante === "" ? transacaoInfos.id_doc_autorizacao : data.comprovante,
        id_doc_termo: data.termo === "" ? transacaoInfos.id_doc_termo : data.termo,
        id_doc_documento_foto: data.docFoto === "" ? transacaoInfos.id_doc_documento_foto : data.docFoto,
        // id_doc_boleto: transacaoInfos.id_doc_boleto,
        //
        conjunto_de_taxa: transacaoInfos.conjunto_de_taxa,
        estabelecimento_id: transacaoInfos.estabelecimento_id,
        maquineta: transacaoInfos.maquineta,
        taxa_servico: transacaoInfos.taxa_servico,
      }, auth);

      // Atualiza os dados do Cliente Transação
      await api.put(`/transacao/cliente/${transacaoInfos.id_cliente}`, {
        nome: data.nome,
        email: data.email,
        cpf: data.cpf,
        rg: data.numero_rg,
        telefone: data.telefone
      }, auth);

      // Atualiza os Dados Bancários
      if (data.tipo_transacao === "Depósito bancário") {
        // Dados bancários
        await api.put(`/dados_banc_trans/${transacaoInfosDadosBancarios.id}`, {
          id_transacao: transacaoInfos.id,
          nome_banco: data.nome_banco,
          agencia: data.agencia,
          numero_conta: data.numero_conta,
          nome_titular: data.nome_titular,
          tipo_conta: data.tipo_conta,
          conta_juridica: data.conta_juridica,
          CPF_CNPJ: data.cpf_titular,
          operacao: data.operacao,
        }, auth);
      };

      // Enviar mensagem para o Admin
      api.post('/message_to_admins', {
        subject: "Atualização de transação",
        message: `A transação de id ${transacaoInfos.id}, criada no dia ${transacaoInfos.created_at.substring(0, 10).split('-').reverse().join('/')}, teve seus dados atualizados.`,
      }, auth).then(response => response.status);

      addToast({
        type: 'success',
        title: 'Deu tudo certo.',
        description: 'Os dados foram atualizados com sucesso.',
      });

      setIsLoading(false);

      history.push('/transacoes');
      
    } catch (error) {

      addToast({
        type: 'error',
        title: 'Erro na edição',
        description: 'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul',
      });

      return;

    }

    // REVISAR
    /* if (data.tipo_transacao === "Boleto") {
      // Boleto
      transacaoInfosBoleto.forEach(async (item) => {
        
        const responseBoleto = await api.put(`/boleto_trans/${item.id}`, {
          id_transacao: transacaoInfos.id,
          codigo_de_barras: item.codigo_de_barras,
          id_boleto: item.id_boleto,
        }, auth);

        if (responseBoleto.data.errors || responseBoleto.data.error) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar dados do boleto',
            description: 'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul',
          });
    
          return;
        }
      })
    } */

  };
  const onError = (errors) => {
    addToast({
      type: 'error',
      title: 'Erro no envio do formulário',
      description: 'Você precisa preencher todos os campos obrigatórios (marcados com *).',
    });
  };

  // Carrega as informações para alimentar a página
  useEffect(() => {
    setIsLoading(true);
    
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    async function requestTransacao() {
      const responseTransacao = await api.get(`/transacao/${idTransacao}`, auth);
      
      const result = responseTransacao.data.data.transacao;
      
      // Salvar dados da transacao
      setTransacaoInfos(result);

      // Salvar dados do cliente e telefone
      api.get(`/transacao/cliente/${result.id_cliente}`, auth).then(response => {
        const clienteTransacao = response.data.data;

        setTransacaoInfosCliente(clienteTransacao);
      });

      if (result.quantidade_boleto > 0) {
        // Salvar dados do boleto
        api.get('/boleto_trans?png=999999', auth).then(response => {
          let boletoTransacao = response.data.data;

          const boletoFiltrado = boletoTransacao.filter(item => item.id_transacao === idTransacao);

          setTransacaoInfosBoleto(boletoFiltrado);
          setTransacaoInfosDadosBancarios([]);
        });
      };
  
      if (result.quantidade_boleto === 0) {
        // Salvar dados bancários
        api.get('/dados_banc_trans?png=999999', auth).then(response => {
          let dadosBancariosTransacao = response.data.data;
          
          const dadosBancariosFiltrados = dadosBancariosTransacao.filter(item => item.id_transacao === idTransacao)
          
          setTransacaoInfosDadosBancarios(dadosBancariosFiltrados[0]);
          setTransacaoInfosBoleto([]);
        });
      };

      // Esconde o loading
      setIsLoading(false);
    }

    requestTransacao();
  }, [idTransacao, userToken]);

  // Faz a validação do E-mail
  function validarEmail() {
    const valorDigitado = getValues("email");
    const indexArroba = valorDigitado.indexOf("@");

    const usuario = valorDigitado.substring(0, indexArroba);
    const dominio = valorDigitado.substring(indexArroba + 1, valorDigitado.length);

    if (
      (usuario.length >= 1) &&
      (dominio.length >= 3) &&
      (usuario.search("@") === -1) &&
      (dominio.search("@") === -1) &&
      (usuario.search(" ") === -1) &&
      (dominio.search(" ") === -1) &&
      (dominio.search(".") !== -1) &&
      (dominio.indexOf(".") >= 1)&&
      (dominio.lastIndexOf(".") < dominio.length - 1)
    ) {
      // e-mail válido
      clearErrors("email");
    } else { 
      // e-mail inválido
      setError("email", {
        type: "manual",
        message: "O E-mail digitado é inválido."
      });
    }
  };

  const [depositoOpened, setDepositoOpened] = useState(true);
  const [boletoOptions, setBoletoOptions] = useState(true);

  // const [infosBoleto, setInfosBoleto] = useState(false)
  const [infosDeposito, setInfosDeposito] = useState(false)

  const onDepositoSelected = useCallback(() => {
    const inputValue = getValues('tipo_transacao');

    if (inputValue === "Depósito bancário") {
      setDepositoOpened(false);
      setBoletoOptions(true);

      // setInfosBoleto(false);
      setInfosDeposito(true);
    } else if (inputValue === "Boleto") {
      setDepositoOpened(true);
      setBoletoOptions(false);

      // setInfosBoleto(true);
      setInfosDeposito(false);
    }
  }, [getValues]);

  // Lista de bancos
  const listaBancos = BankList;

  // const [comprovante, setComprovante] = useState('');
  // const [termo, setTermo] = useState('');
  // const [docFoto, setDocFoto] = useState('');
  // const [boletos, setBoletos] = useState([]);

  // const [anexosHide, setAnexosHide] = useState(true);

  useEffect(() => {
    setValue('numero_autorizacao', transacaoInfos.numero_autorizacao);
    setValue('nome', transacaoInfosCliente.nome);
    setValue('cpf', transacaoInfosCliente.cpf);
    setValue('numero_rg', transacaoInfosCliente.rg);
    setValue('email', transacaoInfosCliente.email);
    setValue('telefone', transacaoInfosCliente.telefone);

    // setValue('comprovante', transacaoInfos.id_doc_autorizacao);
    // setValue('termo', transacaoInfos.id_doc_termo);
    // setValue('docFoto', transacaoInfos.id_doc_documento_foto);

    /* if (transacaoInfosTelefone !== undefined) {
      setValue('telefone', transacaoInfosTelefone.telefone);
    } */

    setValue('tipo_transacao', transacaoInfos.quantidade_boleto === 0 ? "Depósito bancário" : "Boleto");
    onDepositoSelected();

    if (transacaoInfos.quantidade_boleto > 0) {
      setValue('quantidade_boletos', transacaoInfos.quantidade_boleto);
      setValue('codigo_boleto', transacaoInfosBoleto.codigo_de_barras === undefined || null ? "" : transacaoInfosBoleto.codigo_de_barras);
    }

    if (transacaoInfos.quantidade_boleto === 0) {
      setValue('nome_titular', transacaoInfosDadosBancarios.nome_titular);
      setValue('nome_banco', transacaoInfosDadosBancarios.nome_banco);
      setValue('conta_juridica', transacaoInfosDadosBancarios.conta_juridica);
      setValue('cpf_titular', transacaoInfosDadosBancarios.CPF_CNPJ);
      setValue('tipo_conta', transacaoInfosDadosBancarios.tipo_conta);
      setValue('agencia', transacaoInfosDadosBancarios.agencia);
      setValue('numero_conta', transacaoInfosDadosBancarios.numero_conta);
      setValue('operacao', transacaoInfosDadosBancarios.operacao);
    }   

  }, [setValue, transacaoInfos.numero_autorizacao, transacaoInfos.quantidade_boleto, transacaoInfosDadosBancarios.CPF_CNPJ, transacaoInfosBoleto.codigo_de_barras, transacaoInfosCliente.cpf, transacaoInfosCliente.email, transacaoInfosCliente.nome, transacaoInfosCliente.rg, transacaoInfosDadosBancarios.agencia, transacaoInfosDadosBancarios.conta_juridica, transacaoInfosDadosBancarios.cpf, transacaoInfosDadosBancarios.nome_banco, transacaoInfosDadosBancarios.nome_titular, transacaoInfosDadosBancarios.numero_conta, transacaoInfosDadosBancarios.operacao, transacaoInfosDadosBancarios.tipo_conta, transacaoInfosCliente.telefone, onDepositoSelected, transacaoInfosBoleto]);

  const setNumeroBanco = useCallback(() => {
    const nomeBanco = getValues('nome_banco');

    if (nomeBanco === "") {
      return
    }
    if (nomeBanco === "Banco do Brasil S.A.") {
      setValue('numero_banco', "001");
      return
    }
    if (nomeBanco === "Banco Bradesco S.A.") {
      setValue('numero_banco', "237");
      return
    }
    if (nomeBanco === "Caixa Econômica Federal") {
      setValue('numero_banco', "104");
      return
    }
    if (nomeBanco === "Banco Santander (Brasil) S. A.") {
      setValue('numero_banco', "033");
      return
    }
    if (nomeBanco === "Itaú Unibanco S.A.") {
      setValue('numero_banco', "341");
      return
    }
    
    const numerobanco = listaBancos.filter(item => item.label === nomeBanco);
    setValue('numero_banco', numerobanco[0].value);
    
  }, [getValues, setValue, listaBancos]);

  const [comprovanteHide, setComprovanteHide] = useState(true);
  const [termoHide, setTermoHide] = useState(true);
  const [docFotoHide, setDocFotoHide] = useState(true);

  const [downloadBoletoHide, setDownloadBoletoHide] = useState(true);

  const [boleto, setBoleto] = useState('');
  const [codigoBarras, setCodigoBarras] = useState('');
  const [idBoletoTrans, setIdBoletoTrans] = useState('');

  const [valorBoleto, setValorBoleto] = useState('');
  const [dataVencimento, setDataVencimento] = useState('');

  const [editBoletoHide, setEditBoletoHide] = useState(true);

  async function downloadBoleto(item) {

    setIsLoading(true);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    setIdBoletoTrans(item.id);
    // adiciona o boleto selecionado ao input novo_boleto
    setValue("novo_boleto", item.id_boleto);

    await api.get(`/documento_download/${item.id_boleto}`, auth).then(response => {
      const result = response.data.data;
      // const resultReplace = result.replace(`<img src= '`, '').replace(`' />`, '');

      setBoleto(result);
      setCodigoBarras(item.codigo_de_barras);

      if (item.valor === undefined || item.valor === null) {
        setValorBoleto(`Valor não encontrado`);
      } else {
        setValorBoleto(`R$ ${item.valor.toString().replace('.', ',')}`);
      }

      if (item.data_vencimento === undefined || item.data_vencimento === null) {
        setDataVencimento('Data não encontrada');
      } else {
        setDataVencimento(item.data_vencimento.substring(0, 10).split('-').reverse().join('/'));
      }

      setDownloadBoletoHide(false);
    });

    setEditBoletoHide(true);
    setIsLoading(false);
  };

  // Faz o download do comprovante do cliente
  async function downloadComprovante() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    if (transacaoInfos.id_doc_termo === "" || transacaoInfos.id_doc_termo === null) {
      addToast({
        type: "error",
        title: "Erro ao fazer download",
        description:
          "Esta transação foi feita no app e não possui comprovante.",
      });

      setIsLoading(false);

      return;
    }

    await api.get(`/documento_download/${transacaoInfos.id_doc_autorizacao}`, auth).then(response => {
      const result = response.data.data;

      // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
      const linkSource = result;
      const downloadLink = document.createElement("a");
      const fileName = "comprovante.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });

    setIsLoading(false);
  };

  // Faz o download do termo
  async function downloadTermo() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    // Baixa o termo das transações feitas no web
    if (transacaoInfosCliente.assinatura_id === null) {
      await api
      .get(`/documento_download/${transacaoInfos.id_doc_termo}`, auth)
      .then((response) => {
        const result = response.data.data;
        // console.log(result)

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = result;
        const downloadLink = document.createElement("a");
        const fileName = "termo.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    }

    // Baixa o termo das transações feitas no app
    if (transacaoInfosCliente.assinatura_id !== null) {
      await api.post(
        `/transacao/termo/${transacaoInfos.id}`,
        {},
        auth,
      ).then((response) => {
        const result = response.data;
        // console.log(result)

        // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
        const linkSource = `data:application/pdf;base64,${result}`;
        const downloadLink = document.createElement("a");
        const fileName = "termo.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    }

    setIsLoading(false);
  };

  // Faz o download do documento com foto
  async function downloadDocFoto() {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    await api.get(`/documento_download/${transacaoInfos.id_doc_documento_foto}`, auth).then(response => {
      const result = response.data.data;

      // setComprovantePagamento(result.replace(`<img src= '`, '').replace(`' />`, ''))
      const linkSource = result;
      const downloadLink = document.createElement("a");
      const fileName = "documento_com_foto.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });

    setIsLoading(false);
  };

  // UPLOAD DO COMPROVANTE -------------------------------------------- START 
  const [uploadedComprovante, setUploadedComprovante] = useState([]);
  const [progressComprovante, setProgressComprovante] = useState(0);
  const [statusComprovante, setStatusComprovante] = useState({
    uploaded: false,
    error: false
  });

  // Preparar arquivos para upload
  const submitFileComprovante = useCallback((file) => {
    const uploadFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
      // progress: progressComprovante,
      // uploaded: statusComprovante.uploaded,
      // error: statusComprovante.error,
    };

    setUploadedComprovante([...uploadedComprovante, uploadFile]);
    // setUploadedComprovante(uploadFile);
  }, [uploadedComprovante]);

  // Faz o upload de todos os arquivos
  const handleUploadComprovante = useCallback(() => {

    // Envia para a API
    const data = new FormData();

    uploadedComprovante.forEach(item => {
      // console.log(`image${uploadedComprovante.indexOf(item)}`);
      data.append(`image${uploadedComprovante.indexOf(item)}`, item.file);
    });

    api.post('/documento_upload_mult', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization : "Bearer "+userToken,
      },
      onUploadProgress: e => {
        setProgressComprovante(parseInt(Math.round((e.loaded * 100) / e.total)))
      }
    }).then(response => {
      setStatusComprovante({
        uploaded: true,
        error: false
      })

      const value = response.data.data;
      setValue("comprovante", value.id);
    }).catch(() => {
      setStatusComprovante({
        uploaded: false,
        error: true
      })
    });
  }, [uploadedComprovante, setValue, userToken]);
  
  // Remove o arquivo da lista de arquivos
  const handleDeleteComprovante = useCallback((id) => {
    const newFiles = uploadedComprovante.filter(comprovante => comprovante.id !== id);

    setUploadedComprovante(newFiles);
  }, [uploadedComprovante]);
  // UPLOAD DO COMPROVANTE ---------------------------------------------- END

  // Upload do Termo -------------------------------------------------- START 
  const [uploadedTermo, setUploadedTermo] = useState([]);
  const [progressTermo, setProgressTermo] = useState(0);
  const [statusTermo, setStatusTermo] = useState({
    uploaded: false,
    error: false
  });

  // Preparar arquivos para upload
  const submitFileTermo = useCallback((file) => {
    const uploadFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
    };

    setUploadedTermo([...uploadedTermo, uploadFile]);
  }, [uploadedTermo]);
  
  // Faz o upload de todos os arquivos
  const handleUploadTermo = useCallback(() => {

    // Envia para a API
    const data = new FormData();

    uploadedTermo.forEach(item => {
      // console.log(`image${uploadedComprovante.indexOf(item)}`);
      data.append(`image${uploadedTermo.indexOf(item)}`, item.file);
    });

    api.post('/documento_upload_mult', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization : "Bearer "+userToken,
      },
      onUploadProgress: e => {
        setProgressTermo(parseInt(Math.round((e.loaded * 100) / e.total)))
      }
    }).then(response => {
      setStatusTermo({
        uploaded: true,
        error: false
      })

      const value = response.data.data;
      setValue("termo", value.id);
      
    }).catch(() => {
      setStatusTermo({
        uploaded: false,
        error: true
      })
    });
  }, [uploadedTermo, setValue, userToken]);
  
  // Remove o arquivo da lista de arquivos
  const handleDeleteTermo = useCallback((id) => {
    const newFiles = uploadedTermo.filter(termo => termo.id !== id);

    setUploadedTermo(newFiles);
  }, [uploadedTermo]);
  // Upload do Termo ---------------------------------------------------- END 

  // Upload do Documento com foto ------------------------------------- START 
  const [uploadedDocFoto, setUploadedDocFoto] = useState([]);
  const [progressDocFoto, setProgressDocFoto] = useState(0);
  const [statusDocFoto, setStatusDocFoto] = useState({
    uploaded: false,
    error: false
  });

  // Preparar arquivos para upload
  const submitFileDocFoto = useCallback((file) => {
    const uploadFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
    };

    setUploadedDocFoto([...uploadedDocFoto, uploadFile]);
  }, [uploadedDocFoto]);
  
  // Faz o upload de todos os arquivos
  const handleUploadDocFoto = useCallback(() => {

    // Envia para a API
    const data = new FormData();

    uploadedDocFoto.forEach(item => {
      data.append(`image${uploadedDocFoto.indexOf(item)}`, item.file);
    });

    api.post('/documento_upload_mult', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization : "Bearer "+userToken,
      },
      onUploadProgress: e => {
        setProgressDocFoto(parseInt(Math.round((e.loaded * 100) / e.total)))
      }
    }).then(response => {
      setStatusDocFoto({
        uploaded: true,
        error: false
      })

      const value = response.data.data;
      setValue("docFoto", value.id);

    }).catch(() => {
      setStatusDocFoto({
        uploaded: false,
        error: true
      })
    });
  }, [uploadedDocFoto, setValue, userToken]);
  
  // Remove o arquivo da lista de arquivos
  const handleDeleteDocFoto = useCallback((id) => {
    const newFiles = uploadedDocFoto.filter(termo => termo.id !== id);

    setUploadedDocFoto(newFiles);
  }, [uploadedDocFoto]);
  // Upload do Documento com foto --------------------------------------- END 

  const editarBoleto = useCallback(() => {

    setEditBoletoHide(!editBoletoHide);
    setValue("novo_codigo_barras", codigoBarras);

    console.log(valorBoleto);
    console.log(dataVencimento);
    if (valorBoleto !== `Valor não encontrado`) {
      setValue("valor_boleto", valorBoleto);
    }
    if (dataVencimento !== `Data não encontrada`) {
      setValue("data_vencimento", dataVencimento);
    }
    
  }, [codigoBarras, dataVencimento, editBoletoHide, setValue, valorBoleto]);

  const enviarNovoBoleto = useCallback(() => {
    // Bearer token
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    const novoBoleto = getValues("novo_boleto");
    const novoCodigoDeBarras = getValues("novo_codigo_barras");
    const valorBoleto = getValues('valor_boleto');
    const dataVentimento = getValues('data_vencimento');

    const valorFinal = Number(valorBoleto.replace('R$ ', '').replace(',', '.'));
    const dataVencimentoFinal = dataVentimento.split('/').reverse().join('-');

    api.put(`/boleto_trans/${idBoletoTrans}`, {
      codigo_de_barras: novoCodigoDeBarras,
      id_boleto: novoBoleto,
      id_transacao: transacaoInfos.id,
      valor: valorFinal,
      data_vencimento: dataVencimentoFinal,
    }, auth).then(response => {

      if (response.data.data) {
        addToast({
          type: 'success',
          title: 'Deu tudo certo!',
          description: 'O boleto selecionado foi atualizado com sucesso.',
        });
      }

      if (response.data.error || response.data.errors) {
        addToast({
          type: 'error',
          title: 'Algo deu errado!',
          description: 'O boleto selecionado não foi atualizado, tente novamente ou entre em contato com o Deixa no Azul.',
        });
      }

      setEditBoletoHide(true);
      setDownloadBoletoHide(true);

    });
  }, [idBoletoTrans, userToken, getValues, transacaoInfos.id, addToast]);

  // Upload do Boleto ------------------------------------------------- START 
  const [uploadedBoleto, setUploadedBoleto] = useState([]);
  const [progressBoleto, setProgressBoleto] = useState(0);
  const [statusBoleto, setStatusBoleto] = useState({
    uploaded: false,
    error: false
  });

  // Preparar arquivos para upload
  const submitFileBoleto = useCallback((file) => {
    const uploadFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: filesize(file[0].size),
      preview: URL.createObjectURL(file[0]),
      // progress: progressComprovante,
      // uploaded: statusComprovante.uploaded,
      // error: statusComprovante.error,
    };

    setUploadedBoleto([...uploadedBoleto, uploadFile]);
  }, [uploadedBoleto]);

  // Faz o upload de todos os arquivos
  const handleUploadBoleto = useCallback(() => {

    // Envia para a API
    const data = new FormData();

    uploadedBoleto.forEach(item => {
      data.append(`image${uploadedBoleto.indexOf(item)}`, item.file);
    });

    api.post('/documento_upload_mult', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization : "Bearer "+userToken,
      },
      onUploadProgress: e => {
        setProgressBoleto(parseInt(Math.round((e.loaded * 100) / e.total)))
      }
    }).then(response => {
      setStatusBoleto({
        uploaded: true,
        error: false
      })

      const value = response.data.data;
      setValue("novo_boleto", value.id);

      // Função enviar boleto
      enviarNovoBoleto()

    }).catch(() => {
      setStatusBoleto({
        uploaded: false,
        error: true
      })
    });
  }, [uploadedBoleto, setValue, userToken, enviarNovoBoleto]);
  
  // Remove o arquivo da lista de arquivos
  const handleDeleteBoleto = useCallback((id) => {
    const newFiles = uploadedBoleto.filter(termo => termo.id !== id);

    setUploadedBoleto(newFiles);
  }, [uploadedBoleto]);
  // Upload do Boleto --------------------------------------------------- END

  // const [valorEData, serValorEData] = useState(false);

  // Máscara do input de valor da transação
  function inputValueMask(e) {
    let valor = e.currentTarget.value;
    valor = valor.replace(/\D/g, '');
    valor = valor.replace(/(\d)(\d{2})$/, '$1,$2');
    valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '.');

    return e.currentTarget.value = `R$ ${valor}`;
    // setValue('valor', `R$ ${valor}`);
  }

  // Máscara do input de data de vencimento
  function inputDateMask(e) {
    e.currentTarget.maxLength = 10;

    let valor = e.currentTarget.value;
    valor = valor.replace(/\D/g, '');
    valor = valor.replace(/^(\d{2})(\d)/, '$1/$2');
    valor = valor.replace(/(\d{2})(\d)/, '$1/$2');

    return e.currentTarget.value = valor;
    // setValue('data_vencimento', valor);
  }

  // Máscara do input de código de barras
  function codigoDeBarrasMask(e) {
    e.currentTarget.maxLength = 54;

    let value = e.currentTarget.value;

    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{5})(\d)/, '$1.$2');
    value = value.replace(/(\d{5})(\d)/, '$1 $2');
    value = value.replace(/(\d{5})(\d)/, '$1.$2');
    value = value.replace(/(\d{1})(\d{5})(\d)/, '$1$2 $3');
    value = value.replace(/(\d{5})(\d{6})(\d)/, '$1.$2 $3');
    value = value.replace(/(\d{1})(\d{10})/, '$1 $2');

    return e.currentTarget.value = value;
  }

  function verificarCodigoDeBarras() {
    const barCode = getValues('novo_codigo_barras');

    // console.log(codigo.length);
    if (barCode.length < 54) {
      return;
    } else {
      // console.log(barCode);

      if (barCode[3] !== '9') {
        // Mensagem de erro
        addToast({
          type: 'info',
          title: 'Ops, informações não obtidas',
          description: 'Não conseguimos captar as informações do boleto.',
        });

        // serValorEData(true);
        setValue('valor_boleto', ``)
        setValue('data_vencimento', '');
      }

      if (barCode[3] === '9') {
        // Valor
        const campoValor = barCode.substring(barCode.length - 10, barCode.length);
  
        const valorString = `${Number(campoValor.substring(0, 8))},${Number(
          campoValor.substring(8, 10),
        )}`;

        setValue('valor_boleto', `R$ ${valorString}`)

        // Data de vencimento
        const campoData = barCode.substring(barCode.length - 14, barCode.length - 10);

        const resultadoData = moment("19971007", "YYYYMMDD").add(Number(campoData), 'days').format();
        const resultadoSomenteData = resultadoData.split('T');

        const dataVencimento = resultadoSomenteData[0];
        
        setValue('data_vencimento', dataVencimento.split('-').reverse().join('/'));
      }
    }
  }

  return (
    <Container>
      <LoadingContent isLoading={isLoading} />

      <MenuBar subMenuActive="Deixa no Azul" />
      <NewMobileMenu active="Transações" />

      <Page>
        <TopBar pageTitle="Transação -" pageSubtitle="Deixa no Azul" />

        <Content>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <RowBox1>
              <Box>
                <h3>Informações da transação</h3>

                <InputArea>
                  <label>*Nome</label>
                  <InputContainer style={{ borderColor: errors.nome && "red" }}>
                    <input
                      name="nome"
                      type="text"
                      placeholder="Digite o nome"
                      ref={register({ required: true })}
                    />
                    {errors.nome && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <Row>
                  <InputArea>
                    <label>*Número do CPF</label>
                    <InputContainer style={{ borderColor: errors.cpf && "red" }}>
                      <input
                        name="cpf"
                        placeholder="Digite o CPF (apenas números)"
                        onKeyUp={(e) => cpfMask(e)}
                        ref={register({ required: true })}
                      />
                      {errors.cpf && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Número do RG</label>
                    <InputContainer style={{ borderColor: errors.numero_rg && "red" }}>
                      <input
                        name="numero_rg"
                        type="text"
                        placeholder="Digite o RG"
                        ref={register({ required: true })}
                        onKeyUp={(e) => rgMask(e)}
                      />
                      {errors.numero_rg && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>*E-mail</label>
                    <InputContainer style={{ borderColor: errors.email && "red" }}>
                      <input
                        name="email"
                        type="text"
                        placeholder="Digite o e-mail"
                        ref={register({ required: "Campo obrigatório" })}
                        onBlur={() => validarEmail()}
                      />
                      {errors.email && <Error title={errors.email.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Telefone</label>
                    <InputContainer style={{ borderColor: errors.telefone && "red" }}>
                    <input
                      name="telefone"
                      onKeyUp={(e) => telefoneMask(e)}
                      placeholder="Apenas números"
                      ref={register({ required: true })}
                    />
                    {errors.telefone && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row>
              
                <Row>
                  <InputArea>
                    <label>*Data de Criação</label>
                    <p>
                      {moment(transacaoInfosCliente.created_at).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </InputArea>

                  <InputArea>
                    <label>*Última Atualização</label>
                    <p>
                      {moment(transacaoInfosCliente.updated_at).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </InputArea>
                </Row>
              </Box>

              <div>
                <Box>
                  <h3>Informações para pagamentos</h3>

                  <InputArea>
                    <label>*Tipo de transação</label>
                    <InputContainer style={{ borderColor: errors.tipo_transacao && "red" }}>
                    <select
                      name="tipo_transacao"
                      onChange={onDepositoSelected}
                      placeholder="Selecione"
                      ref={register({ required: true })}
                    >
                      <option value="">Selecione</option>
                      <option value="Boleto">Boleto</option>
                      <option value="Depósito bancário">Depósito bancário</option>
                    </select>
                    {errors.tipo_transacao && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Box>

                <BoletoOptions boletoOptions={boletoOptions} className="boleto_infos">
                  <InputArea>
                    <label>*Quantidade de Boletos</label>
                    <p>{transacaoInfos.quantidade_boleto}</p>
                    {/* <InputContainer style={{ borderColor: errors.quantidade_boletos && "red" }}>
                      <input
                        name="quantidade_boletos"
                        type="number"
                        ref={register({ required: infosBoleto })}
                        placeholder="Digite o valor"
                      />
                      {errors.quantidade_boletos && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer> */}
                  </InputArea>

                  {/* <InputArea>
                    <label>Código de barra do boleto</label>
                    <InputContainer style={{ borderColor: errors.codigo_boleto && "red" }}>
                      <input
                        name="codigo_boleto"
                        type="text"
                        ref={register({ required: infosBoleto })}
                        placeholder="Digite o valor"
                      />
                      {errors.codigo_boleto && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea> */}
                </BoletoOptions>
              </div>
            </RowBox1>

            <RowBoxBoleto boletoOptions={boletoOptions}>
              <Box>
                {transacaoInfosBoleto.map(item => (
                  <span key={item.id_doc_boleto} style={{ display: 'flex', alignItems: 'center' }} onClick={() => downloadBoleto(item)}><img src={CodigoBarrasIcon} alt=""/>Boleto {transacaoInfosBoleto.indexOf(item) + 1}</span>
                ))}

                <Row className="marginTop" downloadBoletoHide={downloadBoletoHide}>
                  <InputArea className="barCode">
                    <label>Código de barra do boleto</label>
                    <p>{codigoBarras}</p>
                  </InputArea>

                  <InputArea style={{ marginTop: 10 }}>
                    <label>Valor do boleto</label>
                    <p>{valorBoleto}</p>
                  </InputArea>
                </Row>
                
                <Row className="marginTop" downloadBoletoHide={downloadBoletoHide}>
                  <InputArea style={{ marginTop: 10 }}>
                    <label>Data de vencimento do boleto</label>
                    <p>{dataVencimento}</p>
                  </InputArea>

                  <InputArea className="campoAnexo">
                    <label>*Boleto</label>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <a download href={boleto}>Fazer Download</a>
                      <img src={editIcon} alt="" style={{ cursor: "pointer", width: "16px" }} onClick={(item) => editarBoleto(item)} />
                    </div>
                  </InputArea>
                </Row>

                {/* Edição do boleto */}
                <h3 hidden={editBoletoHide} style={{ marginTop: 20 }}>Editar boleto</h3>

                <Row editBoletoHide={editBoletoHide} style={{ marginTop: "20px" }}>
                  <div>
                    <InputArea className="barCode">
                      <label>Código de barras do boleto</label>
                      <InputContainer style={{ borderColor: errors.novo_codigo_barras && "red", margin: "0" }}>
                        <input
                          name="novo_codigo_barras"
                          type="text"
                          onKeyUp={(e) => codigoDeBarrasMask(e)}
                          onChange={() => verificarCodigoDeBarras()}
                          ref={register}
                          placeholder="Código de barras"
                        />
                        {errors.novo_codigo_barras && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                      </InputContainer>
                    </InputArea>

                    <div> 
                      {/* Input de valor */}
                      <InputArea style={{ marginTop: 10 }}>
                        <label>*Valor do boleto</label>
                        <InputContainer className="barCode" style={{ borderColor: errors.valor_boleto && "red" }}>
                          <input
                            name="valor_boleto"
                            type="text"
                            onKeyUp={(e) => inputValueMask(e)}
                            // disabled={anexoDisabled}
                            ref={register}
                            placeholder="Somente números"
                          />
                          {errors.valor_boleto && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                        </InputContainer>
                      </InputArea>

                      {/* Input da data de vencimento */}
                      <InputArea style={{ marginTop: 10 }}>
                        <label>*Data de vencimento do boleto</label>
                        <InputContainer className="barCode" style={{ borderColor: errors.data_vencimento && "red" }}>
                          <input
                            name="data_vencimento"
                            type="text"
                            onKeyUp={(e) => inputDateMask(e)}
                            // disabled={anexoDisabled}
                            ref={register}
                            placeholder="Somente números"
                          />
                          {errors.data_vencimento && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                        </InputContainer>
                      </InputArea>
                    </div>
                  </div>

                  <InputArea>
                    <label>*Boleto</label>
                    <UploadContainer style={{ borderColor: errors.novo_boleto && "red" }}>
                      <UploadBoleto
                        onUpload={submitFileBoleto}
                        isDisabled={uploadedBoleto.length === 4 ? true : false}
                        isBlue={false}
                      />
                      <input type="text" name="novo_boleto" ref={register} hidden />
                      {errors.novo_boleto && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </UploadContainer>
                    {!!uploadedBoleto.length && <InfoBoleto files={uploadedBoleto} percent={progressBoleto} status={statusBoleto} onDelete={(id) => handleDeleteBoleto(id)} />}

                    {!!uploadedBoleto.length && statusBoleto.uploaded === false && <button type="button" style={{ marginBottom: 0 }} onClick={() => handleUploadBoleto()}>Enviar arquivos</button>}
                  </InputArea>

                  <button type="button" style={{ margin: "0", width: "100px" }} onClick={() => enviarNovoBoleto()}>Salvar</button>
                </Row>
              </Box>

            </RowBoxBoleto>

            <RowBox2 depositoOpened={depositoOpened}>
              <h3>Informações da conta bancária</h3>

              <Row>
                <InputArea>
                  <label>*Nome do titular</label>
                  <InputContainer style={{ borderColor: errors.nome_titular && "red" }}>
                    <input
                      name="nome_titular"
                      type="text"
                      ref={register({ required: infosDeposito })}
                      placeholder="Digite o nome do titular"
                    />
                    {errors.nome_titular && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*Nome do banco</label>
                  <InputContainer style={{ borderColor: errors.nome_banco && "red" }}>
                    <select
                      name="nome_banco"
                      placeholder="Selecione"
                      onChange={setNumeroBanco}
                      ref={register({ required: infosDeposito })}
                    >
                      <option value="">Selecione</option>
                      
                      {listaBancos.map(item => (
                        <option value={item.label}>{item.value+' - '+item.label}</option>
                      ))}
                    </select>
                    {errors.nome_banco && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                  <input type="text" name="numero_banco" ref={register} hidden />
                </InputArea>
              </Row>

              <Row3>
                <InputArea>
                  <label>*Tipo de conta</label>
                  <InputContainer style={{ borderColor: errors.conta_juridica && "red" }}>
                    <select
                      name="conta_juridica"
                      ref={register({ required: infosDeposito })}
                      placeholder="Selecione"
                    >
                      <option value="">Selecione</option>
                      <option value="0">Pessoa Física</option>
                      <option value="1">Pessoa Jurídica</option>
                    </select>
                    {errors.conta_juridica && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*CPF/CNPJ</label>
                  <InputContainer style={{ borderColor: errors.cpf_titular && "red" }}>
                    <input
                      name="cpf_titular"
                      onKeyUp={ getValues('conta_juridica') === '0' ? (e) => cpfMask(e) : (e) => cnpjMask(e) }
                      ref={register({ required: infosDeposito })}
                      placeholder="Digite o CPF"
                    />
                    {errors.cpf_titular && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*Conta</label>
                  <InputContainer style={{ borderColor: errors.tipo_conta && "red" }}>
                    <select
                      name="tipo_conta"
                      placeholder="Selecione"
                      ref={register({ required: infosDeposito })}
                    >
                      <option value="">Selecione</option>
                      <option value={0}>Corrente</option>
                      <option value={1}>Poupança</option>
                    </select>
                    {errors.cpf_titular && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>
              </Row3>

              <Row3>
                <InputArea>
                  <label>*Agência</label>
                  <InputContainer style={{ borderColor: errors.agencia && "red" }}>
                    <input
                      name="agencia"
                      type="text"
                      ref={register({ required: infosDeposito })}
                      placeholder="xxxx"
                    />
                    {errors.agencia && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>*Número da conta</label>
                  <InputContainer style={{ borderColor: errors.numero_conta && "red" }}>
                    <input
                      name="numero_conta"
                      onKeyUp={(e) => contaBancariaMask(e)}
                      type="text"
                      ref={register({ required: infosDeposito })}
                      placeholder="Apenas números"
                    />
                    {errors.numero_conta && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                  </InputContainer>
                </InputArea>

                <InputArea>
                  <label>Operação</label>
                  <InputContainer>
                    <input
                      name="operacao"
                      type="text"
                      ref={register}
                      placeholder="xxx"
                    />
                  </InputContainer>
                </InputArea>
              </Row3>

            </RowBox2>
            
            <RowBox3>
              <Box>
                <h3>Anexos</h3>
                <Row>
                  <InputArea className="campoAnexo">
                    <label>*Comprovante</label>
                    <div className="action">
                      <button type="button" className="downloadButton" onClick={() => downloadComprovante()}>Fazer Download</button>
                      <img src={editIcon} alt="" onClick={() => setComprovanteHide(!comprovanteHide)} />
                    </div>

                    <UploadContainer style={{ borderColor: errors.comprovante && "red" }} comprovanteHide={comprovanteHide}>
                      <UploadComprovante
                        onUpload={submitFileComprovante}
                        isDisabled={uploadedComprovante.length === 4 ? true : false}
                      />
                      <input type="text" name="comprovante" ref={register} hidden />
                      {errors.comprovante && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </UploadContainer>
                    {!!uploadedComprovante.length && <InfoComprovante files={uploadedComprovante} percent={progressComprovante} status={statusComprovante} onDelete={(id) => handleDeleteComprovante(id)} />}

                    {!!uploadedComprovante.length && statusComprovante.uploaded === false && <button type="button" style={{ marginBottom: 0 }} onClick={() => handleUploadComprovante()}>Enviar arquivos</button>}
                  </InputArea>

                  <InputArea className="campoAnexo">
                    <label>*Termo</label>
                    <div className="action">
                      <button type="button" className="downloadButton" onClick={() => downloadTermo()}>Fazer Download</button>
                      <img src={editIcon} alt="" onClick={() => setTermoHide(!termoHide)} />
                    </div>

                    <UploadContainer style={{ borderColor: errors.termo && "red" }} termoHide={termoHide}>
                      <UploadTermo
                        onUpload={submitFileTermo}
                        isDisabled={uploadedTermo.length === 4 ? true : false}
                      />
                      <input type="text" name="termo" ref={register} hidden />
                      {errors.termo && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </UploadContainer>
                    {!!uploadedTermo.length && <InfoTermo files={uploadedTermo} percent={progressTermo} status={statusTermo} onDelete={(id) => handleDeleteTermo(id)} />}

                    {!!uploadedTermo.length && statusTermo.uploaded === false && <button style={{ marginBottom: 0 }} type="button" onClick={() => handleUploadTermo()}>Enviar arquivos</button>}
                  </InputArea>
                </Row>

                <Row className="marginTop">
                  <InputArea className="campoAnexo">
                    <label>*Documento com foto</label>
                    <div className="action">
                      <button type="button" className="downloadButton" onClick={() => downloadDocFoto()}>Fazer Download</button>
                      <img src={editIcon} alt="" onClick={() => setDocFotoHide(!docFotoHide)} />
                    </div>

                    <UploadContainer style={{ borderColor: errors.docFoto && "red" }} docFotoHide={docFotoHide}>
                      <UploadDocFoto
                        onUpload={submitFileDocFoto}
                        isDisabled={uploadedDocFoto.length === 4 ? true : false}
                      />
                      <input type="text" name="docFoto" ref={register} hidden />
                      {errors.docFoto && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </UploadContainer>
                    {!!uploadedDocFoto.length && <InfoDocFoto files={uploadedDocFoto} percent={progressDocFoto} status={statusDocFoto} onDelete={(id) => handleDeleteDocFoto(id)} />}

                    {!!uploadedDocFoto.length && statusDocFoto.uploaded === false && <button type="button" style={{ marginBottom: 0 }} onClick={() => handleUploadDocFoto()}>Enviar arquivos</button>}
                  </InputArea>

                </Row>
              </Box>

              {/* <InfoPaymentContainer>
                <h3>Informações para Cliente final</h3>

                <div>
                  <ValueArea>
                    <label>Valor da parcela</label>
                    <span>12x R$ 100,00</span>
                  </ValueArea>

                  <ValueArea>
                    <label>Valor líquido</label>
                    <span>R$ 1000,00</span>
                  </ValueArea>

                  <ValueArea>
                    <label>Valor total</label>
                    <span>R$ 1200,00</span>
                  </ValueArea>
                </div>
              </InfoPaymentContainer> */}

            </RowBox3>
            
            <button type="submit">Atualizar transação</button>
          </form>
        </Content>
      </Page>
      
    </Container>
  );
}

export default EditarTransacao;
