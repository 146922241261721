import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core/';
import { FaEye } from 'react-icons/fa';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import FormatarValor from '../../components/FormatarValor';

import { MdFiberManualRecord } from 'react-icons/md';
import editIcon from '../../assets/images/edit_icon.svg';

import {
  Container,
  Page,
  Content,
  TableContent,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
} from './styles';

function Transacoes() {
  const history = useHistory();

  const [transacoes, setTransacoes] = useState([]);
  const [comissoes, setComissoes] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const estabelecimentoData = JSON.parse(localStorage.getItem('@DeixaNoAzul:estabelecimentoId'));

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/tabela_transacoes', auth).then(response => {
      const result = response.data;
      // console.log(response);

      setTransacoes(result.reverse());
    });

    api.get('/comissao?png=1000000', auth).then(response => {
      const valueComissoes = response.data.data;
      // console.log(response)
      // console.log(response.status)

      if (response.status === 204) {
        setComissoes([]);

        return;
      }

      setComissoes(valueComissoes);
    });

    /* api.get('/comissao', auth).then(response => {
      let valueComissoes = response.data.data;

      const lastPage = response.data.meta.last_page;
      const total = response.data.meta.total;

      if (total > 15) {
        for (let i = 2; i <= lastPage; i++) {
          api.get(`/comissao?page=${i}`, auth).then(res => {
            let newValue = res.data.data;
            newValue.forEach(item => valueComissoes.push(item));

            setComissoes(valueComissoes);
          });
        }; 
      } else {
        setComissoes(valueComissoes);
      }
    }); */

  }, [userToken, estabelecimentoData.id]);

  // Faz o reload da página a cada 1 minuto
  /* useEffect(() => {

    setInterval(() => {
      window.location.reload(true);
    }, 60000);

  }, []); */

  // Informações Head da tabela
  const headData = [
    { title: 'Cliente', field: 'cliente', sorting: false },
    { title: 'Valor pago ao cliente', field: 'valor_pagamento', sorting: false },
    { title: 'Valor final', field: 'valor_total', sorting: false },
    { title: 'Comissão', field: 'comissao', sorting: false },
    { title: 'Status', field: 'status', align: 'right', sorting: false },
    { title: '', field: 'edit', align: 'center', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = transacoes.map(transacao => {

    let colorStatus = '';
    if(transacao.status === 1) {colorStatus = "#FEAC02"} // Em análise
    if(transacao.status === 2) {colorStatus = "#5097FF"} // Solicitar documentos
    if(transacao.status === 3) {colorStatus = "#5CB85C"} // Aprovada
    if(transacao.status === 4) {colorStatus = "#F35757"} // Cancelada
    if(transacao.status === 5) {colorStatus = "#8850FF"} // Checagem financeira

    let messageStatus = '';
    if(transacao.status === 1) {messageStatus = "Em análise"} // Em análise
    if(transacao.status === 2) {messageStatus = "Solicitar documentos"} // Solicitar documentos
    if(transacao.status === 3) {messageStatus = "Aprovada"} // Aprovada
    if(transacao.status === 4) {messageStatus = "Cancelada"} // Cancelada
    if(transacao.status === 5) {messageStatus = "Checagem financeira"} // Checagem financeira

    const comissao = comissoes.filter(item => item.transacao_id === transacao.id_transacao);
    
    let valor_comissao = '';
    comissao.forEach(item => {
      valor_comissao = item.valor_comissao
    });

    const resultadoComissao = valor_comissao === ''
      ? "Esperando aprovação"
      : `R$ ${FormatarValor(valor_comissao)}`;

    const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    const imgEdit = transacao.status === 3 ? <FaEye color="#333333" size={21} /> : <img src={editIcon} alt=""/>;
    const iconTooltip = transacao.status === 3 ? "Ver Dados da Transação" : "Editar Dados da Transação";

    return {
      cliente: transacao.cliente,
      valor_pagamento: `R$ ${FormatarValor(transacao.valor_pago)}`,
      valor_total: `R$ ${FormatarValor(transacao.valor_final)}`,
      comissao: resultadoComissao,
      status: status,
      edit: <Tooltip title={iconTooltip} placement="top" arrow>
              <span style={{ cursor: "pointer" }} onClick={() => editTransacao(transacao)}>
                { imgEdit }
              </span>
            </Tooltip>
    }
  });

  const editTransacao = useCallback((transacao) => {
    const idReverse = transacao.id_transacao.split('').reverse().join('');

    if (transacao.status === 3) {
      // redireciona para a página de edição
      history.push(`/transacoes/ver/${idReverse}`);

      return;
    }
    
    // redireciona para a página de edição
    history.push(`/transacoes/editar/${idReverse}`);
  }, [history]);

  const filterByStatus = useCallback((value) => {
    const filterValue = Number(value.value);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    if (filterValue !== 0) {
      api.get('/tabela_transacoes', auth).then(response => {
        const valueTransacoes = response.data;

        const filteredData = valueTransacoes.filter(item => item.status === filterValue);

        setTransacoes(filteredData);
      });
    }

    if (filterValue === 0) {
      api.get('/tabela_transacoes', auth).then(response => {
        const valueTransacoes = response.data;

        setTransacoes(valueTransacoes);
      })
    }

  }, [userToken]);

  const valorFinalTransacao = useCallback((transacao) => {
    const valor_total = transacao.valor_final.toString().split('.');
    const valorTotal = `${valor_total[0]},${valor_total[1] ? valor_total[1].substring(0, 2) : "00"}`;

    return <strong>R$ {valorTotal}</strong>
  }, []);

  const transacaoStatus = useCallback((transacao) => {
    let colorStatus = '';
    if(transacao.status === 1) {colorStatus = "#FEAC02"} // Em análise
    if(transacao.status === 2) {colorStatus = "#5097FF"} // Solicitar documentos
    if(transacao.status === 3) {colorStatus = "#5CB85C"} // Aprovada
    if(transacao.status === 4) {colorStatus = "#8850FF"} // Cancelado
    if(transacao.status === 5) {colorStatus = "#F35757"} // Checagem financeira

    let messageStatus = '';
    if(transacao.status === 1) {messageStatus = "Em análise"} // Em análise
    if(transacao.status === 2) {messageStatus = "Solicitar documentos"} // Solicitar documentos
    if(transacao.status === 3) {messageStatus = "Aprovada"} // Aprovada
    if(transacao.status === 4) {messageStatus = "Cancelado"} // Cancelado
    if(transacao.status === 5) {messageStatus = "Checagem financeira"} // Checagem financeira

    return <span style={{ color: colorStatus }}>{messageStatus}</span>
  }, []);

  const valorComissao = useCallback((transacao) => {
    const comissao = comissoes.filter(item => item.transacao_id === transacao.id_transacao);

    let valor_comissao = '';
    comissao.forEach(item => {
      valor_comissao = item.valor_comissao
    });

    const comissao_valor = valor_comissao.toString().split('.');
    const comissaoValor = `${comissao_valor[0]},${comissao_valor[1] ? comissao_valor[1].substring(0, 2) : "00"}`;

    const resultadoComissao = valor_comissao === '' ? "Esperado aprovação" : `R$ ${comissaoValor}`;

    return <strong>{resultadoComissao}</strong>
  }, [comissoes]);

  return (
    <Container>
      <MenuBar active="Transações" subMenuActive="Deixa no Azul" />
      {/* <MobileMenu active="Transações" /> */}
      <NewMobileMenu active="Transações" subMenuActive="Deixa no Azul" />

      <Page>
        <TopBar pageTitle="Transações -" pageSubtitle="Deixa no Azul" />

        <Content>

          <TableContent>

            <Select
              placeholder="Status"
              onChange={(value) => filterByStatus(value)}
              className="filterStatusSelect"
              options={[
                { value: '1', label: 'Em Analise' },
                { value: '2', label: 'Solicitar documentos' },
                { value: '3', label: 'Aprovada' },
                { value: '5', label: 'Checagem financeira' },
                { value: '0', label: 'Todos' }
              ]}
            />

            <MaterialTable
              title="Lista de Transações"
              columns={headData}
              data={bodyData}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    
                  </div>
                )
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                },
                toolbar: {
                  searchPlaceholder: 'Buscar',
                  searchTooltip: 'Buscar'
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum dado encontrado',
                }
              }}
              options={{
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: '#10104F',
                  color: '#FFF'
                },
                emptyValue: "Nenhum dado encontrado"
              }}
            />

          </TableContent>

          <TableContentMobile>
            <Header>
              <h3>Lista de Transações</h3>

              <Select
                placeholder="Status"
                onChange={(value) => filterByStatus(value)}
                className="filterStatusSelect"
                options={[
                  { value: '1', label: 'Em Analise' },
                  { value: '2', label: 'Solicitar documentos' },
                  { value: '3', label: 'Aprovada' },
                  { value: '5', label: 'Checagem financeira' },
                  { value: '0', label: 'Todos' }
                ]}
              />

              <span>Total: {transacoes.length}</span>
            </Header>

            <MobileBody>
              {transacoes.map((transacao) => (
                <Line key={transacao.id}>
                  <span>{transacao.cliente}</span>
                  <p>Valor final: {valorFinalTransacao(transacao)}</p>
                  <p>Comissão: {valorComissao(transacao)}</p>
          
                  <div className="footer">
                    <span>{transacaoStatus(transacao)}</span>
                    <img src={editIcon} style={{ display: transacao.status === 3 ? 'none' : 'block' }} alt="Editar transação" onClick={() => editTransacao(transacao)}/>
                  </div>
                </Line>
              ))}
            </MobileBody>

          </TableContentMobile>

        </Content>
      </Page>
    </Container>
  );
}

export default Transacoes;