import styled, { css } from 'styled-components';

import backgroundImg from '../../assets/images/background.jpg';
import backgroundImgAdmin from '../../assets/images/background_2.jpg';

export const Container = styled.div`
  /* background: #fff; */
`;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;

  padding: 100px 160px 0 30px;

  ${props => props.isAdmin && css`
    background: url(${backgroundImgAdmin}) no-repeat;
    background-position: top left;
    background-size: 100% 256px;
  `}

  @media (max-width: 500px) {
    padding: 50px 20px 80px;
    /* margin-bottom: 100px; */
  }
`;

export const TabContent = styled.div`
  background: #fff;
  border-radius: 8px;

  @media (max-width: 500px) {
    margin-bottom: 90px;
    
    button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.Mui-selected {
      padding: 10px 15px 0;
    }

    button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
      padding: 10px 15px 0;
    }

    span.MuiTab-wrapper {
      font-size: 18px;
    }
  }
`;
