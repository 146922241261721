import React from "react";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logopolitica.png";

import { Container, Header, Content, Body, Paragrafo } from "./styles";

function SignIn() {
  return (
    <Container>
      <Header>
        <img src={logo} alt="Deixa no Azul" />
        <Link
          to="/"
          style={{ color: "#fff", fontWeight: "bold", fontSize: 20 }}
        >
          Login
        </Link>
      </Header>

      <Content>
        <Body>
          <h1>Política de Privacidade</h1>
          <Paragrafo>
            <p>
              A Deixa no Azul surge para oferecer soluções sem burocracia para
              pagamento de qualquer conta usando cartões de crédito para
              parcelamentos sem consulta ao SPS/Serasa.
            </p>

            <p>
              Abaixo você poderá encontrar as informações sobre permissões e
              acessos a dados que você aceita e concorda ao utilizar nossos
              serviços.
            </p>
            <p>
              Portanto, recomendamos que você leia com atenção os termos aqui
              contidos, bem como as suas eventuais atualizações, antes de tomar
              a decisão de começar a usar ou continuar com a utilização dos
              serviços oferecidos pela DEIXA NO AZUL.
            </p>
          </Paragrafo>

          <Paragrafo>
            <h2>Definições Importantes</h2>
            <p>
              Para sua melhor compreensão, listamos abaixo as definições dos
              termos específicos utilizados nesta Política de Privacidade, que
              terão o significado a seguir:
            </p>
            <p>
              <span className="sub-titulo">
                Autoridade Nacional de Proteção de Dados ou ANPD:{" "}
              </span>
              órgão da administração pública direta federal do Brasil, criada
              pela Medida Provisória nº 869, de 27 de dezembro de 2018, que faz
              parte da Presidência da República, e possui atribuições
              relacionadas a proteção de dados pessoais e da privacidade e,
              sobretudo, deve realizar a fiscalização do cumprimento da Lei nº
              13.709/2018 (Lei Geral da Proteção de Dados – LGPD);
            </p>

            <p>
              <span className="sub-titulo">Controlador: </span> é a empresa
              responsável por tomar as decisões sobre o tratamento dos seus
              dados pessoais em cada relação jurídica. Para os fins desta
              Política de Privacidade, a DEIXA NO AZUL será a Controladora de
              seus dados;
            </p>

            <p>
              <span className="sub-titulo">Dados de Acesso: </span>
              são as informações coletadas pelo Controlador dos visitantes em
              seu site, pelo uso e acesso, criando um log (registro de
              atividades efetuadas no site) que conterão o endereço de IP,
              acesso e ações realizadas no site, data e hora de cada ação, tempo
              de uso, informações sobre o dispositivo utilizado para o acesso
              (navegador e versão), além de Cookies;
            </p>

            <p>
              <span className="sub-titulo">Dado Pessoal: </span>
              toda informação relacionada a pessoa natural identificada ou
              identificável, ou seja: todas as informações que permitam
              identificar uma pessoa natural.
            </p>

            <p>
              <span className="sub-titulo">Tratamento: </span>
              todos os processos utilizados pela DEIXA NO AZUL no tratamento aos
              dados de acesso coletados, desde a sua coleta e armazenamento até
              sua eliminação.
            </p>

            <p>
              <span className="sub-titulo">
                Encarregado ou Data Protection Officer (DPO):{" "}
              </span>
              é a pessoa indicada pelo Controlador para atuar como um canal de
              comunicação entre o Controlador, os Titulares dos Dados Pessoais e
              a ANPD.
            </p>

            <p>
              <span className="sub-titulo">
                Legislação Aplicável de Proteção de Dados:{" "}
              </span>
              nos referimos à Lei nº 13.709/2018 (Lei Geral de Proteção de Dados
              ou LGPD), suas atualizações, quaisquer outras leis que se
              relacionem com o mesmo tema da proteção de dados pessoais, bem
              como as portarias, regulamentos e normativos emitidos pela ANPD;
            </p>

            <p>
              <span className="sub-titulo">Operador: </span>
              Operador: é o agente que realiza o Tratamento de Dados Pessoais em
              nome do Controlador. Quando o Controlador contrata uma agência de
              publicidade e programação, ou uma empresa adquirente, por exemplo,
              eles atuam como operadores dos dados que recebem do Controlador;
            </p>

            <p>
              <span className="sub-titulo">Website: </span>
              significa o website{" "}
              <a href="https://www.deixanoazul.com.br/" target="_blank" rel="noopener noreferrer">
                www.deixanoazul.com.br
              </a>
              ;
            </p>

            <p>
              <span className="sub-titulo">Violação de Dados Pessoais: </span>
              significa uma violação de segurança da informação que acarreta a
              destruição acidental ou ilegal, perda, alteração, divulgação ou
              acesso não autorizado a Dados Pessoais transmitidos, armazenados
              ou de outra forma tratados pela DEIXA NO AZUL ou um subcontratado
              autorizado.
            </p>
          </Paragrafo>

          <Paragrafo>
            <h3>Sobre a coleta de dados</h3>

            <p>
              A DEIXA NO AZUL coleta dados pessoais de seus usuários quando
              estes: (i) navegam e/ou cadastram-se em nosso website; (ii)
              utilizam os gadgets e acessam a plataforma de consolidação do
              processamento de dados; (iii) preenchem qualquer formulário para
              participação em webinar e/ou recebimento de ebooks; (iv)
              participam de nossas promoções ou concursos; (v) decidem receber
              nossa newsletter que divulga nossas ofertas, promoções, campanhas;
              e (VI) quando os Estabelecimentos Comerciais disponibilizam seus
              dados pessoais aos Parceiros Licenciados da DEIXA NO AZUL, para
              firmarem contrato de utilização do sistema DEIXA NO AZUL.
            </p>
          </Paragrafo>

          <Paragrafo>
            <h3>Informações que coletamos</h3>

            <p>
              Esclarecemos que a coleta de dados e tratamento de dados pessoais
              pela DEIXA NO AZUL é autorizado pela LGPD sem a necessidade de
              Consentimento expresso pelo Titular, por se encaixar nas hipóteses
              autorizadas no artigo 7º da LGPD.
            </p>

            <p>
              Os dados pessoais que a DEIXA NO AZUL coleta têm diversas fontes,
              e são classificados da seguinte maneira:
            </p>

            <p>
              <span className="sub-titulo">a) </span>
              Dados pessoais informados pelo titular: dados cadastrais tais como
              nome, documentos de identificação (CPF e RG), endereço, data de
              nascimento, dados de contato tais como telefone e e-mail, e dados
              bancários;
            </p>

            <p>
              <span className="sub-titulo">b) </span>
              Dados pessoais que coletamos de terceiros: informações públicas
              sobre processos cíveis, criminais, trabalhistas; Informações sobre
              histórico de crédito e score gerado por bureaus de crédito; dados
              sobre restrições financeiras, tais como: apontamentos negativos,
              restrições de crédito, restrições à constituição de garantia e
              valores devidos, se houver;
            </p>

            <p>
              <span className="sub-titulo">c) </span>
              Dados de navegação e do dispositivo: endereço de IP do dispositivo
              utilizado para acessar os serviços ou gadgets; interações
              realizadas e perfil de uso do site e das redes sociais; cookies;
              informações sobre geolocalização e IP dos dispositivos de acesso;
              dados técnicos divulgados pelo provedor de internet de cada
              usuário;
            </p>

            <p>
              <span className="sub-titulo">d) </span>
              Dados públicos: informações que foram tornadas públicas a respeito
              de cada usuário; informações sobre menções e interações;
              depoimentos referentes à DEIXA NO AZUL postados em redes sociais,
              blogs e websites.
            </p>
          </Paragrafo>

          <Paragrafo>
            <h3>Forma de uso dos dados pessoais</h3>
            <p style={{ marginBottom: 10 }}>
              Conforme estabelece a LGPD, tratamos os dados pessoais dos
              usuários para atender as seguintes finalidades:
            </p>
            <ul>
              <li>
                Prestar os serviços contratados por cada usuário com o Sistema
                DEIXA NO AZUL;
              </li>
              <li>
                Como subsídio na resolução de problemas de interesse dos
                usuários;
              </li>
              <li>
                Verificação de identidade e elegibilidade, autenticação e
                verificação de requisitos para contratação dos serviços e
                produtos;
              </li>
              <li>
                Para Medidas de prevenção e combate a ilícitos, fraudes, crimes
                financeiros e garantia da segurança dos clientes;
              </li>
              <li>
                Se necessário for, em cumprimento a ordem judicial, de órgão
                competente de fiscalização;
              </li>
              <li>
                Marketing e publicidade com vistas a promoção dos serviços e
                produtos, para melhor nos relacionar com nossos clientes;
              </li>
              <li>
                Para gerar estatísticas e levantamentos acerca da interação da
                DEIXA NO AZUL com o seu público-alvo.
              </li>
            </ul>
          </Paragrafo>

          <Paragrafo>
            <h3>Retenção e armazenamento de dados</h3>

            <p>
              A DEIXA NO AZUL procederá com a retenção dos dados pessoais dos
              usuários nos melhores sistemas de servidores de armazenamento em
              nuvem, podendo estes serem alocados em outros países, e
              transferirá os dados respeitando a privacidade de nossos clientes,
              gerando assim a segurança esperada e regulamentada pela LGPD.
            </p>

            <p>
              Caso algum Estabelecimento Comercial expresse formalmente o seu
              desejo de excluir seus dados pessoais do banco de dados da DEIXA
              NO AZUL, responderemos sua solicitação em até 15 (quinze) dias
              úteis.
            </p>

            <p>
              A DEIXA NO AZUL poderá manter certos dados de seus usuários e
              clientes por um período maior, tão somente por força de lei e
              regulamentos dos órgãos competentes.
            </p>
          </Paragrafo>

          <Paragrafo>
            <h3>Compartilhamento de informações</h3>

            <p>
              Os dados pessoais dos usuários e clientes da DEIXA NO AZUL poderão
              ser compartilhados com terceiros parceiros, autoridades e órgãos
              reguladores para finalidades diferentes, quando necessário. Os
              dados poderão ser compartilhados ainda com instituições
              subcontratadas de atendimento ao consumidor, de comunicação, de
              serviços estatísticos, de pesquisas, marketing, serviços
              financeiros, agências de cobrança, de crédito e prevenção a
              fraudes, bancos, instituições financeiras e outros terceiros,
              tendo o compartilhamento como principais finalidades àquelas
              elencadas acima.
            </p>

            <p>
              Todo e qualquer compartilhamento de informações é feito dentro de
              finalidades específicas, respeitando a boa fé contratual das
              Partes e a LGPD.
            </p>
          </Paragrafo>

          <Paragrafo>
            <h3>Direitos dos titulares de dados</h3>

            <p>
              A LGPD assegura a toda pessoa natural o reconhecimento de ser
              titular de seus dados pessoais, devendo então usufruir dos
              princípios de liberdade no usufruto, preservação de intimidade e
              privacidade.
            </p>

            <p>
              Desta forma a DEIXA NO AZUL se compromete em fornecer aos
              Estabelecimentos Comerciais, que solicitarem mediante requisição
              formal, respostas acerca do que segue:
            </p>

            <ul>
              <li>
                <span className="span-li">
                  Confirmação sobre o tratamento e acesso aos dados pessoais:{" "}
                </span>
                poderão os Estabelecimentos Comerciais solicitarem informações
                sobre quais dados seus estão sendo tratados e retidos pela DEIXA
                NO AZUL, que esta lhes informará as categorias de dados pessoais
                e por quanto tempo os dados serão armazenados;
              </li>

              <li>
                <span className="span-li">
                  Informação sobre o compartilhamento de dados com terceiros:{" "}
                </span>
                os Estabelecimentos Comerciais poderão solicitar que a DEIXA NO
                AZUL forneça informações relativas ao compartilhamento de dados
                com terceiros;
              </li>

              <li>
                <span className="span-li">Atualização de dados: </span>
                os dados pessoais armazenados na DEIXA NO AZUL poderão ser
                atualizados e/ou corrigidos mediantes solicitação dos seus
                Estabelecimentos Comerciais credenciados;
              </li>

              <li>
                <span className="span-li">
                  Oposição ao tratamento irregular:{" "}
                </span>
                os Estabelecimentos Comerciais poderão fazer valer quaisquer de
                seus direitos amparados pela LGPD, enviando seus questionamentos
                para o e-mail contato@deixanoazul.com.br. A DEIXA NO AZUL
                procederá com o atendimento de forma adequada, a fim de prestar
                esclarecimentos e de gerar conformidade de seus atos e processos
                à LGPD.
              </li>
            </ul>
          </Paragrafo>

          <Paragrafo>
            <h3>Consentimento</h3>

            <p>
              Reiteramos que a coleta de dados e tratamento de dados pessoais
              pela DEIXA NO AZUL é autorizado pela LGPD sem a necessidade de
              Consentimento expresso pelo Titular, por se encaixar nas hipóteses
              autorizadas no artigo 7º da LGPD.
            </p>

            <p>
              Contudo a DEIXA NO AZUL reconhece a necessidade de gerar
              transparência em seus processos, contribuindo para uma relação
              comercial confiável e duradoura para com seus Estabelecimentos
              Comerciais credenciados e, para tanto, com a finalidade de
              garantir que você tenha completo conhecimento e consciência sobre
              os assuntos abordados nesta Política de Privacidade, solicitaremos
              que nossos clientes leiam e aceitem a presente Política de
              Privacidade, que necessariamente poderá ser atualizada conforme
              alterações legais e regulamentares dos órgãos competentes e da
              própria dinâmica do mercado comercial.
            </p>

            <p>
              Caso a alteração implique em mudanças práticas de Tratamento que
              dependam do seu consentimento, nós solicitaremos o consentimento
              com base nos novos termos.
            </p>

            <p>
              Todo e qualquer compartilhamento de informações é feito dentro de
              finalidades específicas, respeitando a boa fé contratual das
              Partes e a LGPD.
            </p>
          </Paragrafo>

          <Paragrafo>
            <h3>Permissões</h3>

            <p>
              A utilização do app resultará na permissão de acesso a câmera
              local do aparelho para realizar as fotografias necessárias da
              documentação durante os cadastros de transações.
            </p>
          </Paragrafo>

          <Paragrafo>
            <h3>Fale Conosco</h3>

            <p>
              Em caso de dúvidas e esclarecimento acerca da segurança que deve
              envolver a captação, tratamento, armazenamento de seus dados
              pessoais, pedimos que entre em contato com a DEIXA NO AZUL
              enviando um e-mail para{" "}
              <span className="span-email">contato@deixanoazul.com.br</span>,
              que estaremos prontos a lhe atender.
            </p>
          </Paragrafo>
        </Body>
      </Content>
    </Container>
  );
}

export default SignIn;
