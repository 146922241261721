import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core/';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';

import FormAddEC from '../../components/FormAddEC';

import {
  MdFiberManualRecord,
  MdAdd,
  MdRemove,
} from 'react-icons/md';
import editIcon from '../../assets/images/edit_icon.svg';

import api from '../../services/api';

import {
  Container,
  Page,
  Content,
  TableContent,
  AddEC,
  Button,
  FormSection,
  TableContentMobile,
  Header,
  MobileBody,
  Line,
  // Pagination,
  // PaginationButton,
  // PaginationItem,
} from './styles';

function ECRevendedor() {
  const history = useHistory();
  
  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  // const licensedData = JSON.parse(localStorage.getItem('@DeixaNoAzul:licensedId'));

  // Estado que mostra e/ou esconde o formulário de criação
  const [formOpened, setFormOpened] = useState(true);
  
  const [estabelecimentos, setEstabelecimentos] = useState([]);

  // Faz a requisição à API e carrega as informações dos ECs
  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get(`/lista_estabelecimento`, auth).then(response => {
      const arrayData = response.data;
      // console.log(arrayData)

      // const finalData = arrayData.filter(
      //   item => item.licenciado_autorizado_id === licensedData.id
      // );

      setEstabelecimentos(arrayData);
    });

  }, [userToken]);

  /**
   * TABELA DOS ESTABELECIMENTOS (DESKTOP)
   */

  // Informações do cabeçalho da tabela
  const headData = [
    { title: 'Responsável', field: 'resp', sorting: false },
    { title: 'Estabelecimento', field: 'est', sorting: false },
    { title: 'Status', field: 'status', align: 'right', sorting: false },
    { title: '', field: 'edit', align: 'center', sorting: false },
  ];

  // Informações do corpo da tabela
  const bodyData = estabelecimentos.map(establishment => {
    let colorStatus = '';
    if(establishment.status === '0') {colorStatus = "#5CB85C"} // Aprovado
    if(establishment.status === '1') {colorStatus = "#FEAC02"} // Em análise
    if(establishment.status === '2') {colorStatus = "#5097FF"} // Documentos pendentes
    if(establishment.status === '3') {colorStatus = "#8850FF"} // Documentos checados
    if(establishment.status === '4') {colorStatus = "#F35757"} // Reprovado

    let messageStatus = '';
    if(establishment.status === '0') {messageStatus = "Aprovado"} // Aprovado
    if(establishment.status === '1') {messageStatus = "Em análise"} // Em análise
    if(establishment.status === '2') {messageStatus = "Documentos pendentes"} // Documentos pendentes
    if(establishment.status === '3') {messageStatus = "Documentos checados"} // Documentos checados
    if(establishment.status === '4') {messageStatus = "Reprovado"} // Reprovado

    const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    const disabled = establishment.status === '0' ? "disabled" : "abled";

    return {
      resp: establishment.responsavel,
      est: establishment.estabelecimento,
      status: status,
      edit: <Tooltip title="Editar Dados do EC" placement="top" arrow>
          <span className={disabled} onClick={() => editEC(establishment)}><img src={editIcon} alt=""/></span>
        </Tooltip>
    }
  });

  // Faz o filtro dos ECs pelo status
  const filterByStatus = useCallback((value) => {
    const filterValue = value.value;

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    // Se o filtro NÃO for "Todos"
    if (filterValue !== '5') {
      api.get(`/lista_estabelecimento`, auth).then(response => {
        const arrayData = response.data;
  
        // const userId = JSON.parse(localStorage.getItem('@DeixaNoAzul:licensedId'));
  
        /* if (licensedData.id === undefined || null) {
          return
        }; */
  
        /* const finalData = arrayData.filter(
          item => item.licenciado_autorizado_id === licensedData.id
        ); */
  
        const filteredData = arrayData.filter(
          item => item.status === filterValue
        );
  
        setEstabelecimentos(filteredData);
      })
    }

    // Se o filtro for "Todos"
    if (filterValue === '5') {
      api.get(`/lista_estabelecimento`, auth).then(response => {
        const arrayData = response.data;
  
        // const userId = JSON.parse(localStorage.getItem('@DeixaNoAzul:licensedId'));
  
        /* if (licensedData.id === undefined || null) {
          return
        }; */
  
        /* const finalData = arrayData.filter(
          item => item.licenciado_autorizado_id === licensedData.id
        ); */
  
        setEstabelecimentos(arrayData);
      })
    }

  }, [userToken]);

  // Chama a edição de ECs
  const editEC = useCallback((establishment) => {
    // Se estiver aprovada, não segue para a edição
    if (establishment.status === '0') {
      return;
    };

    const idReverse = establishment.id.split('').reverse().join('');
    
    // redireciona para a página de edição
    history.push(`/ec/revendedor/editar/${idReverse}`);
  }, [history]);

  /**
   * FUNÇÕES PARA O MOBILE
   */
  
  // Organiza os status dos estabelecimentos para mostrar na lista mobile
  const estabelecimentoStatus = useCallback((estabelecimento) => {
    let colorStatus = '';
    if(estabelecimento.status === '0') {colorStatus = "#5CB85C"} // Aprovado
    if(estabelecimento.status === '1') {colorStatus = "#FEAC02"} // Em análise
    if(estabelecimento.status === '2') {colorStatus = "#5097FF"} // Documentos pendentes
    if(estabelecimento.status === '3') {colorStatus = "#8850FF"} // Documentos checados
    if(estabelecimento.status === '4') {colorStatus = "#F35757"} // Reprovado

    let messageStatus = '';
    if(estabelecimento.status === '0') {messageStatus = "Aprovado"} // Aprovado
    if(estabelecimento.status === '1') {messageStatus = "Em análise"} // Em análise
    if(estabelecimento.status === '2') {messageStatus = "Documentos pendentes"} // Documentos pendentes
    if(estabelecimento.status === '3') {messageStatus = "Documentos checados"} // Documentos checados
    if(estabelecimento.status === '4') {messageStatus = "Reprovado"} // Reprovado

    return <span style={{ color: colorStatus }}>{messageStatus}</span>
  }, []);

  return (
    <Container>
      <MenuBar active="EC" />
      <NewMobileMenu active="EC" />

      <Page>
        <TopBar pageTitle="Estabelecimento Credenciado" />
        <Content>

          <AddEC>
            <span>Adicionar EC</span>
            <Button onClick={() => setFormOpened(!formOpened)}>
              {formOpened === true ? <MdAdd /> : <MdRemove />}
            </Button>
          </AddEC>

          <FormSection formOpened={formOpened}>
            <FormAddEC />
          </FormSection>

          <TableContent>
            
            <Select
              placeholder="Status"
              onChange={(value) => filterByStatus(value)}
              className="filterStatusSelect"
              options={[
                { value: "0", label: 'Aprovado' },
                { value: "1", label: 'Em Analise' },
                { value: "2", label: 'Doc Pendentes' },
                { value: "3", label: 'Doc Checados' },
                { value: "4", label: 'Reprovado' },
                { value: "5", label: 'Todos' },
              ]}
            />

            <MaterialTable
              title="Lista de Estabelecimentos"
              columns={headData}
              data={bodyData}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    
                  </div>
                )
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                },
                toolbar: {
                  searchPlaceholder: 'Buscar',
                  searchTooltip: 'Buscar'
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum dado encontrado',
                }
              }}
              options={{
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: '#10104F',
                  color: '#FFF'
                },
                emptyValue: "Nenhum dado encontrado"
              }}
            />

          </TableContent>

          <TableContentMobile>
            <Header>
              <h3>Lista de Estabelecimentos</h3>

              <Select
                placeholder="Status"
                onChange={(value) => filterByStatus(value)}
                className="filterStatusSelect" options={[
                  { value: "0", label: 'Aprovado' },
                  { value: "1", label: 'Em Analise' },
                  { value: "2", label: 'Doc Pendentes' },
                  { value: "3", label: 'Doc Checados' },
                  { value: "4", label: 'Reprovado' },
                  { value: "5", label: 'Todos' },
                ]}
              />

              <span>Total: {estabelecimentos.length}</span>
            </Header>
            
            <MobileBody>
              {estabelecimentos.map((estabelecimento) => (
                <Line key={estabelecimento.id}>
                  <span>{estabelecimento.estabelecimento}</span>
                  <p>{estabelecimento.responsavel}</p>
          
                  <div className="footer">
                    <span>{estabelecimentoStatus(estabelecimento)}</span>
                    <img src={editIcon} alt="" onClick={() => editEC(estabelecimento)}/>
                  </div>
                </Line>
              ))}
            </MobileBody>

            {/* <Line>
              <span>Supermercado Martins Lucena  Souza</span>
              <p>Antônio Martins de Lucena Souza Neto</p>

              <div className="footer">
                <span>Aprovado</span>
                <img src={editIcon} alt=""/>
              </div>
            </Line> */}

            {/* <Pagination>
              <span>Total: {total}</span>
              <PaginationButton>
                <PaginationItem><MdNavigateBefore /></PaginationItem>
                {pages.map(page => (
                  <PaginationItem 
                    key={page}
                    onClick={() => setCurrentPage(page)}
                  >{page}</PaginationItem>
                ))}
                <PaginationItem><MdNavigateNext /></PaginationItem>
              </PaginationButton>
            </Pagination> */}

          </TableContentMobile>

        </Content>
      </Page>     
    </Container>
  );
}

export default ECRevendedor;
