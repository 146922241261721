import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdInfoOutline , MdArrowDropDown } from 'react-icons/md';
import api from '../../services/api';

import { useAuth } from '../../hooks/AuthContext';

import avatarImg from '../../assets/images/avatar_placeholder.jpg';
import logoutIcon from '../../assets/images/logout_icon.svg';
import notificationIcon from '../../assets/images/notification.svg';

import {
  Container,
  Title,
  RightSection,
  Notification,
  Profile,
  Avatar,
  DropDown,
  DropDownNotifications,
  Message,
  MobileNotification,
} from './styles';

function TopBar({ pageTitle, pageSubtitle }) {
  const [open, setOpen] = useState(false);
  const [notifyOpen, setNotifyOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState([]);

  const { signOut } = useAuth();

  const userData = JSON.parse(localStorage.getItem('@DeixaNoAzul:loggedUser'));
  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  useEffect(() => {
    function getMessages() {
      api.get('/received_messages/', {
        headers: {
          Authorization : "Bearer "+userToken
        }
      }).then(response => {
        const result = response.data.data;
        // console.log(response);

        const filteredMessages = result.filter(item => item.status === 0);

        setMessages(filteredMessages);
        setUnreadMessages(filteredMessages);
      });
    }

    getMessages()
  }, [userToken]);

  const classNotify = unreadMessages.length === 0 ? "hide notify" : "notify";
  const classRotate = open === false ? "arrow" : "rotate arrow";

  const loggedUser = JSON.parse(
    localStorage.getItem("@DeixaNoAzul:loggedUser")
  );

  const isNotifyBlocked = loggedUser.email === "Bko@deixanoazul.com.br"
    ? true 
    : false;

  return (
    <Container>
      <Title>
        <h1>{pageTitle}</h1>
        <h2>{pageSubtitle}</h2>
      </Title>

      <RightSection>
        {!isNotifyBlocked && (
          <Notification onClick={() => setNotifyOpen(!notifyOpen)}>
            <div className={classNotify}>
              <span>{unreadMessages.length < 100 ? unreadMessages.length : "99+"}</span>
            </div>
            <img src={notificationIcon} alt=""/>
          </Notification>
        )}

        <Profile onClick={() => setOpen(!open)}>
          <Avatar>
            <img src={avatarImg} alt=""/>
          </Avatar>

          <span>{userData.name}</span>

          <div className={classRotate}>
            <MdArrowDropDown />
          </div>
        </Profile>
      </RightSection>

      <DropDownNotifications notifyOpen={notifyOpen}>
        <div className="header">
          <span>Notificações</span>
        </div>

        <Link to="/messages" className="verTodas">
          <button>Ver todas as mensagens</button>
        </Link>

        { unreadMessages.length === 0
          ? <span className="noMessages">Você não tem novas mensagens.</span>
          : messages.map(message => (
            <Link to="/messages">
              <Message key={message.id}>
              <MdInfoOutline size={24} color={message.status === 0 ? '#0073FC' : '#FFF'} />
  
              <div>
                <span className="title">{message.subject}</span>
                <span className="message">{message.body}</span>
                <span className="date">{
                  message.created_at.substring(0, 10).split('-').reverse().join('/')
                }</span>
              </div>
            </Message>
          </Link>
          ))
        }


        {/* <div className="footer">
          <Link to="/messages">Ver todas</Link>
        </div> */}
      </DropDownNotifications>

      <DropDown open={open}>
        <Link to="/profile">Editar perfil</Link>
        <button type="button" onClick={signOut}>
          <img src={logoutIcon} alt="Sair"/>
          Sair
        </button>
      </DropDown>
      
      <MobileNotification onClick={() => setNotifyOpen(!notifyOpen)}>
        <div className={classNotify}>
          <span>{messages.length < 100 ? messages.length : "99+"}</span>
        </div>
        <img src={notificationIcon} alt=""/>
      </MobileNotification>
    </Container>
  );
}

export default TopBar;