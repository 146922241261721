import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { MdHighlightOff } from 'react-icons/md';

import api from '../../services/api';

import { MdKeyboardTab } from 'react-icons/md';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';

import {
  Container,
  Page,
  Content,
  TableContent,
  Infos,
  FirstBox,
  SecondBox,
  ModalSection,
  ModalContent,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
} from './styles';

function ExtratoPagamento() {
  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const loggedUser = JSON.parse(localStorage.getItem('@DeixaNoAzul:loggedUser'));

  const [pagamentos, setPagamentos] = useState([]);
  const [contaDigital, setContaDigital] = useState({
    total_comissao_mes: "",
    total_comissao_ano: ""
  });

  const total_mes = contaDigital.total_comissao_mes.toString().split('.');
  const totalMes = `${total_mes[0]},${total_mes[1] ? total_mes[1].substring(0, 2) : "00"}`

  const total_ano = contaDigital.total_comissao_ano.toString().split('.');
  const totalAno = `${total_ano[0]},${total_ano[1] ? total_ano[1].substring(0, 2) : "00"}`

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/extrato', auth).then(response => {
      const result = response.data;
      console.log(response)

      setPagamentos(result.pagamentos);
      setContaDigital({
        total_comissao_mes: result.total_mes,
        total_comissao_ano: result.total_ano
      })
    });

  }, [userToken]);

  // Informações Head da tabela
  const headData = [
    { title: 'Valor', field: 'valor', sorting: false },
    { title: 'Data solicitação', field: 'solicitacao', sorting: false },
    { title: 'Data pagamento', field: 'pagamento', sorting: false },
    { title: 'Baixar comprovante', field: 'comprovante', sorting: false, align: 'center' },
    { title: 'Status', field: 'status', sorting: false, align: 'center' },
  ];

  // Informações Body da tabela
  // const bodyData =  []
  const bodyData = pagamentos === [] ? []
  : pagamentos.map(pagamento => {
    let colorStatus = '';
    if(pagamento.status === "CANCELADO") {colorStatus = "#F35757"} // Não pago
    if(pagamento.status === "PROCESSANDO") {colorStatus = "#5097FF"} // Processando
    if(pagamento.status === "APROVADO") {colorStatus = "#5CB85C"} // Pago

    let messageStatus = '';
    if(pagamento.status === "CANCELADO") {messageStatus = "Não pago"} // Não pago
    if(pagamento.status === "PROCESSANDO") {messageStatus = "Processando"} // Processando
    if(pagamento.status === "APROVADO") {messageStatus = "Pago"} // Pago

    const status = <span style={{ backgroundColor: colorStatus, color: '#fff', fontSize: '14px', fontWeight: 600, padding: '6px 12px', borderRadius: '3px' }}>{messageStatus}</span>

    const valor_pagamento = pagamento.valor_pagamento.toString().split('.');
    const valorPagamento = `${valor_pagamento[0]},${valor_pagamento[1] ? valor_pagamento[1].substring(0, 2) : "00"}`;

    const dataSolicitacao = pagamento.data_solicitacao.substring(0, 10).split('-').reverse().join('/');
    const dataPagamento = pagamento.data_pagamento === null ? "Esperando aprovação" : pagamento.data_pagamento.substring(0, 10).split('-').reverse().join('/');

    const cursorDownload = pagamento.status === "APROVADO" ? "pointer" : "not-allowed";

    return {
      valor: `R$ ${valorPagamento}`,
      solicitacao: dataSolicitacao,
      pagamento: dataPagamento,
      comprovante: <MdKeyboardTab onClick={() => baixarComprovante(pagamento)} cursor={cursorDownload} style={{ transform: "rotate(90deg)" }} size={30} color="#0073FC"/>,
      status: status,
      // edit: <Tooltip title="Editar Dados do EC" placement="top" arrow><span><MdEdit size={24} style={{cursor: "pointer"}} onClick={() => editEC(establishment)} /></span></Tooltip>
    }
  });

  // Abrir e fechar modal
  const [modalOpened, setModalOpened] = useState(true);

  const [documento, setDocumento] = useState('');

  const baixarComprovante = useCallback((pagamento) => {
    if (pagamento.comprovante === null) {
      return
    }

    setModalOpened(false)

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get(`/documento_download/${pagamento.comprovante}`, auth).then(response => {
      const result = response.data.data;
      
      setDocumento(result);
    })
  }, [userToken]);

  const returnPage = loggedUser.role === "ROLE_LA" ? "/contadigital/la" : "/contadigital/ec";

  function valorPagamento(pagamento) {
    console.log(pagamento)

    const valor_total = pagamento.valor_pagamento.toString().split('.');
    const valorTotal = `${valor_total[0]},${valor_total[1] ? valor_total[1].substring(0, 2) : "00"}`;

    return `R$ ${valorTotal}`
  }

  function transacaoStatus(pagamento) {
    let colorStatus = '';
    if(pagamento.status === "CANCELADO") {colorStatus = "#F35757"} // Não pago
    if(pagamento.status === "PROCESSANDO") {colorStatus = "#5097FF"} // Processando
    if(pagamento.status === "APROVADO") {colorStatus = "#5CB85C"} // Pago

    let messageStatus = '';
    if(pagamento.status === "CANCELADO") {messageStatus = "Não pago"} // Não pago
    if(pagamento.status === "PROCESSANDO") {messageStatus = "Processando"} // Processando
    if(pagamento.status === "APROVADO") {messageStatus = "Pago"} // Pago

    return <span style={{ color: colorStatus }}>{messageStatus}</span>
  }

  return (
    <Container>
      <ModalSection modalOpened={modalOpened}>
        <ModalContent>
          <MdHighlightOff size={30} onClick={() => setModalOpened(!modalOpened)} />
            
          <span>Tem certeza que deseja baixar o documento?</span>
          
          <div className="buttonRow">
            <a href={documento} download>Baixar</a>
            <button className="cancelar" type="button" onClick={() => setModalOpened(!modalOpened)}>Cancelar</button>
          </div>

        </ModalContent>
      </ModalSection>
      
      <MenuBar />
      <NewMobileMenu />
      
      <Page>
        <TopBar pageTitle="Extrato de pagamento" />
        
        <Content>
          
          <TableContent>

          <MaterialTable
            title="Informações de extrato"
            columns={headData}
            data={bodyData}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                </div>
              )
            }}
            localization={{
              pagination: {
                labelRowsSelect: 'linhas',
                labelDisplayedRows: '{from}-{to} de {count}',
                firstTooltip: 'Primeira página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Próxima página',
                lastTooltip: 'Última página'
              },
              toolbar: {
                searchPlaceholder: 'Buscar',
                searchTooltip: 'Buscar'
              },
              body: {
                emptyDataSourceMessage: 'Nenhum dado encontrado',
              }
            }}
            options={{
              search: false,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: '#10104F',
                color: '#FFF'
              },
              emptyValue: "Nenhum dado encontrado"
            }}
          />

          </TableContent>

          <TableContentMobile>
            <Header>
              <h3>Informações de extrato</h3>

              <span>Total: {pagamentos.length}</span>
            </Header>

            <MobileBody>
              {pagamentos.map((pagamento) => (
                <Line key={pagamento.data_solicitacao}>
                  <span>{valorPagamento(pagamento)}</span>
                  <p>Data solicitação: {pagamento.data_solicitacao.substring(0, 10).split('-').reverse().join('/')}</p>
                  <p>Data pagamento: {pagamento.data_pagamento === null ? "Esperando aprovação" : pagamento.data_pagamento.substring(0, 10).split('-').reverse().join('/')}</p>
          
                  {<div className="footer">
                    <span>{transacaoStatus(pagamento)}</span>
                    {pagamento.status === "APROVADO" && <MdKeyboardTab onClick={() => baixarComprovante(pagamento)} style={{ transform: "rotate(90deg)" }} size={18} color="#0073FC"/>}
                  </div>}
                </Line>
              ))}
            </MobileBody>

          </TableContentMobile>

          <Infos>
            <FirstBox>
              <Link to={returnPage}>Voltar</Link>
            </FirstBox>

            <SecondBox>
              <div>
                <label>Total de comissionado no mês</label>
                <p>R$ {totalMes}</p>
              </div>

              <div>
                <label>Total de comissionado no ano</label>
                <p>R$ {totalAno}</p>
              </div>
            </SecondBox>
          </Infos>

        </Content>
      </Page>

    </Container>
  );
}

export default ExtratoPagamento;
