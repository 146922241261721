import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`;

export const BackgroundImg = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > img {
    max-width: 726px;
    width: 100%;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  /* max-width: 600px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    position: absolute;
    bottom: 50px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;
  border-radius: 8px;
  padding: 70px 70px 60px;

  max-width: 500px;

  > img {
    width: 188px;
    height: auto;
    margin-bottom: 30px;
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;

    svg {
      margin-right: 6px;
    }
  }

  @media (max-width: 500px) {
    padding: 60px 20px;

    > img {
      width: 170px;
      height: auto;
      margin-bottom: 30px;
    }
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  p {
    margin-bottom: 12px;
  }
`;
