import React from 'react';
import Dropzone from 'react-dropzone';

import anexoIcon from '../../assets/images/anexo_icon.svg';

import { InputContainer } from './styles';

function UploadComprovante({ onUpload, isDisabled }) {

  const containerClass = isDisabled === true ? "disabled" : "";

  return (
    <Dropzone accept="image/jpeg,image/jpg,image/png,.pdf" onDropAccepted={file => onUpload(file)}>
      { ({ getRootProps, getInputProps }) => (
        <InputContainer
          {...getRootProps()}
          // isDragActive={isDragActive}
          // isDragReject={isDragReject}
          className={containerClass}
        >
          <input {...getInputProps()} disabled={isDisabled} />
          <img src={anexoIcon} alt=""/>
          Escolher arquivo
        </InputContainer>
      ) }
    </Dropzone>
  );
}

export default UploadComprovante;
