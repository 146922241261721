import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {FaSquareFull, FaRegHandshake} from 'react-icons/fa';
import {
  FiCreditCard,
  FiDollarSign,
  FiFileText,
  FiTag,
  FiPercent,
} from 'react-icons/fi';

import api from '../../services/api';

import logoImg from '../../assets/images/logo_white.png';
import transacaoIcon from '../../assets/images/transacao_new_icon.svg';
import cadastroLaIcon from '../../assets/images/licenciados_icon.svg';
import pagamentosIcon from '../../assets/images/pagamentos_new_icon.svg';
import calculadora from '../../assets/images/calculadora_new.svg';

import {
  Container,
  Logo,
  Nav,
  MenuItem,
  MenuItemPagamento,
  SubMenuItem,
  SideMenu,
  TitleSubMenu,
} from './styles';
import {useActiveMenu} from '../../hooks/ActiveMenu';

const useOutsideClick = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref]);

  return ref;
};

function MenuBar({active, subMenuActive}) {
  const loggedUser = JSON.parse(
    localStorage.getItem('@DeixaNoAzul:loggedUser')
  );

  const {activeSideMenu, setActiveSideMenu} = useActiveMenu();
  // const loggedEstabelecimento = JSON.parse(localStorage.getItem('@DeixaNoAzul:estabelecimentoId'));

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const [pagamentosAbertos, setPagamentosAbertos] = useState([]);
  const [seletedPage, setSeletedPage] = useState('');

  // const [displayContaDigitalEC, setDisplayContaDigitalEC] = useState('flex');

  useEffect(() => {
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    if (loggedUser.role === 'ROLE_ADMIN') {
      api.get('/pagamento', auth).then((response) => {
        const result = response.data;
        const pagamentosProcessando = result.filter(
          (item) => item.status === 'PROCESSANDO'
        );
        // console.log('pagamentos', response);

        setPagamentosAbertos(pagamentosProcessando);
      });
    }

    /* if (loggedUser.role === "ROLE_EC") {

      if (loggedEstabelecimento.licenciado_autorizado_id === 'a1426c43-1680-41d4-a6aa-c142e0e461b2') {
        setDisplayContaDigitalEC('none');
      }
    } */
  }, [loggedUser.role, userToken]);

  const classNotify =
    pagamentosAbertos.length === 0 ? 'hide notify' : 'notify';

  const [acessoAdmin, setAcessoAdmin] = useState({
    transacoes: true,
    conta_luz: true,
    certificado_digital: true,
    fgts: true,
    prepago: false, // mudar pra true qnd liberar o e-prepag
    serasa: true,
    licenciados: true,
    revendedores: true,
    estabelecimentos: true,
    pagamentos: true,
    parceiros: true,
    taxas_simulacao: true,
    taxas_comissao: true,
  });

  const isBlocked =
    loggedUser.email === 'Bko@deixanoazul.com.br' ||
    loggedUser.email === 'rogerio@one-consultoria.com' ||
    loggedUser.email === 'ana.pascale@prismapromotora.com.br'
      ? true
      : false;

  useEffect(() => {
    // Acesso exclusivo ao certificado digital
    if (
      loggedUser.email === 'rogerio@one-consultoria.com' ||
      loggedUser.id === '269c9c16-fa72-445c-b437-24bb6f11c29a'
    ) {
      setAcessoAdmin({
        transacoes: false,
        conta_luz: false,
        certificado_digital: true,
        fgts: false,
        prepago: false,
        serasa: false,
        licenciados: false,
        revendedores: false,
        estabelecimentos: false,
        pagamentos: false,
        parceiros: false,
        taxas_simulacao: false,
        taxas_comissao: false,
      });
    }

    // Acesso exclusivo ao FGTS
    if (
      loggedUser.email === 'ana.pascale@prismapromotora.com.br' ||
      loggedUser.id === '4006cba8-13f5-4e21-ba8b-30ddc26ca562'
    ) {
      setAcessoAdmin({
        transacoes: false,
        conta_luz: false,
        certificado_digital: false,
        fgts: true,
        prepago: false,
        licenciados: false,
        revendedores: false,
        estabelecimentos: false,
        pagamentos: false,
        parceiros: false,
        taxas_simulacao: false,
        taxas_comissao: false,
      });
    }
  }, [loggedUser.email, loggedUser.id]);

  function showSideMenu() {
    setActiveSideMenu(true);
  }

  function hideSideMenu() {
    setActiveSideMenu(false);
  }

  const handleClickOutside = () => {
    hideSideMenu();
  };

  const refClicableArea = useOutsideClick(handleClickOutside);

  const handleClick = () => {
    // showSideMenu();
  };

  const handleClickRemoveSideMenu = (event) => {
    event.stopPropagation();
  };

  return (
    <Container
      onMouseOver={activeSideMenu && showSideMenu}
      onClick={handleClickRemoveSideMenu}
    >
      <SideMenu
        active={
          (seletedPage === 'AdminTransações' ||
            seletedPage === 'TaxaComissao' ||
            seletedPage === 'Transações') &&
          activeSideMenu === true
        }
        onMouseOver={showSideMenu}
        onMouseOut={hideSideMenu}
      >
        <TitleSubMenu>
          {(seletedPage === 'AdminTransações' ||
            seletedPage === 'Transações') &&
            'Transações'}
          {seletedPage === 'TaxaComissao' && 'Taxas'}
        </TitleSubMenu>

        {/* Subs menus de Transacoes ADMIN */}
        {seletedPage === 'AdminTransações' &&
          loggedUser.role === 'ROLE_ADMIN' && (
            <>
              {/* Submenu Trasacao ADMIN */}
              {acessoAdmin.transacoes && (
                <Link
                  to="/admin/transacoes"
                  className={
                    loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
                  }
                  onClick={hideSideMenu}
                >
                  <SubMenuItem
                    sideMenu={true}
                    active={subMenuActive === 'Deixa no Azul'}
                    style={{marginTop: 20}}
                  >
                    <span>Deixa no Azul</span>
                  </SubMenuItem>
                </Link>
              )}

              {/* Submenu Trasacao ADMIN */}
              {acessoAdmin.conta_luz && (
                <Link
                  to="/admin/conta-luz"
                  className={
                    loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
                  }
                  onClick={hideSideMenu}
                >
                  <SubMenuItem
                    sideMenu={true}
                    style={{marginBottom: 0, marginTop: -15}}
                    active={subMenuActive === 'Conta de Luz'}
                  >
                    <span>Contas de Luz</span>
                  </SubMenuItem>
                </Link>
              )}

              {/* Submenu Trasacao ADMIN */}
              {acessoAdmin.certificado_digital && (
                <Link
                  to="/admin/certificado-digital"
                  className={
                    loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
                  }
                  onClick={hideSideMenu}
                >
                  <SubMenuItem
                    sideMenu={true}
                    active={subMenuActive === 'Certificado Digital'}
                  >
                    <span>Certificado Digital</span>
                  </SubMenuItem>
                </Link>
              )}

              {/* Submenu Trasacao ADMIN */}
              {acessoAdmin.fgts && (
                <Link
                  to="/admin/saque-fgts"
                  className={
                    loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
                  }
                  onClick={hideSideMenu}
                >
                  <SubMenuItem
                    sideMenu={true}
                    style={{marginTop: -15}}
                    active={subMenuActive === 'Saque FGTS'}
                  >
                    <span>Saque FGTS</span>
                  </SubMenuItem>
                </Link>
              )}

              {/* Submenu Trasacao ADMIN */}
              {acessoAdmin.prepago && (
                <Link
                  to="/admin/eprepag"
                  className={
                    loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
                  }
                  onClick={hideSideMenu}
                >
                  <SubMenuItem
                    sideMenu={true}
                    style={{marginTop: -15}}
                    active={subMenuActive === 'E-prepag'}
                  >
                    <span>Prepago</span>
                  </SubMenuItem>
                </Link>
              )}

              {/* Submenu Trasacao ADMIN */}
              {acessoAdmin.serasa && (
                <Link
                  to="/admin/consulta-serasa"
                  className={
                    loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
                  }
                  onClick={hideSideMenu}
                >
                  <SubMenuItem
                    sideMenu={true}
                    style={{marginTop: -15}}
                    active={subMenuActive === 'Consulta Serasa'}
                  >
                    <span>Limpa Nome</span>
                  </SubMenuItem>
                </Link>
              )}
            </>
          )}

        {/* Subs menus de Taxas de Transacoes ADMIN */}
        {seletedPage === 'TaxaComissao' && (
          <>
            {/* Submenu Taxa Admin */}
            {!isBlocked && (
              <Link
                to="/admin/taxacomissao/Transacao"
                className={
                  loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
                }
                onClick={hideSideMenu}
              >
                <SubMenuItem
                  sideMenu={true}
                  style={{marginTop: 20}}
                  active={subMenuActive === 'Deixa no Azul Taxas'}
                >
                  <span>Deixa no Azul</span>
                </SubMenuItem>
              </Link>
            )}

            {/* Submenu Taxa Admin */}
            {!isBlocked && (
              <Link
                to="/admin/taxacomissao/TransacaoPagamentoContaDeLuz"
                className={
                  loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
                }
                onClick={hideSideMenu}
              >
                <SubMenuItem
                  sideMenu={true}
                  style={{marginTop: -15}}
                  active={subMenuActive === 'Contas de Luz Taxas'}
                >
                  <span>Contas de Luz</span>
                </SubMenuItem>
              </Link>
            )}

            {/* Submenu Taxa Admin */}
            {!isBlocked && (
              <Link
                to="/admin/taxacomissao/TransacaoCertificadoDigital"
                className={
                  loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
                }
                onClick={hideSideMenu}
              >
                <SubMenuItem
                  sideMenu={true}
                  style={{marginTop: -15}}
                  active={
                    subMenuActive === 'Certificado Digital Taxas'
                  }
                >
                  <span>Certificado Digital</span>
                </SubMenuItem>
              </Link>
            )}

            {/* Submenu Taxa Admin */}
            {!isBlocked && (
              <Link
                to="/admin/taxacomissaofgts"
                className={
                  loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
                }
                onClick={hideSideMenu}
              >
                <SubMenuItem
                  sideMenu={true}
                  style={{marginTop: -15}}
                  active={subMenuActive === 'FGTS Taxas'}
                >
                  <span>FGTS</span>
                </SubMenuItem>
              </Link>
            )}
          </>
        )}

        {/* Subs menus Transacoes EC */}
        {seletedPage === 'Transações' && (
          <>
            {/* Submenu Transações EC */}
            <Link
              to="/transacoes"
              className={
                loggedUser.role === 'ROLE_EC' ? '' : 'disabled'
              }
              onClick={hideSideMenu}
            >
              <SubMenuItem
                sideMenu={true}
                style={{marginBottom: 10, marginTop: 30}}
                active={subMenuActive === 'Deixa no Azul'}
              >
                <span>Deixa no Azul</span>
              </SubMenuItem>
            </Link>

            {/* Submenu Transações EC */}
            <Link
              to="/conta-de-luz"
              className={
                loggedUser.role === 'ROLE_EC' ? '' : 'disabled'
              }
              onClick={hideSideMenu}
            >
              <SubMenuItem
                sideMenu={true}
                style={{marginBottom: 10}}
                active={subMenuActive === 'Conta de Luz'}
              >
                <span>Contas de Luz</span>
              </SubMenuItem>
            </Link>

            {/* Submenu Transações EC  */}
            <Link
              to="/certificado-digital"
              className={
                loggedUser.role === 'ROLE_EC' ? '' : 'disabled'
              }
              onClick={hideSideMenu}
            >
              <SubMenuItem
                sideMenu={true}
                active={subMenuActive === 'Certificado Digital'}
              >
                <span>Certificado Digital</span>
              </SubMenuItem>
            </Link>

            {/* Submenu Transações EC */}
            <Link
              to="/eprepag"
              className={
                loggedUser.role === 'ROLE_EC' ? '' : 'disabled'
              }
              onClick={hideSideMenu}
            >
              <SubMenuItem
                sideMenu={true}
                style={{marginTop: -15}}
                active={subMenuActive === 'E-prepag'}
              >
                <span>E-prepag</span>
              </SubMenuItem>
            </Link>
          </>
        )}

        {/* Subs menus Transacoes Parceiros */}
        {seletedPage === 'AdminTransações' &&
          loggedUser.role === 'ROLE_PT' && (
            <>
              {/* Submenu PARCEIRO */}
              <Link
                to="/parceiro/transacoes"
                className={
                  loggedUser.role === 'ROLE_PT' ? '' : 'disabled'
                }
                onClick={hideSideMenu}
              >
                <SubMenuItem
                  style={{marginBottom: -5, marginTop: 20}}
                  active={subMenuActive === 'Deixa no Azul'}
                >
                  <span>Deixa no Azul</span>
                </SubMenuItem>
              </Link>

              {/* Submenu PARCEIRO */}
              <Link
                to="/parceiro/conta-luz"
                className={
                  loggedUser.role === 'ROLE_PT' ? '' : 'disabled'
                }
                onClick={hideSideMenu}
              >
                <SubMenuItem
                  style={{marginBottom: -5}}
                  active={subMenuActive === 'Conta de Luz'}
                >
                  <span>Contas de Luz</span>
                </SubMenuItem>
              </Link>

              {/* Submenu PARCEIRO */}
              <Link
                to="/parceiro/certificado-digital"
                className={
                  loggedUser.role === 'ROLE_PT' ? '' : 'disabled'
                }
                onClick={hideSideMenu}
              >
                <SubMenuItem
                  style={{marginBottom: -5}}
                  active={subMenuActive === 'Certificado Digital'}
                >
                  <span>Certificado Digital</span>
                </SubMenuItem>
              </Link>

              {/* Submenu PARCEIRO */}
              <Link
                to="/parceiro/saque-fgts"
                className={
                  loggedUser.role === 'ROLE_PT' ? '' : 'disabled'
                }
                onClick={hideSideMenu}
              >
                <SubMenuItem active={subMenuActive === 'Saque FGTS'}>
                  <span>Saque FGTS</span>
                </SubMenuItem>
              </Link>
            </>
          )}
      </SideMenu>

      <Logo>
        <img src={logoImg} alt="Deixa no azul" />
      </Logo>

      <Nav ref={refClicableArea} onClick={handleClick}>
        {/* Estabelecimentos LA */}
        <Link
          to="/ec"
          className={loggedUser.role === 'ROLE_LA' ? '' : 'disabled'}
        >
          <MenuItem className={active === 'EC' ? 'active' : ''}>
            <FiFileText color="#08BBE9" size={22} />
            <span>Estabelecimentos</span>
          </MenuItem>
        </Link>

        {/* Conta digital do LA */}
        <Link
          to="/contadigital/la"
          className={loggedUser.role !== 'ROLE_LA' ? 'disabled' : ''}
        >
          <MenuItem className={active === 'CD' ? 'active' : ''}>
            <FiDollarSign color="#08BBE9" size={22} />
            <span>Conta digital</span>
          </MenuItem>
        </Link>

        {/* LA Transações */}
        <Link
          style={{display: 'none'}}
          to="/eprepag"
          className={loggedUser.role === 'ROLE_LA' ? '' : 'disabled'}
        >
          <MenuItem
            className={active === 'Transações' ? 'active' : ''}
          >
            <img src={transacaoIcon} alt="Transações" />
            <span>Transações</span>
          </MenuItem>
        </Link>

        {/* Submenu LA */}
        <Link
          style={{display: 'none'}}
          to="/eprepag"
          className={loggedUser.role === 'ROLE_LA' ? '' : 'disabled'}
        >
          <SubMenuItem style={{marginTop: -15}}>
            <FaSquareFull
              color={
                subMenuActive === 'E-prepag'
                  ? '0073FC'
                  : 'transparent'
              }
              size={10}
            />
            <span
              style={{
                fontWeight:
                  subMenuActive === 'E-prepag' ? 'bold' : 'normal',
              }}
            >
              E-prepag
            </span>
          </SubMenuItem>
        </Link>

        {/* EC Simulador*/}
        <Link
          to="/simulador"
          className={loggedUser.role === 'ROLE_EC' ? '' : 'disabled'}
        >
          <MenuItem
            className={active === 'Simulador' ? 'active' : ''}
          >
            <FiCreditCard color="#08BBE9" size={22} />
            {/* <img src={simuladorIcon} alt="Simulador" /> */}
            <span>Simulador</span>
          </MenuItem>
        </Link>

        {/* EC Transações*/}
        <Link
          to="#"
          className={loggedUser.role === 'ROLE_EC' ? '' : 'disabled'}
          onClick={showSideMenu}
          onMouseOver={() => {
            setSeletedPage('Transações');
            showSideMenu();
          }}
        >
          <MenuItem
            className={active === 'Transações' ? 'active' : ''}
          >
            <img src={transacaoIcon} alt="Transações" />
            <span>Transações</span>
          </MenuItem>
        </Link>

        {/* Conta digital do EC */}
        <Link
          to="/contadigital/ec"
          className={loggedUser.role !== 'ROLE_EC' ? 'disabled' : ''}
        >
          <MenuItem className={active === 'CD' ? 'active' : ''}>
            <FiDollarSign color="#08BBE9" size={22} />
            <span>Conta digital</span>
          </MenuItem>
        </Link>

        {/* Estabelecimentos do Revendedor */}
        <Link
          to="/ec/revendedor"
          className={loggedUser.role === 'ROLE_RV' ? '' : 'disabled'}
        >
          <MenuItem className={active === 'EC' ? 'active' : ''}>
            <FiFileText color="#08BBE9" size={22} />
            <span>Estabelecimentos</span>
          </MenuItem>
        </Link>

        {/* Conta digital do Revendedor */}
        <Link
          to="/contadigital/revendedor"
          className={loggedUser.role !== 'ROLE_RV' ? 'disabled' : ''}
        >
          <MenuItem className={active === 'CD' ? 'active' : ''}>
            <FiDollarSign color="#08BBE9" size={22} />
            <span>Conta digital</span>
          </MenuItem>
        </Link>

        {/* ADMIN */}
        <Link
          to="#"
          // to="/admin/transacoes-novas"
          className={
            loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
          }
          onClick={showSideMenu}
          onMouseOver={() => {
            setSeletedPage('AdminTransações');
            showSideMenu();
          }}
        >
          <MenuItem
            className={active === 'AdminTransações' ? 'active' : ''}
            style={{marginBottom: 15}}
          >
            <img src={transacaoIcon} alt="Transações" />
            <span>Transações</span>
          </MenuItem>
        </Link>

        {acessoAdmin.parceiros && (
          <Link
            to="/admin/parceiros"
            className={
              loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
            }
          >
            <MenuItemPagamento
              className={active === 'Parceiros' ? 'active' : ''}
            >
              <FaRegHandshake color="#08BBE9" size={22} />
              <span>Licenciados 01</span>
            </MenuItemPagamento>
          </Link>
        )}

        {acessoAdmin.licenciados && (
          <Link
            to="/admin/licenciados"
            className={
              loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
            }
          >
            <MenuItem
              className={active === 'CadastroLA' ? 'active' : ''}
            >
              <img src={cadastroLaIcon} alt="Cadastro LA" />
              <span>Licenciados 02</span>
            </MenuItem>
          </Link>
        )}

        {acessoAdmin.revendedores && (
          <Link
            to="/admin/revendedor"
            className={
              loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
            }
          >
            <MenuItem
              className={active === 'Revendedores' ? 'active' : ''}
            >
              <FiTag color="#08BBE9" size={22} />
              <span>Licenciados 03</span>
            </MenuItem>
          </Link>
        )}

        {acessoAdmin.estabelecimentos && (
          <Link
            to="/admin/estabelecimentos"
            className={
              loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
            }
          >
            <MenuItem
              className={active === 'AnaliseEC' ? 'active' : ''}
            >
              <FiFileText color="#08BBE9" size={22} />
              <span>Estabelecimentos</span>
            </MenuItem>
          </Link>
        )}

        {acessoAdmin.pagamentos && (
          <Link
            to="/admin/pagamentos"
            className={
              loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
            }
          >
            <MenuItemPagamento
              className={active === 'Pagamentos' ? 'active' : ''}
            >
              <div className={classNotify}>
                <span>
                  {pagamentosAbertos.length < 100
                    ? pagamentosAbertos.length
                    : '99+'}
                </span>
              </div>

              <img
                src={pagamentosIcon}
                alt="Pagamentos"
                style={{width: 22}}
              />
              <span>Pagamentos</span>
            </MenuItemPagamento>
          </Link>
        )}

        {/* Taxas de Simulação */}
        {!isBlocked && (
          <Link
            to="/admin/taxadesimulacao"
            className={
              loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
            }
          >
            <MenuItemPagamento
              className={active === 'TaxaDeSilumacao' ? 'active' : ''}
            >
              <FiPercent color="#08BBE9" size={22} />
              <span>Taxas de Simulação</span>
            </MenuItemPagamento>
          </Link>
        )}

        {/* Taxas de Comissão */}
        {!isBlocked && (
          <Link
            to="#"
            className={
              loggedUser.role === 'ROLE_ADMIN' ? '' : 'disabled'
            }
            onClick={showSideMenu}
            onMouseOver={() => {
              setSeletedPage('TaxaComissao');
              showSideMenu();
            }}
          >
            <MenuItem
              className={active === 'TaxaComissao' ? 'active' : ''}
            >
              <img
                style={{width: 18}}
                src={calculadora}
                alt="TaxaComissao"
              />
              <span>Taxas de Comissão</span>
            </MenuItem>
          </Link>
        )}

        {/* Submenu */}
        {/* <Link
          to="/admin/taxacomissaoeprepag"
          className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
        >
          <SubMenuItem style={{marginTop: -15}}>
            <FaSquareFull color={subMenuActive === "E-prepag Taxas" ? "0073FC" : "transparent"} size={10} />
            <span
              style={{
                fontWeight: subMenuActive === "E-prepag Taxas" ? "bold" : "normal"
              }}
            >
              E-prepag
            </span>
          </SubMenuItem>
        </Link> */}

        {/* PARCEIRO */}
        <Link
          to="#"
          className={loggedUser.role === 'ROLE_PT' ? '' : 'disabled'}
          onClick={showSideMenu}
          onMouseOver={() => {
            setSeletedPage('AdminTransações');
            showSideMenu();
          }}
        >
          <MenuItem
            className={active === 'AdminTransações' ? 'active' : ''}
          >
            <img src={transacaoIcon} alt="Transações" />
            <span>Transações</span>
          </MenuItem>
        </Link>

        {/* Revendedores PARCEIRO */}
        <Link
          to="/parceiro/revendedores"
          className={loggedUser.role === 'ROLE_PT' ? '' : 'disabled'}
        >
          <MenuItem
            className={active === 'Revendedores' ? 'active' : ''}
          >
            <FiTag color="#08BBE9" size={22} />
            <span>Revendedores</span>
          </MenuItem>
        </Link>

        {/* Estabelecimentos PARCEIRO */}
        <Link
          to="/parceiro/estabelecimentos"
          className={loggedUser.role === 'ROLE_PT' ? '' : 'disabled'}
        >
          <MenuItem
            className={active === 'AnaliseEC' ? 'active' : ''}
          >
            <FiFileText color="#08BBE9" size={22} />
            <span>Estabelecimentos</span>
          </MenuItem>
        </Link>

        {/* Conta digital do Parceiro */}
        <Link
          // style={{ display: 'none' }}
          to="/parceiro/contadigital"
          className={loggedUser.role !== 'ROLE_PT' ? 'disabled' : ''}
        >
          <MenuItem className={active === 'CD' ? 'active' : ''}>
            <FiDollarSign color="#08BBE9" size={22} />
            <span>Conta digital</span>
          </MenuItem>
        </Link>
      </Nav>
    </Container>
  );
}

export default MenuBar;
