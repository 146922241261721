import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #10104F;

  display: flex;
  align-items: center;
  /* justify-content: center; */

  padding-left: 10%;

  > span {
    position: absolute;
    bottom: 40px;
    font-family: 'DM Sans', sans-serif;
    color: #ffffff;
  }
`;

export const BackgroundImg = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;

  > img {
    width: 72%;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  
  width: 30%;

  h1 {
    font-family: 'DM Sans', sans-serif;
    font-size: 26px;
    color: #ffffff;
    margin-bottom: 30px;
  }
  
  > span {
    font-family: 'DM Sans', sans-serif;
    font-size: 26px;
    line-height: 1.1em;
    margin-bottom: 30px;
    color: #ffffff;

    blue {
      color: #08BBE9;
      font-weight: 700;
    }
  }
`;

