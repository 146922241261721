import React, { createContext, useState, useContext } from 'react';

const PagamentoInfosContext = createContext();

function PagamentoInfosProvider({ children }) {
  const [pagamentoInfos, setPagamentoInfos] = useState([]);
  const [pagamentoInfosDadosBancarios, setPagamentoInfosDadosBancarios] = useState([]);
  const [pagamentoInfosNotaFiscal, setPagamentoInfosNotaFiscal] = useState([]);

  return (
    <PagamentoInfosContext.Provider
      value={{
        pagamentoInfos, setPagamentoInfos,
        pagamentoInfosDadosBancarios, setPagamentoInfosDadosBancarios,
        pagamentoInfosNotaFiscal, setPagamentoInfosNotaFiscal,
      }}
    >
      {children}
    </PagamentoInfosContext.Provider>
  );
};

function usePagamentoInfos() {
  const context = useContext(PagamentoInfosContext);
  const {
    pagamentoInfos, setPagamentoInfos,
    pagamentoInfosDadosBancarios, setPagamentoInfosDadosBancarios,
    pagamentoInfosNotaFiscal, setPagamentoInfosNotaFiscal,
  } = context;
  return {
    pagamentoInfos, setPagamentoInfos,
    pagamentoInfosDadosBancarios, setPagamentoInfosDadosBancarios,
    pagamentoInfosNotaFiscal, setPagamentoInfosNotaFiscal,
  };
};

export { PagamentoInfosProvider, usePagamentoInfos };
