import {createGlobalStyle} from 'styled-components';

import 'react-circular-progressbar/dist/styles.css';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #F5F8FB;
    color: #373A3C;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, select, a {
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }

  strong {
    font-weight: 700;
  }

  button {
    cursor: pointer;
  }

  .rdrDateDisplayItemActive {
    border: 1px solid #0073FC;
  }

  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    background: #0073FC;
  }

  .rdrDefinedRangesWrapper .rdrStaticRangeSelected{
    color: #0073FC;
  }

  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
    border: 1px solid #0073FC;
  }
`;
