import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { MdErrorOutline } from 'react-icons/md';
import ReCAPTCHA from "react-google-recaptcha";

import api from '../../services/api';

import { useToast } from '../../hooks/ToastContext';

import backgroundImg from '../../assets/images/acompanhamento_login.png'
import logoImg from '../../assets/images/logo.png';

import {
  Container,
  BackgroundImg,
  Content,
  Box,
  InputArea,
  InputContainer,
  Error,
} from './styles';

function Acompanhamento() {
  const { addToast } = useToast();

  const history = useHistory();

  const token = localStorage.getItem('@DeixaNoAzul:accessToken');

  function verificarUsuarioLogado(props) {

    if (token) {
      return `/user/acompanhamento/transacao/${props}`;
    } else {
      return `/acompanhamento/transacao/${props}`;
    }
  };

  const [recaptchaMarked, setRecaptchaMarked] = useState(false);

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data) => {
    
    if (recaptchaMarked === false) {
      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Você precisa marcar o reCAPTCHA confirmando que você não é um robô.',
      });

      return
    }
    
    const response = await api.get(`/consulta_transacao/${data.id_transacao}`);

    const result = response.data;

    if (result.length === 0) {
      addToast({
        type: 'error',
        title: 'Erro ao acessar transação',
        description: 'Você precisa indicar um id de transação válido para ter acesso.',
      });

      return
    }

    history.push(verificarUsuarioLogado(result[0].id));
  };
  const onError = (errors) => {
    // console.log(errors);
    
    addToast({
      type: 'error',
      title: 'Erro ao acessar transação',
      description: 'O campo "Id da transação" é obrigatório.',
    });
  };

  // Função chamada quando um usuário marca o reCaptcha
  function changeRecaptcha() {
    setRecaptchaMarked(true);
  };

  return (
    <Container>
      <BackgroundImg>
        <img src={backgroundImg} alt="Deixa no azul"/>
      </BackgroundImg>

      <Content>
        <Box>

          <img src={logoImg} alt="Deixa no Azul" />

          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <InputArea>
              <label>*Id da transação</label>
              <InputContainer style={{ borderColor: errors.id_transacao && "red" }}>
                <input
                  name="id_transacao"
                  type="text"
                  ref={register({ required: true })}
                  placeholder="Digite o id da transação"
                />
              {errors.id_transacao && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
              </InputContainer>
            </InputArea>

            <ReCAPTCHA
              sitekey="6LeHye8ZAAAAAPm9wlM9Ra14FTanm7ewxhEmIWAu"
              onChange={changeRecaptcha}
            />

            <button type="submit">Acessar</button>
          </form>

          <Link to="/">Voltar para o login</Link>

        </Box>

        <span>© 2020. Todos os direitos reservados</span>
      </Content>
    </Container>
  );
}

export default Acompanhamento;