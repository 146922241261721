import React, { useState, useCallback, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
// import { Tooltip } from '@material-ui/core/';
// import { MdFiberManualRecord } from 'react-icons/md';
// import { FaEye } from 'react-icons/fa';

import api from '../../services/api';

// import TopBar from '../../components/TopBar';
// import MenuBar from '../../components/MenuBar';
// import NewMobileMenu from '../../components/NewMobileMenu';
import FormatarValor from '../../components/FormatarValor';

// import editIcon from '../../assets/images/edit_icon.svg';

import {
  Container,
  // Page,
  // Content,
  TableContent,
  Infos,
  Box,
  InfosArea,
  Navegador,
  BotãoAba,
} from './styles';

function ParceiroTransacoes() {
  // const history = useHistory();

  const [transacoes, setTransacoes] = useState([]);
  // const [estabelecimentos, setEstabelecimentos] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const parceiroInfos = JSON.parse(localStorage.getItem('@DeixaNoAzul:parceiroId'));

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get(`partner/transaction/report/${parceiroInfos.id}`, auth).then(response => {
      const result = response.data.data;
      console.log('transacoes', result);

      setTransacoes(result);
    });

  }, [parceiroInfos.id, userToken]);

  // Informações Head da tabela
  const headData = [
    { title: 'Responsável', field: 'resp', sorting: false },
    { title: 'Estabelecimento', field: 'est', sorting: false },
    { title: 'Licenciado Autorizado', field: 'la', sorting: false },
    { title: 'Valor', field: 'valor', sorting: false },
    { title: 'Data', field: 'data', sorting: false },
    // { title: 'Status', field: 'status', align: 'right', sorting: false },
    { title: '', field: 'edit', align: 'center', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = transacoes === [] || transacoes === "Nenhuma transação encontrada" ? []
  : transacoes.map(transacao => {

    // let colorStatus = '';
    // if(transacao.status === 1) {colorStatus = "#FEAC02"} // Em análise
    // if(transacao.status === 2) {colorStatus = "#5097FF"} // Solicitar documentos
    // if(transacao.status === 3) {colorStatus = "#5CB85C"} // Aprovada
    // if(transacao.status === 4) {colorStatus = "#F35757"} // Cancelada
    // if(transacao.status === 5) {colorStatus = "#8850FF"} // Checagem financeira

    // let messageStatus = '';
    // if(transacao.status === 1) {messageStatus = "Em análise"} // Em análise
    // if(transacao.status === 2) {messageStatus = "Solicitar documentos"} // Solicitar documentos
    // if(transacao.status === 3) {messageStatus = "Aprovada"} // Aprovada
    // if(transacao.status === 4) {messageStatus = "Cancelada"} // Cancelada
    // if(transacao.status === 5) {messageStatus = "Checagem financeira"} // Checagem financeira

    // console.log(transacao);

    // const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    // const valor = <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {totalTransação}</span>;

    // const imgEdit = transacao.status === 3 ? <FaEye color="#333333" size={21} /> : <img src={editIcon} alt=""/>;
    // const iconTooltip = transacao.status === 3 ? "Ver Dados da Transação" : "Editar Dados da Transação";

    const dataTransacao = transacao.created_at.substring(0, 10).split('-').reverse().join('/');
    const horaTransacao = transacao.created_at.substring(11, 16);

    const dataEHora = <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataTransacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaTransacao}</span>
    </div>;

    return {
      resp: transacao.cliente,
      est: transacao.estabelecimento,
      la: transacao.licenciado,
      valor: <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {FormatarValor(transacao.valor_final)}</span>,
      data: dataEHora,
      // status: status,
      /* edit: <Tooltip title={iconTooltip} placement="top" arrow><span style={{ cursor: "pointer" }} onClick={() => editTransacao(transacao)}>
            { imgEdit }
        </span></Tooltip> */
    }
  });

  /* const editTransacao = useCallback((transacao) => {
    // const idReverse = transacao.id_transacao.split('').reverse().join('');

    // redireciona para a página de edição
    // history.push(`/admin/transacoes/editar/${idReverse}`);
  }, []); */

  const filterByStatus = useCallback((value) => {
    const filterValue = Number(value.value);

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    if (filterValue !== 0) {
      api.get('/transacoes_admin', auth).then(response => {
        const valueTransacoes = response.data;

        const filteredData = valueTransacoes.filter(item => item.status === filterValue);
  
        setTransacoes(filteredData);
      })
    }

    if (filterValue === 0) {
      api.get('/transacoes_admin', auth).then(response => {
        const valueTransacoes = response.data;

        setTransacoes(valueTransacoes);
      })
    }

  }, [userToken]);

  // Abas START
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [tab4, setTab4] = useState(false);

  const open1 = () => {
    setTab1(true)
    setTab2(false)
    setTab3(false)
    setTab4(false)
  }
  const open2 = () => {
    setTab1(false)
    setTab2(true)
    setTab3(false)
    setTab4(false)
  }
  const open3 = () => {
    setTab1(false)
    setTab2(false)
    setTab3(true)
    setTab4(false)
  }
  const open4 = () => {
    setTab1(false)
    setTab2(false)
    setTab3(false)
    setTab4(true)
  }
  // Abas END

  return (
    <Container>
      {/* <Content> */}
      <Infos style={{ display: 'none' }}>
        <Box>
          <InfosArea>
            <label>Quantidade de Transações</label>
            <span>236</span>
          </InfosArea>
        </Box>

        <Box>
          <InfosArea>
            <label>Valor Total de Transações</label>
            <span>R$ 10.523,69</span>
          </InfosArea>
        </Box>

        <Box>
          <Navegador>
            <BotãoAba className="first" tab1={tab1} onClick={open1}>TOTAL</BotãoAba>
            <BotãoAba tab2={tab2} onClick={open2}>LICENCIADOS</BotãoAba>
            <BotãoAba tab3={tab3} onClick={open3}>REVENDEDORES</BotãoAba>
            <BotãoAba className="last" tab4={tab4} onClick={open4}>ESTABELECIMENTOS</BotãoAba>
          </Navegador>
          
          <InfosArea>
            <label>Quantidade de Comissões</label>
            <span>200</span>
          </InfosArea>

          <div className="separador" />

          <InfosArea>
            <label>Valor Total de Comissões</label>
            <span>R$ 1.523,69</span>
          </InfosArea>
        </Box>
      </Infos>

      <TableContent>

        <Select
          placeholder="Selecione"
          onChange={(value) => filterByStatus(value)}
          className="filterStatusSelect"
          options={[
            { value: '1', label: 'Em Analise' },
            { value: '2', label: 'Solicitar documentos' },
            { value: '3', label: 'Aprovada' },
            { value: '4', label: 'Cancelada' },
            { value: '5', label: 'Checagem financeira' },
            { value: '0', label: 'Todos' }
          ]}
        />

        <MaterialTable
          title="Lista de Transações"
          columns={headData}
          data={bodyData}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                
              </div>
            )
          }}
          localization={{
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página'
            },
            toolbar: {
              searchPlaceholder: 'Buscar',
              searchTooltip: 'Buscar'
            },
            body: {
              emptyDataSourceMessage: 'Nenhum dado encontrado',
            }
          }}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: '#10104F',
              color: '#FFF'
            },
            emptyValue: "Nenhum dado encontrado"
          }}
        />

      </TableContent>
      {/* </Content> */}
    </Container>
  );
};

export default ParceiroTransacoes;
