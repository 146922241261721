import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
`;

export const Header = styled.div`
  background-color: #10104f;
  height: 85px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* background-color: red; */
`;

export const Body = styled.div`
  /* width: 70%; */
  margin-top: 40px;
  /* background-color: blue; */

  h1 {
    color: #10104f;
    margin-bottom: 30px;
  }

  h2 {
    color: #08bbe9;
  }

  h3 {
    color: #10104f;
    margin-bottom: 10px;
  }

  @media (max-width: 500px) {
    margin-left: 10px;
  }
`;

export const Paragrafo = styled.div`
  margin-bottom: 30px;
  max-width: 900px;
  /* background-color: red; */

  p {
    margin-bottom: 20px;
    color: #7d7d7d;
  }

  span.sub-titulo {
    color: #08bbe9;
    font-weight: bold;
  }

  span.span-li {
    font-weight: bold;
  }

  span.span-email {
    color: #08bbe9;
    font-weight: 600;
  }

  ul {
    color: #7d7d7d;
    margin-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }
`;
