import styled, { css } from 'styled-components';

import backgroundImg from '../../assets/images/background_2.jpg';

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 30px 160px 40px 30px;

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const AddLA = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-bottom: 30px;

  > span {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin-right: 30px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #10104F;
  border-radius: 50%;
  border: none;
  outline-style: none;

  width: 50px;
  height: 50px;

  > svg {
    width: 30px;
    height: 30px;
  }
`;

export const FormSection = styled.div`
  margin-bottom: 30px;

  /* Reduz a margem do box com o form */
  .gJVqjo {
    margin-bottom: 40px;
  }

  ${props => props.formOpened && css`
    display: none;
  `}
`;

export const TableContent = styled.div`
  position: relative;

  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-paddingCheckbox {
    background: #10104F;
  }

  span.MuiButtonBase-root.MuiTableSortLabel-root.Mui-disabled {
    color: transparent;
  }

  .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
    background-color: #fff;
  }

  tr.MuiTableRow-root {
    border: none;
  }

  tr.MuiTableRow-root:nth-of-type(odd) {
    background-color: #F5F5F5;
  }

  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
    padding-bottom: 20px;
    height: 100px;
  }

  .MuiToolbar-regular {
    min-height: 84px;
  }
  
  .css-2b097c-container {
    position: absolute;
    box-sizing: border-box;
    width: 140px;
    top: 23px;
    right: 300px;
    z-index: 50;
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const TableContentMobile = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px 3px;
  margin-bottom: 100px;

  @media (min-width: 500px) {
    display: none;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid #EEEEEE;
  padding: 0 17px 10px;

  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .filterStatusSelect.css-2b097c-container {
    width: 160px;
    margin-bottom: 20px;
  }

  > span {
    margin-bottom: 20px;
  }
`;

export const MobileBody = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  height: 448px;
`;

export const Line = styled.div`
  padding: 15px 17px;
  border-bottom: 1px solid #EEEEEE;

  > span {
    font-size: 18px;
    color: #313131;
  }

  > p {
    font-size: 14px;
    color: #9FA7BA;
  }

  div.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    > span {
      font-size: 12px;
      font-weight: 700;
      /* color: #5CB85C; */
    }
  }
`;

export const Infos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  margin-bottom: 40px;
`;

export const Box = styled.div`
  background: #FFFFFF;
  padding: 15px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;

  position: relative;

  label {
    font-size: 16px;
    color: #373A3C;
  }

  span {
    font-size: 16px;
    font-weight: bold;
    color: #0073FC;

    &.aprovadas {
      color: #5CB85C;
    }

    &.em_analise {
      color: #FEAC02;
    }

    &.solicitar_documentos {
      color: #5097FF;
    }

    &.checagem_financeira {
      color: #8850FF;
    }

    &.reprovadas {
      color: #F35757;
    }
  }
`;

export const InfosArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Bloco1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const Grafico = styled.div`
  
`;
