import React, { useEffect, useState } from 'react';

import api from '../../services/api';

// import { useBankData } from '../../hooks/BankDataContext';

import { Container, FirstRow, Row, InputArea, BoxEmail } from './styles';

function BankingDataView() {
  // const { bankData } = useBankData();

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const loggedUser = JSON.parse(localStorage.getItem('@DeixaNoAzul:loggedUser'));

  // Lista de bancos
  // const listaBancos = BankList;
  const [dataBank, setDataBank] = useState({});

  // Preenche os inputs com os valores já cadastrados
  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    // Se é um LA
    if (loggedUser.role === 'ROLE_LA') {
      const licensedData = JSON.parse(localStorage.getItem('@DeixaNoAzul:licensedId'));

      api.get(`/conta/${licensedData.dados_bancarios_id}`, auth).then(response => {
        const bankData = response.data.data;
        // console.log(bankData)
  
        setDataBank({
          tipo_chave_pix: bankData.tipo_chave_pix,
          chave_pix: bankData.chave_pix,
          nome_titular: bankData.nome_titular,
          nome_banco: bankData.nome_banco,
          conta_juridica: bankData.conta_juridica,
          cpf_cnpj: bankData.cpf_cnpj,
          tipo_conta: bankData.tipo_conta,
          agencia: bankData.agencia,
          numero_conta: bankData.numero_conta,
          operacao: bankData.operacao,
        });
      })
    };
    // Se é um EC
    if (loggedUser.role === 'ROLE_EC') {
      const estabelecimentoData = JSON.parse(localStorage.getItem('@DeixaNoAzul:estabelecimentoId'));

      api.get(`/conta/${estabelecimentoData.dados_bancarios_id}`, auth).then(res => {
        const bankData = res.data.data;
        // console.log(bankData)

        setDataBank({
          tipo_chave_pix: bankData.tipo_chave_pix,
          chave_pix: bankData.chave_pix,
          nome_titular: bankData.nome_titular,
          nome_banco: bankData.nome_banco,
          conta_juridica: bankData.conta_juridica,
          cpf_cnpj: bankData.cpf_cnpj,
          tipo_conta: bankData.tipo_conta,
          agencia: bankData.agencia,
          numero_conta: bankData.numero_conta,
          operacao: bankData.operacao,
        });
      });
    };
    // Se é um RV
    if (loggedUser.role === 'ROLE_RV') {
      const revendedorData = JSON.parse(localStorage.getItem('@DeixaNoAzul:revendedorId'));

      api.get(`/conta/${revendedorData.dados_bancarios_id}`, auth).then(res => {
        const bankData = res.data.data;
        // console.log(bankData)

        setDataBank({
          tipo_chave_pix: bankData.tipo_chave_pix,
          chave_pix: bankData.chave_pix,
          nome_titular: bankData.nome_titular,
          nome_banco: bankData.nome_banco,
          conta_juridica: bankData.conta_juridica,
          cpf_cnpj: bankData.cpf_cnpj,
          tipo_conta: bankData.tipo_conta,
          agencia: bankData.agencia,
          numero_conta: bankData.numero_conta,
          operacao: bankData.operacao,
        });
      });
    };
    
  }, [loggedUser.id, loggedUser.role, userToken]);

  return (
    <Container>
      <BoxEmail>
        <span>Para solicitar alteração dos dados bancários, envie um e-mail para <a href="mailto:comercial@deixanoazul.com.br"><i>comercial@deixanoazul.com.br</i></a></span>
      </BoxEmail>

      <FirstRow>
        <InputArea>
          <label>*Tipo de Chave Pix</label>
          <p>{dataBank.tipo_chave_pix === null ? "Valor não cadastrado" : dataBank.tipo_chave_pix}</p>
        </InputArea>

        <InputArea>
          <label>*Chave Pix</label>
          <p>{dataBank.chave_pix === null ? "Valor não cadastrado" : dataBank.chave_pix}</p>
        </InputArea>
      </FirstRow>

      <FirstRow>
        <InputArea>
          <label>*Nome do titular</label>
          <p>{dataBank.nome_titular === null ? "Valor não cadastrado" : dataBank.nome_titular}</p>
        </InputArea>

        <InputArea>
          <label>*Nome do banco</label>
          <p>{dataBank.nome_banco === null ? "Valor não cadastrado" : dataBank.nome_banco}</p>
        </InputArea>
      </FirstRow>

      <Row>
        <InputArea>
          <label>*Tipo de conta</label>
          <p>{dataBank.conta_juridica === 0 ? "Pessoa Física" : "Pessoa Jurídica"}</p>
        </InputArea>

        <InputArea>
          <label>*CPF/CNPJ</label>
          <p>{dataBank.cpf_cnpj === null ? "Valor não cadastrado" : dataBank.cpf_cnpj}</p>
        </InputArea>

        <InputArea>
          <label>*Conta</label>
          <p>{dataBank.tipo_conta === null ? "Valor não cadastrado" : dataBank.tipo_conta}</p>
        </InputArea>
      </Row>

      <Row>
        <InputArea>
          <label>*Agência</label>
          <p>{dataBank.agencia === null ? "Valor não cadastrado" : dataBank.agencia}</p>
        </InputArea>

        <InputArea>
          <label>*Número da conta</label>
          <p>{dataBank.numero_conta === null ? "Valor não cadastrado" : dataBank.numero_conta}</p>
        </InputArea>

        <InputArea>
          <label>Operação</label>
          <p>{dataBank.operacao === null ? "Valor não cadastrado" : dataBank.operacao}</p>
        </InputArea>
      </Row>

    </Container>
  );
}

export default BankingDataView;
