import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import { useAuth } from '../../hooks/AuthContext';

import backgroundImg from "../../assets/images/background-credenciamento.png";
import logoImg from "../../assets/images/logo.png";

import { Container, BackgroundImg, Content, Box } from './styles';

function SelfCredenciamento() {
  const anoAtual = new Date().getFullYear();

  const { signOut } = useAuth();

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const loggedUser = JSON.parse(localStorage.getItem('@DeixaNoAzul:loggedUser'));

  useEffect(() => {
    if (userToken && loggedUser) {
      signOut();
    }
  }, [loggedUser, signOut, userToken]);

  return (
    <Container>
      <BackgroundImg>
        <img src={backgroundImg} alt="Deixa no azul" />
      </BackgroundImg>

      <Content>
        <img src={logoImg} alt="Deixa no Azul" />

        <Box>
          <span>Seja muito bem vindo(a) ao <blue>Deixa no Azul</blue>.</span>

          <span>Aqui você pode cadastrar o seu <blue>estabelecimento</blue> para se tornar um de nossos credenciados.</span>

          <span className="bold">Vamos começar?</span>

          <Link to="/credenciamento-criar">Cadastrar</Link>
        </Box>

        <span>© {anoAtual}. Todos os direitos reservados</span>
      </Content>
    </Container>
  );
};

export default SelfCredenciamento;
