import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  flex: 1;
  height: 100%;
  width: 100%;
  padding-bottom: 40px;

  > span {
    /* position: absolute; */
    /* bottom: 40px; */
    margin-top: 80px;
  }
`;

export const Background = styled.img`
  /* position: absolute;
  z-index: 09;
  right: 30px;
  bottom: 0; */
  /* width: 30%; */
  height: 55%;
  margin-left: auto;
  margin-top: -26%;
  margin-bottom: -40px;
  margin-right: 30px;
  /* display: none; */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 80%;

  > img {
    width: 180px;
    height: auto;
    margin-top: 40px;
    margin-bottom: 30px;
  }
`;

export const FormSection = styled.div`
  background: #ffffff;
  width: 100%;
  border-radius: 10px;

  position: relative;
  z-index: 10;
`;
