import React from 'react';
import { Link } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';

import FormEditLA from '../../components/FormEditLA';

import {
  Container,
  Page,
  Content,
  TabContent,
  Back,
  Button,
} from './styles';

function AdminEditarLicenciados() {
  return (
    <Container>
      <MenuBar />
      <NewMobileMenu />

      <Page>
        <TopBar pageTitle="Editar Licenciado 02" />

        <Content>
          <Back>
            <Link to="/admin/licenciados">
              <Button>
                <MdArrowBack size={24} />
              </Button>
            </Link>
            <span>Voltar para Cadastro de Licenciados 02</span>
          </Back>

          <TabContent>
            <FormEditLA />
          </TabContent>
        </Content>
      </Page>
    </Container>
  );
}

export default AdminEditarLicenciados;
