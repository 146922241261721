import styled, { css } from 'styled-components';

// import { shade } from 'polished';

import backgroundImg from '../../assets/images/background.jpg';
import backgroundImgAdmin from '../../assets/images/background_2.jpg';

export const Container = styled.div``;

export const Page = styled.div`
  margin-left: 220px;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* height: calc(100vh - 72px); */
  background: url(${backgroundImg}) no-repeat;
  background-position: top left;
  background-size: 100% 256px;
  box-sizing: border-box;

  padding: 100px 160px 60px 30px;

  ${props => props.isAdmin && css`
    background: url(${backgroundImgAdmin}) no-repeat;
    background-position: top left;
    background-size: 100% 256px;
  `}

  @media (max-width: 500px) {
    padding: 50px 20px 20px;
  }
`;

export const MessagesContent = styled.div`
  background: #fff;
  padding: 20px 30px 30px;
  border-radius: 8px;
  /* margin-bottom: 30px; */

  h3 {
    margin-bottom: 30px;
  }

  span.title {
    font-size: 16px;
    font-weight: 600;
    color: #373a3c;
  }

  span.date {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #9FA7BA;

    @media (max-width: 500px) {
      margin-left: 10px;
    }
  }

  p.message {
    display: block;
    color: #9FA7BA;
  }

  span.status {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 600;
    background: #08BBE9;
    color: #fff;
    padding: 2px 8px;
    border-radius: 4px;
    max-height: 25px;

    @media (max-width: 500px) {
      margin-left: 10px;
    }
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;

  background: #fff;
  border: 2px solid #F1F5F9;
  border-radius: 6px;
  margin-bottom: 20px;

  transition: 0.2s;

  > div {
    width: 100%;
  }

  .header {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 20px;

    width: 100%;
  }

  span.title {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    color: #373a3c;
  }

  span.message {
    display: block;
    font-size: 14px;
    margin-bottom: 7px;
    color: #9FA7BA;
  }

  span.date {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: #9FA7BA;
  }

  &:hover {
    background: #F1F5F9;
  }
`;

export const MessageHeader = styled.div`
  display: flex;
  align-items: center;
  
  height: 60px;
  padding: 14px 20px;
  cursor: pointer;
`;

export const MessageBody = styled.div`
  display: none;
  /* flex-direction: column; */
  padding: 0 20px 14px;

  margin-top: 20px;
  width: 100%;

  ${props => props.show === props.id && css`
    display: block;
  `}

  button.goTransaction {
    display: flex;
    align-items: center;

    padding: 6px 18px;
    background: transparent;
    background: linear-gradient(#08BBE9, #41B6EF, #008AEB);
    border: none;
    color: #FFFFFF;
    font-weight: 600;
    /* border: none; */
    outline-style: none;
    border-radius: 4px;

    margin-top: 20px;
    transition: 0.4s;

    > svg {
      margin-left: 6px;
    }
  }
`;