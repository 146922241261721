import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import api from "../../services/api";

import TopBar from "../../components/TopBar";
import MenuBar from "../../components/MenuBar";
import NewMobileMenu from "../../components/NewMobileMenu";
import LoadingContent from '../../components/LoadingContent';

import rectangle from '../../assets/images/rectangle.png'

import FormatarValor from '../../components/FormatarValor';


import {
  Container,
  Page,
  Content,
  RowBox1,
  Box,
  Row,
  Row3,
  Row4,
  InputArea,
  BlueBox,
  StatusArea,
  BoxFoto,
  Box2,

} from './styles';

function AprovarEprepag() {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const userToken = localStorage.getItem("@DeixaNoAzul:accessToken");

  // const [userID, setUserID] = useState("");

  const idTransacao = location.pathname
    .replace("/aprovar-eprepag/", "");

  const [transacaoInfos, setTransacaoInfos] = useState({});
  const [transacaoCliente, setTransacaoCliente] = useState({});

  const [produtos, setProdutos] = useState([]);
  const [estabelecimentos, setEstabelecimentos] = useState([]);
  const [licenciado, setLicenciado] = useState({});
  const [comissao, setComissao] = useState();

  const loggedUser = JSON.parse(
    localStorage.getItem("@DeixaNoAzul:loggedUser")
  );

  // Faz as requisições para preencher as informações
  useEffect(() => {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    async function requestTransacao() {
      const responseTransacao = await api.get(
        `/eprepag/${idTransacao}`,
        auth
      );
     
      // console.log(responseTransacao);

      const responseTransacaoGift = await api.get(
        "eprepag/get/portifolio",
        auth
      );


      const responseEstabe = await api.get(
        "estabelecimento?png=99999",
        auth
      );


      const responseComissao = await api.get(
        `comissao?conditions=transacao_id:=:${idTransacao}`,
        auth
      );


      setEstabelecimentos(responseEstabe.data.data)
      setTransacaoCliente(responseTransacao.data.data);
      setProdutos(responseTransacaoGift.data.data.produtos.JOGO);
      setComissao(responseComissao.data.data)
    }
    
    requestTransacao();
    setIsLoading(false)
  }, [idTransacao, userToken]);


  let produtoNome = '';

  produtos.filter((prod) => {
    if(String(prod.id) === String(transacaoCliente.code)){
      produtoNome = prod.nome;
    }
  });

  let estavelecimetoNome = '';
  let idLa = '';

  estabelecimentos.filter((esta) => {
    if(String(esta.id) === String(transacaoCliente.estabelecimento_id)){
      estavelecimetoNome = esta.nome_fantasia;
      idLa = esta.licenciado_autorizado_id;
    }
  });

  useEffect(() => {
    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    async function loadLa() {
      const responseTransacao = await api.get(
        `/licenciado/${idLa}`,
        auth
      );

      setLicenciado(responseTransacao.data.data)
    }

    if(idLa !== '') {
      loadLa();
    }
  }, [idLa, userToken])

  // function statusTag() {

  //   let colorStatus = '';
  //   if(transacaoInfos.status === 'EM_ANALISE') {colorStatus = "#FEAC02"} // Em análise
  //   if(transacaoInfos.status === 'APROVADO') {colorStatus = "#5CB85C"} // Aprovada
  //   if(transacaoInfos.status === 'CANCELADO') {colorStatus = "#F35757"} // Cancelada
  //   if(transacaoInfos.status === 'REJEITADO') {colorStatus = "#F35757"} // Cancelada

  //   let messageStatus = '';
  //   if(transacaoInfos.status === 'EM_ANALISE') {messageStatus = "Em análise"} // Em análise
  //   if(transacaoInfos.status === 'APROVADO') {messageStatus = "Aprovada"} // Aprovada
  //   if(transacaoInfos.status === 'CANCELADO') {messageStatus = "Cancelada"} // Cancelada
  //   if(transacaoInfos.status === 'REJEITADO') {messageStatus = "Rejeitada"} // Cancelada

  //   return (
  //     <Status style={{ background: colorStatus }}>
  //       <span>{messageStatus}</span>
  //     </Status>
  //   )
  // }

  const dataTransacao = transacaoCliente.created_at && transacaoCliente.created_at.substring(0, 10).split('-').reverse().join('/');

  return (
    <Container>
      <LoadingContent isLoading={isLoading} />

      <MenuBar active="Transações" subMenuActive="E-prepag" />
      <NewMobileMenu active="Transações" />

      <Page>
        <TopBar pageTitle="Transação -" pageSubtitle="E-prepag" />

        <Content>
          <RowBox1>
            <Box2 style={{}}>
              <h3>Dados do Gift Card</h3>

              <Row>
                <InputArea>
                  <label>E-mail</label>
                  <p>{transacaoCliente.email}</p>
                </InputArea>

                <InputArea>
                  <label>Gift Card</label>
                  <p>{produtoNome}</p>
                </InputArea>
              </Row>
            </Box2>

            <div>
              <BoxFoto> 
                <Row>
                    <img style={{marginTop: -20, backgroundColor: "#fff", padding: 20, borderRadius: 8}}  src={rectangle} />
                </Row>
              </BoxFoto>
            </div>

            <div>
              <BlueBox style={{ marginBottom: 90 }}>
                <h3>Informações de Pagamento</h3>
              
                <Row3> 
                  <InputArea>
                    <label>Valor</label>
                    <p>R$ {FormatarValor(transacaoCliente.valor)}</p>
                  </InputArea>

                  {/* <InputArea>
                    <label>Forma de pagamento</label>
                    <p>R$ {valorPagamento}</p>
                  </InputArea> */}

                  <InputArea>
                    <label>Data da Transação</label>
                    <p>{dataTransacao}</p>
                  </InputArea>
                </Row3>
              </BlueBox>
            </div>

            <div />

            <div>
              <Box style={{ marginTop: -90 }}> 
                <h3>Informações da Transação</h3>
                <Row4>
                  {
                    loggedUser.role === "ROLE_ADMIN" ? "" : (
                      <InputArea>
                      <label>Comissão</label>
                      <p>
                        {
                          comissao !== undefined ? 
                            comissao.length === 1 &&
                              `R$ ${FormatarValor(comissao[0].valor_comissao)}` : ""
                        }  
                      </p>
                      </InputArea>
                    )
                  }

                  <InputArea>
                    <label>Cód. da Transação</label>
                    <p>{transacaoCliente.code}</p>
                  </InputArea>

                  <InputArea>
                    <label>Estabelecimento</label>
                    <p>{estavelecimetoNome}</p>
                  </InputArea>

                  <InputArea>
                    <label>Licenciado</label>
                    <p>{licenciado.nome_fantasia}</p>
                  </InputArea>
                </Row4>
              </Box>
            </div>
          </RowBox1>

          <StatusArea>
            <form /* onSubmit={handleSubmit(onSubmit, onError)} */>
              {/* <InputContainer2 statusColor={'dytdtyduy'}>
                <select
                  name="status"
                  placeholder="Selecione"
                >
                  <option value="1">Em Análise</option>
                  <option value="2">Solicitar documentos</option>
                  <option value="3">Aprovada</option>
                  <option value="4">Cancelada</option>
                  <option value="5">Checagem financeira</option>
                </select>
              </InputContainer2> */}
            </form>

            <button
              type="button"
              onClick={() => history.push("/eprepag")}
            >
              Voltar
            </button>
          </StatusArea>
        </Content>
      </Page>
    </Container>
  );
};

export default AprovarEprepag;
