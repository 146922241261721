import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Tooltip } from '@material-ui/core/';
import { MdFiberManualRecord } from 'react-icons/md';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import FormatarValor from '../../components/FormatarValor';

import editIcon from '../../assets/images/edit_icon.svg';
import ecIconBlue from '../../assets/images/ec_icon_blue.svg';
import laIconBlue from '../../assets/images/la_icon_blue.svg';
import revendedorIconBlue from '../../assets/images/revendedor_icon_blue.svg';

import {
  Container,
  Page,
  Content,
  TableContent,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
} from './styles';

function AdminPagamentos() {
  const history = useHistory();

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const [pagamentos, setPagamentos] = useState([]);

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/pagamento', auth).then(response => {
      const result = response.data;
      console.log('response pagamento', response)
      // const pagamentosProcessando = result.filter(item => item.status === "PROCESSANDO");
      // console.log(pagamentosProcessando);
      
      setPagamentos(result);
    });

  }, [userToken]);

  // Informações Head da tabela
  const headData = [
    { title: 'Data da solicitação', field: 'solicitação', sorting: false },
    { title: 'Valor', field: 'valor', sorting: false },
    { title: 'Forma de pagamento', field: 'forma_pagamento', sorting: false },
    { title: 'Data da aprovação', field: 'aprovação', sorting: false },
    { title: 'Nome do solicitante', field: 'nome', sorting: false },
    { title: 'Papel', field: 'papel', sorting: false },
    { title: 'Status', field: 'status', align: 'center', sorting: false },
    { title: '', field: 'edit', align: 'center', sorting: false },
  ];

  // Informações Body da tabela
  const bodyData = pagamentos === [] ? []
  : pagamentos.map(pagamento => {
    let colorStatus = '';
    if(pagamento.status === "CANCELADO" || pagamento.status === "NAO_APROVADO") {colorStatus = "#F35757"} // Não pago
    if(pagamento.status === "PROCESSANDO") {colorStatus = "#5097FF"} // Não pago
    if(pagamento.status === "APROVADO") {colorStatus = "#5CB85C"} // Pago

    let messageStatus = '';
    if(pagamento.status === "CANCELADO" || pagamento.status === "NAO_APROVADO") {messageStatus = "Cancelado"} // Aprovado
    if(pagamento.status === "PROCESSANDO") {messageStatus = "Processando"} // Aprovado
    if(pagamento.status === "APROVADO") {messageStatus = "Aprovado"} // Em análise

    const status = <Tooltip title={messageStatus} placement="top" arrow><span><MdFiberManualRecord color={colorStatus} /></span></Tooltip>

    const dataSolicitacao = pagamento.data_solicitacao.substring(0, 10).split('-').reverse().join('/');
    const horaSolicitacao = pagamento.data_solicitacao.substring(11, 16);
    const dataEHoraSolicitacao = <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataSolicitacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaSolicitacao}</span>
    </div>;
    
    const dataAprovacao = pagamento.data_aprovacao === null ? "Em aprovação" : pagamento.data_aprovacao.substring(0, 10).split('-').reverse().join('/');
    const horaAprovacao = pagamento.data_aprovacao === null ? "Em aprovação" : pagamento.data_aprovacao.substring(11, 16);

    const dataEHoraAprovacao = pagamento.data_aprovacao === null ? "Em aprovação"
    : <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{dataAprovacao}</span>
      <span style={{ color: '#A6AAB4', fontSize: 14 }}>{horaAprovacao}</span>
    </div>;

    let formaPagamento = '';
    if (pagamento.forma_pagamento === 'TRANSF') { formaPagamento = 'Transferência' }
    if (pagamento.forma_pagamento === 'PIX') { formaPagamento = 'Pix' }
    if (pagamento.forma_pagamento === 'BOLETO') { formaPagamento = 'Boleto' }
    if (pagamento.forma_pagamento === '') { formaPagamento = 'Transferência' }

    let papel = '';
    if (pagamento.papel === "ROLE_LA") { papel = <span style={{ display: 'flex', alignItems: 'center' }}><img src={laIconBlue} alt="Licenciado Autorizado" style={{ width: '17px', marginRight: '6px' }} /> LA</span> };
    if (pagamento.papel === "ROLE_EC") { papel = <span style={{ display: 'flex', alignItems: 'center' }}><img src={ecIconBlue} alt="Estabelecimento Credenciado" style={{ width: '16px', marginRight: '6px' }} /> EC</span> };
    if (pagamento.papel === "ROLE_RV") { papel = <span style={{ display: 'flex', alignItems: 'center' }}><img src={revendedorIconBlue} alt="Revendedor" style={{ width: '16px', marginRight: '6px' }} /> Revendedor</span> };

    const disabled = pagamento.status === 3 ? "disabled" : "abled";

    return {
      solicitação: dataEHoraSolicitacao,
      valor:  <span style={{ color: "#0073FC", fontWeight: 600 }}>R$ {FormatarValor(pagamento.valor_pagamento)}</span>,
      forma_pagamento: formaPagamento,
      aprovação: dataEHoraAprovacao,
      nome: pagamento.nome_solicitante,
      papel: papel,
      status: status,
      edit: <Tooltip title="Editar Dados do Pagamento" placement="top" arrow>
          <span className={disabled} onClick={() => editPagamento(pagamento)}><img src={editIcon} alt="" /></span>
        </Tooltip>
    }
  });

  const filterByStatus = useCallback((value) => {
    const filterValue = value.value;

    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    if (filterValue !== "TODOS") {
      api.get('/pagamento', auth).then(response => {
        const result = response.data;

        const resultadoFiltrado = result.filter(item => item.status === filterValue)
        
        setPagamentos(resultadoFiltrado);
      });
    }

    if (filterValue === "TODOS") {
      api.get('/pagamento', auth).then(response => {
        const result = response.data;
        
        setPagamentos(result);
      });
    }

  }, [userToken]);

  const editPagamento = useCallback(async (pagamento) => {
    const idReverse = pagamento.pagamento_id.split('').reverse().join('');

    // redireciona para a página de edição
    history.push(`/admin/pagamentos/editar/${idReverse}`);
  }, [history]);

  /* -------------------- FUNÇÕES PARA O MOBILE -------------------- */
  
  // Organiza os status dos pagamentos para mostrar na lista mobile
  const pagamentoStatus = useCallback((pagamento) => {
    let colorStatus = '';
    if(pagamento.status === "CANCELADO") {colorStatus = "#F35757"} // Não pago
    if(pagamento.status === "PROCESSANDO") {colorStatus = "#5097FF"} // Não pago
    if(pagamento.status === "APROVADO") {colorStatus = "#5CB85C"} // Pago

    let messageStatus = '';
    if(pagamento.status === "CANCELADO") {messageStatus = "Cancelado"} // Aprovado
    if(pagamento.status === "PROCESSANDO") {messageStatus = "Processando"} // Aprovado
    if(pagamento.status === "APROVADO") {messageStatus = "Aprovado"} // Em análise

    return <span style={{ color: colorStatus }}>{messageStatus}</span>
  }, []);

  // Organiza os valores dos pagamentos
  function valorPagamento(pagamento) {
    const valor_pagamento = pagamento.valor_pagamento.toString().split('.');
    const valorPagamento = `${valor_pagamento[0]},${valor_pagamento[1] ? valor_pagamento[1].substring(0, 2) : "00"}`;

    return `R$ ${valorPagamento}`
  };

  // Organiza as datas de solicitação dos pagamentos
  function dataSolicitacao(pagamento) {
    const dataSolicitacao = pagamento.data_solicitacao.substring(0, 10).split('-').reverse().join('/');

    return dataSolicitacao
  };

  return (
    <Container>
      <MenuBar active="Pagamentos" />
      <NewMobileMenu active="Pagamentos" />

      <Page>
        <TopBar pageTitle="Pagamentos" />

        <Content>

          <TableContent>

            <Select
              placeholder="Selecione"
              onChange={(value) => filterByStatus(value)}
              className="filterStatusSelect"
              options={[
                { value: 'APROVADO', label: 'Aprovado' },
                { value: 'PROCESSANDO', label: 'Processando' },
                { value: 'CANCELADO', label: 'Cancelado' },
                { value: 'TODOS', label: 'Todos' }
              ]}
            />

            <MaterialTable
              title="Lista de Solicitações"
              columns={headData}
              data={bodyData}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    
                  </div>
                )
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                },
                toolbar: {
                  searchPlaceholder: 'Buscar',
                  searchTooltip: 'Buscar'
                },
                body: {
                  emptyDataSourceMessage: 'Nenhum dado encontrado',
                }
              }}
              options={{
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: '#10104F',
                  color: '#FFF'
                },
                emptyValue: "Nenhum dado encontrado"
              }}
            />

          </TableContent>

          <TableContentMobile>
            <Header>
              <h3>Lista de Transações</h3>

              <Select
              placeholder="Selecione"
              onChange={(value) => filterByStatus(value)}
              className="filterStatusSelect"
              options={[
                { value: 'APROVADO', label: 'Aprovado' },
                { value: 'PROCESSANDO', label: 'Processando' },
                { value: 'CANCELADO', label: 'Cancelado' },
                { value: 'TODOS', label: 'Todos' }
              ]}
            />

              <span>Total: {pagamentos.length}</span>
            </Header>

            <MobileBody>
              {pagamentos.map((pagamento) => (
                <Line key={pagamento.id}>
                  <span>{valorPagamento(pagamento)}</span>
                  <p>{pagamento.papel === "ROLE_LA" ? "LA: " : "EC: "}{pagamento.nome_solicitante}</p>
                  <p>{dataSolicitacao(pagamento)}</p>
                  {/* <p>Papel: {pagamento.papel === "ROLE_LA" ? "LA: " : "EC: "}</p> */}
          
                  <div className="footer">
                    <span>{pagamentoStatus(pagamento)}</span>
                    <img src={editIcon} alt="" onClick={() => editPagamento(pagamento)}/>
                  </div>
                </Line>
              ))}
            </MobileBody>
          </TableContentMobile>

        </Content>

      </Page>
    </Container>
  );
}

export default AdminPagamentos;