import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.ul`
  margin-top: 20px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;

    & + li {
      margin-top: 15px;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  img {
    width: 40px;
    height: 36px;
    border-radius: 5px;
    margin-right: 6px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    strong.branco {
      color: #fff;
    }

    strong.preto {
      color: #373A3C;
    }

    span {
      font-size: 12px;
      color: #999;
      margin-top: 5px;

      > button {
        border: 0;
        background: transparent;
        outline-style: none;
        color: #e83f5b;
        padding: 0;
        margin: 0 0 0 8px;
        font-size: 14px;

        cursor: pointer;

        &:hover {
          background: transparent;
          color: ${shade(0.2, '#e83f5b')}
        }
      }
    }
  }
`;
