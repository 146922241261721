import React, { createContext, useState, useContext, /* useEffect */ } from 'react';

// import api from '../services/api';
// import { useLicensed } from './LicensedContext';

const BankDataContext = createContext();

function BankDataProvider({ children }) {
  // const { licensed } = useLicensed();
  const [bankData, setBankData] = useState([]);

  return (
    <BankDataContext.Provider
      value={{
        bankData,
        setBankData
      }}
    >
      {children}
    </BankDataContext.Provider>
  );
}

function useBankData() {
  const context = useContext(BankDataContext);
  const { bankData, setBankData } = context;
  return { bankData, setBankData };
}

export { BankDataProvider, useBankData }