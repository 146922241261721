import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Select from 'react-select';
import MaterialTable, {MTableToolbar} from 'material-table';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';

import soNumeroSimbolosMask from '../../components/InputMasks/soNumeroSimbolosMask';

import FormAddTaxaComissao from '../../components/FormAddTaxaComissao';

import {useToast} from '../../hooks/ToastContext';

import {
  MdAdd,
  MdRemove,
  // MdEdit,
  // MdNavigateBefore,
  // MdNavigateNext,
} from 'react-icons/md';
import editIcon from '../../assets/images/edit_icon.svg';

import api from '../../services/api';
// import { useLicensed } from '../../hooks/LicensedContext';

import {
  Container,
  Page,
  Content,
  TableContent,
  AddLA,
  Button,
  FormSection,
  TableContentMobile,
  Header,
  Line,
  MobileBody,
  BoxEditaTaxa,
} from './styles';

function TaxaComissao() {
  const location = useLocation();

  const typeTransacao = location.pathname.replace(
    '/admin/taxacomissao/',
    ''
  );

  // console.log(typeTransacao);

  const {addToast} = useToast();
  const [formOpened, setFormOpened] = useState(true);

  const [taxaComissao, setTaxaComissao] = useState([]);
  const [taxaComissaoVisiveis, setTaxaComissaoVisiveis] = useState(
    []
  );

  const [editarTaxa, setEditarTaxa] = useState(false);
  const [editarTaxaId, setEditarTaxaId] = useState();

  const [taxaComissaoValor, setTaxaComissaoValor] = useState('');

  const [numeroDeParcelas, setNumeroDeParcelas] = useState('');
  const [taxaDeServico, setTaxaDeServico] = useState('');
  const [role, setRole] = useState('');

  const [taxaId, setTaxaId] = useState('');

  const [filtroPapel, setFiltroPapel] = useState('TODOS');
  const filtroTipo = typeTransacao; // antigo state sem uso do set

  const [subMenuText, setSubMenuText] = useState('');

  const [
    taxaComissaoValorMenorQueZero,
    setTaxaComissaoValorMenorQueZero,
  ] = useState(false);

  // const [selectParcelaMobile, setSelectParcelaMobile] = useState(false);
  const selectParcelaMobile = false;

  const [atualizaTaxaComissao, setAtualizaTaxaComissao] =
    useState(false);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  // Faz a requisição a /tax_comissoes para pegar as taxas e alimentar o array
  useEffect(() => {
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    api.get('/tax_comissoes?png=999999', auth).then((response) => {
      const result = response.data.data;
      console.log('taxa_comissoes', response);

      setTaxaComissao(result);
      const transacoesFiltradas = result.filter(
        (item) => item.transacao_type === typeTransacao
      );

      setTaxaComissaoVisiveis(transacoesFiltradas);
      // setTaxaComissaoVisiveis(result);

      /* if (filtroPapel !== "") {
        // FILTRO POR PAPEL
        if (filtroPapel) {
          // eslint-disable-next-line array-callback-return
          const filtroResult = result.filter((item) => {
            if (item.role === filtroPapel) {
              return item;
            }
          });
          setTaxaComissao(filtroResult);
        }
      } else {
        setTaxaComissao(result);
        setTaxaComissaoVisiveis(result);
      } */
    });
  }, [userToken, typeTransacao]);

  function verificaValorTaxa(e) {
    setTaxaComissaoValor(e);

    if (Number(taxaComissaoValor) <= 0) {
      setTaxaComissaoValorMenorQueZero(true);
    } else {
      setTaxaComissaoValorMenorQueZero(false);
    }

    if (e === '' || Number(e) <= 0) {
      setTaxaComissaoValorMenorQueZero(true);
    } else {
      setTaxaComissaoValorMenorQueZero(false);
    }
  }

  useEffect(() => {
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    if (taxaId !== '') {
      async function update() {
        try {
          await api.put(
            `/tax_comissoes/${taxaId}`,
            {
              numero_parcelas: numeroDeParcelas,
              taxa: taxaDeServico,
              role: role,
            },
            auth
          );

          addToast({
            type: 'success',
            title: 'Deu tudo certo!',
            description: 'Taxa atualizada com sucesso.',
          });

          setAtualizaTaxaComissao(!atualizaTaxaComissao);
          setEditarTaxa(false);
          setTaxaId('');
        } catch (error) {
          if (
            error.response.data.message === 'This tax already exists'
          ) {
            addToast({
              type: 'error',
              title: 'Erro na atualização da taxa ',
              description: 'Taxa já existente',
            });
          } else {
            addToast({
              type: 'error',
              title: 'Erro em alterar parcela',
              description: 'Erro em alterar taxa',
            });
          }
        }
      }

      update();
    }
  }, [
    addToast,
    atualizaTaxaComissao,
    numeroDeParcelas,
    role,
    taxaDeServico,
    taxaId,
    userToken,
  ]);

  // Informações Head da tabela
  const headData = [
    {
      title: 'Número de Parcelas',
      field: 'numero_parcela',
      sorting: false,
    },
    {title: 'Papel', field: 'role', sorting: false},
    {
      title: 'Tipo de transação',
      field: 'transacao_type',
      sorting: false,
    },
    {
      title: 'Taxas de Comissão',
      field: 'taxa_comissao',
      sorting: false,
    },
  ];

  const bodyData = taxaComissaoVisiveis.map((taxa) => {
    const numero_parcela = <p>{taxa.numero_parcelas}</p>;

    const role = (
      <p>
        {taxa.role === 'ROLE_EC' && 'EC'}
        {taxa.role === 'ROLE_LA' && 'LA'}
        {taxa.role === 'ROLE_RV' && 'RV'}
        {taxa.role === 'ROLE_PT' && 'Parceiro'}
      </p>
    );

    const transacao_type = (
      <p>
        {taxa.transacao_type === 'Transacao' && 'Pagamento de contas'}
        {taxa.transacao_type === 'TransacaoPagamentoContaDeLuz' &&
          'Conta de luz'}
        {taxa.transacao_type === 'TransacaoCertificadoDigital' &&
          'Certificado Digital'}
      </p>
    );

    const taxa_comissao = (
      <BoxEditaTaxa
        taxaComissaoValorMenorQueZero={taxaComissaoValorMenorQueZero}
      >
        {editarTaxa === true && editarTaxaId === taxa.id ? (
          <div className="boxInput">
            <div className="input">
              <span className="porcentagem">%</span>
              <input
                value={taxaComissaoValor}
                name="taxa_comissao"
                placeholder={taxa.taxa.toFixed(2)}
                onChange={(e) => verificaValorTaxa(e.target.value)}
                onKeyUp={(e) => soNumeroSimbolosMask(e)}
              />
            </div>
            <button
              onClick={() => {
                if (taxaComissaoValorMenorQueZero === false) {
                  setTaxaId(taxa.id);
                  setTaxaDeServico(taxaComissaoValor);
                  setNumeroDeParcelas(taxa.numero_parcelas);
                  setRole(taxa.role);
                } else {
                  return;
                }
              }}
            >
              ok
            </button>
            <button
              className="cancelar"
              onClick={() => {
                setEditarTaxa(false);
              }}
            >
              x
            </button>
          </div>
        ) : (
          <>
            <span>%</span> <span>{taxa.taxa.toFixed(2)}</span>{' '}
            <span
              style={{cursor: 'pointer', marginLeft: 15}}
              onClick={() => {
                setEditarTaxa(true);
                setEditarTaxaId(taxa.id);
                setTaxaComissaoValor(taxa.taxa);
                setTaxaComissaoValorMenorQueZero(false);
              }}
            >
              <img src={editIcon} alt="" style={{width: 12}} />
            </span>
          </>
        )}
      </BoxEditaTaxa>
    );

    return {
      numero_parcela: numero_parcela,
      role: role,
      transacao_type: transacao_type,
      taxa_comissao: taxa_comissao,
    };
  });

  function filterByRole(value) {
    const filterValue = value.value;
    // console.log(filterValue);

    if (filterValue !== 'TODOS' && filtroTipo === 'TODOS') {
      const transacoesFiltradas = taxaComissao.filter(
        (item) => item.role === filterValue
      );

      setTaxaComissaoVisiveis(transacoesFiltradas);
    }
    if (filterValue !== 'TODOS' && filtroTipo !== 'TODOS') {
      const transacoesFiltradas = taxaComissao.filter(
        (item) =>
          item.role === filterValue &&
          item.transacao_type === filtroTipo
      );

      setTaxaComissaoVisiveis(transacoesFiltradas);
    }
    if (filterValue === 'TODOS' && filtroTipo === 'TODOS') {
      setTaxaComissaoVisiveis(taxaComissao);
    }
    if (filterValue === 'TODOS' && filtroTipo !== 'TODOS') {
      const transacoesFiltradas = taxaComissao.filter(
        (item) => item.transacao_type === filtroTipo
      );

      setTaxaComissaoVisiveis(transacoesFiltradas);
    }
  }

  // Antigo filterByType(value)
  useEffect(() => {
    function teste() {
      const filterValue = typeTransacao;
      // console.log(statusSelecionado);

      if (filterValue !== 'TODOS') {
        const transacoesFiltradas = taxaComissao.filter(
          (item) => item.transacao_type === filterValue
        );

        setTaxaComissaoVisiveis(transacoesFiltradas);
      }

      if (typeTransacao === 'Transacao') {
        setSubMenuText('Deixa no Azul Taxas');
      }

      if (typeTransacao === 'TransacaoPagamentoContaDeLuz') {
        setSubMenuText('Contas de Luz Taxas');
      }

      if (typeTransacao === 'TransacaoCertificadoDigital') {
        setSubMenuText('Certificado Digital Taxas');
      }

      if (filterValue !== 'TODOS' && filtroPapel !== 'TODOS') {
        const transacoesFiltradas = taxaComissao.filter(
          (item) =>
            item.transacao_type === filterValue &&
            item.role === filtroPapel
        );

        setTaxaComissaoVisiveis(transacoesFiltradas);
      }
      if (filterValue === 'TODOS' && filtroPapel === 'TODOS') {
        setTaxaComissaoVisiveis(taxaComissao);
      }
      if (filterValue === 'TODOS' && filtroPapel !== 'TODOS') {
        const transacoesFiltradas = taxaComissao.filter(
          (item) => item.role === filtroPapel
        );

        setTaxaComissaoVisiveis(transacoesFiltradas);
      }
    }

    teste();
  }, [filtroPapel, taxaComissao, typeTransacao]);

  /* function mascaraSoNumero(e) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");

    return (e.currentTarget.value = value);
  } */

  function titlePage() {
    if (typeTransacao === 'TransacaoCertificadoDigital') {
      return 'Certificado Digital';
    }

    if (typeTransacao === 'TransacaoPagamentoContaDeLuz') {
      return 'Conta de Luz';
    }

    if (typeTransacao === 'Transacao') {
      return 'Deixa no Azul';
    }
  }

  return (
    <>
      <Container>
        <MenuBar active="TaxaComissao" subMenuActive={subMenuText} />
        <NewMobileMenu
          active="TaxaComissao"
          subMenuActive={subMenuText}
        />

        <Page>
          <TopBar
            pageTitle="Taxas de Comissão - "
            pageSubtitle={titlePage()}
          />

          <Content>
            <AddLA>
              {/* <span>Adicionar Taxa de Simulação</span> */}
              <Button onClick={() => setFormOpened(!formOpened)}>
                {formOpened === true ? (
                  <MdAdd color="#fff" />
                ) : (
                  <MdRemove color="#fff" />
                )}
              </Button>
            </AddLA>

            <FormSection formOpened={formOpened}>
              <FormAddTaxaComissao />
            </FormSection>

            <TableContent style={{zIndex: 0}}>
              {/* <Select
                placeholder="Tipo transação"
                onChange={(value) => {
                  setFiltroTipo(value.value);
                  // filterByType(value);
                }}
                className="select2"
                options={[
                  { value: "Transacao", label: "Pagamento de contas" },
                  { value: "TransacaoPagamentoContaDeLuz", label: "Conta de luz" },
                  { value: "TransacaoCertificadoDigital", label: "Certificado Digital" },
                  { value: "TODOS", label: "Todos" },
                ]}
              /> */}

              <Select
                placeholder="Papel"
                onChange={(value) => {
                  setFiltroPapel(value.value);
                  filterByRole(value);
                }}
                className="select3"
                options={[
                  {value: 'ROLE_EC', label: 'EC'},
                  {value: 'ROLE_RV', label: 'RV'},
                  {value: 'ROLE_LA', label: 'LA'},
                  {value: 'ROLE_PT', label: 'Parceiro'},
                  {value: 'TODOS', label: 'Todos'},
                ]}
              />

              <MaterialTable
                style={{position: 'relative'}}
                title="Lista de taxas"
                columns={headData}
                data={bodyData}
                components={{
                  Toolbar: (props) => (
                    <div>
                      <MTableToolbar {...props} />
                    </div>
                  ),
                }}
                localization={{
                  pagination: {
                    labelRowsSelect: 'linhas',
                    labelDisplayedRows: '{from}-{to} de {count}',
                    firstTooltip: 'Primeira página',
                    previousTooltip: 'Página anterior',
                    nextTooltip: 'Próxima página',
                    lastTooltip: 'Última página',
                  },

                  toolbar: {
                    searchPlaceholder: false,
                    searchTooltip: false,
                  },
                  body: {
                    emptyDataSourceMessage: 'Nenhum dado encontrado',
                  },
                }}
                options={{
                  actionsColumnIndex: -1,
                  headerStyle: {
                    backgroundColor: '#10104F',
                    color: '#FFF',
                  },
                  emptyValue: 'Nenhum dado encontrado',
                  search: false,
                }}
              />
            </TableContent>

            <TableContentMobile>
              <Header>
                <h3>Taxa de comissão</h3>

                {/* <span>Total: </span> */}
                <Select
                  placeholder="Papel"
                  onChange={(value) => setFiltroPapel(value.value)}
                  className="select4"
                  options={[
                    {value: 'ROLE_EC', label: 'EC'},
                    {value: 'ROLE_LA', label: 'LA'},
                    {value: 'ROLE_RV', label: 'RV'},
                    {value: '', label: 'Todos'},
                  ]}
                />
              </Header>

              <MobileBody>
                {taxaComissaoVisiveis.map((taxa) => (
                  <Line
                    key={taxa.id}
                    selectParcelaMobile={selectParcelaMobile}
                  >
                    <div className="coluna1">
                      <div>
                        <p>Número de parcelas</p>
                        <div>
                          {/* <select
                            name="numero_parcela"
                            placeholder="Selecione"
                            // ref={register({ required: true })}
                            className="listagem"
                            onChange={(e) => {
                              setNumeroDeParcelas(e.target.value);
                              setTaxaId(taxa.id);
                              setTaxaDeServico(taxa.taxa);
                              setRole(taxa.role);
                              setSelectParcelaMobile(false);
                            }}
                            onClick={() => {
                              setSelectParcelaMobile(true);
                            }}
                          >
                            <option value="">{taxa.numero_parcelas}</option>
                            <option value="0">0x</option>
                            <option value="1">1x</option>
                            <option value="2">2x</option>
                            <option value="3">3x</option>
                            <option value="4">4x</option>
                            <option value="5">5x</option>
                            <option value="6">6x</option>
                            <option value="7">7x</option>
                            <option value="8">8x</option>
                            <option value="9">9x</option>
                            <option value="10">10x</option>
                            <option value="11">11x</option>
                            <option value="12">12x</option>
                            <option value="13">13x</option>
                            <option value="14">14x</option>
                            <option value="15">15x</option>
                            <option value="16">16x</option>
                            <option value="17">17x</option>
                            <option value="18">18x</option>
                          </select> */}
                          <span>{taxa.numero_parcelas}</span>
                        </div>
                      </div>
                      <div>
                        <p>Papel</p>
                        <div>
                          {/* <select
                            name="role"
                            placeholder="Selecione"
                            // ref={register({ required: true })}
                            className="listagem"
                            onChange={(e) => {
                              setNumeroDeParcelas(taxa.numero_parcelas);
                              setTaxaId(taxa.id);
                              setTaxaDeServico(taxa.taxa);
                              setRole(e.target.value);
                            }}
                          >
                            <option>
                              {taxa.role === "ROLE_EC" && "EC"}
                              {taxa.role === "ROLE_LA" && "LA"}
                              {taxa.role === "ROLE_RV" && "RV"}
                            </option>

                            <option value="ROLE_EC">EC</option>
                            <option value="ROLE_LA">LA</option>
                            <option value="ROLE_RV">RV</option>
                          </select> */}
                          <span>
                            {taxa.role === 'ROLE_EC' && 'EC'}
                            {taxa.role === 'ROLE_LA' && 'LA'}
                            {taxa.role === 'ROLE_RV' && 'RV'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="coluna2">
                      <p>Taxas de comissão</p>
                      <div>
                        <BoxEditaTaxa
                          taxaComissaoValorMenorQueZero={
                            taxaComissaoValorMenorQueZero
                          }
                        >
                          {editarTaxa === true &&
                          editarTaxaId === taxa.id ? (
                            <div className="boxInput">
                              <div className="input">
                                <span
                                  style={{fontSize: 16}}
                                  className="porcentagem"
                                >
                                  %
                                </span>
                                <input
                                  value={taxaComissaoValor}
                                  name="taxa_comissao"
                                  placeholder={taxa.taxa.toFixed(2)}
                                  onChange={(e) =>
                                    verificaValorTaxa(e.target.value)
                                  }
                                  onKeyUp={(e) =>
                                    soNumeroSimbolosMask(e)
                                  }
                                />
                              </div>
                              <button
                                onClick={() => {
                                  if (
                                    taxaComissaoValorMenorQueZero ===
                                    false
                                  ) {
                                    setTaxaId(taxa.id);
                                    setTaxaDeServico(
                                      taxaComissaoValor
                                    );
                                    setNumeroDeParcelas(
                                      taxa.numero_parcelas
                                    );
                                    setRole(taxa.role);
                                  } else {
                                    return;
                                  }
                                }}
                              >
                                ok
                              </button>
                              <button
                                className="cancelar"
                                onClick={() => {
                                  setEditarTaxa(false);
                                }}
                              >
                                x
                              </button>
                            </div>
                          ) : (
                            <>
                              <span>%</span>{' '}
                              <span>{taxa.taxa.toFixed(2)}</span>{' '}
                              <span
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: 15,
                                }}
                                onClick={() => {
                                  setEditarTaxa(true);
                                  setEditarTaxaId(taxa.id);
                                  setTaxaComissaoValor(taxa.taxa);
                                  setTaxaComissaoValorMenorQueZero(
                                    false
                                  );
                                }}
                              >
                                <img
                                  src={editIcon}
                                  alt=""
                                  style={{width: 18}}
                                />
                              </span>
                            </>
                          )}
                        </BoxEditaTaxa>
                      </div>
                    </div>
                  </Line>
                ))}
              </MobileBody>
            </TableContentMobile>
          </Content>
        </Page>
      </Container>
    </>
  );
}

export default TaxaComissao;
