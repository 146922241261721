import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { MdErrorOutline } from 'react-icons/md';
// import { Tooltip } from '@material-ui/core/';
// import { jsPDF } from "jspdf";
// import { uniqueId } from "lodash";
// import moment from 'moment';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import LoadingContent from '../../components/LoadingContent';

// Context API
import { useToast } from '../../hooks/ToastContext';
import { useSimulatorContaDeLuz } from '../../hooks/SimulatorContaDeLuzContext';

// Máscaras
import contaBancariaMask from '../../components/InputMasks/contaBancariaMask';
import cpfMask from '../../components/InputMasks/cpfMask';
// import rgMask from '../../components/InputMasks/rgMask';
// import cnpjMask from '../../components/InputMasks/cnpjMask';
import telefoneMask from '../../components/InputMasks/telefoneMask';
import cepMask from '../../components/InputMasks/cepMask';

// import companhias from '../../assets/json/cias_energia.json';
import BankList from '../../assets/json/bancos.json';

import {
  Container,
  Page,
  Content,
  RowBox1,
  // RowBoxInfos,
  Box,
  // BoxBlue,
  // ValueArea,
  // BoletoOptions,
  InputArea,
  Row,
  Row3,
  Row4,
  RowBox2,
  // RowBox3,
  InputContainer,
  Error,
  Separator,
} from './styles';

function CriarTransacaoContaDeLuz() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const {
    simuladorContaDeLuz,
    ciaEnergiaSimuladorContaDeLuz,
    userSimuladorContaDeLuz,
  } = useSimulatorContaDeLuz();

  const { register, handleSubmit, setValue, errors } = useForm();
  const onSubmit = async (data) => {
    setIsLoading(true);
    console.log(data);   

    const auth = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    /* const dataFormatada = data.data_nascimento.split('/').reverse().join('-');
    const dataLeituraFormatada = ciaEnergiaSimuladorContaDeLuz.data_leitura
      .split('/')
      .reverse()
      .join('-');
    const dataEmissaoFormatada = data.data_emissao
      .split('/')
      .reverse()
      .join('-'); */

    const valor_renda_familiar = data.renda_familiar
      .replace('.', '')
      .replace('.', '');
    
      const bodyCliente = {
        name: data.nome,
        cpf: data.cpf,
        email: data.email,
        profissao: 'Outros',
        data_aniversario: data.data_nascimento,
        rg: data.rg,
        rg_emissor: data.rg_orgao,
        rg_uf: data.rg_uf,
        rg_data_emissao: data.data_emissao,
        sex: data.sexo,
        //
        nacionalidade: data.nacionalidade,
        naturalidade: data.naturalidade,
        estado_civil: data.estado_civil,
        numero_telefone_1: data.telefone,
        // numero_telefone_2: data.telefone,
        // numero_telefone_3: data.telefone,
        nome_pai: data.nome_pai,
        nome_mae: data.nome_mae,
        //
        renda_familiar: valor_renda_familiar,
        //
        contato_adicional_1_nome: data.nome_referencia1,
        contato_adicional_1_endereco: data.endereco_referencia1,
        contato_adicional_1_numero_telefone: data.telefone_referencia1,
        contato_adicional_2_nome: data.nome_referencia2,
        contato_adicional_2_endereco: data.endereco_referencia2,
        contato_adicional_2_numero_telefone: data.telefone_referencia2,
        //
        banco_agencia: data.agencia,
        banco_conta: data.numero_conta,
        banco_tipo_conta: data.tipo_conta,
        banco_nome: data.nome_banco,
        //
        endereco_cep: data.cep,
        endereco_rua: data.rua,
        endereco_numero: data.numero,
        endereco_complemento: data.complemento,
        endereco_bairro: data.bairro,
        endereco_cidade: data.cidade,
        endereco_uf: data.uf,
      };
    
    try {
      const responseCliente = await api.post(
        '/energy/transaction/client',
        bodyCliente,
        auth,
      );

      console.log('responseCliente', responseCliente);
      const resultCliente = responseCliente.data.data;

      const responseTransacao = await api.post(
        '/energy/transaction',
        {
          numero_parcelas: simuladorContaDeLuz.parcelas,
          valor_pagamento: simuladorContaDeLuz.valor_pagamento,
          valor_adicional: simuladorContaDeLuz.valor_adicional,
          valor_final: simuladorContaDeLuz.valor_total,
          taxa: simuladorContaDeLuz.taxa,
          valor_parcela: simuladorContaDeLuz.valor_parcela,
          companhia: ciaEnergiaSimuladorContaDeLuz.cia_energia,
          data_leitura: ciaEnergiaSimuladorContaDeLuz.data_leitura,
          numero_instalacao: ciaEnergiaSimuladorContaDeLuz.numero_instalacao,
          cliente_id: resultCliente.id,
        },
        auth,
      );

      console.log('responseTransacao', responseTransacao);

      setIsLoading(false);

      addToast({
        type: 'success',
        title: 'Deu tudo certo.',
        description: 'Empréstimo solicitado com sucesso.',
      });

      history.push('/conta-de-luz');

    } catch (error) {
      console.log('error', error.response);

      setIsLoading(false);

      addToast({
        type: 'error',
        title: 'Erro ao solicitar transação',
        description: 'Algo deu errado. Tente novamente ou entre em contato com o Deixa no Azul.',
      });
    }
  };
  const onError = (errors) => {
    addToast({
      type: 'error',
      title: 'Erro no envio do formulário',
      description: 'Você precisa preencher todos os campos obrigatórios (marcados com *).',
    });
  };

  useEffect(() => {
    setValue('nome', userSimuladorContaDeLuz.nome);
    setValue('cpf', userSimuladorContaDeLuz.cpf);
    setValue('data_nascimento', userSimuladorContaDeLuz.data_nascimento);
    setValue('email', userSimuladorContaDeLuz.email);
    setValue('telefone', userSimuladorContaDeLuz.telefone);
  }, [setValue, userSimuladorContaDeLuz.cpf, userSimuladorContaDeLuz.data_nascimento, userSimuladorContaDeLuz.email, userSimuladorContaDeLuz.nome, userSimuladorContaDeLuz.telefone]);

  // CEP do endereço pessoal
  const onBlurCepPes = useCallback(async (e) => {
    const typedValue = e.target.value;

    const cep = typedValue?.replace(/[^0-9]/g, '');
    if (cep?.length !== 8) {
      return
    }

    await fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json()) 
      .then((data) => {
        setValue('rua',data.logradouro);
        setValue('bairro', data.bairro);
        setValue('uf', data.uf);
        setValue('cidade', data.localidade);
      });
  }, [setValue]);

  // Lista de bancos
  const listaBancos = BankList;

  // Máscara do valor da transação
  const inputValueMask = useCallback((e) => {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    e.currentTarget.value = value;
  }, []);

  return (
    <Container>
      <LoadingContent isLoading={isLoading} />

      <MenuBar />
      <NewMobileMenu />

      <Page>
        <TopBar pageTitle="Criar Transação" />

        <Content>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <RowBox2>
              <Box>
                <h3>Informações de cadastro</h3>

                <Row>
                  <InputArea>
                    <label>*Nome completo</label>
                    <InputContainer style={{ borderColor: errors.nome && "red" }}>
                      <input
                        name="nome"
                        type="text"
                        placeholder="Digite o nome completo"
                        ref={register({ required: true })}
                        // onBlur={() => setLocalStorage()}
                      />
                      {errors.nome && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*E-mail</label>
                    <InputContainer style={{ borderColor: errors.email && "red" }}>
                      <input
                        name="email"
                        type="text"
                        placeholder="Digite o e-mail"
                        ref={register({ required: "Campo obrigatório" })}
                        // onBlur={() => validarEmail()}
                      />
                      {errors.email && <Error title={errors.email.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row>

                <Row3>
                  <InputArea>
                    <label>*CPF</label>
                    <InputContainer style={{ borderColor: errors.cpf && "red" }}>
                      <input
                        name="cpf"
                        placeholder="Digite o CPF (apenas números)"
                        onKeyUp={(e) => cpfMask(e)}
                        ref={register({ required: "Campo obrigatório" })}
                        // onBlur={() => validarCPF()}
                      />
                      {errors.cpf && <Error title={errors.cpf.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Data de nascimento</label>
                    <InputContainer style={{ borderColor: errors.data_nascimento && "red" }}>
                      <input
                        name="data_nascimento"
                        type="date"
                        placeholder="Digite sua data de nascimento"
                        ref={register({ required: "Campo obrigatório" })}
                        // onBlur={validDate}
                      />
                      {errors.data_nascimento && <Error title={errors.data_nascimento.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                  
                  <InputArea>
                    <label>*Telefone</label>
                    <InputContainer style={{ borderColor: errors.telefone && "red" }}>
                    <input
                      name="telefone"
                      onKeyUp={(e) => telefoneMask(e)}
                      placeholder="Apenas números"
                      ref={register({ required: true })}
                      // onBlur={() => setLocalStorage()}
                    />
                    {errors.telefone && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row3>

                <Row4>
                  <InputArea>
                    <label>*RG</label>
                    <InputContainer style={{ borderColor: errors.rg && "red" }}>
                      <input
                        name="rg"
                        type="text"
                        placeholder="Digite o seu RG (apenas números)"
                        ref={register({ required: true })}
                        // onBlur={() => setLocalStorage()}
                      />
                      {errors.rg && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Órgão expedidor</label>
                    <InputContainer style={{ borderColor: errors.rg_orgao && "red" }}>
                      <input
                        name="rg_orgao"
                        type="text"
                        // placeholder="Digite o e-mail"
                        ref={register({ required: "Campo obrigatório" })}
                        // onBlur={() => validarEmail()}
                      />
                      {errors.rg_orgao && <Error title={errors.rg_orgao.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*UF</label>
                    <InputContainer style={{ borderColor: errors.rg_uf && "red" }}>
                      <input
                        name="rg_uf"
                        type="text"
                        // placeholder="Digite o e-mail"
                        ref={register({ required: "Campo obrigatório" })}
                        // onBlur={() => validarEmail()}
                      />
                      {errors.rg_uf && <Error title={errors.rg_uf.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                
                  <InputArea>
                    <label>*Data de emissão</label>
                    <InputContainer style={{ borderColor: errors.data_emissao && "red" }}>
                      <input
                        name="data_emissao"
                        type="date"
                        placeholder="Digite a data de emissão"
                        ref={register({ required: "Campo obrigatório" })}
                        // onBlur={validDate}
                      />
                      {errors.data_emissao && <Error title={errors.data_emissao.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row4>

                <Row4>
                  <InputArea>
                    <label>*Naturalidade</label>
                    <InputContainer style={{ borderColor: errors.naturalidade && "red" }}>
                      <input
                        name="naturalidade"
                        type="text"
                        // placeholder="Digite o e-mail"
                        ref={register({ required: "Campo obrigatório" })}
                        // onBlur={() => validarEmail()}
                      />
                      {errors.naturalidade && <Error title={errors.naturalidade.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Nacionalidade</label>
                    <InputContainer style={{ borderColor: errors.nacionalidade && "red" }}>
                      <input
                        name="nacionalidade"
                        type="text"
                        // placeholder="Digite o e-mail"
                        ref={register({ required: "Campo obrigatório" })}
                        // onBlur={() => validarEmail()}
                      />
                      {errors.nacionalidade && <Error title={errors.nacionalidade.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Sexo</label>
                    <InputContainer style={{ borderColor: errors.sexo && "red" }}>
                      <select
                        name="sexo"
                        placeholder="Selecione"
                        ref={register({ required: true })}
                      >
                        <option value="">Selecione</option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                      </select>
                      {errors.sexo && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Estado civil</label>
                    <InputContainer style={{ borderColor: errors.estado_civil && "red" }}>
                      <select
                        name="estado_civil"
                        placeholder="Selecione"
                        ref={register({ required: true })}
                      >
                        <option value="">Selecione</option>
                        <option value="Solteiro">Solteiro</option>
                        <option value="Casado">Casado</option>
                        <option value="Divorciado">Divorciado</option>
                        <option value="Viúvo">Viúvo</option>
                      </select>
                      {errors.estado_civil && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row4>

                <Row>
                  <InputArea>
                    <label>*Nome da mãe</label>
                    <InputContainer style={{ borderColor: errors.nome_mae && "red" }}>
                      <input
                        name="nome_mae"
                        type="text"
                        placeholder="Digite o nome da sua mãe"
                        ref={register({ required: true })}
                        // onBlur={() => setLocalStorage()}
                      />
                      {errors.nome_mae && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Nome do pai</label>
                    <InputContainer style={{ borderColor: errors.nome_pai && "red" }}>
                      <input
                        name="nome_pai"
                        type="text"
                        placeholder="Digite o nome do seu pai"
                        ref={register({ required: "Campo obrigatório" })}
                        // onBlur={() => validarEmail()}
                      />
                      {errors.nome_pai && <Error title={errors.nome_pai.message}><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row>
              </Box>
            </RowBox2>

            <RowBox2>
              <Box>
                <h3>Informações de endereço</h3>

                <Row4>
                  <InputArea>
                    <label>*CEP</label>
                    <InputContainer style={{ borderColor: errors.cep && "red" }}>
                      <input
                        name="cep"
                        type="text"
                        placeholder="Apenas números (8 dígitos)"
                        ref={register({ required: true })}
                        onKeyUp={(e) => cepMask(e)}
                        onBlur={(e) => onBlurCepPes(e)}
                      />
                      {errors.cep && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Rua</label>
                    <InputContainer style={{ borderColor: errors.rua && "red" }}>
                      <input
                        name="rua"
                        type="text"
                        placeholder="Digite o endereço"
                        ref={register({ required: true })}
                      />
                      {errors.rua && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Bairro</label>
                    <InputContainer style={{ borderColor: errors.bairro && "red" }}>
                      <input
                        name="bairro"
                        type="text"
                        placeholder="Digite o bairro"
                        ref={register({ required: true })}
                      />
                      {errors.bairro && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                  
                  <InputArea>
                    <label>*Número</label>
                    <InputContainer style={{ borderColor: errors.numero && "red" }}>
                      <input
                        name="numero"
                        type="text"
                        placeholder="xxxx"
                        ref={register({ required: true })}
                      />
                      {errors.numero && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row4>

                <Row3>
                  <InputArea>
                    <label>Complemento</label>
                    <InputContainer style={{ borderColor: errors.complemento && "red" }}>
                      <input
                        name="complemento"
                        type="text"
                        placeholder="Ex. Casa"
                        ref={register}
                      />
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*UF</label>
                    <InputContainer style={{ borderColor: errors.uf && "red" }}>
                      <select
                        name="uf"
                        placeholder="Selecione"
                        ref={register({ required: true })}
                      >
                        <option value="">Selecione</option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MT">MT</option>
                        <option value="MS">MS</option>
                        <option value="MG">MG</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                      </select>
                      {errors.uf && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Cidade</label>
                    <InputContainer style={{ borderColor: errors.cidade && "red" }}>
                      <input
                        name="cidade"
                        type="text"
                        placeholder="Digite o nome da cidade"
                        ref={register({ required: true })}
                      />
                      {errors.cidade && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row3>
              </Box>
            </RowBox2>
          
            <RowBox1>
              <Box>
                <h3>Dados bancários</h3>

                <Row>
                  <InputArea>
                    <label>*Nome do banco</label>
                    <InputContainer style={{ borderColor: errors.nome_banco && "red" }}>
                      <select
                        name="nome_banco"
                        placeholder="Selecione"
                        // onChange={setNumeroBanco}
                        ref={register}
                      >
                        <option value="">Selecione</option>

                        {listaBancos.map(item => (
                          <option value={item.label}>{item.value+' - '+item.label}</option>
                        ))}
                      </select>
                      {errors.nome_banco && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                    <input type="text" name="numero_banco" ref={register} hidden />
                  </InputArea>
              
                  <InputArea>
                    <label>*Tipo da Conta</label>
                    <InputContainer style={{ borderColor: errors.tipo_conta && "red" }}>
                      <select
                        name="tipo_conta"
                        placeholder="Selecione"
                        ref={register}
                      >
                        <option value="">Selecione</option>
                        <option value="Corrente">Corrente</option>
                        <option value="Poupança">Poupança</option>
                      </select>
                      {errors.tipo_conta && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row>

                <Row>
                  <InputArea>
                    <label>*Agência</label>
                    <InputContainer style={{ borderColor: errors.agencia && "red" }}>
                      <input
                        name="agencia"
                        type="number"
                        placeholder="xxxx"
                        ref={register}
                      />
                      {errors.agencia && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Número da conta</label>
                    <InputContainer style={{ borderColor: errors.numero_conta && "red" }}>
                      <input
                        name="numero_conta"
                        type="text"
                        placeholder="Apenas números"
                        ref={register}
                        onKeyUp={(e) => contaBancariaMask(e)}
                      />
                      {errors.numero_conta && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row>
              </Box>

              <div>
                <Box>
                  <h3>Renda</h3>

                  <InputArea>
                    <label>*Renda familiar</label>
                    <InputContainer style={{ borderColor: errors.renda_familiar && "red" }}>
                      <input
                      name="renda_familiar"
                      type="text"
                      ref={register({ required: true })}
                      onKeyUp={(e) => inputValueMask(e)}
                      placeholder="Digite o valor (apenas números)"
                      />
                      {errors.renda_familiar && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Box>
              </div>
            </RowBox1>
          
            <RowBox2>
              <Box>
                <h3>Referências</h3>

                <Row3>
                  <InputArea>
                    <label>*Nome completo</label>
                    <InputContainer style={{ borderColor: errors.nome_referencia1 && "red" }}>
                      <input
                        name="nome_referencia1"
                        type="text"
                        placeholder="Digite o nome completo"
                        ref={register({ required: true })}
                        // onBlur={() => setLocalStorage()}
                      />
                      {errors.nome_referencia1 && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Endereço completo</label>
                    <InputContainer style={{ borderColor: errors.endereco_referencia1 && "red" }}>
                      <input
                        name="endereco_referencia1"
                        type="text"
                        placeholder="Digite o endereço completo"
                        ref={register({ required: true })}
                        // onBlur={() => setLocalStorage()}
                      />
                      {errors.endereco_referencia1 && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Telefone</label>
                    <InputContainer style={{ borderColor: errors.telefone_referencia1 && "red" }}>
                    <input
                      name="telefone_referencia1"
                      onKeyUp={(e) => telefoneMask(e)}
                      placeholder="Apenas números"
                      ref={register({ required: true })}
                      // onBlur={() => setLocalStorage()}
                    />
                    {errors.telefone_referencia1 && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row3>

                <Separator />

                <Row3>
                  <InputArea>
                    <label>*Nome completo</label>
                    <InputContainer style={{ borderColor: errors.nome_referencia2 && "red" }}>
                      <input
                        name="nome_referencia2"
                        type="text"
                        placeholder="Digite o nome completo"
                        ref={register({ required: true })}
                        // onBlur={() => setLocalStorage()}
                      />
                      {errors.nome_referencia2 && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Endereço completo</label>
                    <InputContainer style={{ borderColor: errors.endereco_referencia2 && "red" }}>
                      <input
                        name="endereco_referencia2"
                        type="text"
                        placeholder="Digite o endereço completo"
                        ref={register({ required: true })}
                        // onBlur={() => setLocalStorage()}
                      />
                      {errors.endereco_referencia2 && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>

                  <InputArea>
                    <label>*Telefone</label>
                    <InputContainer style={{ borderColor: errors.telefone_referencia2 && "red" }}>
                    <input
                      name="telefone_referencia2"
                      onKeyUp={(e) => telefoneMask(e)}
                      placeholder="Apenas números"
                      ref={register({ required: true })}
                      // onBlur={() => setLocalStorage()}
                    />
                    {errors.telefone_referencia2 && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                    </InputContainer>
                  </InputArea>
                </Row3>
              </Box>
            </RowBox2>
          
            <button type="submit">Solicitar empréstimo</button>
          </form>
        </Content>
      </Page>
    </Container>
  );
};

export default CriarTransacaoContaDeLuz;
