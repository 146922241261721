import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { FiArrowRight } from 'react-icons/fi';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';

import {
  Container,
  Page,
  Content,
  MessagesContent,
  Message,
  MessageHeader,
  MessageBody,
} from './styles';

function Mensagens() {
  const history = useHistory();
  
  const [messages, setMessages] = useState([]);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');
  const loggedUser = JSON.parse(localStorage.getItem('@DeixaNoAzul:loggedUser'));

  useEffect(() => {
    const auth = {
      headers: {
        Authorization : "Bearer "+userToken
      }
    };

    api.get('/received_messages/', auth).then(response => {
      const result = response.data.data;
      // console.log(result);

      setMessages(result.reverse());
    });

  }, [userToken]);

  const changeStatus = useCallback((message) => {
    
    if (message.status === 0) {
      api.put('/mark_read', {
        id: message.id
      }, {
        headers: {
          Authorization : "Bearer "+userToken
        }
      });
    }

  }, [userToken]);

  // Altera o background de acordo com o usuário logado
  const isAdmin = loggedUser.role === 'ROLE_ADMIN' ? true : false;
  const isEC = loggedUser.role === 'ROLE_EC' ? true : false;
  const isLA = loggedUser.role === 'ROLE_LA' ? true : false;
  const isRV = loggedUser.role === 'ROLE_RV' ? true : false;

  // Função que redireciona o Admin para a transação
  const irParaTransacaoAdmin = useCallback((message) => {
    const id = message.replace('A transação de id ', '').split(',');
    const idReverse = id[0].split('').reverse().join('');

    // redireciona para a página de edição
    history.push(`/admin/transacoes/editar/${idReverse}`);
  }, [history]);

  // Função que redireciona o EC para a transação
  const irParaTransacaoEC = useCallback((id) => {
    const idReverse = id.split('').reverse().join('');

    // redireciona para a página de edição
    history.push(`/transacoes/editar/${idReverse}`);
  }, [history]);

  // Função que redireciona o EC para a transação
  const irParaEstabelecimentoLA = useCallback((id) => {
    const idReverse = id.split('').reverse().join('');

    // redireciona para a página de edição
    if (isLA) {
      history.push(`/ec/editar/${idReverse}`);
    }
    if (isRV) {
      history.push(`/ec/revendedor/editar/${idReverse}`);
    }
  }, [history, isLA, isRV]);
  
  const [messageId, setMessageId] = useState('');

  // Função que verifica se há um transacao_id no corpo da mensagem para ECs
  function verificarTransacaoId(message) {
    const messageDivided = message.split('|');

    // console.log(messageDivided.length);

    if (messageDivided.length > 1 && messageDivided[0].includes('Transação:')) {
      const id = messageDivided[0].replace('Transação: ', '');

      return (
        <button type="button" className="goTransaction" onClick={() => irParaTransacaoEC(id)}>
          Acessar transação
          <FiArrowRight color="#FFFFFF" size={16} />
        </button>
      )
    }
  }

  // Função que verifica se dhá um ec_id no corpo da mensagem para LAs
  function verificarEstabelecimentoId(message) {
    const messageDivided = message.split('|');

    // console.log(messageDivided.length);

    if (messageDivided.length > 1 && messageDivided[0].includes('Estabelecimento:')) {
      const id = messageDivided[0].replace('Estabelecimento: ', '');

      return (
        <button type="button" className="goTransaction" onClick={() => irParaEstabelecimentoLA(id)}>
          Acessar estabelecimento
          <FiArrowRight color="#FFFFFF" size={16} />
        </button>
      )
    }
  }

  // Função que retorna o corpo da mensagem para o EC
  function ecMessageBody(message) {
    const messageDivided = message.split('|');

    if (messageDivided.length > 1 && messageDivided[0].includes('Transação:')) {
      return <p className="message">{messageDivided[1]}</p>
    } else {
      return <p className="message">{message}</p>
    }
  }

  // Função que retorna o corpo da mensagem para o LA
  function laMessageBody(message) {
    const messageDivided = message.split('|');

    if (messageDivided.length > 1 && messageDivided[0].includes('Estabelecimento:')) {
      return <p className="message">{messageDivided[1]}</p>
    } else {
      return <p className="message">{message}</p>
    }
  }

  return (
    <Container>
      <MenuBar />
      <NewMobileMenu />

      <Page>
        <TopBar pageTitle="Mensagens" />

        <Content isAdmin={isAdmin}>
          <MessagesContent>
            <h3>Mensagens</h3>

            {messages.map(message => (
              <Message key={message.id}>
                <MessageHeader onClick={() => {
                  messageId === message.id
                    ? setMessageId('')
                    : setMessageId(message.id);
                  changeStatus(message);
                }}>
                  <span className="title">{message.subject}</span>
                  <span className="date">{message.created_at.substring(0, 10).split('-').reverse().join('/')}</span>
                  {message.status === 0 
                    ? <span className="status">Não lida</span>
                    : <span className="status" style={{ background: '#CCCCCC' }}>Lida</span>
                  }
                </MessageHeader>

                <MessageBody show={messageId} id={message.id}>
                  {!isEC && !isLA && !isRV && <p className="message">{message.body}</p>}
                  {isEC && ecMessageBody(message.body)}
                  {isLA && laMessageBody(message.body)}
                  {isRV && laMessageBody(message.body)}
                  
                  {isAdmin && (
                    <button type="button" className="goTransaction" onClick={() => irParaTransacaoAdmin(message.body)}>
                      Acessar transação
                      <FiArrowRight color="#FFFFFF" size={16} />
                    </button>
                  )}
                  
                  {isEC && verificarTransacaoId(message.body)}

                  {isLA && verificarEstabelecimentoId(message.body)}
                  {isRV && verificarEstabelecimentoId(message.body)}
                </MessageBody>
              </Message>
            ))}

            {/* {messages.map(message => (
              <Accordion
                key={message.id}
                square
                expanded={expanded === `panel${messages.indexOf(message) + 1}`}
                onChange={handleChange(`panel${messages.indexOf(message) + 1}`)}
                onClick={() => changeStatus(message)}
              >
                <AccordionSummary aria-controls={`panel${messages.indexOf(message) + 1}d-content`} id={`panel${messages.indexOf(message) + 1}d-header`}>
                  <span className="title">{message.subject}</span>
                  <span className="date">{message.created_at.substring(0, 10).split('-').reverse().join('/')}</span>
                  <span className="status">{message.status === 0 ? "Não lida" : "Lida"}</span>
                </AccordionSummary>

                <AccordionDetails>
                  <p className="message">{message.body}</p>
                  {isAdmin && (
                    <button type="button" className="goTransaction" onClick={() => irParaTransacao(message.body)}>
                      Acessar transação
                      <FiArrowRight color="#FFFFFF" size={16} />
                    </button>
                  )}
                </AccordionDetails>
              </Accordion>
            ))} */}
          </MessagesContent>

        </Content>
      </Page>
    </Container>
  );
}

export default Mensagens;