import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import {
  FiCreditCard,
  FiMenu,
  FiLogOut,
  FiUser,
  FiDollarSign,
  FiX,
  FiAward,
  FiTag,
  FiFileText,
  FiPercent,
} from 'react-icons/fi';
import { FaSquareFull } from "react-icons/fa";

import { useAuth } from "../../hooks/AuthContext";

import logoImg from "../../assets/images/logo_icon.png";
import transacaoIcon from "../../assets/images/transacao_new_icon.svg";
import pagamentosIcon from "../../assets/images/pagamentos_new_icon.svg";
// import porcentagem from "../../assets/images/porcentagem.svg";
import calculadora from "../../assets/images/calculadora_new.svg";

import {
  Container,
  Button,
  Logo,
  Nav,
  MenuItem,
  LogOut,
  SubMenuItem,
  MenuSpace,
  SubMenuDropdown,
} from './styles';

function NewMobileMenu({ active, subMenuActive }) {
  const location = useLocation();

  const page = location.pathname;

  const loggedUser = JSON.parse(
    localStorage.getItem("@DeixaNoAzul:loggedUser")
  );

  const [open, setOpen] = useState(false); // default deve ser false
  const [transacoesOpened, setTransacoesOpened] = useState(false); // default deve ser false
  const [taxaComissaoOpened, setTaxaComissaoOpened] = useState(false); // default deve ser false

  // const [openMais, setOpenMais] = useState(false);

  useEffect(() => {
    if (
      page.includes('/transacoes') ||
      page.includes('/conta-de-luz') ||
      page.includes('/admin/transacoes') ||
      page.includes('/admin/conta-luz')
    ) {
      setTransacoesOpened(true);
    }
  }, [page]);

  const { signOut } = useAuth();

  return (
    <Container>
      <Button style={{ background: open ? '#FFFFFF' : '#10104f' }} onClick={() => setOpen(!open)}>
        {open ? <FiX color="#08BBE9" size={20} /> : <FiMenu color="#08BBE9" size={20} />}
      </Button>

      <Nav open={open}>
        <Logo>
          <img src={logoImg} alt="Deixa no azul" />
        </Logo>

        <MenuSpace>
          <Link
            to="/ec"
            className={loggedUser.role === "ROLE_LA" ? "" : "disabled"}
          >
            <MenuItem className={active === "EC" ? "active" : ""}>
              <FiFileText color="#08BBE9" size={16} />
              <span>EC</span>
            </MenuItem>
          </Link>

          {/* Conta digital do LA */}
          <Link
            to="/contadigital/la"
            className={
              loggedUser.role === "ROLE_ADMIN" ||
              loggedUser.role === "ROLE_EC" ||
              loggedUser.role === "ROLE_RV"
                ? "disabled"
                : ""
            }
          >
            <MenuItem className={active === "CD" ? "active" : ""}>
              <FiDollarSign color="#08BBE9" size={16} />
              <span>Conta digital</span>
            </MenuItem>
          </Link>

          <Link
            to="/simulador"
            className={loggedUser.role === "ROLE_EC" ? "" : "disabled"}
          >
            <MenuItem className={active === "Simulador" ? "active" : ""}>
              <FiCreditCard color="#08BBE9" size={16} />
              <span>Simulador</span>
            </MenuItem>
          </Link>

          <Link
            // to="/"
            className={loggedUser.role === "ROLE_EC" ? "" : "disabled"}
            onClick={() => setTransacoesOpened(!transacoesOpened)}
          >
            <MenuItem className={active === "Transações" ? "active" : ""}>
              <img src={transacaoIcon} alt="Transações" />
              <span>Transações</span>
            </MenuItem>
          </Link>
          
          <SubMenuDropdown transacoesOpened={transacoesOpened}>
            {/* Submenu */}
            <Link
              to="/transacoes"
              className={loggedUser.role === "ROLE_EC" ? "" : "disabled"}
              style={{ marginBottom: -10 }}
            >
              <SubMenuItem style={{ marginBottom: 10, marginTop: -10 }}>
                <FaSquareFull color={subMenuActive === "Deixa no Azul" ? "0073FC" : "transparent"} size={10} />
                <span
                  style={{
                    fontWeight: subMenuActive === "Deixa no Azul" ? "bold" : "normal"
                  }}
                >
                  Deixa no Azul
                </span>
              </SubMenuItem>
            </Link>
            
            {/* Submenu */}
            <Link
              to="/conta-de-luz"
              className={loggedUser.role === "ROLE_EC" ? "" : "disabled"}
            >
              <SubMenuItem>
                <FaSquareFull color={subMenuActive === "Conta de Luz" ? "0073FC" : "transparent"} size={10} />
                <span
                  style={{
                    fontWeight: subMenuActive === "Conta de Luz" ? "bold" : "normal"
                  }}
                >
                  Contas de Luz
                </span>
              </SubMenuItem>
            </Link>

            {/* Submenu */}
             <Link
              to="/eprepag"
              className={loggedUser.role === "ROLE_EC" ? "" : "disabled"}
            >
              <SubMenuItem>
                <FaSquareFull color={subMenuActive === "E-prepag" ? "0073FC" : "transparent"} size={10} />
                <span
                  style={{
                    fontWeight: subMenuActive === "E-prepag" ? "bold" : "normal"
                  }}
                >
                  E-prepag
                </span>
              </SubMenuItem>
            </Link>
          </SubMenuDropdown>

          {/* Conta digital do EC */}
          <Link
            to="/contadigital/ec"
            className={
              loggedUser.role === "ROLE_ADMIN" ||
              loggedUser.role === "ROLE_LA" ||
              loggedUser.role === "ROLE_RV"
                ? "disabled"
                : ""
            }
          >
            <MenuItem className={active === "CD" ? "active" : ""}>
              <FiDollarSign color="#08BBE9" size={16} />
              <span>Conta digital</span>
            </MenuItem>
          </Link>

          <Link
            to="/ec/revendedor"
            className={loggedUser.role === "ROLE_RV" ? "" : "disabled"}
          >
            <MenuItem className={active === "EC" ? "active" : ""}>
              <FiFileText color="#08BBE9" size={16} />
              <span>EC</span>
            </MenuItem>
          </Link>

          {/* Conta digital do Revendedor */}
          <Link
            to="/contadigital/revendedor"
            className={
              loggedUser.role === "ROLE_ADMIN" ||
              loggedUser.role === "ROLE_LA" ||
              loggedUser.role === "ROLE_EC"
                ? "disabled"
                : ""
            }
          >
            <MenuItem className={active === "CD" ? "active" : ""}>
              <FiDollarSign color="#08BBE9" size={16} />
              <span>Conta digital</span>
            </MenuItem>
          </Link>

          <Link
            // to="/admin/transacoes"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
            onClick={() => setTransacoesOpened(!transacoesOpened)}
          >
            <MenuItem className={active === "AdminTransações" ? "active" : ""}>
              <img src={transacaoIcon} alt="Transações" />
              <span>Transações</span>
            </MenuItem>
          </Link>

          <SubMenuDropdown transacoesOpened={transacoesOpened}>
            {/* Submenu */}
            <Link
              to="/admin/transacoes"
              className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
              style={{ marginBottom: -10 }}
            >
              <SubMenuItem style={{ marginBottom: 10 }}>
                <FaSquareFull color={subMenuActive === "Deixa no Azul" ? "0073FC" : "transparent"} size={10} />
                <span
                  style={{
                    fontWeight: subMenuActive === "Deixa no Azul" ? "bold" : "normal"
                  }}
                >
                  Deixa no Azul
                </span>
              </SubMenuItem>
            </Link>

            {/* Submenu */}
            <Link
              to="/admin/conta-luz"
              className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
            >
              <SubMenuItem>
                <FaSquareFull color={subMenuActive === "Conta de Luz" ? "0073FC" : "transparent"} size={10} />
                <span
                  style={{
                    fontWeight: subMenuActive === "Conta de Luz" ? "bold" : "normal"
                  }}
                >
                  Contas de Luz
                </span>
              </SubMenuItem>
            </Link>

            {/* Submenu */}
            <Link
              to="/eprepag"
              className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
            >
              <SubMenuItem>
                <FaSquareFull color={subMenuActive === "E-prepag" ? "0073FC" : "transparent"} size={10} />
                <span
                  style={{
                    fontWeight: subMenuActive === "E-prepag" ? "bold" : "normal"
                  }}
                >
                  E-prepag
                </span>
              </SubMenuItem>
            </Link>

            <Link
              to="/fgts"
              className={loggedUser.role === "ROLE_ADMIN"  || loggedUser.role === "ROLE_LA"  || loggedUser.role === "ROLE_EC"? "" : "disabled"}
            >
              <SubMenuItem>
                <FaSquareFull color={subMenuActive === "FGTS" ? "0073FC" : "transparent"} size={10} />
                <span
                  style={{
                    fontWeight: subMenuActive === "FGTS" ? "bold" : "normal"
                  }}
                >
                  FGTS
                </span>
              </SubMenuItem>
            </Link>
          </SubMenuDropdown>

          <Link
            to="/admin/licenciados"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItem className={active === "CadastroLA" ? "active" : ""}>
              <FiAward color="#08BBE9" size={16} />
              <span>Licenciados</span>
            </MenuItem>
          </Link>

          <Link
            to="/admin/revendedor"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItem className={active === "Revendedores" ? "active" : ""}>
              <FiTag color="#08BBE9" size={16} />
              <span>Revendedores</span>
            </MenuItem>
          </Link>

          <Link
            to="/admin/estabelecimentos"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItem className={active === "AnaliseEC" ? "active" : ""}>
              <FiFileText color="#08BBE9" size={16} />
              <span>ECs</span>
            </MenuItem>
          </Link>

          <Link
            to="/admin/pagamentos"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItem className={active === "Pagamentos" ? "active" : ""}>
              <img src={pagamentosIcon} alt="Pagamentos" style={{ width: 18 }} />
              <span>Pagamentos</span>
            </MenuItem>
          </Link>

          <Link
            to="/admin/taxadesimulacao"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItem className={active === "TaxaDeSilumacao" ? "active" : ""}>
              <FiPercent color="#08BBE9" size={16} />
              <span>Taxas de Simulação</span>
            </MenuItem>
          </Link>

          {/* <Link
            to="/admin/taxacomissao"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
          >
            <MenuItem className={active === "TaxaComissao" ? "active" : ""}>
              <img src={calculadora} alt="Taxa de Comissão" style={{ width: 16 }} />
              <span>Taxas de Comissão</span>
            </MenuItem>
          </Link> */}

          <Link
            // to="/admin/transacoes"
            className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
            onClick={() => setTaxaComissaoOpened(!taxaComissaoOpened)}
          >
            <MenuItem className={active === "TaxaComissao" ? "active" : ""}>
              <img src={calculadora} alt="Taxa de Comissão" style={{ width: 16 }} />
              <span>Taxas de Comissão</span>
            </MenuItem>
          </Link>

          <SubMenuDropdown transacoesOpened={taxaComissaoOpened}>
              <Link
                to="/admin/taxacomissao/Transacao"
                className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
              >
                  <SubMenuItem style={{marginTop: -15}}>
                    <FaSquareFull color={subMenuActive === "Deixa no Azul Taxas" ? "0073FC" : "transparent"} size={10} />
                    <span
                      style={{
                        fontWeight: subMenuActive === "Deixa no Azul Taxas" ? "bold" : "normal"
                      }}
                    >
                      Deixa no Azul
                    </span>
                  </SubMenuItem>
              </Link>
              <Link
                to="/admin/taxacomissao/TransacaoPagamentoContaDeLuz"
                className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
              >
                  <SubMenuItem style={{marginTop: -15}}>
                    <FaSquareFull color={subMenuActive === "Contas de Luz Taxas" ? "0073FC" : "transparent"} size={10} />
                    <span
                      style={{
                        fontWeight: subMenuActive === "Contas de Luz Taxas" ? "bold" : "normal"
                      }}
                    >
                      Contas de Luz
                    </span>
                  </SubMenuItem>
              </Link>
              <Link
                  to="/admin/taxacomissao/TransacaoCertificadoDigital"
                  className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
                >
                <SubMenuItem style={{marginTop: -15}}>
                  <FaSquareFull color={subMenuActive === "Certificado Digital Taxas" ? "0073FC" : "transparent"} size={10} />
                  <span
                    style={{
                      fontWeight: subMenuActive === "Certificado Digital Taxas" ? "bold" : "normal"
                    }}
                  >
                    Certificado Digital
                  </span>
                </SubMenuItem>
              </Link>
            {/* Submenu */}
              <Link
              to="/admin/taxacomissaoeprepag"
                  className={loggedUser.role === "ROLE_ADMIN" ? "" : "disabled"}
                >
                <SubMenuItem style={{marginTop: -15}}>
                  <FaSquareFull color={subMenuActive === "E-prepag Taxas" ? "0073FC" : "transparent"} size={10} />
                  <span
                    style={{
                      fontWeight: subMenuActive === "E-prepag Taxas" ? "bold" : "normal"
                    }}
                  >
                    E-prepag
                  </span>
                </SubMenuItem>
              </Link>
          </SubMenuDropdown>

          <Link to="/profile">
            <MenuItem className={active === "Perfil" ? "active" : ""}>
              <FiUser color="#08BBE9" size={16} />
              <span>Editar perfil</span>
            </MenuItem>
          </Link>

          <LogOut onClick={() => signOut()}>
            <FiLogOut color="#F35757" size={16} />
            <span>Sair</span>
          </LogOut>
        </MenuSpace>
      </Nav>
    </Container>
  );
};

export default NewMobileMenu;
