/* eslint-disable array-callback-return */
import React, {useState, useEffect, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {MdErrorOutline, MdHighlightOff} from 'react-icons/md';

import api from '../../services/api';

import TopBar from '../../components/TopBar';
import MenuBar from '../../components/MenuBar';
import NewMobileMenu from '../../components/NewMobileMenu';
import LoadingContent from '../../components/LoadingContent';
import SendMessageComponent from '../../components/SendMessageComponent';

import FormatarValor from '../../components/FormatarValor';

import {useToast} from '../../hooks/ToastContext';

import {sendMessage} from '../../utils/sendMessage';

import {
  Container,
  Page,
  Content,
  RowBox1,
  Box,
  Row3,
  Row4,
  InputArea,
  BlueBox,
  StatusArea,
  Box2,
  InputContainer2,
  ModalSection,
  ModalContent,
  Mensagem,
  Error,
  Confirmar,
  Aprovado,
  BoxInfo,
  EditarPagamento,
  InputContainer,
} from './styles';

function AdminEditarFGTS() {
  const history = useHistory();
  const location = useLocation();
  const {addToast} = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const userToken = localStorage.getItem('@DeixaNoAzul:accessToken');

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    errors,
  } = useForm();

  // const [userID, setUserID] = useState("");

  const idTransacao = location.pathname.replace(
    '/admin/fgts/editar/',
    ''
  );

  const [transacao, setTransacao] = useState({});
  const [estabelecimento, setEstabelecimento] = useState({});
  const [licenciado, setLicenciado] = useState({});

  const [statusColor, setStatusColor] = useState('');
  const [editStatusHide, setEditStatusHide] = useState(false);

  const [modalOpened, setModalOpened] = useState(true);
  const [messageHide, setMessageHide] = useState(true);

  const tipoTransacao = ''; // antigo state sem uso do set

  const [aprovadoHide, setAprovadoHide] = useState(true);
  const [requiredMessage, setRequiredMessage] = useState(false);
  const [confirmarHide, setConfirmarHide] = useState(true);

  const aceitarBoletoRejeitado = false; // antigo state sem uso do set

  const editarPagamentoHide = true; // antigo state sem uso do set
  // const [aprovarTransacaoRejeitada, setAprovarTransacaoRejeitada] = useState(false);
  // const [boletoEmAberto, setBoletoEmAberto] = useState(false);

  // const [userID, setUserID] = useState("");

  const loggedUser = JSON.parse(
    localStorage.getItem('@DeixaNoAzul:loggedUser')
  );

  const [userID, setUserID] = useState('');
  const [allowMessage, setAllowMessage] = useState(false);

  // const [editarValor, setEditarValor] = useState(false); // Default false

  // Faz as requisições para preencher as informações
  useEffect(() => {
    setIsLoading(true);

    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    async function requestTransacao() {
      // Faz a requisição e filtra a transação FGTS
      const responseTransacao = await api.get(
        `/fgts?png=99999`,
        auth
      );

      const resultTransacao = responseTransacao.data.data;
      const transacaoFiltrada = resultTransacao.filter(
        (item) => item.id === idTransacao
      );

      const responseEstabe = await api.get(
        `/estabelecimento/${transacaoFiltrada[0].estabelecimento_id}`,
        auth
      );
      const resultEC = responseEstabe.data.data;

      const responseLA = await api.get(
        `/licenciado/${resultEC.licenciado_autorizado_id}`,
        auth
      );
      const resultLA = responseLA.data.data;

      // console.log('resultTransacao', resultTransacao);
      // console.log('transacaoFiltrada', transacaoFiltrada[0]);
      // console.log('resultEC', resultEC);
      // console.log('resultLA', resultLA);

      if (
        transacaoFiltrada[0].transacao_status_id === 2 ||
        transacaoFiltrada[0].transacao_status_id === 4 ||
        transacaoFiltrada[0].transacao_status_id === 5 ||
        transacaoFiltrada[0].transacao_status_id === 6
      ) {
        setAllowMessage(true);
      } else {
        setAllowMessage(false);
      }

      setUserID(resultEC.user_id);

      // setValue('valor', FormatarValor(transacaoFiltrada[0].valor_a_retirar));
      setValue('anos', transacaoFiltrada[0].anos);

      setTransacao(transacaoFiltrada[0]);
      setEstabelecimento(resultEC);
      setLicenciado(resultLA);

      setIsLoading(false);
    }

    requestTransacao();
  }, [idTransacao, setValue, userToken]);

  // let transacao = {};

  /* transacaoCliente.filter((trans) => {
    if(idTransacao === trans.id){
      transacao = trans
    }
  }); */

  // let estabelecimetoNome = '';
  // let idLa = '';
  // let estabelecimento = {};

  /* estabelecimentos.filter((esta) => {
    if(String(esta.id) === String(transacao.estabelecimento_id)){
      estabelecimetoNome = esta.nome_fantasia;
      idLa = esta.licenciado_autorizado_id;
      estabelecimento = esta;
    }
  }); */

  useEffect(() => {
    setStatusColor(String(transacao.transacao_status_id));
    if (
      String(transacao.transacao_status_id) === '3' ||
      String(transacao.transacao_status_id) === '4'
    ) {
      setEditStatusHide(true);
    }
  }, [transacao]);

  /* useEffect(() => {
    const auth = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    async function loadLa() {
      const responseTransacao = await api.get(
        `/licenciado/${idLa}`,
        auth
      );

      setLicenciado(responseTransacao.data.data)
    }

    if(idLa !== '') {
      loadLa();
    }
  }, [idLa, userToken]) */

  const changeStatus = useCallback(() => {
    const selectValue = getValues('status');

    setStatusColor(selectValue);

    if (selectValue === '3') {
      setRequiredMessage(false);

      setAprovadoHide(false);
      setMessageHide(true);
      setConfirmarHide(true);
    }

    if (selectValue === '2' || selectValue === '4') {
      setRequiredMessage(true);

      setMessageHide(false);
      setAprovadoHide(true);
      setConfirmarHide(true);
    }

    if (
      selectValue === '5' ||
      selectValue === '1' ||
      selectValue === '6'
    ) {
      setRequiredMessage(false);

      setConfirmarHide(false);
      setMessageHide(true);
      setAprovadoHide(true);
    }
  }, [getValues]);

  const dataNascimento =
    transacao.data_nascimento &&
    transacao.data_nascimento
      .substring(0, 10)
      .split('-')
      .reverse()
      .join('/');

  const openModal = useCallback(() => {
    const selectValue = getValues('status');

    if (getValues('valor') === '') {
      setError('valor', {
        type: 'manual',
        message: 'Campo obrigatório',
      });

      addToast({
        type: 'error',
        title: 'Erro ao atualizar transação',
        description: 'Você precisa preencher o Valor negociado.',
      });

      return;
    } else {
      clearErrors('valor');
    }

    if (getValues('anos') === '') {
      setError('anos', {
        type: 'manual',
        message: 'Campo obrigatório',
      });

      addToast({
        type: 'error',
        title: 'Erro ao atualizar transação',
        description: 'Você precisa preencher a Quantidade de anos.',
      });

      return;
    } else {
      clearErrors('anos');
    }

    // console.log(selectValue);
    // console.log( transacao.transacao_status_id);

    if (Number(selectValue) === transacao.transacao_status_id) {
      addToast({
        type: 'info',
        title: 'Atualização não efetuada',
        description:
          'Você precisa selecionar um status diferente do atual.',
      });

      return;
    }

    if (selectValue !== '1') {
      setModalOpened(false);
    }

    if (selectValue === '1' && transacao.transacao_status_id !== 1) {
      setModalOpened(false);
    }
  }, [
    addToast,
    clearErrors,
    getValues,
    setError,
    transacao.transacao_status_id,
  ]);

  const onSubmit = async (data) => {
    setIsLoading(true);

    // Bearer token
    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    // console.log(data.status);
    // console.log(statusColor);

    try {
      // Atualiza os dados da transação
      api
        .put(
          `/fgts/status/${transacao.id}`,
          {
            // Dados a ser alterado
            status: statusColor,
          },
          auth
        )
        .then(async (response) => {
          console.log('responseStatus', response);

          if (response === undefined) {
            addToast({
              type: 'error',
              title: 'Erro na atualização',
              description:
                'Estamos com um problema na atualização dessa transação. Tente novamente mais tarde ou entre em contato com a Deixa no Azul.',
            });

            return;
          }

          if (response.data.errors || response.data.error) {
            addToast({
              type: 'error',
              title: 'Erro na atualização',
              description:
                'Algo deu errado, tente novamente ou entre em contato com o Deixa no Azul.',
            });

            return;
          }

          if (data.status === '2' || data.status === '4') {
            // mensagem
            const messageSender = await sendMessage({
              recipient_id: estabelecimento.user_id,
              subject: 'Atualização de status',
              message: data.message,
              transaction_type: 'TransacaoFgts',
              transaction_id: transacao.id,
            });

            // Exige mensagem de erro no envio da mensagem
            if (messageSender.status === false) {
              addToast({
                type: 'error',
                title: 'Erro ao enviar mensagem',
                description:
                  'A transação foi editada mas não conseguimos enviar sua mensagem.',
              });
            }
          }

          addToast({
            type: 'success',
            title: 'Deu tudo certo',
            description: 'Dados atualizados com sucesso.',
          });

          setIsLoading(false);
          history.push('/admin/saque-fgts');
        });
    } catch (error) {
      setIsLoading(false);

      addToast({
        type: 'error',
        title: 'Erro na atualização',
        description:
          'Ocorreu um erro ao atualizar a transação, tente novamente ou entre em contato.',
      });
    }
  };
  const onError = (errors) => {
    // console.log(errors)
    addToast({
      type: 'error',
      title: 'Erro na atualização',
      description:
        'Ocorreu um erro ao atualizar seus dados, tente novamente.',
    });
  };

  // Máscara do valor solicitado
  const inputValueMask = useCallback((e) => {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
    e.currentTarget.value = value;
  }, []);

  async function updateValue() {
    if (getValues('valor') === '') {
      setError('valor', {
        type: 'manual',
        message: 'Campo obrigatório',
      });

      addToast({
        type: 'error',
        title: 'Erro ao atualizar transação',
        description: 'Você precisa preencher o Valor negociado.',
      });

      return;
    } else {
      clearErrors('valor');
    }

    if (getValues('anos') === '') {
      setError('anos', {
        type: 'manual',
        message: 'Campo obrigatório',
      });

      addToast({
        type: 'error',
        title: 'Erro ao atualizar transação',
        description: 'Você precisa preencher a Quantidade de anos.',
      });

      return;
    } else {
      clearErrors('anos');
    }

    const auth = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };

    setIsLoading(true);

    const newValue = Number(
      getValues('valor')
        .replace('.', '')
        .replace('.', '')
        .replace('.', '')
        .replace(',', '.')
    );
    // console.log(newValue);

    try {
      await api
        .put(
          `/fgts/${idTransacao}`,
          {
            valor_a_retirar: newValue,
            anos: getValues('anos'),
          },
          auth
        )
        .then((response) => {
          console.log('responseUpdateValue', response);
          setIsLoading(false);

          addToast({
            type: 'success',
            title: 'Deu tudo certo',
            description:
              'O valor negociado e a quantidade de anos foram atualizados. Prossiga para a mudança do status da transação.',
          });
          // window.location.reload(true);
        });
    } catch (error) {
      // console.log(error.response);
      setIsLoading(false);

      addToast({
        type: 'error',
        title: 'Erro na atualização',
        description:
          'Ocorreu um erro ao atualizar o valor da transação, tente novamente ou entre em contato.',
      });
    }
  }

  return (
    <Container>
      <SendMessageComponent
        userID={userID}
        transactionID={idTransacao}
        transactionType={'TransacaoFgts'}
        allowMessage={allowMessage}
      />

      <LoadingContent isLoading={isLoading} />

      <ModalSection modalOpened={modalOpened}>
        <ModalContent>
          <MdHighlightOff
            size={30}
            onClick={() => setModalOpened(!modalOpened)}
          />
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Aprovado aprovadoHide={aprovadoHide}>
              {tipoTransacao === 'Dados bancários' && (
                <span>Anexar comprovante da transação</span>
              )}

              {tipoTransacao === 'Dados bancários' && (
                <BoxInfo>
                  <span>
                    Você pode anexar até 10 documentos. Após
                    selecionar os documentos, clique em <b>Enviar.</b>
                  </span>{' '}
                  <br />
                </BoxInfo>
              )}

              {aceitarBoletoRejeitado === false ? (
                <span style={{textAlign: 'center'}}>
                  Tem certeza que deseja aprovar essa transação?
                </span>
              ) : (
                <span
                  style={{textAlign: 'center', fontWeight: 'bold'}}
                >
                  Existe um ou mais boleto com o status rejeitado,
                  <br /> deseja salvar a transação como concluída{' '}
                  <br /> mesmo assim?
                </span>
              )}
            </Aprovado>

            <Mensagem messageHide={messageHide}>
              <span>Digite um comentário para o Estabelecimento</span>

              <div className="inputArea">
                <div className="labelRow">
                  <label>Digite aqui</label>
                  {errors.message && (
                    <Error
                      title="Campo obrigatório"
                      style={{marginLeft: '8px'}}
                    >
                      <MdErrorOutline
                        color="c53030"
                        size={20}
                        style={{marginBottom: '8px'}}
                      />
                    </Error>
                  )}
                </div>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  ref={register({required: requiredMessage})}
                  style={{borderColor: errors.message && 'red'}}
                />
              </div>
            </Mensagem>

            <Confirmar confirmarHide={confirmarHide}>
              <span>
                Tem certeza que deseja alterar o status da transação?
              </span>
            </Confirmar>

            <EditarPagamento
              editarPagamentoHide={editarPagamentoHide}
            >
              <span>
                Tem certeza que deseja alterar o comprovante de
                pagamento?
              </span>
            </EditarPagamento>

            <div className="buttonRow">
              {aceitarBoletoRejeitado === false ? (
                <button type="submit">Alterar</button>
              ) : (
                <button
                  type="submit"
                  onClick={() => {
                    // setAprovarTransacaoRejeitada(true);
                  }}
                >
                  Salvar
                </button>
              )}
              <button
                className="cancelar"
                type="button"
                onClick={() => setModalOpened(!modalOpened)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </ModalContent>
      </ModalSection>

      <MenuBar
        active={
          loggedUser.role === 'ROLE_ADMIN'
            ? 'AdminTransações'
            : 'Transações'
        }
        subMenuActive="FGTS"
      />
      <NewMobileMenu
        active={
          loggedUser.role === 'ROLE_ADMIN'
            ? 'AdminTransações'
            : 'Transações'
        }
        subMenuActive="FGTS"
      />

      <Page>
        <TopBar pageTitle="Transação -" pageSubtitle="FGTS" />

        <Content>
          <RowBox1>
            <Box2 style={{}}>
              <h3>Dados do Cliente</h3>

              <Row3>
                <InputArea>
                  <label>Nome</label>
                  <p>{transacao.nome}</p>
                </InputArea>

                <InputArea>
                  <label>Data de Nascimento</label>
                  <p>{dataNascimento}</p>
                </InputArea>

                <InputArea>
                  <label>CPF</label>
                  <p>{transacao.cpf}</p>
                </InputArea>
              </Row3>

              <Row3>
                <InputArea>
                  <label>Telefone</label>
                  <p>{transacao.telefone_sms}</p>
                </InputArea>

                <InputArea>
                  <label>Whatsapp</label>
                  <p>{transacao.telefone_wpp}</p>
                </InputArea>

                <InputArea>
                  <label>E-mail</label>
                  <p>{transacao.email}</p>
                </InputArea>
              </Row3>
            </Box2>

            <div></div>

            {/* Box azul */}
            <div>
              <BlueBox style={{}}>
                <h3>Informações de Pagamento</h3>

                <Row3>
                  <InputArea>
                    <label>Saldo em conta FGTS</label>
                    <p>
                      R$ {FormatarValor(transacao.valor_em_conta)}
                    </p>
                  </InputArea>

                  <InputArea>
                    <label>Valor negociado</label>
                    {editStatusHide ? (
                      <p>
                        R$ {FormatarValor(transacao.valor_a_retirar)}
                      </p>
                    ) : (
                      <InputContainer
                        className="textInput"
                        style={{
                          borderColor: errors.valor && 'red',
                          marginRight: 0,
                        }}
                      >
                        <input
                          name="valor"
                          type="text"
                          placeholder="Digite o valor"
                          // defaultValue={FormatarValor(transacao.valor_a_retirar)}
                          onChange={(e) => inputValueMask(e)}
                          ref={register({required: true})}
                        />
                        {errors.valor && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    )}

                    {/* {!editarValor ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p>R$ {FormatarValor(transacao.valor_a_retirar)}</p>
                        {!editStatusHide && (
                          <button
                            type='button'
                            onClick={() => {
                              setEditarValor(!editarValor);
                            }}
                            style={{
                              display: 'flex',
                              background: 'transparent',
                              border: 'none',
                              padding: 0,
                              marginLeft: 10,
                            }}
                          >
                            <FiEdit color='#FFFFFF' />
                          </button>
                        )}
                      </div>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <InputContainer style={{ borderColor: errors.valor && "red", marginRight: 0 }}>
                          <input
                            name="valor"
                            type="text" 
                            placeholder="Digite o valor"
                            defaultValue={FormatarValor(transacao.valor_a_retirar)}
                            onChange={(e) => inputValueMask(e)}
                            ref={register({ required: false })}
                          />
                          {errors.valor && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
                        </InputContainer>
                        <button
                          type='button'
                          onClick={() => updateValue()}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'transparent',
                            borderColor: '#fff',
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderRadius: 4,
                            height: 40,
                            width: 40,
                            marginLeft: 10,
                          }}
                        >
                          <FiSend color='#FFFFFF' size={20} />
                        </button>
                      </div>
                    )} */}
                  </InputArea>

                  <InputArea>
                    <label>Quantidade de anos</label>
                    {editStatusHide ? (
                      <p>{transacao.anos}</p>
                    ) : (
                      <InputContainer
                        className="textInput"
                        style={{
                          borderColor: errors.anos && 'red',
                          marginRight: 0,
                        }}
                      >
                        <input
                          name="anos"
                          type="number"
                          placeholder="Quantidade de anos"
                          min={1}
                          max={7}
                          // defaultValue={FormatarValor(transacao.valor_a_retirar)}
                          onChange={(e) => inputValueMask(e)}
                          ref={register({required: true})}
                        />
                        {errors.anos && (
                          <Error title="Campo obrigatório">
                            <MdErrorOutline
                              color="c53030"
                              size={20}
                            />
                          </Error>
                        )}
                      </InputContainer>
                    )}
                  </InputArea>
                </Row3>

                {!editStatusHide && (
                  <Row3 style={{marginTop: 20}}>
                    <button
                      type="button"
                      onClick={() => updateValue()}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#FE9902',
                        borderColor: '#FE9902',
                        color: '#fff',
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderRadius: 4,
                        height: 40,
                        // width: 40,
                        // marginLeft: 10,
                      }}
                    >
                      Atualizar valor e ano
                    </button>
                  </Row3>
                )}
              </BlueBox>
            </div>

            <div />

            <div>
              <Box style={{}}>
                <h3>Informações da Transação</h3>
                <Row4>
                  <InputArea>
                    <label>Comissão</label>
                    {/* <p>{transacaoCliente.code}</p> */}
                    <p style={{fontStyle: 'italic', opacity: 0.3}}>
                      Dado não encontrado
                    </p>
                  </InputArea>

                  {/* <InputArea>
                    <label>Cód. da Transação</label>
                    <p style={{ fontStyle: 'italic', opacity: 0.3 }}>Dado não encontrado</p>
                  </InputArea> */}

                  <InputArea>
                    <label>Estabelecimento</label>
                    <p>{estabelecimento.nome_fantasia}</p>
                  </InputArea>

                  <InputArea>
                    <label>Licenciado</label>
                    <p>{licenciado.nome_fantasia}</p>
                  </InputArea>
                </Row4>
              </Box>
            </div>
          </RowBox1>

          <StatusArea editStatusHide={editStatusHide}>
            <form /* onSubmit={handleSubmit(onSubmit, onError)} */>
              <InputContainer2 statusColor={statusColor}>
                <select
                  name="status"
                  placeholder="Selecione"
                  ref={register({required: true})}
                  onChange={changeStatus}
                  value={statusColor}
                >
                  <option value="1">Em análise</option>
                  <option value="6">Em processamento</option>
                  <option value="2">Solicitar documentos</option>
                  <option value="3">Aprovada</option>
                  <option value="4">Cancelada</option>
                  <option value="5">Checagem financeira</option>
                </select>
              </InputContainer2>
            </form>

            <button
              type="button"
              onClick={() => {
                openModal();
              }}
            >
              Salvar
            </button>
          </StatusArea>
        </Content>
      </Page>
    </Container>
  );
}

export default AdminEditarFGTS;
