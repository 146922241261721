import React, { useCallback } from 'react';
// import { FieldError } from "react-hook-form";
import { FiXCircle } from 'react-icons/fi';
import { MdErrorOutline } from 'react-icons/md';
import { useForm } from "react-hook-form";
// import { uniqueId } from "lodash";
// import filesize from "filesize";

// import api from "../../services/api";

// import UploadPagamento from "../../components/Upload/pagamento";
// import InfoPagamento from "../../components/UploadInfo/pagamento";

import { Container,
  ModalContent,
  Title,
  // Subtitle,
  InputArea,
  InputContainer,
  Error,
  // BoxInfo,
  // UploadContainer,
} from './styles';

export function ModalChangeStatus({
  isFocused,
  requiredMessage,
  errors,
  name,
  // register,
  type,
  label,
  placeholder,
  modalTitle,
  modalMessage,
  // inputFocus,
  // inputBlur,
  onCloseModal,
  onSubmit,
  inputValue,
  ...rest
}) {
  // const userToken = localStorage.getItem("@DeixaNoAzul:accessToken");

  const { register, getValues } = useForm();

  // console.log(modalMessage);

  // Upload do Comprovante de pagamento da transação ------------------ START
  // const [uploadedPagamento, setUploadedPagamento] = useState([]);
  // const [progressPagamento, setProgressPagamento] = useState(0);
  /* const [statusPagamento, setStatusPagamento] = useState({
    uploaded: false,
    error: false,
  }); */

  /* // Preparar arquivos para upload
  const submitFileComprovantePagamento = useCallback(
    (file) => {
      const uploadFile = {
        file: file[0],
        id: uniqueId(),
        name: file[0].name,
        readableSize: filesize(file[0].size),
        preview: URL.createObjectURL(file[0]),
      };

      setUploadedPagamento([...uploadedPagamento, uploadFile]);
    },
    [uploadedPagamento]
  ); */

  /* // Remove o arquivo da lista de arquivos
  const handleDeletePagamento = useCallback(
    (id) => {
      const newFiles = uploadedPagamento.filter((termo) => termo.id !== id);

      setUploadedPagamento(newFiles);
    },
    [uploadedPagamento]
  ); */

  // Faz o upload do documento
  /* async function handleUploadToAPI() {
    const data = new FormData();

    uploadedPagamento.forEach((item) => {
      data.append(`image${uploadedPagamento.indexOf(item)}`, item.file);
    });

    if (uploadedPagamento.length === 0) {
      onSubmit({nota_fiscal_id: null})
    } else {
      await api
        .post("/documento_upload_mult", data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + userToken,
          },
          onUploadProgress: (e) => {
            setProgressPagamento(
              parseInt(Math.round((e.loaded * 100) / e.total))
            );
          },
        })
        .then((response) => {
          setStatusPagamento({
            uploaded: true,
            error: false,
          });

          const value = response.data.data;
          setValue("doc_pagamento", value.id);

          // Chama o onSubmit para passar o id para a página pai
          onSubmit({nota_fiscal_id: value.id})
        })
        .catch(() => {
          setStatusPagamento({
            uploaded: false,
            error: true,
          });
        });
    }
  } */

  // Máscara do valor da transação
  const inputValueMask = useCallback((e) => {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    e.currentTarget.value = value;

    /* if (Number(value.replace('.', '').replace('.', '').replace(',', '.')) > 150) {
      addToast({
        type: 'info',
        title: 'Valor da comissão muito alto',
        description: 'Você está inserindo uma comissão maior que R$ 150,00.',
      });
    } */
  }, []);

  return (
    <Container>
      <ModalContent>
        <FiXCircle
          size={30}
          onClick={() => {
            onCloseModal();
          }}
        />

        <Title>
          <span>{modalTitle}</span>
        </Title>

        {/* {modalMessage === 'aprovado' && (
          <Subtitle>
            <span>Anexar a nota fiscal de serviço</span>
          </Subtitle>
        )} */}

        {/* {modalMessage === 'aprovado' && (
          <BoxInfo>
            <span>
              Você pode anexar até 10 documentos. Após selecionar os
              documentos, clique em <b>Enviar.</b>
            </span>{" "}
            <br />
          </BoxInfo>
        )} */}

        {/* {modalMessage === 'aprovado' && (
          <InputArea className="campoAnexo">
            <UploadContainer
              style={{ borderColor: error.doc_pagamento && "red" }}
            >
              <UploadPagamento
                onUpload={submitFileComprovantePagamento}
                isDisabled={uploadedPagamento.length === 10 ? true : false}
                isWhite={false}
              />
              <input
                type="text"
                name="doc_pagamento"
                ref={register}
                hidden
              />
              {error.doc_pagamento && (
                <Error title="Campo obrigatório">
                  <MdErrorOutline color="c53030" size={20} />
                </Error>
              )}
            </UploadContainer>
            
            <div
              className="upload"
              style={{ height: uploadedPagamento.length <= 1 ? '80px' : '130px' }}
            >
              {!!uploadedPagamento.length && (
                <InfoPagamento
                  files={uploadedPagamento}
                  percent={progressPagamento}
                  status={statusPagamento}
                  onDelete={(id) => handleDeletePagamento(id)}
                  isBlack={false}
                />
              )}
            </div>
          </InputArea>
        )} */}

        {modalMessage === 'mensagem' && (
          <InputArea>
            <label style={{ display: label === undefined ? 'none' : 'block' }}>{label}</label>
            <InputContainer style={{ borderColor: errors && "red" }}>
              <textarea
                ref={register}
                type={type}
                name={name}
                id={name}
                placeholder={placeholder}
                cols="30"
                rows="10"
                {...rest}
              />
              {errors && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>}
            </InputContainer>
          </InputArea>
        )}

        {/* Valor da comissão */}
        {modalMessage === 'aprovado' && (
          <InputArea>
            <label>Valor da comissão</label>
            <InputContainer>
              <input
                defaultValue="150,00"
                name="valor_comissao" 
                type="text" 
                placeholder="Comissão do LA ou Revenda" 
                ref={register}
                onKeyUp={(e) => inputValueMask(e)}
              />
              {/* {errors.valor_comissao && <Error title="Campo obrigatório"><MdErrorOutline color="c53030" size={20} /></Error>} */}
            </InputContainer>
          </InputArea>
        )}

        <div className="buttonRow">
          <button
            type="button"
            onClick={() => {
              modalMessage === 'aprovado'
                ? onSubmit({comissao: getValues("valor_comissao")})
                : onSubmit({message: getValues(name)});
            }}
          >
            Enviar
          </button>

          <button className="cancelar" type="button" onClick={() => onCloseModal()}>Cancelar</button>
        </div>
      </ModalContent>
    </Container>
  );
};
